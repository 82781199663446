import React, { useMemo } from "react";
// import { TextProps as TextPropsOriginal } from "rebass";
import {
  createGlobalStyle,
  css,
  DefaultTheme,
  ThemeProvider as StyledComponentsThemeProvider,
} from "styled-components/macro";

import { Colors } from "./styled";

export * from "./components";

// type TextProps = Omit<TextPropsOriginal, "css">;

export const MEDIA_WIDTHS = {
  upToExtraSmall: 500,
  upToSmall: 720,
  upToMedium: 960,
  upToLarge: 1280,
};

// Migrating to a standard z-index system https://getbootstrap.com/docs/5.0/layout/z-index/
// Please avoid using deprecated numbers
export enum Z_INDEX {
  deprecated_zero = 0,
  deprecated_content = 1,
  dropdown = 1000,
  sticky = 1020,
  fixed = 1030,
  modalBackdrop = 1040,
  offcanvas = 1050,
  modal = 1060,
  popover = 1070,
  tooltip = 1080,
}

const mediaWidthTemplates: {
  [width in keyof typeof MEDIA_WIDTHS]: typeof css;
} = Object.keys(MEDIA_WIDTHS).reduce((accumulator, size) => {
  // eslint-disable-next-line @typescript-eslint/no-extra-semi
  (accumulator as any)[size] = (a: any, b: any, c: any) => css`
    @media (max-width: ${(MEDIA_WIDTHS as any)[size]}px) {
      ${css(a, b, c)}
    }
  `;
  return accumulator;
}, {}) as any;

const white = "#FFFFFF";
const black = "#000000";

function colors(darkMode: boolean): Colors {
  return {
    darkMode,
    // base
    white,
    black,

    // font-family
    font_weight_5: "Gilroy-Medium",
    font_weight_7: "Gilroy-Bold",
    font_weight_9: "Gilroy-ExtraBold",

    // font-size
    size12: "12px",
    size14: "14px",
    size16: "16px",
    size18: "18px",
    size20: "20px",
    size24: "24px",
    size48: "48px",

    // text
    text1: darkMode ? "#FFFFFF" : "#141414",
    text2: darkMode ? "rgba(255, 255, 255, 0.75)" : "#565A69",
    text3: darkMode ? "rgba(255, 255, 255, 0.7)" : "#565A69",
    text4: darkMode ? "rgba(255, 255, 255, 0.54);" : "#6E727D",
    text5: darkMode ? "rgba(255, 255, 255, 0.5);" : "#6E727D",
    text6: darkMode ? "rgba(255, 255, 255, 0.16)" : "#C3C5CB",
    text7: darkMode ? "#B9B9B9" : "#PIKOEF2",
    text8: darkMode ? "#8F92A1" : "#PIKOEF2",
    text9: darkMode ? "rgba(255, 255, 255, 0.87)" : "",
    text10: darkMode ? "#21B8E8" : "",
    text11: darkMode ? "#0A0A0A" : "#PIKOEF2",

    // backgrounds / greys
    bg0: darkMode ? "#353535" : "#FFF",
    bg1: darkMode ? "rgba(255, 255, 255, 0.05)" : "#F7F8FA",
    bg2: darkMode ? "rgba(255, 255, 255, 0.1)" : "#PIKOEF2",
    bg3: darkMode ? "rgba(255, 255, 255, 0.08)" : "#CED0D9",
    bg4: darkMode ? "rgba(255, 255, 255, 0.16)" : "#888D9B",
    bg5: darkMode ? "rgba(255, 255, 255, 0.2)" : "#888D9B",
    bg6: darkMode ? "#242424" : "#6C7284",
    bg7: darkMode ? "#282828" : "#6C7284",
    bg8: darkMode ? "rgba(0, 0, 0, 0.2)" : "#6C7284",
    bg9: darkMode ? "rgba(0, 0, 0, 0.25)" : "#6C7284",
    bg10: darkMode ? "#00453A" : "#6C7284",
    bg11: darkMode ? "#161616" : "#6C7284",
    bg12: darkMode ? "#101010" : "#6C7284",
    bg13: darkMode ? "#313131" : "#6C7284",

    //specialty colors
    modalBG: darkMode ? "rgba(0,0,0,.425)" : "rgba(0,0,0,0.3)",
    advancedBG: darkMode ? "rgba(0,0,0,0.1)" : "rgba(255,255,255,0.6)",

    //primary colors
    primary1: darkMode ? "#14D1B2;" : "#FFB405",
    primary2: darkMode ? "#3680E7" : "#FFB405",
    primary3: darkMode ? "#4D8FEA" : "#f7bd36",
    primary4: darkMode ? "#376bad70" : "#FFB405",
    primary5: darkMode ? "#153d6f70" : "#e0f7fa",

    // color text
    primaryText1: darkMode ? "#14D1B2" : "#FFB405",
    primaryText2: darkMode ? "#FFD166" : "#FFB405",
    primaryText3: darkMode ? "#EDD068" : "#FFB405",
    primaryText4: darkMode ? "#FFD100" : "#FFB405",
    primaryText5: darkMode ? "#14D1B2" : "#FFB405",
    primaryText6: darkMode ? "#FFD166" : "#FFB405",

    // secondary colors
    secondary1: darkMode ? "#2172E5" : "#FFB405",
    secondary2: darkMode ? "#17000b26" : "#FF4905",
    secondary3: darkMode ? "#17000b26" : "#FDEAF1",

    // other
    red1: darkMode ? "#FF4343" : "#DA2D2B",
    red2: darkMode ? "#F82D3A" : "#DF1F38",
    red3: "#D60000",
    green1: darkMode ? "#14D1B2" : "#007D35",
    yellow1: "#FFD166",
    yellow2: "#FF8F00",
    yellow3: "#F3B71E",
    blue1: darkMode ? "#2172E5" : "#0068FC",
    blue2: darkMode ? "#5199FF" : "#0068FC",
    error: darkMode ? "#FD4040" : "#DF1F38",
    success: darkMode ? "#27AE60" : "#007D35",
    warning: "#FF8F00",

    // dont wanna forget these blue yet
    blue4: darkMode ? "#153d6f70" : "#C4D9F8",
    // blue5: darkMode ? '#153d6f70' : '#EBF4FF',
  };
}

function theme(darkMode: boolean): DefaultTheme {
  return {
    ...colors(darkMode),

    grids: {
      sm: 8,
      md: 12,
      lg: 24,
    },

    //shadows
    shadow1: darkMode ? "#000" : "#2F80ED",

    // media queries
    mediaWidth: mediaWidthTemplates,

    // css snippets
    flexColumnNoWrap: css`
      display: flex;
      flex-flow: column nowrap;
    `,
    flexRowNoWrap: css`
      display: flex;
      flex-flow: row nowrap;
    `,
  };
}

export default function ThemeProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  // const darkMode = useIsDarkMode();

  const themeObject = useMemo(() => theme(true), []);

  return (
    <StyledComponentsThemeProvider theme={themeObject}>
      {children}
    </StyledComponentsThemeProvider>
  );
}

export const ThemedGlobalStyle = createGlobalStyle`
html {
  color: ${({ theme }) => theme.text1};
  background-color: ${({ theme }) => theme.bg1} !important;
  font-family: "Gilroy";
  line-height: 150%;
  }

  
.container {
  width: 1280px;
  max-width: 100%;
}

.flex-1 {
  flex: 1;
}

.underLine {
  border-bottom: 1px dashed rgba(255, 255, 255, 0.5);
}

.pointer {
  cursor: pointer;
}

.text-right {
  text-align: right;
}

.text-center {
  text-align: center;
}

button{
  :hover {
    :disabled {
      background: #06342B!important;
      color: rgba(255,255,255,0.38)!important;
    }
  }
  :disabled {
      background: #06342B!important;
      color: rgba(255,255,255,0.38)!important;
    }
}

@media only screen and (max-width: 1480px) {
  .container {
    width: 1140px;
    padding: 0 16px;
  }
}

@media only screen and (max-width: 1380px) {
  .container {
    width: 1080px;
  }
}

@media only screen and (max-width: 1280px) {
  .container {
    width: 100%;
  }
}

@media only screen and (max-width: 680px) {
  .container {
    padding: 0 12px;
  }
}
`;
