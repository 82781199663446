interface IAddresses {
  [key: number]: { [key: string]: string };
}

export const addresses: IAddresses = {
  42161: {
    NATIVE_TOKEN: "0x82aF49447D8a07e3bd95BD0d56f35241523fBab1",
    WETH: "0x82aF49447D8a07e3bd95BD0d56f35241523fBab1",
    ETH: "0x82aF49447D8a07e3bd95BD0d56f35241523fBab1",
    BTC: "0x2f2a2543B76A4166549F7aaB2e75Bef0aefC5B0f",
    USDC: "0xaf88d065e77c8cC2239327C5EDb3A432268e5831",
    ARB: "0x912CE59144191C1204E64559FE8253a0e49E6548",
    pyth: "0xff1a0f4744e8582DF1aE09D5611b887B6a12925C",
    VaultPriceFeed: "0x6d2a92F065728Bca065512316Fad32064B2868D1",
    PIKO: "0xB3C05aC1F7627be11D0294840Ce0d98341155425",
    esPIKO: "0x32e1A7f45aae68fc853E8FD169e3A95f991980BE",
    RewardRouter: "0x735ABD6A24B66dEBfafb41919759065D9dA9ae16",

    PID: "0x37C0D8470758740436697FB4a131D0C84cC0997b",
    PIDLogic: "0xdba38a74216D494F833C70B6131780b1383c54b5",
    FeeRouter: "0x1E11Fc9f0E39554D11d548aCe8593a13e96E77A3",
    UserFeeResv: "0x00554ED190B2bF720bFC017A51dEE160Ab0e3517",
    InviteToEarn: "0x74CE9a32f96a14DA75B7B45a51aE294A4CCE5eae",
    TradeRebate: "0x466E322b56436BA0BC540F63fD6bb0f9097aB2F3",
    BurnToScore: "0xabD7AAf88897fE623015A107C881812bE0fCBD59",
    InfoHelper: "0xd86DD2400EDDC864aFC1ABb8B692cf119106F3a5",

    Treasury: "0xD667f01F7095839eb85DfA700d9a861195c55eDd",
    Reader: "0x7DD192C8E49e0352ab3A22DD06606B10dD6Ea5F5",
    VaultReader: "0xD3dcf52d11243cdDD58d09A50aB03Dd5D6E260a3",
    PositionReader: "0xF5df648b3a09aed4238319A2F3cA3B370CE2F0Bf",
    PLP_β: "0xCC92BA7323ede9A03638AD586A1Ec2A8dd699999",
    Vault_PLP_β: "0x5a8C24c486183AD60b94A23173336a487C014970",
    VaultUtils_PLP_β: "0x884D3fb5443846A406b972219D8AD2BbeCBE875c",
    VaultUtilsSup_PLP_β_1: "0xca512Ee54396a7FCB69334023c18bf61e8DECFdD",
    VaultUtilsSup_PLP_β: "0x061d1AD15C74928967A25664F3CA1F589ee12eC0",
    VaultStorage_PLP_β: "0x613Ca4d66B83bF03C879af603C956Cf094fDB7e5",
    OrderBook_PLP_β: "0x2A782f37267b2c0e400A80213e0d2321cdD2e98b",
    PlpManager_PLP_β: "0x9A60569D2b2095ccB65aCE2054169eeF31Bbb314",
    RewardPool_PLP_β: "0x9F21a01224772918CB5fa832fA8Fa9F57C6459c3",
    RouterSign_PLP_β: "0xE6FaFd8C81A84dda04394F511B4827cAA3A9Bd1a",
    TradeStorage: "0x92d3881631A8A8806b0555df75733A72c28aA357",

    vePIKO: "0x68127faBccc09e50642e19F3003cC48d00a737eb",
    veesPIKO: "0xBC9fCb7d59eD6Bf3B65044313174F8D08DB667bc",
    totalVeToken: "0x81533A921f33b769C37DddD9Ab83ea8E244223bF",
    DisToken: "0xfa54456934897f73CAA04E82F8666FaC1d671c82",
    DisFee: "0x928F1e49B31aF6316fAE1A51358aeFb73C7cd42a",
    vePIKO_stakeHelper: "0x176a896468e384385624350D234937D2FFF0710A",
    veesPIKO_stakeHelper: "0xEAfd2Cd252344DD8c8f4c3416A29C77d7E6A9cf5",
    randomSource: "0xF9E2CB74f135554b1fcAeBe9A7874ff6889a5DeB",
    NFTUtils: "0x91cB3D85Bea6d2C8c8A89c1064696c4326A0c766",

    TokenVesting: "0x0000000000000000000000000000000000000001",
    iPIKOVesting: "0x0000000000000000000000000000000000000001",
    Deployer: "0x0000000000000000000000000000000000000001",
  },
  421613: {
    NATIVE_TOKEN: "0xEe01c0CD76354C383B8c7B4e65EA88D00B06f36f",
    WETH: "0xEe01c0CD76354C383B8c7B4e65EA88D00B06f36f",
    BTC: "0x16Fa53e7C585377Eb020622B73FbACBa88ba3a2b",
    USDC: "0x470AC774bb8327Fde0fa0565e2680540B28A3341",
    USDT: "0x50aAF50E4DA16aa5b1F12eAF48a62d093CAfd61E",
    DAI: "0x0c9dF6B22DcC519B7e1073B2C3199B69E1994a59",
    ARB: "0xDbc943574967dB5B31566bb196D81A480Bc35eEc",
    OP: "0xd3c5258C1f7c929631573f03aA79BFdB7AE2d453",
    BNB: "0xcFe8416fDE583B14BFbb40B4d0913a6fcEe57076",
    SOL: "0x5118c155d2451baDa831B00FB42341e8B73F072E",
    SUI: "0xf80f925c2A27c2E1D87F227691FEa894180abc38",
    MATIC: "0xF12b2E4c915eFaDEA572dF035c7ed26A0Dc28eba",
    PEPE: "0x503ba7f5FDeCDEc23a7562B53D515648eD05EeA0",
    DOGE: "0x9ec8F26f7bfFcf5390aAF4AEBd69561d46f3e79a",

    PIKO: "0x0E172Fa24C2C8c2cbd4C2503f29f4499F4e3518B",
    esPIKO: "0x513Ecfa44bee41b4f786d53275aFb81f32255337",
    RewardRouter: "0x9b84Ba9F82B9D6A66F30D0A92DB16af3f8467511",
    randomSource: "0xC75e4e783Ed2A8cC547315E2A5EB42fef325F8f7",
    NFTUtils: "0x86eB13ca9cb8F9Ca5c8bE8648323193B422FC940",
    PID: "0xc1105d954236234150098AC7fe03A0Bc8Ca7F963",
    PIDLogic: "0xabDb16748CB8E571eD6Ea23084FbB8C826c81319",
    FeeRouterCb: "0x5d53b87F22fC7727364f60E1CbC0f4d434f40847",
    UserFeeResv: "0x0Db1a29EB5E498f715c1232B2045873f576534aC",
    InviteToEarn: "0xEe4C237C0fcd39786f386Accc5Df5bB215A34bC2",
    TradeRebate: "0xbbEAe7a4b5e98AEbD93D20D158C8e1484ea2b894",
    BurnToScore: "0x3E3D5f8fA4159B7dA10C960EC0A24Ff5464784fF",
    InfoHelper: "0x2B58A1eE69F636649ca37F2706521E53bF8f24a6",
    Treasury: "0xf6Bee490960E5279b9a2dF92445Ccb948c59d22B",
    Reader: "0xd6E6f55A205c3C54d605FD0c8f5B49213377E70F",
    VaultReader: "0x0906B3415c1B76ccdd05948b5Ad5c9b0e6D8EF9C",
    PositionReader: "0x148Cfb0B236860A9DC4dd654afdb87474dE7CBdc",

    PLP_β: "0x382503435A6f33D9878D0f52A6742cbD87da9154",
    Vault_PLP_β: "0x433dFa05dD9c1774654211a38FEa52656A193ea4",
    VaultUtils_PLP_β: "0xA7efb7Ba5FA639EDD579a5d9E7B7b3c32894e60e",
    VaultUtilsSup_PLP_β: "0xeA218a90eFcD89a7dAb02a2aff8cd9E84e629929",
    VaultStorage_PLP_β: "0x4cf1135b4671B44788A68D96721C2D9F5e8b4fBb",
    TradeStorage_PLP_β: "0x9fc330e1cDA613E0c5fAB1471A4D2aAB4afB1647",
    OrderBook_PLP_β: "0xf5614A0531fFeD96fd82E0Ef83d20f47cB9758cB",
    PlpManager_PLP_β: "0xa0290725AdD3D699200D037f9894A76d1D2a7855",
    RewardPool_PLP_β: "0x23eCD379Fd47D193f5D30C5334A780e085efc9fD",
    RouterSign_PLP_β: "0xd5A115949272C49d1E85FE430A8F0e219c206bf3",
    FeeRouter_PLP_β: "0xA1868C7797EC024693697bE8E6EFE2bF708594f9",

    PLP_α: "0x0BC7541dC1c9FA31aB27d926ee9274Fd5BB14725",
    Vault_PLP_α: "0x8DF0E063a71e866Ce9eF2864d6bb1e5078Ee8F8a",
    VaultUtils_PLP_α: "0x6d7474b7a79c57306Ed99Fa4CDB86B57f4db45F9",
    VaultUtilsSup_PLP_α: "0x91f58bC29Ba7e7f6a92F1942fFcD219d4d183bcf",
    VaultStorage_PLP_α: "0xf5bf7752783a012104eA80C910825dbC410B08ba",
    TradeStorage_PLP_α: "0x602945AdB097E94F27a79d64902f32121868357E",
    OrderBook_PLP_α: "0x656B3CB22431E29273748083F2BfB1F91EF1e4ae",
    PlpManager_PLP_α: "0x950038a90c605a086324416884fF605010610cD3",
    RewardPool_PLP_α: "0xC5472598Dd4722718944b7dbc8f5EeC19E593aB9",
    RouterSign_PLP_α: "0x7311a27b132594fDEB8Ee8971ceFbAB7afF3B039",
    FeeRouter_PLP_α: "0x34797f67f4142c7Bb85D8471B51BeD29420F57d6",

    vePIKO: "0x434C32bD3b5AF973cAd1C360d14F8b5e866C8D57",
    veesPIKO: "0xF67c4AffE8d22097e9BA50B5C38BC2a271008982",
    totalVeToken: "0x396faDedCe747F724eeb1AE6673002a756b307F1",
    dis_token: "0xdFEfc24E2F1000e50Da61FD4A8e34657805BAEd9",
    dis_fee: "0x119cB4012439216bE35277E0d7384092BD2581A8",
    vePIKO_stakeHelper: "0x060F47F98e15363EE9169C70c606D3755b6433d0",
    veesPIKO_stakeHelper: "0x5Aa11d5968437213fc5BA423219336474675442c",

    TokenVesting: "0x8910C5737ea102211Fd912423a06c858F19C1990",
    TokenVestingFix: "0xADCd32F490246A6923ca82AaA7F39875adc7C8B0",
  },
  280: {
    NATIVE_TOKEN: "0x33F1FBe337A19BebCa41a3DCbA896752729286Ea",
    WETH: "0x33F1FBe337A19BebCa41a3DCbA896752729286Ea",
    ETH: "0x33F1FBe337A19BebCa41a3DCbA896752729286Ea",
    BTC: "0xF3F37c9923F8AcDe2CACD81AfE985fd7b279bE16",
    USDT: "0x0519112Ef3720a2f8bBC4587dd38773B0D013eE9",
    USDC: "0xC64101683Af0CcA47c38d3Ab0dA2969aEb0833bB",
    DAI: "0xeA1d91484A51769e444fB13f8dCB8F419817A7Ea",
    VaultPriceFeedV3Fast: "0x3198463F1FA4737d21d73EffC888f058788d43CA",
    //TOKENS
    PIKO: "0x40073eA329079b4e89EbaDC52D21479Db792d854",
    esPIKO: "0xbE2bA2d42080561D709C5548DA58f44CdB5e3D46",

    // Common Utils
    ServerPriceFeed: "0x937fac9Dfb2C8d510A0F116cDa8d5A72D5F00070",
    VaultPriceFeed: "0x9cdBe190e67BFa143671782CADE6ac84a07C88fa",

    //Reader
    OrderBookReader: "0x348CF6C92cB74230c126dfAcd2639BDeB3904Bbc",
    VaultReader: "0x1C523C6B86F2Aa638d13cB482ebe8936987a4992",
    Reader: "0x3453CC80e6D10Fa5defCF38850F9624C9c672365",
    PositionReader: "0xf05B29ef48c431801d7d528b7D746c8fF8D05b18",

    // fee
    RewardRouter: "0x73e9662488d351A56269089FD472951ECDe318A9",
    FeeRouter: "0x62548BF26Fb4778d362c8C5A5b271966b1F83d63",
    UserFeeResv: "0x1Eb0552D1A5165cC535779C0DCCe64ce7961F626",

    //DAO
    Treasury: "0xf0425A397Fa8E7740192eC368C773E899E082E49",
    TreasuryResv: "0x5cC8099F4c24133389F67A6FcCb86187795abe12",
    InstVesting: "0x18A203cB32318F407753bAB108A2AC6e92e9ACf4",
    Bond: "0x07639d69566b7a85b218e1f9d71f35681D1F0483",

    // PID
    PID: "0x18d49A38d7C16F2Fa6CeBe9942A8774359DddE53",
    PIDLogic: "0x2FE30C090CEDd843536f966A10bEda81c06a8245",
    InfoHelper: "0xac71e8E20F086c7959ecc9584c86b1642dcaD488",

    //PLP
    PLP_β: "0xeB855F21C3eCe7C7725e3a0B0cFd22D7FCBEF633",
    Vault_PLP_β: "0x4E7adB565c59ff67ed91F0E29b1f55fc16240868",
    VaultUtils_PLP_β: "0x353258d8d2F30632461b1cAeeAd9e044d8FA8690",
    VaultStorage_PLP_β: "0x223E69cf3C088fCe56e790EC25Cdc3a6B262b961",
    RouterSign_PLP_β: "0x8B85403746C23BC83B461ED931904C34C0DAA107",
    PlpManager_PLP_β: "0xDE38bb72674834dffBC6010f93ee556065dB6811",
    RewardPool_PLP_β: "0xC9fE797702779f9276C61F72BBb99CF47ccEB7Fa",
    OrderBook_PLP_β: "0x4A8CcA9365d15C860185a713b81d7Ee3969BE371",
    PositionRouter_PLP_β: "0x5Ee2E6B209890AAdC0A7bcf58DDc64DA096a6B13",

    // Activity
    BurnToScore: "0x732E5d40a5C17A82505bA9DA6333749a3C795c57",
    InviteToEarn: "0xF62df9a75a08c8Ea5fbBAfE7dA4B10E414E4e1D3",
    TradeRebate: "0xd70819a5f3A11CA2563209988220d83B4787d281",
    TradeStorage: "0xDBCc6F9FbFC69F88eC0539ae34a18E3eC5b6dc39",

    // TestOnly

    TwapPrice: "0xFF9fd97D5EBb1085ff6f6e12f0ada919D4696aa0",
    faucet: "0xC0a0CD009d2E2B5b6644ED3031573a8e71EfA019",

    // Contract
    randomSource: "0xb15E20d68437A57b0536CE15dE4781a1E47a40A7",
    NFTUtils: "0xB8b6BCD98Bc6c453EaE071FBA9D1C177b194448F",
    Timelock: "0x1749D555a90b4F62f02510BA86170Ca7a6Da3855",

    // stake espnk and pnk
    vePIKO: "0xBa75F8c842F6b88545267A19127d4A9008c8cF60",
    veesPIKO: "0xF3b5330dA2CBf66cD593C0452ec077fC3c0D8937",
    totalVeToken: "0xBE05deD6b24F45Ca8Cfd856b56a321FFF8A3284c",
    vePIKO_stakeHelper: "0xd9A4019607407EdAab40DaBf13185e2c1dfb5Fa6",
    veesPIKO_stakeHelper: "0xa757cE1974c80498E0089ccBACc45814ADf7cb85",
    TokenVesting_old: "0xD9959A6E5883E08D43B51Fd85576B9d88bcdd1cC",
    TokenVesting: "0xc54E8CaC2deA249ff09E83850691ABCb0547d572",
    DisFee: "0x12433725614EEF7993b7718FD2841C3e91Ef7F17",
    DisToken: "0x92CbD7F5E2bd1D965ADE0eB31AA2D87E515d8E0D",

    LpManager: "0x8840C0B6D29f43D1e22bdd67d162a2Fc63B8023c",
    PLP: "0x7a6Ed37C4690F930640F92379FD2Be7fD32f6e03",

    Vault: "0x07da10649555608fA24a450F518e730E223FbAB9",
    VaultUtils: "0xA7712B6845051BB3be21e1995253664cd80dA7Ed",
    VaultStorage: "0x2eAb3B4dB41a9D970b59459490d0e6BB0Db20B6F",
    RouterSign: "0x302c60E802848fE1C8dfc89AbA3C3eeEBaCe3F9F",
    PlpManager: "0x603C9F8a7c807D6e8EEfd6875678971B59ad4491",
    Router: "0x7E923De39CC56Ee26030D7406e1B766F4c668Adf",
    PositionRouter: "0x3fC60D96a3735Bdd433DA41Aa978F55C30F29904",
    RewardTracker: "0x98b63F268A3D03BB4B9B1E75206A30d1e0416382",
    OrderBook: "0x44111682420F634dBA24778271914C3357299Ab8",
    PositionManager: "0x5aAAE46594D4f0518E03DEa1b6D4D291B4de38AF",

    PnkLp: "0x566Ec8678bCC6A483Adb6D7615c3939cA191348C",

    PositionManager_PLP_β: "0xcBf52AeC88446FFB2dbd4ADbB27d4A6468ef2f78",
    aPIKO: "0xB024210aDdBf311407795924b3C68Ba0A282b22C",
    PUSD: "0xBf3BCc95a72ec5aeAFA7c88fe776F4d6dC6fb068",
    LP_Farm: "0x0000000000000000000000000000000000000001",
    LP_Marketing: "0x0000000000000000000000000000000000000001",
    Contracts_Reserve: "0x0000000000000000000000000000000000000001",
    PIKOStaking: "0x0000000000000000000000000000000000000001",
    IDO: "0x0000000000000000000000000000000000000001",
    PIKO_burn: "0x0000000000000000000000000000000000000001",
    PIKO_LP: "0x0000000000000000000000000000000000000001",
    PIKO_DAO: "0x0000000000000000000000000000000000000001",
    dis_token: "0x0000000000000000000000000000000000000001",
    pikoLpToken: "0x0000000000000000000000000000000000000001",
  },
  324: {
    NATIVE_TOKEN: "0x5AEa5775959fBC2557Cc8789bC1bf90A239D9a91",
    WETH: "0x5AEa5775959fBC2557Cc8789bC1bf90A239D9a91",
    ETH: "0x5AEa5775959fBC2557Cc8789bC1bf90A239D9a91",
    WBTC: "0xBBeB516fb02a01611cBBE0453Fe3c580D7281011",
    BTC: "0xBBeB516fb02a01611cBBE0453Fe3c580D7281011",
    USDC: "0x3355df6D4c9C3035724Fd0e3914dE96A5a83aaf4",
    USDT: "0x493257fD37EDB34451f62EDf8D2a0C418852bA4C",

    // Oracle
    pyth: "0xf087c864AEccFb6A2Bf1Af6A0382B0d0f6c5D834",
    ServerPriceFeed: "0xfCe076F9fA38aa3a947808a66F0f877687A18884",
    VaultPriceFeed: "0xA42d35b46BF1621535a36dA1aFEA3bfB57Bc2b64",
    // Tokens
    esPIKO: "0xCaa5ba70830f8557fd6516Cf632b08AA4B6A528F",
    PIKO: "0xf8C6dA1bbdc31Ea5F968AcE76E931685cA7F9962",

    //  Common Utils
    randomSource: "0x4d804D35093FAE18116398207ca098a1A70414B0",
    NFTUtils: "0xe7746E06E61B65cCBc3CC0f48a2eD6Cc21C1bF2a",

    // Fee
    RewardRouter: "0xAbeAe8991e59508b999eb67E6a06500377552d31",
    FeeRouter: "0x86989DE7cE37Eb0DFC7b0Aa5Ae64fb165C31b341",
    UserFeeResv: "0x5E1796B05b717f7D4Cb4BA180D430D2F334C1B54",

    //DAO
    Treasury: "0xCED97A84296CBcAD13A3539EAda64aaD5F57AB37",

    // PID
    PID: "0x56Cb97749ABCEff4676736DBe7cD18046133f027",
    PIDLogic: "0x7D3Ea4Efe6459d6a030E65EEE9C1Ab0c636C9044",
    InfoHelper: "0x6092aff4Aa8F4BcD47AB67a3891dF1e48627D5BB",

    // Reader
    VaultReader: "0x6d0e00CD82dCe746953427c814C1d31CEe4f6B34",
    Reader: "0x83ECBdB3D837f0449A822A9A8649a87D80571136",
    PositionReader: "0xc35167183C56d88dDBC4864F73b7aE06205485c2",

    // plp

    PLP_β: "0x8FdF5A1880832e9043Ce35B729A1e6C850b09b23",
    Vault_PLP_β: "0x87A43dfAB5068c9Ae2f75da2906559bc9A71b42d",
    VaultUtils_PLP_β: "0x6BcFFd9d155338A7f2736f72dE07de9f5C333c01",
    VaultStorage_PLP_β: "0x33579126BF9B42113BF69783c1c307e04b15EA14",
    RouterSign_PLP_β: "0x3525956A1e40a7B5d252aEB2a8ab2334A0ACee47",
    PlpManager_PLP_β: "0xa5D4263E56C17fe33c565f40f183C3F222206744",
    RewardPool_PLP_β: "0x536D092230A3372030a63414f5932CAD74fC9Db6",
    OrderBook_PLP_β: "0x6483b77AC97300ef90804dECC0d370Fe5D668e62",
    PositionRouter_PLP_β: "0xf68e3F71691335525e4275B1f0cc89AA3477a384",
    FeeRouter_PLP_β: "0x86989DE7cE37Eb0DFC7b0Aa5Ae64fb165C31b341",

    // Activity
    BurnToScore: "0x08601c93b3bf68040d0df1efdEb547E0f17A41F9",

    // Contract
    vePIKO: "0x2A283C805D11ad77161Be0c503805a2b8Bc7Fd84",
    veesPIKO: "0x4203511B63F3D3ac6dEA18cA9d1f249f11EC9C4D",
    totalVeToken: "0xdbD9Bf16Dc549Dc0272ABFF1670Fb6E658EcCb20",
    vePIKO_stakeHelper: "0xba07A87D6C735F58880CDE0fcEb96e1832db6362",
    veesPIKO_stakeHelper: "0x9Dc63f91076f2aA0e5500Ef59C51fe91C77A1319",
    TokenVesting_old: "0xc1fd08878cD9c7615B72378830032a93478c4cF0",
    TokenVesting: "0xD2253b402Ef28eae3213Db958C438bc60BE6Ebe1",
    DisFee: "0x609419Cd1d067E80b82F2253424CFdDF51121E9e",
    DisToken: "0x7750488C6cB42Fb6074CdaC69F103596aC5F094c",

    // end

    // Volume in circulation
    iPIKOVesting: "0x2BE011A5806Db4aB889BE527d06F5aEB0f797FbC",
    Deployer: "0x866ED307fdf982A193d259fbb08ffbeB1562aEF6",

    aPIKO: "0xB024210aDdBf311407795924b3C68Ba0A282b22C",
    PUSD: "0xBf3BCc95a72ec5aeAFA7c88fe776F4d6dC6fb068",

    PnkLp: "0x566Ec8678bCC6A483Adb6D7615c3939cA191348C",
    TwapPrice: "0x9ca2d020BeAaE5dF204b9CdE5Ae37CC20D8175b0",
    TreasuryResv: "0xf521b5dba6FF0d513eC08Fe9eF6a46bE90Cc9a8e",
    InstVesting: "0x8b34cDEde186d59Bdfa3a67e005568D6f45e7261",
    Bond: "0x56a4Aef7579dE9Ed7328eC91eBeB8A7eb0AF75bF",

    LP_Farm: "0x0000000000000000000000000000000000000001",
    LP_Marketing: "0x0000000000000000000000000000000000000001",
    Contracts_Reserve: "0x0000000000000000000000000000000000000001",
    PIKOStaking: "0x0000000000000000000000000000000000000001",
    IDO: "0x0000000000000000000000000000000000000001",
    PIKO_burn: "0x0000000000000000000000000000000000000001",
    PIKO_LP: "0x0000000000000000000000000000000000000001",
    PIKO_DAO: "0x0000000000000000000000000000000000000001",
    dis_token: "0x0000000000000000000000000000000000000001",
    pikoLpToken: "0x0000000000000000000000000000000000000001",

    Pool_izumi: "0x0060CAC5C52bCd21446a677e02f02a6e85aE9535",
    InviteToEarn: "0x0A625190D47da9F0746b40Cc08fEE10bF31cd752",
    TradeRebate: "0xc65Aa8652bde4C777D97e3D21c8a02dca5c8fAEf",
    TradeStorage: "0x83Ca28a63FAFCE34b68Aae4F852EE1B23Beab696",
  },
  5001: {
    NATIVE_TOKEN: "0x1236dc14efb42081d109037e60030c54da25b1f2",
    WETH: "0x1A03B5D533AB076ff17bE2049B5E9F4E5cde4FeB",
    ETH: "0x1A03B5D533AB076ff17bE2049B5E9F4E5cde4FeB",
    BTC: "0xcD95537E742DE0eCcaaae656929676982EEF007A",
    USDT: "0x896B643636b39d65052EB855E10eF860ac6ddD42",
    USDC: "0xD1a8Cc4F682EDE9A3E80ac8Fb657a73a51A8c005",
    DAI: "0x7c89cD989d56BC67414c10236947c8FF2156BEe6",
    DOGE: "0x4eEfCf59DE17939fD681233aD58a24c7E9e6cC4A",
    LINK: "0x823A2784Cc71E352c80E8DCb2AB0fa68DC0Fe5DA",
    MATIC: "0x7FC562070d9cd9811d397b2cC40d794A2BdDE411",
    ADA: "0x34929bb4E7A17996c0e9B4fd6719dBcc2B66783b",
    DOT: "0xE723e7c0dE4E3552F713eCAE99C2f674CEFf514b",
    PNK: "0x77Ae539A1f531Ed339E11FedBF5A940eC22eaeF8",
    ServerPriceFeed: "0xB8c304B98DeD67481f55565e8BFfCaa24D441D88",
    VaultPriceFeedV3Fast: "0x729E9b2cDCC9564956875316Cd41192B35aCd197",
    randomSource: "0x4877bc67839800db6aD53c1B4c5D84238916b6DD",
    NFTUtils: "0xa36e0E0FBcd423F1812491Ad9d9Db60152B1E1a5",
    Timelock: "0xB766bba988AeA08690A5DC8C3B930d73942145C3",
    PID: "0xAd25ac3deE93caBBDe8A925A3d866F567a9C1576",
    FeeRouter: "0xf4ED4C805be4425E50E1eE03E4E019B1707d4065",
    RewardRouter: "0x4d35CC973b6038EAaaf06C0D9Ab61790D891A4dD",
    UserFeeResv: "0x4F0CBe519a03e0d3BE3A77279F5F97986b47E6ec",
    LpManager: "0x8840C0B6D29f43D1e22bdd67d162a2Fc63B8023c",
    Reader: "0xB19577ad7617BbC26667b71e953aCf98f8d660B2",
    PositionReader: "0x31292d10Bb135180903f494eA4ba744A3F29720d",
    OrderBookReader: "0xaB341B44fd83FC140B74eaf2fa0fC89b5FDAeB80",
    VaultReader: "0xf7919Bf8A3F69B0bD1d12ee7697f56558C4798dd",
    PLP: "0x7a6Ed37C4690F930640F92379FD2Be7fD32f6e03",
    InfoHelper: "0x114e5CD7b2A81424c73cB99e49066F32DBDe3475",
    BurnToScore: "0xb1B16622c09f6C1A792AEbDdDa89E00e8DeF3F56",
    InviteToEarn: "0x45699a5dB7361551043E723096e6161BeE035641",

    Vault: "0x07da10649555608fA24a450F518e730E223FbAB9",
    VaultUtils: "0xA7712B6845051BB3be21e1995253664cd80dA7Ed",
    VaultStorage: "0x2eAb3B4dB41a9D970b59459490d0e6BB0Db20B6F",
    RouterSign: "0x302c60E802848fE1C8dfc89AbA3C3eeEBaCe3F9F",
    PlpManager: "0x603C9F8a7c807D6e8EEfd6875678971B59ad4491",
    Router: "0x7E923De39CC56Ee26030D7406e1B766F4c668Adf",
    PositionRouter: "0x3fC60D96a3735Bdd433DA41Aa978F55C30F29904",
    RewardTracker: "0x98b63F268A3D03BB4B9B1E75206A30d1e0416382",
    OrderBook: "0x44111682420F634dBA24778271914C3357299Ab8",
    PositionManager: "0x5aAAE46594D4f0518E03DEa1b6D4D291B4de38AF",

    PnkLp: "0x566Ec8678bCC6A483Adb6D7615c3939cA191348C",
    TwapPrice: "0x9ca2d020BeAaE5dF204b9CdE5Ae37CC20D8175b0",
    Treasury: "0xAB2C8973d64b935845Ba8d7EA206873e458a7544",
    TreasuryResv: "0xf521b5dba6FF0d513eC08Fe9eF6a46bE90Cc9a8e",
    InstVesting: "0x8b34cDEde186d59Bdfa3a67e005568D6f45e7261",
    Bond: "0x56a4Aef7579dE9Ed7328eC91eBeB8A7eb0AF75bF",

    // PLP_β
    PLP_β: "0x5Dce3337CE99450718e101057E9C1D6cb62ed5A6",
    Vault_PLP_β: "0xa63e5EA4Aa31f3Ac66b585b525a164416729BBbc",
    VaultUtils_PLP_β: "0x3BE618888f0951953E338f65b2519708F58D30a3",
    VaultStorage_PLP_β: "0x12Fc921B516863ac778336e6b0e869d51165AF82",
    RouterSign_PLP_β: "0xcf5A3c7fd630b42473B70176975650FfeD8A9Ed7",
    PlpManager_PLP_β: "0xbc4CA6b8D72634316EFaa884f264F8F257268E06",
    Router_PLP_β: "0xf96401c4F60Ab8C1FB5762cDe338cD615c75a6e4",
    RewardTracker_PLP_β: "0xa23883800087f05BE1aa602548FCd85a5295a3C3",
    OrderBook_PLP_β: "0x15BD62950E0a3bD7E43eE8FCb1f6fe9E2D09c4B1",
    PositionRouter_PLP_β: "0xC18F8366a701943B921bE44A3B15e65db86e29dB",
    PositionManager_PLP_β: "0xcBf52AeC88446FFB2dbd4ADbB27d4A6468ef2f78",

    PIKO: "0xa604E1cA54093692d9De106976c389A4f7Ad1093",
    vePIKO: "0xD05585aCFabc20abd9E223eA9F1EAafAc3F8c2dc",
    vePIKO_stakeHelper: "0x43a5dBEec20C0fc13eE6c55887194e7006A49B35",
    DisToken: "0xef407cFB80Bf06Be5D84EE641e8406D45e25c2dA",
    aPIKO: "0xB024210aDdBf311407795924b3C68Ba0A282b22C",
    esPIKO: "0xe7a0e9BEA09e4A437Ccb11ab44bE15d3133E2e3c",
    veesPIKO: "0x97cC847bF229787fEdC614C96169e1aC298C31fE",
    veesPIKO_stakeHelper: "0xB42000D50ae7D3262f8c25a45692E963273b6131",
    PUSD: "0xBf3BCc95a72ec5aeAFA7c88fe776F4d6dC6fb068",
    DisFee: "0x28374ba9A1cad98D946ef8de9638ed579dC35B50",
    totalVeToken: "0x7569B1E13851698E47bdc2920329e9d16B6224ef",
    TokenVesting: "0x93775996c6E8124BadE65c3Ce57C67A9CE77C7ca",

    TradeRebate: "0x0000000000000000000000000000000000000001",
    LP_Farm: "0x0000000000000000000000000000000000000001",
    LP_Marketing: "0x0000000000000000000000000000000000000001",
    Contracts_Reserve: "0x0000000000000000000000000000000000000001",
    PIKOStaking: "0x0000000000000000000000000000000000000001",
    IDO: "0x0000000000000000000000000000000000000001",
    PIKO_burn: "0x0000000000000000000000000000000000000001",
    PIKO_LP: "0x0000000000000000000000000000000000000001",
    PIKO_DAO: "0x0000000000000000000000000000000000000001",
    dis_token: "0x0000000000000000000000000000000000000001",
    pikoLpToken: "0x0000000000000000000000000000000000000001",
    faucet: "0x125BA39A4744eFAC52A911D2B73c3cA02442F71A",
    VaultPriceFeed: "0x3198463F1FA4737d21d73EffC888f058788d43CA",
  },
  5000: {
    NATIVE_TOKEN: "0xdEAddEaDdeadDEadDEADDEAddEADDEAddead1111",
    WETH: "0xdEAddEaDdeadDEadDEADDEAddEADDEAddead1111",
    ETH: "0xdEAddEaDdeadDEadDEADDEAddEADDEAddead1111",
    BTC: "0xCAbAE6f6Ea1ecaB08Ad02fE02ce9A44F09aebfA2",
    USDC: "0x09Bc4E0D864854c6aFB6eB9A9cdF58aC190D0dF9",
    MNT: "0xDeadDeAddeAddEAddeadDEaDDEAdDeaDDeAD0000",
    ARB: "0x5207f033802bE9140c181c0ac8939BBA346Fe33F",
    OP: "0x863421A6E6B6892fD90DF0e45F7A380B0151DaF9",
    BNB: "0x05F9E9AD23AE0792b01B39A79f504A3d194cdE95",
    SOL: "0xB5f08Ba6BB9252c3D58C0e5578EDbBF944934CeC",
    SUI: "0xd763DB329Ea6A6B541894CD7e84deC780026Bb93",
    MATIC: "0x559d236efD6b9E845E5438ACF69294b24d63ebc7",
    PEPE: "0x6d2a92F065728Bca065512316Fad32064B2868D1",
    DOGE: "0xdfb568BDF6d9343D6bD1980d6B47372196cfFC7d",

    pyth: "0xA2aa501b19aff244D90cc15a4Cf739D2725B5729",
    VaultPriceFeed: "0xe0446be6df5537e1bA788123322eCFC126B4AeDB",

    RewardRouter: "0x68127faBccc09e50642e19F3003cC48d00a737eb",
    randomSource: "0xBC9fCb7d59eD6Bf3B65044313174F8D08DB667bc",
    NFTUtils: "0x81533A921f33b769C37DddD9Ab83ea8E244223bF",
    PID: "0xfa54456934897f73CAA04E82F8666FaC1d671c82",
    PIDLogic: "0x01CCDa3BC844540f47540D6ec5Ea394a44B0BEaf",
    FeeRouterCb: "0x928F1e49B31aF6316fAE1A51358aeFb73C7cd42a",
    UserFeeResv: "0x176a896468e384385624350D234937D2FFF0710A",
    InviteToEarn: "0xEAfd2Cd252344DD8c8f4c3416A29C77d7E6A9cf5",
    TradeRebate: "0x061d1AD15C74928967A25664F3CA1F589ee12eC0",
    BurnToScore: "0xd204451d180D4FCf55C3F5fB020e6E862AcafC1f",
    InfoHelper: "0xB9DdcEb28C5eD580f3B10e9BA744A77e4816bd57",
    Treasury: "0xCe41645E18668De6A66DB1AF921346EA38cE665b",
    Reader: "0x8De3381c762EA8bE4331d0Afcc967BC168e6B19E",
    VaultReader: "0x0bfef2e23F437f6f0C432317D232599240EF2fe6",
    PositionReader: "0xA7fDE2D46D7290DE6FAc3bC8C5626CB4A05de9fB",

    PLP_β: "0xD1a5661a4d994dFEf0439CBe1073A18E82A22CA2",
    Vault_PLP_β: "0x600396b3cB8453E2EeBb1009ed46478D1579382a",
    VaultUtils_PLP_β: "0x4a79fc3e4cC11Efd6e6a66E7312634EC94d78A6c",
    VaultUtilsSup_PLP_β: "0xFa12e1D12E788Ba4A2CA35E71381690AE5DEF186",
    VaultStorage_PLP_β: "0x71902679E0E2eB7DF03B162891Ff6C0f6a968BA8",
    TradeStorage_PLP_β: "0x18d0cd6F479137D1ABFdBa83b27DAc3A498d1b21",
    OrderBook_PLP_β: "0x3913685cdca21A7301B23d7429d15a63DB29CD16",
    PlpManager_PLP_β: "0x4E56E79401d5BF598dA5fD147B77A641acb7Af6A",
    RewardPool_PLP_β: "0x5118a47Bf790DacC014932F0fDEa914c6D1b95Fa",
    RouterSign_PLP_β: "0x90dD9DD891EEa29D8e633Cc890167566b41AF124",
    FeeRouter_PLP_β: "0x8ACf03fF4bE37D4874312e521c1CA430328BF886",

    PLP_α: "0x63cb9739E4774E46178029F3E1343870c0840ae2",
    Vault_PLP_α: "0x822F50b5c93de6620db73093Ab6baB9dBF411e05",
    VaultUtils_PLP_α: "0x28a6e46Db200beD1cCA0D614FB76F1Ef8a9E7dd6",
    VaultUtilsSup_PLP_α: "0x8E0ABD96614D2110364a71353cD8a9799c1CF1b7",
    VaultStorage_PLP_α: "0xFE0194e7223D7701C77A1184eb35df20fc12D4e9",
    TradeStorage_PLP_α: "0x50B08CF76548ad0fdaC9C68edD4E423Ceddb5834",
    OrderBook_PLP_α: "0x346aDAcC47a82Df92cbA3fA60EDB2dB1bD4217D8",
    PlpManager_PLP_α: "0x270C01Ed1528de0dE3c7F9Ff1C49610E705fE537",
    RewardPool_PLP_α: "0x6f42E10621aFD32Ec33d930240a12f8be8026cf0",
    RouterSign_PLP_α: "0x17Dc1Fd218197EDD900B4C182dd186149F9ce181",
    FeeRouter_PLP_α: "0xbEADe69a1FBBBBaA666384C7A9a8414365986092",

    PIKO: "0x0000000000000000000000000000000000000001",
    TokenVesting: "0x0000000000000000000000000000000000000001",
    iPIKOVesting: "0x0000000000000000000000000000000000000001",
    Deployer: "0x0000000000000000000000000000000000000001",
  },
};

export const PRICE_KEY = {
  [421613]: {
    KEY_WETH:
      "0xca80ba6dc32e08d06f1aa886011eed1d77c77be9eb761cc10d72b7d0a2fd57a6",
    KEY_ETH:
      "0xca80ba6dc32e08d06f1aa886011eed1d77c77be9eb761cc10d72b7d0a2fd57a6",
    KEY_BTC:
      "0xf9c0172ba10dfa4d19088d94f5bf61d3b54d5bd7483a322a982e1373ee8ea31b",
    KEY_USDC:
      "0x41f3625971ca2ed2263e78573fe5ce23e13d2558ed3f2e47ab0f84fb9e7ae722",
    KEY_USDT:
      "0x1fc18861232290221461220bd4e2acd1dcdfbc89c84092c93c18bdc7756c1588",
    KEY_DAI:
      "0x87a67534df591d2dd5ec577ab3c75668a8e3d35e92e27bf29d9e2e52df8de412",
    KEY_ARB:
      "0x37f40d2898159e8f2e52b93cb78f47cc3829a31e525ab975c49cc5c5d9176378",
    KEY_OP:
      "0x71334dcd37620ce3c33e3bafef04cc80dec083042e49b734315b36d1aad7991f",
    KEY_BNB:
      "0xecf553770d9b10965f8fb64771e93f5690a182edc32be4a3236e0caaa6e0581a",
    KEY_SOL:
      "0xfe650f0367d4a7ef9815a593ea15d36593f0643aaaf0149bb04be67ab851decd",
    KEY_SUI:
      "0x50c67b3fd225db8912a424dd4baed60ffdde625ed2feaaf283724f9608fea266",
    KEY_MATIC:
      "0xd2c2c1f2bba8e0964f9589e060c2ee97f5e19057267ac3284caef3bd50bd2cb5",
    KEY_PEPE:
      "0xed82efbfade01083ffa8f64664c86af39282c9f084877066ae72b635e77718f0",
    KEY_DOGE:
      "0x31775e1d6897129e8a84eeba975778fb50015b88039e9bc140bbd839694ac0ae",
  },
  [42161]: {
    KEY_ARB:
      "0x3fa4252848f9f0a1480be62745a4629d9eb1322aebab8a791e344b3b9c1adcf5",
    KEY_BTC:
      "0xe62df6c8b4a85fe1a67db44dc12de5db330f7ac66b72dc658afedf0f4a415b43",
    KEY_ETH:
      "0xff61491a931112ddf1bd8147cd1b641375f79f5825126d665480874634fd0ace",
    KEY_WETH:
      "0xff61491a931112ddf1bd8147cd1b641375f79f5825126d665480874634fd0ace",
    KEY_USDC:
      "0xeaa020c61cc479712813461ce153894a96a6c00b21ed0cfc2798d1f9a9e9c94a",
  },
  [280]: {
    KEY_BNB:
      "0xecf553770d9b10965f8fb64771e93f5690a182edc32be4a3236e0caaa6e0581a",
    KEY_WBNB:
      "0xecf553770d9b10965f8fb64771e93f5690a182edc32be4a3236e0caaa6e0581a",
    KEY_BTC:
      "0xf9c0172ba10dfa4d19088d94f5bf61d3b54d5bd7483a322a982e1373ee8ea31b",
    KEY_ETH:
      "0xca80ba6dc32e08d06f1aa886011eed1d77c77be9eb761cc10d72b7d0a2fd57a6",
    KEY_WETH:
      "0xca80ba6dc32e08d06f1aa886011eed1d77c77be9eb761cc10d72b7d0a2fd57a6",
    KEY_BUSD:
      "0x06c532524fabd49cc142815502d785e4f34ec3bd035480efb770568d423f46c6",
    KEY_USDC:
      "0x41f3625971ca2ed2263e78573fe5ce23e13d2558ed3f2e47ab0f84fb9e7ae722",
    KEY_USDT:
      "0x1fc18861232290221461220bd4e2acd1dcdfbc89c84092c93c18bdc7756c1588",
    KEY_DAI:
      "0x87a67534df591d2dd5ec577ab3c75668a8e3d35e92e27bf29d9e2e52df8de412",
    KEY_DOGE:
      "0x31775e1d6897129e8a84eeba975778fb50015b88039e9bc140bbd839694ac0ae",
    KEY_ADA:
      "0x73dc009953c83c944690037ea477df627657f45c14f16ad3a61089c5a3f9f4f2",
    KEY_LINK:
      "0x83be4ed61dd8a3518d198098ce37240c494710a7b9d85e35d9fceac21df08994",
    KEY_CAKE:
      "0x3ea98c0336f6a8506dc34567da82178f6f7a664b206ae8eaf8ab8d96721ecccc",
    KEY_DOT:
      "0x36032e522b810babd8e3148e9f0d588af9e95e93b97ffb58566b837fdbd31f7f",
    KEY_FIL:
      "0xb5622d32f36dc820af288aab779133ef1205d3123bbe256603849b820de48b87",
    KEY_GMT:
      "0xc999fe8a75bf9528931deb832e2934dd1ac138757d7f2c1398675b6f521d556f",
    KEY_INJ:
      "0x2d9315a88f3019f8efa88dfe9c0f0843712da0bac814461e27733f6b83eb51b3",
    KEY_LTC:
      "0x997e0bf451cb36b4aea096e6b5c254d700922211dd933d9d17c467f0d6f34321",
    KEY_MATIC:
      "0xd2c2c1f2bba8e0964f9589e060c2ee97f5e19057267ac3284caef3bd50bd2cb5",
    KEY_MBOX:
      "0x70bf2974182b6ebf4eac64977170074315e7a07d7b58a963758060924ee9a192",
    KEY_XVS:
      "0x6ff709bf1f0fdb46d991ae6839163df1e050d143429d737fc650b6b214347808",
  },
  [324]: {
    KEY_ARB:
      "0x3fa4252848f9f0a1480be62745a4629d9eb1322aebab8a791e344b3b9c1adcf5",
    KEY_BNB:
      "0x2f95862b045670cd22bee3114c39763a4a08beeb663b145d283c31d7d1101c4f",
    KEY_WBNB:
      "0x2f95862b045670cd22bee3114c39763a4a08beeb663b145d283c31d7d1101c4f",
    KEY_BTC:
      "0xe62df6c8b4a85fe1a67db44dc12de5db330f7ac66b72dc658afedf0f4a415b43",
    KEY_ETH:
      "0xff61491a931112ddf1bd8147cd1b641375f79f5825126d665480874634fd0ace",
    KEY_WETH:
      "0xff61491a931112ddf1bd8147cd1b641375f79f5825126d665480874634fd0ace",
    KEY_BUSD:
      "0x5bc91f13e412c07599167bae86f07543f076a638962b8d6017ec19dab4a82814",
    KEY_USDC:
      "0xeaa020c61cc479712813461ce153894a96a6c00b21ed0cfc2798d1f9a9e9c94a",
    KEY_USDT:
      "0x2b89b9dc8fdf9f34709a5b106b472f0f39bb6ca9ce04b0fd7f2e971688e2e53b",
    KEY_XRP:
      "0xec5d399846a9209f3fe5881d70aae9268c94339ff9817e8d18ff19fa05eea1c8",
    KEY_LTC:
      "0x6e3f3fa8253588df9326580180233eb791e03b443a3ba7a1d892e73874e19a54",
    KEY_DOGE:
      "0xdcef50dd0a4cd2dcc17e45df1676dcb336a11a61c69df7a0299b0150c672d25c",
    KEY_LINK:
      "0x8ac0c70fff57e9aefdf5edf44b51d62c2d433653cbb2cf5cc06bb115af04d221",
    KEY_MATIC:
      "0x5de33a9112c2b700b8d30b8a3402c103578ccfa2765696471cc672bd5cf6ac52",
    KEY_ADA:
      "0x2a01deaec9e51a579277b34b122399984d0bbf57e2458a7e42fecd2829867a0d",
    KEY_DOT:
      "0xca3eed9b267293f6595901c734c7525ce8ef49adafe8284606ceb307afa2ca5b",
    KEY_DAI:
      "0xb0948a5e5313200c632b51bb5ca32f6de0d36e9950a942d19751e833f70dabfd",
  },
  [5001]: {
    KEY_BTC:
      "0xf9c0172ba10dfa4d19088d94f5bf61d3b54d5bd7483a322a982e1373ee8ea31b",
    KEY_ETH:
      "0xca80ba6dc32e08d06f1aa886011eed1d77c77be9eb761cc10d72b7d0a2fd57a6",
    KEY_WETH:
      "0xca80ba6dc32e08d06f1aa886011eed1d77c77be9eb761cc10d72b7d0a2fd57a6",
    KEY_BUSD:
      "0x06c532524fabd49cc142815502d785e4f34ec3bd035480efb770568d423f46c6",
    KEY_USDC:
      "0x41f3625971ca2ed2263e78573fe5ce23e13d2558ed3f2e47ab0f84fb9e7ae722",
    KEY_USDT:
      "0x1fc18861232290221461220bd4e2acd1dcdfbc89c84092c93c18bdc7756c1588",
    KEY_DAI:
      "0x87a67534df591d2dd5ec577ab3c75668a8e3d35e92e27bf29d9e2e52df8de412",
    KEY_DOGE:
      "0x31775e1d6897129e8a84eeba975778fb50015b88039e9bc140bbd839694ac0ae",
    KEY_ADA:
      "0x73dc009953c83c944690037ea477df627657f45c14f16ad3a61089c5a3f9f4f2",
    KEY_LINK:
      "0x83be4ed61dd8a3518d198098ce37240c494710a7b9d85e35d9fceac21df08994",
    KEY_CAKE:
      "0x3ea98c0336f6a8506dc34567da82178f6f7a664b206ae8eaf8ab8d96721ecccc",
    KEY_DOT:
      "0x36032e522b810babd8e3148e9f0d588af9e95e93b97ffb58566b837fdbd31f7f",
    KEY_FIL:
      "0xb5622d32f36dc820af288aab779133ef1205d3123bbe256603849b820de48b87",
    KEY_GMT:
      "0xc999fe8a75bf9528931deb832e2934dd1ac138757d7f2c1398675b6f521d556f",
    KEY_INJ:
      "0x2d9315a88f3019f8efa88dfe9c0f0843712da0bac814461e27733f6b83eb51b3",
    KEY_LTC:
      "0x997e0bf451cb36b4aea096e6b5c254d700922211dd933d9d17c467f0d6f34321",
    KEY_MATIC:
      "0xd2c2c1f2bba8e0964f9589e060c2ee97f5e19057267ac3284caef3bd50bd2cb5",
    KEY_MBOX:
      "0x70bf2974182b6ebf4eac64977170074315e7a07d7b58a963758060924ee9a192",
    KEY_XVS:
      "0x6ff709bf1f0fdb46d991ae6839163df1e050d143429d737fc650b6b214347808",
  },

  [5000]: {
    KEY_BTC:
      "0xe62df6c8b4a85fe1a67db44dc12de5db330f7ac66b72dc658afedf0f4a415b43",
    KEY_ETH:
      "0xff61491a931112ddf1bd8147cd1b641375f79f5825126d665480874634fd0ace",
    KEY_WETH:
      "0xff61491a931112ddf1bd8147cd1b641375f79f5825126d665480874634fd0ace",
    KEY_USDC:
      "0xeaa020c61cc479712813461ce153894a96a6c00b21ed0cfc2798d1f9a9e9c94a",
    KEY_ARB:
      "0x3fa4252848f9f0a1480be62745a4629d9eb1322aebab8a791e344b3b9c1adcf5",
    KEY_OP:
      "0x385f64d993f7b77d8182ed5003d97c60aa3361f3cecfe711544d2d59165e9bdf",
    KEY_BNB:
      "0x2f95862b045670cd22bee3114c39763a4a08beeb663b145d283c31d7d1101c4f",
    KEY_SOL:
      "0xef0d8b6fda2ceba41da15d4095d1da392a0d2f8ed0c6c7bc0f4cfac8c280b56d",
    KEY_SUI:
      "0x23d7315113f5b1d3ba7a83604c44b94d79f4fd69af77f804fc7f920a6dc65744",
    KEY_MATIC:
      "0x5de33a9112c2b700b8d30b8a3402c103578ccfa2765696471cc672bd5cf6ac52",
    KEY_PEPE:
      "0xd69731a2e74ac1ce884fc3890f7ee324b6deb66147055249568869ed700882e4",
    KEY_DOGE:
      "0xdcef50dd0a4cd2dcc17e45df1676dcb336a11a61c69df7a0299b0150c672d25c",
  },
};
