import { Trans } from "@lingui/macro";
import { Box, useMediaQuery } from "@mui/material";
import moment from "moment";
import { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import Dots from "src/components/Dots";
import { LinkBox } from "src/components/Header";
import { formatAmount2 } from "src/helpers/Helpers";
import { useWeb3Context } from "src/hooks";
import useCountDown from "src/hooks/useCountDown";
import { ThemedText } from "src/theme/text";
import CountDown from "src/views/leaderboard/TradingCompetition/CountDown";
import {
  useTotalTradeVol,
  useUserTradeVol,
  useUserTrdeclaimable,
  useUserTrderoundRewards,
} from "src/views/leaderboard/hooks";
import styled from "styled-components/macro";

const TradeInfoBox = styled(Box)`
  display: flex;
  justify-content: space-between;
  fill: rgba(10, 213, 189, 0.1);
  border: 1px solid #0ad5bd;
  filter: drop-shadow(0px 0px 28px rgba(20, 209, 178, 0.8));
  padding: 24px 20px;
  border-radius: 16px;
  grid-gap: 16px;
  @media only screen and (max-width: 1280px) {
    flex-direction: column;
    grid-gap: 24px;
  }
`;

export default function TradeInfo() {
  const { address, chainID } = useWeb3Context();
  const isSmallScreen = useMediaQuery("(max-width: 1280px)");
  const isVerySmallScreen = useMediaQuery("(max-width: 680px)");
  const [isStart, setIsStart] = useState<any>(true);
  const [currCount, setCurrCount] = useState<any>(0);
  const [currRoundIds, setcurrRoundId] = useState<any>(
    Math.floor(Date.now() / 1000 / 86400)
  );
  const [currRoundId, setCurrRoundId] = useState<any>(0);
  const userTrade = useUserTradeVol(currRoundIds);
  const totalTrade = useTotalTradeVol(currRoundIds);
  const claimAmount = useUserTrdeclaimable(currRoundIds);
  const roundRewards = useUserTrderoundRewards(currRoundIds);

  const roundId1 = Math.floor(Date.now() / 1000 / 86400);
  const isdisabled = Math.floor(roundId1) == Math.floor(currRoundId);
  const coundown = useCountDown(
    isdisabled
      ? moment()
          .utcOffset("+00")
          .startOf("day")
          .add(1, "d")
          .subtract(1, "s")
          .unix()
      : 0
  );
  useEffect(() => {
    const currTime = new Date().getTime() / 1000;
    const sec = Math.floor(currTime / (24 * 60 * 60));
    setCurrRoundId(sec + currCount);
  }, [currCount, address, chainID]);

  return (
    <TradeInfoBox>
      <Box
        display="flex"
        flexDirection="column"
        alignItems={isSmallScreen ? "center" : "flex-start"}
      >
        <ThemedText.Text1Bold>
          <Trans>Trade to Earn</Trans>
        </ThemedText.Text1Bold>
        <Box display="flex" alignItems="center" mt="8px">
          <ThemedText.Text1 fontSize={12}>
            <Trans>Prize Pool</Trans>
          </ThemedText.Text1>
          <ThemedText.PrimaryText4Bold fontSize={16} ml="8px">
            {formatAmount2(roundRewards, 2, true)} esPIKO
          </ThemedText.PrimaryText4Bold>
        </Box>
        <ThemedText.Text5 fontSize={12} mt="8px">
          {formatAmount2(
            roundRewards?.div(totalTrade || 0).times(1000),
            2,
            true
          )}{" "}
          esPIKO/per $1000 Trading Volume
        </ThemedText.Text5>
      </Box>
      <Box display="flex" flexDirection="column" alignItems="center">
        <ThemedText.Text1 fontSize={12}>
          <Trans>Total Trading Volume</Trans>
        </ThemedText.Text1>
        <ThemedText.Text1Bold fontSize={20} mt="12px">
          ${formatAmount2(totalTrade, 2, true)}
        </ThemedText.Text1Bold>
        <LinkBox component={NavLink} to={`/Trade`}>
          <ThemedText.PrimaryText1 fontSize={12} mt="10px" className="pointer">
            Trade Now{" >>"}
          </ThemedText.PrimaryText1>
        </LinkBox>
      </Box>
      <Box display="flex" flexDirection="column" alignItems="center">
        <ThemedText.Text1 fontSize={12}>
          <Trans>Your Trading Volume</Trans>
        </ThemedText.Text1>
        <ThemedText.Text1Bold fontSize={20} mt="12px">
          ${formatAmount2(userTrade, 2, true)}
        </ThemedText.Text1Bold>
        <ThemedText.Text5 fontSize={12} mt="10px">
          Percentage of Total Volume:{" "}
          {formatAmount2(userTrade?.div(totalTrade || 0).times(100), 2, true)}%
        </ThemedText.Text5>
      </Box>
      <Box display="flex" flexDirection="column" alignItems="center">
        <ThemedText.Text1 fontSize={12}>
          <Trans>Reward Today</Trans>
        </ThemedText.Text1>
        <ThemedText.Text1Bold fontSize={20} mt="12px">
          {formatAmount2(claimAmount, 2, true)} esPIKO
        </ThemedText.Text1Bold>
        <LinkBox component={NavLink} to={`/Leaderboard`}>
          <ThemedText.PrimaryText1 fontSize={12} mt="10px" className="pointer">
            Claim{" >>"}
          </ThemedText.PrimaryText1>
        </LinkBox>
      </Box>
      <Box display="flex" flexDirection="column" alignItems="center">
        <Box display="flex" alignItems="center" className="">
          <Dots />
          <ThemedText.Text4Bold ml="8px">
            {isStart ? "Ends in" : "Start"}
          </ThemedText.Text4Bold>
        </Box>
        <Box mt="8px">
          <CountDown coundown={coundown} />
        </Box>
      </Box>
    </TradeInfoBox>
  );
}
