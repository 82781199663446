import { Trans } from "@lingui/macro";
import { Box, useMediaQuery } from "@mui/material";
import { ThemedText } from "src/theme/text";
import styled from "styled-components";
import AccountInfo from "./AccountInfo";
import Referral from "./Referral";
import ReferStep from "./ReferStep";
import { shorten } from "src/helpers";
import { useWeb3Context } from "src/hooks";
import { useBasicInfo } from "src/hooks/useQueryPID";
import NoPid from "./AccountInfo/NoPid";
import { useLocation } from "react-router";
import { useEffect, useState } from "react";
import Dots from "src/components/Dots";
import { RANK_LIST } from "src/helpers/rankConstants";
import CountDown from "./AccountInfo/CountDown";
import LevelTooltip from "./AccountInfo/LevelTooltip";
import EPointProgress from "./AccountInfo/EPointProgress";
import TradeInfo from "./TradeInfo";
const PortfolioBox = styled(Box)`
  width: 100%;
  .titleBox {
    padding-bottom: 24px;
    border-bottom: 1px solid ${({ theme }) => theme.bg4};

    .account {
      margin-top: 48px;
    }
  }
  @media only screen and (max-width: 1280px) {
    .titleBox {
      padding-bottom: 16px;

      .account {
        margin-top: 24px;
      }
    }
  }
`;

export default function Portfolio() {
  const isSmallScreen = useMediaQuery("(max-width: 1280px)");
  const isVerySmallScreen = useMediaQuery("(max-width: 680px)");
  const { address, chainID } = useWeb3Context();
  const baseInfo = useBasicInfo();
  const location: any = useLocation();

  const [showNo, SetShowNo] = useState(false);
  useEffect(() => {
    if (location.state?.showmint) {
      SetShowNo(true);
    }
  }, [location]);

  return (
    <PortfolioBox className="portfolio-view">
      <Box display="flex" justifyContent="center" className="titleBox">
        <div className="container">
          <TradeInfo />
          <ThemedText.Title mt="20px">
            <Trans>Profile</Trans>
          </ThemedText.Title>
          {baseInfo?.rank > 0 && (
            <Box
              display="flex"
              flexDirection={isVerySmallScreen ? "column" : "row"}
              alignItems={isVerySmallScreen ? "flex-start" : "center"}
              className="account"
            >
              <Box display="flex" alignItems="center">
                {/* <img src="" /> */}
                <Box className="">
                  <ThemedText.Text4Bold>
                    <Trans>Name</Trans>
                  </ThemedText.Text4Bold>
                  <ThemedText.Text1Bold mt="4px">
                    {baseInfo?.nickName}
                  </ThemedText.Text1Bold>
                </Box>
              </Box>
              <Box
                ml={isVerySmallScreen ? 0 : "96px"}
                mt={isVerySmallScreen ? "30px" : 0}
                className=""
              >
                <ThemedText.Text4Bold>
                  <Trans>Address</Trans>
                </ThemedText.Text4Bold>
                <ThemedText.Text3 fontSize={24} lineHeight="168%">
                  {isSmallScreen ? shorten(address) : address}
                </ThemedText.Text3>
              </Box>
              <Box
                ml={isVerySmallScreen ? 0 : "96px"}
                mt={isVerySmallScreen ? "30px" : 0}
                className="infoItem"
              >
                <Box display="flex" alignItems="center" className="">
                  <Dots bgColor="#14D1B2" />
                  <ThemedText.Text4Bold ml="8px">
                    <Trans>Level</Trans>
                  </ThemedText.Text4Bold>
                  <ThemedText.Text5 fontSize={12} ml="16px">
                    <CountDown />
                  </ThemedText.Text5>
                </Box>
                <Box display="flex" alignItems="center" mt="12px" className="">
                  <ThemedText.Text1Bold fontSize={18}>
                    {RANK_LIST[baseInfo?.rank]}
                  </ThemedText.Text1Bold>
                  {chainID == 324 && (
                    <Box display="inline-flex" ml="10px" className="pointer">
                      <LevelTooltip />
                    </Box>
                  )}
                </Box>
                <Box
                  display="flex"
                  flexDirection="column"
                  mt="8px"
                  className=""
                >
                  <EPointProgress />
                </Box>
              </Box>
            </Box>
          )}
        </div>
      </Box>
      <Box display="flex" justifyContent="center">
        <div className="container">
          {baseInfo?.rank > 0 ? (
            <>
              <AccountInfo />
              <Referral />
            </>
          ) : (
            <Box mt={isSmallScreen ? "20px" : "60px"}>
              <NoPid showNo={showNo} />
            </Box>
          )}
          <ReferStep />
        </div>
      </Box>
    </PortfolioBox>
  );
}
