import { Box } from "@mui/material";
import styled from "styled-components/macro";
import Slider, { SliderTooltip } from "rc-slider";
import Column from "src/components/Column";
import Row from "src/components/Row";
import { useEffect, useState } from "react";
import useDebounce from "src/utils/useDebounce";

const LeverageBox = styled(Box)<{ isLong?: boolean }>`
  display: flex;
  align-items: center;
  margin-top: 16px;
  .rc-slider-step {
    height: 8px;
    background: rgba(255, 255, 255, 0.1);
    border-radius: 4px;
  }
  .rc-slider-dot {
    display: none;
  }

  .Slidern {
    font-weight: 400;
    font-size: 12px;
    color: rgba(255, 255, 255, 0.54);
    cursor: pointer;
  }
  .rc-slider-track {
    position: absolute;
    left: 0;
    height: 8px;
    border-radius: 4px;
    background: ${({ isLong }) => (isLong ? "#14D1B2" : "#FA5341")};
  }

  .rc-slider-rail {
    display: none;
    position: absolute;
    width: 100%;
    background-color: #e9e9e9;
    height: 8px;
    border-radius: 4px;
  }
  .rc-slider-handle {
    position: absolute;
    width: 14px;
    height: 14px;
    cursor: pointer;
    cursor: -webkit-grab;
    margin-top: -3px;
    cursor: grab;
    border-radius: 50%;
    border: 4px solid rgba(255, 255, 255, 1);
    background-color: rgba(0, 0, 0, 1);
    touch-action: pan-x;
    &:hover {
      border-color: rgba(255, 255, 255, 1);
    }
  }
`;

const ColumnBox = styled(Column)`
  width: 60px;
  .mete {
    font-weight: 500;
    font-size: 14px;
    color: rgba(255, 255, 255, 0.54);
    letter-spacing: -0.4px;
  }
`;

const RowBox = styled(Row)`
  padding: 10px 12px 10px 10px;
  background: #000000;
  border: 1px solid rgba(255, 255, 255, 0.16);
  border-radius: 4px;
  .LeverageBoxinputs {
    padding: 0;
    color: rgba(255, 255, 255, 0.87);
    font-weight: 500;
    font-size: 14px;
    width: 40px;
  }
  .x {
    font-weight: 500;
    font-size: 14px;
    color: rgba(255, 255, 255, 0.54);
  }
`;

const SliderBox = styled(Box)`
  width: 100%;
  position: relative;
  margin-top: 24px;
`;

const leverageMarks = {
  2: "2x",
  5: "5x",
  10: "10x",
  15: "15x",
  20: "20x",
  25: "25x",
  30: "30x",
  35: "35x",
  40: "40x",
  45: "45x",
  50: "50x",
};
const leverageMarkss = [2, 5, 10, 15, 20, 25, 50];

const leverageSliderHandle = (props) => {
  const { value, dragging, index, ...restProps } = props;
  return (
    <SliderTooltip
      prefixCls="rc-slider-tooltip"
      overlay={`${parseFloat(value).toFixed(2)}x`}
      visible={dragging}
      placement="top"
      key={index}
    >
      <Slider.Handle value={value} {...restProps} />
    </SliderTooltip>
  );
};

export default function Leverage({
  leverageOption,
  onChange,
  defaultValue,
  isLong,
}: any) {
  const InputBlur = (e: any) => {
    if (e.target.value <= 0) {
      onChange(2);
    } else if (e.target.value >= 50) {
      onChange(50);
    } else {
      onChange(Number(e.target.value || 1.1).toFixed(0));
    }
  };
  const handleDebounce = useDebounce((value) => {
    setLeveragValue(value);
  }, 0);
  const [leveragValue, setLeveragValue] = useState(defaultValue);

  useEffect(() => {
    handleDebounce(leverageOption);
    return () => {
      handleDebounce.cancel();
    };
  }, [handleDebounce, leverageOption]);

  const InputChange = (e: any) => {
    setLeveragValue(Number(e.target.value || 0).toFixed(0));
  };
  return (
    <LeverageBox isLong={isLong}>
      <Row gap={"15px"}>
        <ColumnBox>
          <div className="mete">Leverage:</div>
          <RowBox mt={"8px"}>
            <input
              type="number"
              className="LeverageBoxinputs"
              defaultValue={defaultValue}
              value={leveragValue}
              onChange={InputChange}
              onBlur={InputBlur}
              min={0}
              max={50}
            />
            <div className="x">x</div>
          </RowBox>
        </ColumnBox>
        <SliderBox>
          <Slider
            style={{ flex: "1" }}
            min={1.1}
            max={50}
            step={0.1}
            onChange={onChange}
            marks={leverageMarks}
            handle={leverageSliderHandle}
            value={leverageOption}
            defaultValue={defaultValue}
            className="sliders"
          />
          {/* <RowBetween mt={"6px"}>
            {leverageMarkss.map((item, index) => {
              return (
                <Box
                  className="Slidern"
                  key={"sldsa" + index}
                  onClick={() => onChange(item)}
                >
                  {item}x
                </Box>
              );
            })}
          </RowBetween> */}
        </SliderBox>
      </Row>
    </LeverageBox>
  );
}
