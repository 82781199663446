import Tooltip, { TooltipProps, tooltipClasses } from "@mui/material/Tooltip";
import styled from "styled-components";
import { Box } from "@mui/material";
import { formatAmount } from "src/helpers/Helpers";
import Tooltips from "src/components/Tooltips/totalTips";
import { TipsIconBox } from "src/components/TipsIcon";
// import "./tooltip.css";

export const HtmlTooltipBox = styled(
  ({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
  )
)(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    borderRadius: "4px",
    border: "1px solid rgba(255, 255, 255, 0.16)",
    background: "#232323",
    boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
    padding: "0",
    maxWidth: "500px",
    fontSize: "14px",
    color: "rgba(255, 255, 255, 0.87)",
  },
}));

export const TooltipContentBox = styled(Box)`
  padding: 0;
  max-width: 100%;
  overflow-x: auto;
  font-size: 14px;
  color: rgba(255, 255, 255, 0.87);
  .rankLine {
    height: 100%;
    border-left: 1px solid rgba(255, 255, 255, 0.08);
  }
  .rankHead {
    height: 50px;
    background: #0c0c0c;
  }
  .rankListTips {
    .rankItem {
      height: 30px;
      &:nth-child(2n){
        background: #0c0c0c;
      }
​    }
  }
`;

function TooltipContent() {
  return (
    <TooltipContentBox>
      <Box
        display="flex"
        alignItems={"center"}
        className="rankHead font-weight-b"
      >
        <Box display="flex" justifyContent={"center"} width="50px" className="">
          Level
        </Box>
        <div className="rankLine"></div>
        <Box pl="8px" width="120px" className="">
          Points
        </Box>
        <div className="rankLine"></div>
        <Box pl="8px" width="100px" className="">
          veToken
        </Box>
        <div className="rankLine"></div>
        <Box pl="8px" width="80px" className="">
          Discounts
        </Box>
        <div className="rankLine"></div>
        <Box pl="8px" width="70px" className="">
          Rebates
        </Box>
        <div className="rankLine"></div>
        <Box pl="8px" width="150px" className="">
          esPIKO vesting Days
        </Box>
      </Box>
      <div className="rankListTips">
        <Box display="flex" alignItems={"center"} className="rankItem">
          <Box
            display="flex"
            justifyContent={"center"}
            width="50px"
            className="font-weight-b"
          >
            E
          </Box>
          <div className="rankLine"></div>
          <Box pl="8px" width="120px" className="">
            0
          </Box>
          <div className="rankLine"></div>
          <Box pl="8px" width="100px" className="">
            0
          </Box>
          <div className="rankLine"></div>
          <Box pl="8px" width="80px" className="">
            0%
          </Box>
          <div className="rankLine"></div>
          <Box pl="8px" width="70px" className="">
            -5%
          </Box>
          <div className="rankLine"></div>
          <Box pl="8px" width="150px" className="">
            180
          </Box>
        </Box>
        <Box display="flex" alignItems={"center"} className="rankItem">
          <Box
            display="flex"
            justifyContent={"center"}
            width="50px"
            className="font-weight-b"
          >
            D
          </Box>
          <div className="rankLine"></div>
          <Box pl="8px" width="120px" className="">
            100
          </Box>
          <div className="rankLine"></div>
          <Box pl="8px" width="100px" className="">
            0
          </Box>
          <div className="rankLine"></div>
          <Box pl="8px" width="80px" className="">
            0%
          </Box>
          <div className="rankLine"></div>
          <Box pl="8px" width="70px" className="">
            -10%
          </Box>
          <div className="rankLine"></div>
          <Box pl="8px" width="150px" className="">
            150
          </Box>
        </Box>
        <Box display="flex" alignItems={"center"} className="rankItem">
          <Box
            display="flex"
            justifyContent={"center"}
            width="50px"
            className="font-weight-b"
          >
            C
          </Box>
          <div className="rankLine"></div>
          <Box pl="8px" width="120px" className="">
            {formatAmount(5000, 0, 0, true)}
          </Box>
          <div className="rankLine"></div>
          <Box pl="8px" width="100px" className="">
            0
          </Box>
          <div className="rankLine"></div>
          <Box pl="8px" width="80px" className="">
            0%
          </Box>
          <div className="rankLine"></div>
          <Box pl="8px" width="70px" className="">
            -15%
          </Box>
          <div className="rankLine"></div>
          <Box pl="8px" width="150px" className="">
            120
          </Box>
        </Box>
        <Box display="flex" alignItems={"center"} className="rankItem">
          <Box
            display="flex"
            justifyContent={"center"}
            width="50px"
            className="font-weight-b"
          >
            B
          </Box>
          <div className="rankLine"></div>
          <Box pl="8px" width="120px" className="">
            {formatAmount(25000, 0, 0, true)}
          </Box>
          <div className="rankLine"></div>
          <Box pl="8px" width="100px" className="">
            0
          </Box>
          <div className="rankLine"></div>
          <Box pl="8px" width="80px" className="">
            -5%
          </Box>
          <div className="rankLine"></div>
          <Box pl="8px" width="70px" className="">
            -15%
          </Box>
          <div className="rankLine"></div>
          <Box pl="8px" width="150px" className="">
            90
          </Box>
        </Box>
        <Box display="flex" alignItems={"center"} className="rankItem">
          <Box
            display="flex"
            justifyContent={"center"}
            width="50px"
            className="font-weight-b"
          >
            A
          </Box>
          <div className="rankLine"></div>
          <Box pl="8px" width="120px" className="">
            {formatAmount(125000, 0, 0, true)}
          </Box>
          <div className="rankLine"></div>
          <Box pl="8px" width="100px" className="">
            0
          </Box>
          <div className="rankLine"></div>
          <Box pl="8px" width="80px" className="">
            -5%
          </Box>
          <div className="rankLine"></div>
          <Box pl="8px" width="70px" className="">
            -20%
          </Box>
          <div className="rankLine"></div>
          <Box pl="8px" width="150px" className="">
            75
          </Box>
        </Box>
        <Box display="flex" alignItems={"center"} className="rankItem">
          <Box
            display="flex"
            justifyContent={"center"}
            width="50px"
            className="font-weight-b"
          >
            S
          </Box>
          <div className="rankLine"></div>
          <Box pl="8px" width="120px" className="">
            {formatAmount(500000, 0, 0, true)}
          </Box>
          <div className="rankLine"></div>
          <Box pl="8px" width="100px" className="">
            {formatAmount(10000, 0, 0, true)}
          </Box>
          <div className="rankLine"></div>
          <Box pl="8px" width="80px" className="">
            -10%
          </Box>
          <div className="rankLine"></div>
          <Box pl="8px" width="70px" className="">
            -30%
          </Box>
          <div className="rankLine"></div>
          <Box pl="8px" width="150px" className="">
            50
          </Box>
        </Box>
        <Box display="flex" alignItems={"center"} className="rankItem">
          <Box
            display="flex"
            justifyContent={"center"}
            width="50px"
            className="font-weight-b"
          >
            SS
          </Box>
          <div className="rankLine"></div>
          <Box pl="8px" width="120px" className="">
            {formatAmount(1000000, 0, 0, true)}
          </Box>
          <div className="rankLine"></div>
          <Box pl="8px" width="100px" className="">
            {formatAmount(30000, 0, 0, true)}
          </Box>
          <div className="rankLine"></div>
          <Box pl="8px" width="80px" className="">
            -10%
          </Box>
          <div className="rankLine"></div>
          <Box pl="8px" width="70px" className="">
            -35%
          </Box>
          <div className="rankLine"></div>
          <Box pl="8px" width="150px" className="">
            30
          </Box>
        </Box>
      </div>
    </TooltipContentBox>
  );
}

export default function LevelTooltip() {
  return (
    <>
      <Tooltips
        position="bottom"
        handleClassName=""
        renderContent={<TooltipContent />}
        width="auto"
        padding="0"
      >
        <TipsIconBox />
      </Tooltips>
    </>
  );
}
