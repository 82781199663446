import React, { useState, useEffect, useMemo } from "react";

import Tooltips from "src/components/Tooltips/totalTips";
import Modal from "../Modal/Modal";
import "rc-slider/assets/index.css";

import useSWR from "swr";
import { ethers, logger } from "ethers";

import { BsArrowRight } from "react-icons/bs";
import PendingIcon from "../../assets/images/loading/pending.svg";
import {
  helperToast,
  formatAmount,
  bigNumberify,
  USD_DECIMALS,
  USDX_DECIMALS,
  LONG,
  SHORT,
  SWAP,
  MARKET,
  SWAP_ORDER_OPTIONS,
  LEVERAGE_ORDER_OPTIONS,
  DEFAULT_HIGHER_SLIPPAGE_AMOUNT,
  getPositionKey,
  getUsd,
  BASIS_POINTS_DIVISOR,
  MARGIN_FEE_BASIS_POINTS,
  PRECISION,
  USDX_ADDRESS,
  STOP,
  LIMIT,
  SWAP_OPTIONS,
  DUST_BNB,
  isTriggerRatioInverted,
  usePrevious,
  formatAmountFree,
  fetcher,
  parseValue,
  expandDecimals,
  shouldRaiseGasError,
  getTokenInfo,
  getLiquidationPrice,
  approveTokens,
  getLeverage,
  isSupportedChain,
  getExchangeRate,
  getExchangeRateDisplay,
  getNextToAmount,
  getNextFromAmount,
  getMostAbundantStableToken,
  useLocalStorageSerializeKey,
  useLocalStorageByChainId,
  calculatePositionDelta,
  replaceNativeTokenAddress,
  adjustForDecimals,
  formatAmount2,
  execInc,
  getLiquidationPrice2,
  getPositionSignature,
  getPositionSignaturegns
} from "../../helpers/Helpers";
import { ReactComponent as Sting } from 'src/assets/images/trade/sting.svg'
import { getConstant } from "../../configs/getConstant";
import * as Api from "../../Api";

import TokenSelector from "./TokenSelector5";
import ExchangeInfoRow from "./ExchangeInfoRow";
// import ConfirmationBox from "./ConfirmationBox";
import ConfirmationBox from "./ConfirmationBoxDialog";
// import OrdersToa from "./OrdersToa";
import OrdersToa from "./OrdersToaDialog";

import { getTokens, getWhitelistedTokens, getToken, getTokenBySymbol } from "../../configs/Tokens";
import PositionRouter from "../../abis/PositionRouter.json";
import Router from "../../abis/Router.json";
import Token from "../../abis/Token.json";
import WETH from "../../abis/WETH.json";

import { ReactComponent as LongImg } from "../../assets/images/long.svg";
import { ReactComponent as ShortImg } from "../../assets/images/short.svg";
import { ReactComponent as SwapImg } from "../../assets/images/swap.svg";
import { useWeb3Context } from "src/hooks";
import { Box } from "@material-ui/core";
import { toastError, toastSuccess, toastWaiting } from "src/helpers/toastHelpers";
import { useSelector } from "react-redux";
import { useRouterSignContract, useInterfaceMulticall, useVaultPriceFeedContract, useOrderBookContract } from "src/hooks/useContract";
import { addresses as ADDRESS } from 'src/configs/constants.ts'
import cx from "classnames";
import Row, { RowBetween } from "../Row";
import Column from "../Column";
import TooltipItem, { TooltipText } from "../TooltipItem";
import { compareAddress } from "src/utils/address";
import { SwapBtn } from "../Button/SwapBtn";
import { useGetPLPData } from "src/hooks/useGetPLPData";
import useBlockNumber, { useFastForwardBlockNumber } from 'src/lib/hooks/useBlockNumber'
import { useUpDataBlockNumber } from 'src/lib/hooks/useUpDataBlockNumber'
import { useQueryApproved } from "src/hooks/useTokenApprove"
import { useQueryValidSwap } from 'src/hooks/useTradeInfo'
import TradeTable from "src/components/TradeAll/TradeTable";
import MarketTable from "src/components/TradeAll/MarketTable";
import Leverage from "src/components/TradeAll/Leverage";
import { TakeProfit } from "../TakeProfit";
import { MaxTab } from "../MaxTab";
import { ClaimBtn } from "../Button/ClaimBtn";
import { ConfirmBtn } from "../Button/ConfirmBtn";
import { getPythPrices } from "src/services/api";
import { Klines } from "./ConfirmStyles";
import { useSwapFeeBas } from "../Plp/hooks";
import { useMinExecutionFee } from "src/hooks/useSwapHook";
import { useEthersSigner } from "src/hooks/web3Context";
import { usePyth_Price } from "src/state/http/hooks";
const SWAP_ICONS = {
  [LONG]: LongImg,
  [SHORT]: ShortImg,
  [SWAP]: SwapImg,
};
const { AddressZero } = ethers.constants;


function getNextAveragePrice({ size, sizeDelta, hasProfit, delta, nextPrice, isLong }) {
  if (!size || !sizeDelta || !delta || !nextPrice) {
    return;
  }
  const nextSize = size.add(sizeDelta);
  let divisor;
  if (isLong) {
    divisor = hasProfit ? nextSize.add(delta) : nextSize.sub(delta);
  } else {
    divisor = hasProfit ? nextSize.sub(delta) : nextSize.add(delta);
  }
  if (!divisor || divisor.eq(0)) {
    return;
  }
  const nextAveragePrice = nextPrice.mul(nextSize).div(divisor);
  return nextAveragePrice;
}

export default function SwapBox(props) {
  let {
    plpName,
    pendingPositions,
    setPendingPositions,
    infoTokens,
    currInfoTokens,
    fromTokenAddress,
    setFromTokenAddress,
    toTokenAddress,
    setToTokenAddress,
    swapOption,
    setSwapOption,
    positionsMap,
    pendingTxns,
    setPendingTxns,
    tokenSelection,
    setTokenSelection,
    setIsConfirming,
    isConfirming,
    isPendingConfirmation,
    setIsPendingConfirmation,
    flagOrdersEnabled,
    chainId,
    nativeTokenAddress,
    savedSlippageAmount,
    openSettings,
    totalTokenWeights,
    usdxSupply,
    orders,
    savedIsPnlInLeverage,
    orderBookApproved,
    positionRouterApproved,
    isWaitingForPluginApproval,
    approveOrderBook,
    approvePositionRouter,
    setIsWaitingForPluginApproval,
    isWaitingForPositionRouterApproval,
    setIsWaitingForPositionRouterApproval,
    isPluginApproving,
    isPositionRouterApproving,
    savedShouldDisableOrderValidation,
    minExecutionFeeUSD,
    minExecutionFeeErrorMessage,
  } = props;
  const { connected, connect, address } = useWeb3Context();
  const provider = useEthersSigner();
  const [fromValue, setFromValue] = useState("");
  const [toValue, setToValue] = useState("");
  const [anchorOnFromAmount, setAnchorOnFromAmount] = useState(true);
  const [isApproving, setIsApproving] = useState(false);
  const [isWaitingForApproval, setIsWaitingForApproval] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [modalError, setModalError] = useState(false);
  const [isHigherSlippageAllowed, setIsHigherSlippageAllowed] = useState(false);
  const multicall = useInterfaceMulticall()
  const upDataBlock = useUpDataBlockNumber()
  let allowedSlippage = savedSlippageAmount;
  if (isHigherSlippageAllowed) {
    allowedSlippage = DEFAULT_HIGHER_SLIPPAGE_AMOUNT;
  }

  const PLPMAP = useSelector(state => {
    return state.app.PLPMAP || {};
  })
  const allToken = useSelector(state => {
    return state.app.allToken || [];
  })
  const TOKEN_MAP = useSelector(state => {
    return state.app.tokensMap || {};
  })

  const isGNS = !!PLPMAP[plpName]?.isGNS

  const defaultCollateralSymbol = getConstant(chainId, "defaultCollateralSymbol");
  // TODO hack with useLocalStorageSerializeKey
  const [shortCollateralAddress, setShortCollateralAddress] = useLocalStorageByChainId(
    chainId,
    "Short-Collateral-Address",
    getTokenBySymbol(chainId, defaultCollateralSymbol).address,
  );
  const [poolUsdt, setPoolUsdt] = useState(0)
  const [utilizationUsdt, setUtilizationUsdt] = useState(0)
  useEffect(() => {
    if (PLPMAP[plpName]) {
      const chainInfoArr = PLPMAP[plpName].chainInfoArr;
      if (PLPMAP[plpName].isGNS) {
        const stableTokensArr = chainInfoArr
        setPoolUsdt(stableTokensArr[0]?.poolAmount)
        setUtilizationUsdt(stableTokensArr[0]?.utilization)
      } else {
        const stableTokensArr = chainInfoArr?.filter(token => compareAddress(token.address, shortCollateralAddress))
        setPoolUsdt(stableTokensArr[0]?.poolAmount)
        setUtilizationUsdt(stableTokensArr[0]?.utilization)
      }
    }
  }, [PLPMAP, plpName])
  let [orderOption, setOrderOption] = useLocalStorageSerializeKey([chainId, "Order-option"], MARKET);

  // const PythPrices = usePyth_Price()

  let isLong = swapOption === LONG;
  let isLimit = orderOption === LIMIT;
  let isShort = swapOption === SHORT;
  let isSwap = swapOption === SWAP;

  function getTokenLabel() {
    switch (true) {
      case isLong:
        return "Long";
      case isShort:
        return "Short";
      case isSwap:
        return "Receive";
      default:
        return "";
    }
  }
  const [leverageOption, setLeverageOption] = useLocalStorageSerializeKey(
    [chainId, "Exchange-swap-leverage-option"],
    "2",
  );
  const [isLeverageSliderEnabled, setIsLeverageSliderEnabled] = useLocalStorageSerializeKey(
    [chainId, "Exchange-swap-leverage-slider-enabled"],
    true,
  );

  const hasLeverageOption = isLeverageSliderEnabled && !isNaN(parseFloat(leverageOption));
  const [ordersToaOpen, setOrdersToaOpen] = useState(false);

  if (!flagOrdersEnabled) {
    orderOption = MARKET;
  }

  const onOrderOptionChange = option => {
    setOrderOption(option);
  };

  const isMarketOrder = orderOption === MARKET;
  const orderOptions = isSwap ? SWAP_ORDER_OPTIONS : LEVERAGE_ORDER_OPTIONS;
  const orderOptionLabels = { [STOP]: "Trigger" };

  const [triggerPriceValue, setTriggerPriceValue] = useState("");
  const triggerPriceUsd = isMarketOrder ? 0 : parseValue(triggerPriceValue, USD_DECIMALS);
  const vaultPriceFeedcontract = useVaultPriceFeedContract()
  const onTriggerPriceChange = evt => {
    setTriggerPriceValue(evt.target.value || "");
  };

  const onTriggerRatioChange = evt => {
    setTriggerRatioValue(evt.target.value || "");
  };

  let positionKey;
  if (isLong) {
    positionKey = getPositionKey(address, toTokenAddress, toTokenAddress, true, nativeTokenAddress);
  }
  if (isShort) {
    positionKey = getPositionKey(address, shortCollateralAddress, toTokenAddress, false, nativeTokenAddress);
  }
  if (isGNS) {
    positionKey = getPositionKey(address, fromTokenAddress, toTokenAddress, isLong, nativeTokenAddress);
  }
  const existingPosition = positionKey ? positionsMap[positionKey] : undefined;

  const hasExistingPosition = existingPosition && existingPosition.size && existingPosition.size.gt(0);
  const tokens = allToken;
  const fromTokens = tokens.filter(token => !token.isGNS);
  const stableTokens = tokens.filter(token => token.isStable);
  const indexTokens = tokens.filter(token => !token.isStable && !token.isWrapped);
  const shortableTokens = indexTokens.filter(token => token.isShortable);
  const swapTokens = tokens.filter(token => !token.isGNS);

  let toTokens = tokens
  if (isLong) {
    toTokens = indexTokens
  } else if (isShort) {
    toTokens = shortableTokens
  } else if (isSwap) {
    toTokens = swapTokens
  }

  const needOrderBookApproval = !isMarketOrder && !orderBookApproved;
  const prevNeedOrderBookApproval = usePrevious(needOrderBookApproval);

  const needPositionRouterApproval = (isLong || isShort) && isMarketOrder;
  const prevNeedPositionRouterApproval = usePrevious(needPositionRouterApproval);

  useEffect(() => {
    if (!needOrderBookApproval && prevNeedOrderBookApproval && isWaitingForPluginApproval) {
      setIsWaitingForPluginApproval(false);
      toastSuccess("Orders enabled!")
    }
  }, [needOrderBookApproval, prevNeedOrderBookApproval, setIsWaitingForPluginApproval, isWaitingForPluginApproval]);

  useEffect(() => {
    if (!needPositionRouterApproval && prevNeedPositionRouterApproval && isWaitingForPositionRouterApproval) {
      setIsWaitingForPositionRouterApproval(false);
      toastSuccess("Leverage enabled!")
    }
  }, [
    needPositionRouterApproval,
    prevNeedPositionRouterApproval,
    setIsWaitingForPositionRouterApproval,
    isWaitingForPositionRouterApproval,
  ]);

  useEffect(() => {
    if (!needOrderBookApproval && prevNeedOrderBookApproval && isWaitingForPluginApproval) {
      setIsWaitingForPluginApproval(false);
      toastSuccess("Orders enabled!")
    }
  }, [needOrderBookApproval, prevNeedOrderBookApproval, setIsWaitingForPluginApproval, isWaitingForPluginApproval]);

  const { routerSign_addr, orderBookAddress, vault_address, chainInfoArr } = useGetPLPData(plpName)
  const RouterSignContract = useRouterSignContract(routerSign_addr)
  const minExecutionFee = useMinExecutionFee(orderBookAddress);
  const spenders = useMemo(() => {
    if (!routerSign_addr) return;

    if (isLimit) {
      return orderBookAddress
    }
    return routerSign_addr
  }, [chainId, routerSign_addr, isLimit, isSwap])

  const tokenAllowanceAddress = fromTokenAddress === AddressZero ? nativeTokenAddress : fromTokenAddress;

  const { ApprovedNumber: tokenAllowance, approveToken } = useQueryApproved(tokenAllowanceAddress, spenders, fromValue);

  const { data: hasOutdatedUi } = Api.useHasOutdatedUi();
  const fromToken = TOKEN_MAP[fromTokenAddress.toLowerCase()]
  const toToken = TOKEN_MAP[toTokenAddress.toLowerCase()]
  const shortCollateralToken = getTokenInfo(currInfoTokens, shortCollateralAddress);
  const fromTokenInfo = getTokenInfo(infoTokens, fromTokenAddress);
  const toTokenInfo = getTokenInfo(currInfoTokens, toTokenAddress);
  const renderAvailableLongLiquidity = () => {
    if (!isLong) {
      return null;
    }
    return (
      <div className="Exchange-info-row">
        <div className="Exchange-info-label">Available Liquidity</div>
        <div className="align-right">
          {
            isGNS ?
              <Tooltips
                position="right"
                renderContent={
                  <>
                    {
                      toTokenInfo?.maxGlobalLongSize?.gt(0) ?
                        <>
                          <div className="toolBottom2">
                            <TooltipItem label={`Max ${toTokenInfo?.symbol} long capacity`} val={`$${formatAmount(toTokenInfo?.maxGlobalLongSize, USD_DECIMALS, 2, true)}`} />
                          </div>
                          <TooltipItem label={`Current ${toTokenInfo?.symbol} long`} val={`$${formatAmount(toTokenInfo?.globalLongSize, USD_DECIMALS, 2, true)}`} />
                        </>
                        :
                        <>
                          <div className="toolBottom2">
                            <TooltipItem label={`Max ${toTokenInfo?.symbol} long capacity`} val={`$${formatAmount2(poolUsdt * 1, 2, true)}`} />
                          </div>
                          <TooltipItem label={`Current long`} val={`$${formatAmount2(utilizationUsdt * poolUsdt, 2, true)}`} />
                        </>
                    }
                  </>
                } >
                {
                  toTokenInfo?.maxGlobalLongSize?.gt(0) ?
                    <span className="toolBottom">{formatAmount(toTokenInfo?.maxAvailableLong, USD_DECIMALS, 2, true)}</span>
                    :
                    <span className="toolBottom">{formatAmount2(poolUsdt * 1 - utilizationUsdt * poolUsdt, 2, true)}</span>
                }
              </Tooltips>
              :
              <Tooltips
                position="right"
                renderContent={
                  <>
                    <div className="toolBottom2">
                      <TooltipItem label={`Max ${toTokenInfo?.symbol} long capacity`} val={`$${formatAmount(toTokenInfo?.maxLongCapacity, USD_DECIMALS, 2, true)}`} />
                    </div>
                    <TooltipItem label={`Current ${toTokenInfo?.symbol} long`} val={`$${formatAmount(toTokenInfo?.longSize, USD_DECIMALS, 2, true)}`} />
                  </>
                }
              >
                <span className="toolBottom">{formatAmount(toTokenInfo?.maxAvailableLong, USD_DECIMALS, 2, true)}</span>
              </Tooltips>
          }
        </div>
      </div>
    );
  };

  const fromBalance = fromTokenInfo ? fromTokenInfo.balance : bigNumberify(0);
  const toBalance = toTokenInfo ? toTokenInfo.balance : bigNumberify(0);

  const fromAmount = parseValue(fromValue, fromToken && fromToken.decimals);
  const toAmount = parseValue(toValue, toToken && toToken.decimals);

  const isPotentialWrap = (fromToken?.isNative && toToken?.isWrapped) || (fromToken?.isWrapped && toToken?.isNative);
  const isWrapOrUnwrap = isSwap && isPotentialWrap;
  const [needApproval, setNeedApproval] = useState(true)
  const needApprove =
    fromTokenAddress !== AddressZero &&
    tokenAllowance &&
    fromAmount &&
    fromAmount.gt(tokenAllowance) &&
    !isWrapOrUnwrap;

  useEffect(() => {
    setNeedApproval(needApprove)
  }, [needApprove])
  const prevFromTokenAddress = usePrevious(fromTokenAddress);
  const prevNeedApproval = usePrevious(needApproval);
  const prevToTokenAddress = usePrevious(toTokenAddress);

  const fromUsdMin = getUsd(fromAmount, fromTokenAddress, false, infoTokens);
  const toUsdMax = getUsd(toAmount, toTokenAddress, true, infoTokens, orderOption, triggerPriceUsd);
  const indexTokenAddress = toTokenAddress === AddressZero ? nativeTokenAddress : toTokenAddress;
  const collateralTokenAddress = isLong ? indexTokenAddress : shortCollateralAddress;
  const collateralToken = TOKEN_MAP[collateralTokenAddress.toLowerCase()];

  const [triggerRatioValue, setTriggerRatioValue] = useState("");


  const triggerRatioInverted = useMemo(() => {
    return isTriggerRatioInverted(fromTokenInfo, toTokenInfo);
  }, [toTokenInfo, fromTokenInfo]);

  const maxToTokenOut = useMemo(() => {
    const value = toTokenInfo?.availableAmount?.gt(toTokenInfo.poolAmount?.sub(toTokenInfo.bufferAmount))
      ? toTokenInfo?.poolAmount?.sub(toTokenInfo.bufferAmount)
      : toTokenInfo?.availableAmount;

    if (!value) {
      return bigNumberify(0);
    }

    return value.gt(0) ? value : bigNumberify(0);
  }, [toTokenInfo]);

  const maxToTokenOutUSD = useMemo(() => {
    return getUsd(maxToTokenOut, toTokenAddress, false, infoTokens);
  }, [maxToTokenOut, toTokenAddress, infoTokens]);

  const maxFromTokenInUSD = useMemo(() => {
    const value = fromTokenInfo?.maxUsdxAmount
      ?.sub(fromTokenInfo.usdxAmount)
      .mul(expandDecimals(1, USD_DECIMALS))
      .div(expandDecimals(1, USDX_DECIMALS));

    if (!value) {
      return bigNumberify(0);
    }

    return value.gt(0) ? value : bigNumberify(0);
  }, [fromTokenInfo]);

  const maxFromTokenIn = useMemo(() => {
    if (fromTokenInfo?.maxPrice > 0) {
      return maxFromTokenInUSD?.mul(expandDecimals(1, fromTokenInfo?.decimals)).div(fromTokenInfo?.maxPrice).toString();
    } else {
      return bigNumberify(0);
    }
  }, [maxFromTokenInUSD, fromTokenInfo]);

  let maxSwapAmountUsd = bigNumberify(0);

  if (maxToTokenOutUSD && maxFromTokenInUSD) {
    maxSwapAmountUsd = maxToTokenOutUSD.lt(maxFromTokenInUSD) ? maxToTokenOutUSD : maxFromTokenInUSD;
  }

  const triggerRatio = useMemo(() => {
    if (!triggerRatioValue) {
      return bigNumberify(0);
    }
    let ratio = parseValue(triggerRatioValue, USD_DECIMALS);
    if (ratio.eq(0)) {
      return bigNumberify(0);
    }
    if (triggerRatioInverted) {
      ratio = PRECISION.mul(PRECISION).div(ratio);
    }
    return ratio;
  }, [triggerRatioValue, triggerRatioInverted]);

  useEffect(() => {
    if (
      fromToken &&
      fromTokenAddress === prevFromTokenAddress &&
      !needApproval &&
      prevNeedApproval &&
      isWaitingForApproval
    ) {
      setIsWaitingForApproval(false);
      // toastSuccess(`${fromToken.symbol} approved!`)
    }
  }, [
    fromTokenAddress,
    prevFromTokenAddress,
    needApproval,
    prevNeedApproval,
    setIsWaitingForApproval,
    fromToken.symbol,
    isWaitingForApproval,
    fromToken,
  ]);

  useEffect(() => {
    if (!toTokens.find(token => token.address === toTokenAddress)) {
      setToTokenAddress(swapOption, toTokens[0].address);
    }
  }, [swapOption, toTokens, toTokenAddress, setToTokenAddress]);

  useEffect(() => {
    if (swapOption !== SHORT) {
      return;
    }
    if (toTokenAddress === prevToTokenAddress) {
      return;
    }
    for (let i = 0;i < stableTokens.length;i++) {
      const stableToken = stableTokens[i];
      const key = getPositionKey(address, stableToken.address, toTokenAddress, false, nativeTokenAddress);
      const position = positionsMap[key];
      if (position && position.size && position.size.gt(0)) {
        setShortCollateralAddress(position.collateralToken);
        return;
      }
    }
  }, [
    address,
    toTokenAddress,
    prevToTokenAddress,
    swapOption,
    positionsMap,
    stableTokens,
    nativeTokenAddress,
    shortCollateralAddress,
    setShortCollateralAddress,
  ]);

  useEffect(() => {
    const updateSwapAmounts = () => {
      if (anchorOnFromAmount) {
        if (!fromAmount) {
          setToValue("");
          return;
        }
        if (toToken) {
          const { amount: nextToAmount } = getNextToAmount(
            plpName,
            chainId,
            fromAmount,
            fromTokenAddress,
            toTokenAddress,
            infoTokens,
            undefined,
            !isMarketOrder && triggerRatio,
            usdxSupply,
            totalTokenWeights,
            isSwap,
          );

          const nextToValue = formatAmountFree(nextToAmount, toToken.decimals, toToken.decimals);
          setToValue(nextToValue);
        }
        return;
      }

      if (!toAmount) {
        setFromValue("");
        return;
      }
      if (fromToken) {
        const { amount: nextFromAmount } = getNextFromAmount(
          plpName,
          chainId,
          toAmount,
          fromTokenAddress,
          toTokenAddress,
          infoTokens,
          undefined,
          !isMarketOrder && triggerRatio,
          usdxSupply,
          totalTokenWeights,
          isSwap,
        );
        const nextFromValue = formatAmountFree(nextFromAmount, fromToken.decimals, fromToken.decimals);
        setFromValue(nextFromValue);
      }
    };

    const updateLeverageAmounts = () => {
      // if (!hasLeverageOption) {
      //   return;
      // }
      if (anchorOnFromAmount) {
        if (!fromAmount) {
          setToValue("");
          return;
        }

        const toTokenInfo = getTokenInfo(infoTokens, toTokenAddress);
        if (toTokenInfo && toTokenInfo.maxPrice && fromUsdMin && fromUsdMin.gt(0)) {
          const leverageMultiplier = parseInt(leverageOption * BASIS_POINTS_DIVISOR);
          const toTokenPriceUsd =
            !isMarketOrder && triggerPriceUsd && triggerPriceUsd.gt(0) ? triggerPriceUsd : toTokenInfo.maxPrice;

          const { feeBasisPoints } = getNextToAmount(
            plpName,
            chainId,
            fromAmount,
            fromTokenAddress,
            collateralTokenAddress,
            infoTokens,
            undefined,
            undefined,
            usdxSupply,
            totalTokenWeights,
            isSwap,
          );

          let fromUsdMinAfterFee = fromUsdMin;
          if (feeBasisPoints) {
            fromUsdMinAfterFee = fromUsdMin.mul(BASIS_POINTS_DIVISOR - feeBasisPoints).div(BASIS_POINTS_DIVISOR);
          }
          const toNumerator = fromUsdMinAfterFee.mul(leverageMultiplier).mul(BASIS_POINTS_DIVISOR);
          const toDenominator = bigNumberify(MARGIN_FEE_BASIS_POINTS)
            .mul(leverageMultiplier)
            .add(bigNumberify(BASIS_POINTS_DIVISOR).mul(BASIS_POINTS_DIVISOR));

          const nextToUsd = toNumerator.div(toDenominator);

          const nextToAmount = nextToUsd.mul(expandDecimals(1, toToken.decimals)).div(toTokenPriceUsd);

          const nextToValue = formatAmountFree(nextToAmount, toToken.decimals, toToken.decimals);

          setToValue(nextToValue);
        }
        return;
      }

      if (!toAmount) {
        setFromValue("");
        return;
      }

      const fromTokenInfo = getTokenInfo(infoTokens, fromTokenAddress);

      if (fromTokenInfo && fromTokenInfo?.minPrice && toUsdMax && toUsdMax.gt(0)) {
        const leverageMultiplier = parseInt(leverageOption * BASIS_POINTS_DIVISOR);

        const baseFromAmountUsd = toUsdMax.mul(BASIS_POINTS_DIVISOR).div(leverageMultiplier);

        let fees = toUsdMax.mul(MARGIN_FEE_BASIS_POINTS).div(BASIS_POINTS_DIVISOR);

        const { feeBasisPoints } = getNextToAmount(
          plpName,
          chainId,
          fromAmount,
          fromTokenAddress,
          collateralTokenAddress,
          infoTokens,
          undefined,
          undefined,
          usdxSupply,
          totalTokenWeights,
          isSwap,
        );

        if (feeBasisPoints) {
          const swapFees = baseFromAmountUsd.mul(feeBasisPoints).div(BASIS_POINTS_DIVISOR);
          fees = fees.add(swapFees);
        }

        const nextFromUsd = baseFromAmountUsd.add(fees);

        const nextFromAmount = nextFromUsd.mul(expandDecimals(1, fromToken.decimals)).div(fromTokenInfo?.minPrice);

        const nextFromValue = formatAmountFree(nextFromAmount, fromToken.decimals, fromToken.decimals);

        setFromValue(nextFromValue);
      }
    };

    if (isSwap) {
      updateSwapAmounts();
    }

    if (isLong || isShort) {
      updateLeverageAmounts();
    }
  }, [
    anchorOnFromAmount,
    fromAmount,
    toAmount,
    fromToken,
    toToken,
    fromTokenAddress,
    toTokenAddress,
    infoTokens,
    isSwap,
    isLong,
    isShort,
    leverageOption,
    fromUsdMin,
    toUsdMax,
    isMarketOrder,
    triggerPriceUsd,
    triggerRatio,
    hasLeverageOption,
    usdxSupply,
    totalTokenWeights,
    chainId,
    collateralTokenAddress,
    indexTokenAddress,
  ]);

  let entryMarkPrice;
  let exitMarkPrice;
  if (toTokenInfo) {
    entryMarkPrice = swapOption === LONG ? toTokenInfo.maxPrice : toTokenInfo.minPrice;
    exitMarkPrice = swapOption === LONG ? toTokenInfo.minPrice : toTokenInfo.maxPrice;
  }
  let leverage = bigNumberify(0);
  if (fromUsdMin && toUsdMax && fromUsdMin.gt(0)) {
    const fees = toUsdMax.mul(MARGIN_FEE_BASIS_POINTS).div(BASIS_POINTS_DIVISOR);
    if (fromUsdMin.sub(fees).gt(0)) {
      leverage = toUsdMax.mul(BASIS_POINTS_DIVISOR).div(fromUsdMin.sub(fees));
    }
  }

  let nextAveragePrice = isMarketOrder ? entryMarkPrice : triggerPriceUsd;
  if (hasExistingPosition) {
    let nextDelta, nextHasProfit;

    if (isMarketOrder) {
      nextDelta = existingPosition.delta;
      nextHasProfit = existingPosition.hasProfit;
    } else {
      const data = calculatePositionDelta(triggerPriceUsd || bigNumberify(0), existingPosition);
      nextDelta = data.delta;
      nextHasProfit = data.hasProfit;
    }

    nextAveragePrice = getNextAveragePrice({
      size: existingPosition.size,
      sizeDelta: toUsdMax,
      hasProfit: nextHasProfit,
      delta: nextDelta,
      nextPrice: isMarketOrder ? entryMarkPrice : triggerPriceUsd,
      isLong,
    });
  }

  const liquidationPrice = getLiquidationPrice({
    isLong,
    size: hasExistingPosition ? existingPosition.size : bigNumberify(0),
    collateral: hasExistingPosition ? existingPosition.collateral : bigNumberify(0),
    averagePrice: nextAveragePrice,
    entryFundingRate: hasExistingPosition ? existingPosition.entryFundingRate : bigNumberify(0),
    cumulativeFundingRate: hasExistingPosition ? existingPosition.cumulativeFundingRate : bigNumberify(0),
    sizeDelta: toUsdMax,
    collateralDelta: fromUsdMin,
    increaseCollateral: true,
    increaseSize: true,
  });
  // const liquidationPrice = getLiquidationPrice2({
  //   isLong,
  //   size: hasExistingPosition ? existingPosition.size : toTokenInfo?.size,
  //   collateral: hasExistingPosition ? existingPosition.collateral : toTokenInfo?.collateral,
  //   averagePrice: nextAveragePrice,
  //   pendingPremiumFee: toTokenInfo?.pendingPremiumFee,
  //   pendingFundingFee: toTokenInfo?.pendingFundingFee,
  //   pendingPositionFee: toTokenInfo?.pendingPositionFee,
  // }, toTokenInfo?.guaranteedUsd)
  const existingLiquidationPrice = existingPosition ? getLiquidationPrice(existingPosition) : undefined;
  // const existingLiquidationPrice = existingPosition ? getLiquidationPrice2(existingPosition) : undefined;
  let displayLiquidationPrice = liquidationPrice ? liquidationPrice : existingLiquidationPrice;

  if (hasExistingPosition) {
    const collateralDelta = fromUsdMin ? fromUsdMin : bigNumberify(0);
    const sizeDelta = toUsdMax ? toUsdMax : bigNumberify(0);
    leverage = getLeverage({
      size: existingPosition.size,
      sizeDelta,
      collateral: existingPosition.collateral,
      collateralDelta,
      increaseCollateral: true,
      entryFundingRate: existingPosition.entryFundingRate,
      cumulativeFundingRate: existingPosition.cumulativeFundingRate,
      increaseSize: true,
      hasProfit: existingPosition.hasProfit,
      delta: existingPosition.delta,
      includeDelta: savedIsPnlInLeverage,
    });
  } else if (hasLeverageOption) {
    leverage = bigNumberify(parseInt(leverageOption * BASIS_POINTS_DIVISOR));
  }

  const getSwapError = () => {
    if (fromTokenAddress === toTokenAddress) {
      return ["Select different tokens"];
    }

    if (!isMarketOrder) {
      if ((toToken.isStable || toToken.isUsdx) && (fromToken.isStable || fromToken.isUsdx)) {
        return ["Select different tokens"];
      }

      if (fromToken.isNative && toToken.isWrapped) {
        return ["Select different tokens"];
      }

      if (toToken.isNative && fromToken.isWrapped) {
        return ["Select different tokens"];
      }
    }

    if (!fromAmount || fromAmount.eq(0)) {
      return ["Enter an amount"];
    }
    if (!toAmount || toAmount.eq(0)) {
      return ["Enter an amount"];
    }

    const fromTokenInfo = getTokenInfo(infoTokens, fromTokenAddress);
    if (!fromTokenInfo || !fromTokenInfo.minPrice) {
      return ["Incorrect network"];
    }
    // if (fromTokenInfo && fromTokenInfo.balance && fromAmount && fromAmount.gt(fromTokenInfo.balance)) {
    //   return [`Insufficient ${fromTokenInfo.symbol} balance`];
    // }

    const toTokenInfo = getTokenInfo(infoTokens, toTokenAddress);

    if (!isMarketOrder) {
      if (!triggerRatioValue || triggerRatio.eq(0)) {
        return ["Enter a price"];
      }

      const currentRate = getExchangeRate(fromTokenInfo, toTokenInfo);
      if (currentRate && currentRate.lt(triggerRatio)) {
        return [`Price ${triggerRatioInverted ? "below" : "above"} Mark Price`];
      }
    }

    // if (
    //   !isWrapOrUnwrap &&
    //   toToken &&
    //   toTokenAddress !== USDX_ADDRESS &&
    //   toTokenInfo &&
    //   toTokenInfo.availableAmount &&
    //   toAmount.gt(toTokenInfo.availableAmount)
    // ) {
    //   return ["Insufficient liquidity"];
    // }
    // if (
    //   !isWrapOrUnwrap &&
    //   toAmount &&
    //   toTokenInfo.bufferAmount &&
    //   toTokenInfo.poolAmount &&
    //   toTokenInfo.bufferAmount.gt(toTokenInfo.poolAmount.sub(toAmount))
    // ) {
    //   return ["Insufficient liquidity"];
    // }

    if (
      fromUsdMin &&
      fromTokenInfo.maxUsdxAmount &&
      fromTokenInfo.maxUsdxAmount.gt(0) &&
      fromTokenInfo.usdxAmount &&
      fromTokenInfo.maxPrice
    ) {
      const usdxFromAmount = adjustForDecimals(fromUsdMin, USD_DECIMALS, USDX_DECIMALS);
      const nextUsdxAmount = fromTokenInfo.usdxAmount.add(usdxFromAmount);

      if (nextUsdxAmount.gt(fromTokenInfo.maxUsdxAmount)) {
        return [`${fromTokenInfo.symbol} pool exceeded`];
      }
    }

    return [false];
  };

  const getLeverageError = () => {
    if (hasOutdatedUi) {
      return ["Page outdated, please refresh"];
    }

    if (!toAmount || toAmount.eq(0)) {
      return ["Enter an amount"];
    }
    let toTokenInfo = getTokenInfo(currInfoTokens, toTokenAddress);
    if (toTokenInfo && toTokenInfo.isStable) {
      return [`${swapOption === LONG ? "Longing" : "Shorting"} ${toTokenInfo.symbol} not supported`];
    }

    const fromTokenInfo = getTokenInfo(currInfoTokens, fromTokenAddress);
    if (fromTokenInfo && fromTokenInfo.balance && fromAmount && fromAmount.gt(fromTokenInfo.balance)) {
      return [`Insufficient ${fromTokenInfo.symbol} balance`];
    }

    if (leverage && leverage.eq(0)) {
      return ["Enter an amount"];
    }
    if (!isMarketOrder && (!triggerPriceValue || triggerPriceUsd.eq(0))) {
      return ["Enter a price"];
    }

    if (!hasExistingPosition && fromUsdMin && fromUsdMin.lt(expandDecimals(10, USD_DECIMALS))) {
      return ["Min order: 10 USD"];
    }

    if (leverage && leverage.lt(1.1 * BASIS_POINTS_DIVISOR)) {
      return ["Min leverage: 1.1x"];
    }

    if (leverage && leverage.gt(50.5 * BASIS_POINTS_DIVISOR)) {
      return ["Max leverage: 50.5x"];
    }

    if (!isMarketOrder && entryMarkPrice && triggerPriceUsd && !savedShouldDisableOrderValidation) {
      if (isLong && entryMarkPrice.lt(triggerPriceUsd)) {
        return ["Price above Mark Price"];
      }
      if (!isLong && entryMarkPrice.gt(triggerPriceUsd)) {
        return ["Price below Mark Price"];
      }
    }

    if (isLong) {
      let requiredAmount = toAmount;
      if (fromTokenAddress !== toTokenAddress) {
        const { amount: swapAmount } = getNextToAmount(
          plpName,
          chainId,
          fromAmount,
          fromTokenAddress,
          toTokenAddress,
          infoTokens,
          undefined,
          undefined,
          usdxSupply,
          totalTokenWeights,
          isSwap,
        );
        requiredAmount = requiredAmount.add(swapAmount);
        if (toToken && toTokenAddress !== USDX_ADDRESS) {
          if (!toTokenInfo.availableAmount) {
            return ["Liquidity data not loaded"];
          }
          if (!isGNS && toTokenInfo.availableAmount && requiredAmount.gt(toTokenInfo.availableAmount)) {
            return ["Insufficient liquidity"];
          }
        }

        if (
          !isGNS &&
          toTokenInfo.poolAmount &&
          toTokenInfo.bufferAmount &&
          toTokenInfo.bufferAmount.gt(toTokenInfo.poolAmount.sub(swapAmount))
        ) {
          return ["Insufficient liquidity", true, "BUFFER"];
        }

        if (
          fromUsdMin &&
          fromTokenInfo.maxUsdxAmount &&
          fromTokenInfo.maxUsdxAmount.gt(0) &&
          fromTokenInfo.minPrice &&
          fromTokenInfo.usdxAmount
        ) {
          const usdxFromAmount = adjustForDecimals(fromUsdMin, USD_DECIMALS, USDX_DECIMALS);
          const nextUsdxAmount = fromTokenInfo.usdxAmount.add(usdxFromAmount);
          if (nextUsdxAmount.gt(fromTokenInfo.maxUsdxAmount)) {
            return [`${fromTokenInfo.symbol} pool exceeded, try different token`, true, "MAX_USDX"];
          }
        }
      }

      if (toTokenInfo && toTokenInfo.maxPrice) {
        const sizeUsd = toAmount.mul(toTokenInfo.maxPrice).div(expandDecimals(1, toTokenInfo.decimals));
        if (
          toTokenInfo.maxGlobalLongSize &&
          toTokenInfo.maxGlobalLongSize.gt(0) &&
          toTokenInfo.maxAvailableLong &&
          sizeUsd.gt(toTokenInfo.maxAvailableLong)
        ) {
          return [`Max ${toTokenInfo.symbol} long exceeded`];
        }
      }
    }

    if (isShort) {
      let stableTokenAmount = bigNumberify(0);
      if (fromTokenAddress !== shortCollateralAddress && fromAmount && fromAmount.gt(0)) {
        const { amount: nextToAmount } = getNextToAmount(
          plpName,
          chainId,
          fromAmount,
          fromTokenAddress,
          shortCollateralAddress,
          currInfoTokens,
          undefined,
          undefined,
          usdxSupply,
          totalTokenWeights,
          isSwap,
        );
        stableTokenAmount = nextToAmount;
        if (stableTokenAmount.gt(shortCollateralToken.availableAmount)) {
          return [`Insufficient liquidity, change "Profits In"`];
        }

        if (
          shortCollateralToken.bufferAmount &&
          shortCollateralToken.poolAmount &&
          shortCollateralToken.bufferAmount.gt(shortCollateralToken.poolAmount.sub(stableTokenAmount))
        ) {
          // suggest swapping to collateralToken
          return [`Insufficient liquidity, change "Profits In"`, true, "BUFFER"];
        }

        if (
          fromTokenInfo.maxUsdxAmount &&
          fromTokenInfo.maxUsdxAmount.gt(0) &&
          fromTokenInfo.minPrice &&
          fromTokenInfo.usdxAmount
        ) {
          const usdxFromAmount = adjustForDecimals(fromUsdMin, USD_DECIMALS, USDX_DECIMALS);
          const nextUsdxAmount = fromTokenInfo.usdxAmount.add(usdxFromAmount);
          if (nextUsdxAmount.gt(fromTokenInfo.maxUsdxAmount)) {
            return [`${fromTokenInfo.symbol} pool exceeded, try different token`, true, "MAX_USDX"];
          }
        }
      }
      if (
        !shortCollateralToken ||
        !fromTokenInfo ||
        !toTokenInfo ||
        !toTokenInfo.maxPrice ||
        !shortCollateralToken.availableAmount
      ) {
        return ["Fetching token info..."];
      }

      const sizeUsd = toAmount.mul(toTokenInfo.maxPrice).div(expandDecimals(1, toTokenInfo.decimals));
      const sizeTokens = sizeUsd
        .mul(expandDecimals(1, shortCollateralToken.decimals))
        .div(shortCollateralToken.minPrice);

      if (!toTokenInfo.maxAvailableShort) {
        return ["Liquidity data not loaded"];
      }

      if (
        !isGNS &&
        toTokenInfo.maxGlobalShortSize &&
        toTokenInfo.maxGlobalShortSize.gt(0) &&
        toTokenInfo.maxAvailableShort &&
        sizeUsd.gt(toTokenInfo.maxAvailableShort)
      ) {
        return [`Max ${toTokenInfo.symbol} short exceeded`];
      }

      stableTokenAmount = stableTokenAmount.add(sizeTokens);
      if (stableTokenAmount.gt(shortCollateralToken.availableAmount)) {
        return [`Insufficient liquidity, change "Profits In"`];
      }
    }

    return [false];
  };

  const getToLabel = () => {
    if (isSwap) {
      return "Receive";
    }
    if (isLong) {
      return "Long";
    }
    return "Short";
  };

  const getError = () => {
    if (isSwap) {
      return getSwapError();
    }
    return getLeverageError();
  };

  const renderOrdersToa = () => {
    if (!ordersToaOpen) {
      return null;
    }

    return (
      <OrdersToa
        setIsVisible={setOrdersToaOpen}
        approveOrderBook={approveOrderBook}
        isPluginApproving={isPluginApproving}
      />
    );
  };

  const renderErrorModal = () => {
    const inputCurrency = fromToken.address === AddressZero ? "ETH" : fromToken.address;
    let outputCurrency;
    if (isLong) {
      outputCurrency = toToken.address === AddressZero ? "ETH" : toToken.address;
    } else {
      outputCurrency = shortCollateralToken.address;
    }
    let externalSwapUrl = `https://app.uniswap.org/#/swap?inputCurrency=${inputCurrency}&outputCurrency=${outputCurrency}`;
    let externalSwapName = "Uniswap";
    const label =
      modalError === "BUFFER" ? `${shortCollateralToken.symbol} Required` : `${fromToken.symbol} Capacity Reached`;
    const swapTokenSymbol = isLong ? toToken.symbol : shortCollateralToken.symbol;
    return (
      <Modal isVisible={!!modalError} setIsVisible={setModalError} label={label} className="Error-modal font-base">
        <div>You need to select {swapTokenSymbol} as the "Pay" token to initiate this trade.</div>
        <br />
        {isShort && (
          <div>
            Alternatively you can select a different "Profits In" token.
            <br />
            <br />
          </div>
        )}
        <div href={externalSwapUrl} target="_blank" rel="noreferrer">
          Buy {swapTokenSymbol} on {externalSwapName}
        </div>
      </Modal>
    );
  };

  const isPrimaryEnabled = () => {
    if (isStopOrder) {
      return true;
    }
    if (!connected) {
      return true;
    }
    const [error, modal] = getError();
    if (error && !modal) {
      return false;
    }
    if (needOrderBookApproval && isWaitingForPluginApproval) {
      return false;
    }
    if ((needApproval && isWaitingForApproval) || isApproving) {
      return false;
    }
    if (needPositionRouterApproval && isWaitingForPositionRouterApproval) {
      return false;
    }
    if (isPositionRouterApproving) {
      return false;
    }
    if (isApproving) {
      return false;
    }
    if (isSubmitting) {
      return false;
    }

    return true;
  };

  const getPrimaryText = () => {
    if (isStopOrder) {
      return "Open a position";
    }
    if (!connected) {
      return "Connect Wallet";
    }
    if (!isSupportedChain(Number(chainId))) {
      return "Incorrect Network";
    }
    const [error, modal] = getError();
    if (error && !modal) {
      return <div className="" style={{ whiteSpace: "break-spaces" }}>{error}</div>;
    }
    // if (needPositionRouterApproval && isWaitingForPositionRouterApproval) {
    //   return <Box display="flex" justifyContent="center" alignItems="center">
    //     <div className="">Enabling Leverage</div>
    //     <img src={PendingIcon} height={20} className="ml-12" />
    //   </Box>
    // }
    // if (isPositionRouterApproving) {
    //   return <Box display="flex" justifyContent="center" alignItems="center">
    //     <div className="">Enabling Leverage</div>
    //     <img src={PendingIcon} height={20} className="ml-12" />
    //   </Box>
    // }
    // if (needPositionRouterApproval) {
    //   return "Enable Leverage";
    // }

    if (needApproval && isWaitingForApproval) {
      return <Box display="flex" justifyContent="center" alignItems="center">
        <div className="">Waiting for Approval</div>
        <img src={PendingIcon} height={20} className="ml-12" />
      </Box>;
    }
    if (isApproving) {
      return <Box display="flex" justifyContent="center" alignItems="center">
        <div className="">Approving {fromToken.symbol}</div>
        <img src={PendingIcon} height={20} className="ml-12" />
      </Box>;
    }
    if (needApproval) {
      return `Approve ${fromToken.symbol}`;
    }

    // if (needOrderBookApproval && isWaitingForPluginApproval) {
    //   return <Box display="flex" justifyContent="center" alignItems="center">
    //     <div className="">Enabling Orders</div>
    //     <img src={PendingIcon} height={20} className="ml-12" />
    //   </Box>
    // }
    // if (isPluginApproving) {
    //   return <Box display="flex" justifyContent="center" alignItems="center">
    //     <div className="">Enabling Orders</div>
    //     <img src={PendingIcon} height={20} className="ml-12" />
    //   </Box>
    // }
    // if (needOrderBookApproval) {
    //   return "Enable Orders";
    // }

    // if (!isMarketOrder) return `Create 1 ${orderOption.charAt(0) + orderOption.substring(1).toLowerCase()} Order`;

    if (isSwap) {
      if (toUsdMax && toUsdMax.lt(fromUsdMin.mul(95).div(100))) {
        return "High Slippage, Swap Anyway";
      }
      return "Swap";
    }

    if (isLong) {
      const indexTokenInfo = getTokenInfo(infoTokens, toTokenAddress);
      if (indexTokenInfo && indexTokenInfo.minPrice) {
        const { amount: nextToAmount } = getNextToAmount(
          plpName,
          chainId,
          fromAmount,
          fromTokenAddress,
          indexTokenAddress,
          infoTokens,
          undefined,
          undefined,
          usdxSupply,
          totalTokenWeights,
          isSwap,
        );
        const nextToAmountUsd = nextToAmount
          .mul(indexTokenInfo.minPrice)
          .div(expandDecimals(1, indexTokenInfo.decimals));
        // console.log("nextToAmountUsd", nextToAmountUsd, "fromUsdMin", fromUsdMin)
        if (fromTokenAddress === USDX_ADDRESS && nextToAmountUsd.lt(fromUsdMin.mul(98).div(100))) {
          return "High USDX Slippage, Long Anyway";
        }
      }
      return `Long ${toToken.symbol}`;
    }

    return `Short ${toToken.symbol}`;
  };

  const onSelectFromToken = token => {
    setFromTokenAddress(swapOption, token.address);
    setIsWaitingForApproval(false);

    if (isShort && token.isStable) {
      setShortCollateralAddress(token.address);
    }
  };

  const onSelectShortCollateralAddress = token => {
    setShortCollateralAddress(token.address);
  };

  const onSelectToToken = token => {
    setToTokenAddress(swapOption, token.address);
  };

  const onFromValueChange = e => {
    setAnchorOnFromAmount(true);
    setFromValue(e.target.value);
  };

  const onToValueChange = e => {
    setAnchorOnFromAmount(false);
    setToValue(e.target.value);
  };

  const switchTokens = () => {
    if (PLPMAP[plpName].isGNS) return;
    if (fromAmount && toAmount) {
      if (anchorOnFromAmount) {
        setToValue(formatAmountFree(fromAmount, fromToken.decimals, 8));
      } else {
        setFromValue(formatAmountFree(toAmount, toToken.decimals, 8));
      }
      setAnchorOnFromAmount(!anchorOnFromAmount);
    }
    setIsWaitingForApproval(false);

    const updatedTokenSelection = JSON.parse(JSON.stringify(tokenSelection));

    updatedTokenSelection[swapOption] = {
      from: toTokenAddress,
      to: fromTokenAddress,
    };
    setTokenSelection(updatedTokenSelection);
  };

  const wrap = async () => {
    setIsSubmitting(true);
    const contract = new ethers.Contract(nativeTokenAddress, WETH.abi, signer);
    Api.callContract(chainId, contract, "deposit", {
      value: fromAmount,
      sentMsg: "Swap submitted.",
      successMsg: `Swapped ${formatAmount(fromAmount, fromToken.decimals, 4, true)} ${fromToken.symbol
        } for ${formatAmount(toAmount, toToken.decimals, 4, true)} ${toToken.symbol}!`,
      failMsg: "Swap failed.",
      setPendingTxns,
    })
      .then(async res => { })
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  const unwrap = async () => {
    setIsSubmitting(true);

    const contract = new ethers.Contract(nativeTokenAddress, WETH.abi, signer);
    Api.callContract(chainId, contract, "withdraw", [fromAmount], {
      sentMsg: "Swap submitted!",
      failMsg: "Swap failed.",
      successMsg: `Swapped ${formatAmount(fromAmount, fromToken.decimals, 4, true)} ${fromToken.symbol
        } for ${formatAmount(toAmount, toToken.decimals, 4, true)} ${toToken.symbol}!`,
      setPendingTxns,
    })
      .then(async res => { })
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  const swap = async () => {
    if (fromToken.isNative && toToken.isWrapped) {
      wrap();
      return;
    }
    if (fromTokenAddress.isWrapped && toToken.isNative) {
      unwrap();
      return;
    }

    setIsSubmitting(true);
    let path = [fromTokenAddress, toTokenAddress];
    // let path = [ADDRESS[chainId].ETH, ADDRESS[chainId].BTC];
    if (anchorOnFromAmount) {
      const { path: multiPath } = getNextToAmount(
        plpName,
        chainId,
        fromAmount,
        fromTokenAddress,
        toTokenAddress,
        infoTokens,
        undefined,
        undefined,
        usdxSupply,
        totalTokenWeights,
        isSwap,
      );
      if (multiPath) {
        path = multiPath;
      }
    } else {
      const { path: multiPath } = getNextFromAmount(
        plpName,
        chainId,
        toAmount,
        fromTokenAddress,
        toTokenAddress,
        infoTokens,
        undefined,
        undefined,
        usdxSupply,
        totalTokenWeights,
        isSwap,
      );
      if (multiPath) {
        path = multiPath;
      }
    }

    let method;
    let contract;
    let value;
    let params;
    let minOut;
    if (shouldRaiseGasError(getTokenInfo(infoTokens, fromTokenAddress), fromAmount)) {
      setIsSubmitting(false);
      setIsPendingConfirmation(true);
      toastError(`Leave at least ${formatAmount(DUST_BNB, 18, 3)} ${getConstant(chainId, "nativeTokenSymbol")} for gas`)
      return;
    }
    const ids = replaceNativeTokenAddress(path, nativeTokenAddress).map((x) => infoTokens[x.toLowerCase()]?.pirceId);
    const prcieData = await getPythPrices(ids, chainId);
    const minFees = minExecutionFee;
    // await vaultPriceFeedcontract.updatePriceFeeds()
    if (!prcieData || !minExecutionFee) {
      toastError("System error!");
      setIsSubmitting(false);
      setIsPendingConfirmation(false);
      return
    }

    if (!isMarketOrder) {
      minOut = toAmount;
      Api.createSwapOrder(plpName, chainId, provider, path, fromAmount, minOut, triggerRatio, nativeTokenAddress, orderBookAddress, vault_address, fees, minFees, prcieData.priceData, {
        sentMsg: "Swap Order submitted!",
        successMsg: "Swap Order created!",
        failMsg: "Swap Order creation failed.",
        pendingTxns,
        setPendingTxns,
      })
        .then(() => {
          setIsConfirming(false);
        })
        .finally(() => {
          setIsSubmitting(false);
          setIsPendingConfirmation(false);
        });
      return;
    }

    path = replaceNativeTokenAddress(path, nativeTokenAddress);
    method = "swap";
    value = bigNumberify(0)
    if (toTokenAddress === AddressZero) {
      method = "swapTokensToETH";
    }

    minOut = 0; // toAmount.mul(BASIS_POINTS_DIVISOR - allowedSlippage).div(BASIS_POINTS_DIVISOR);
    params = [path, fromAmount, minOut, address, prcieData.priceData];
    if (fromTokenAddress === AddressZero) {
      method = "swapETHToTokens";
      value = fromAmount
      params = [path, minOut, address, prcieData.priceData];
    }
    contract = RouterSignContract
    Api.callContract(chainId, contract, method, params, {
      value,
      sentMsg: `Swap ${!isMarketOrder ? " order " : ""} submitted!`,
      successMsg: `Swapped ${formatAmount(fromAmount, fromToken.decimals, 4, true)} ${fromToken.symbol
        } for ${formatAmount(toAmount, toToken.decimals, 4, true)} ${toToken.symbol}!`,
      failMsg: "Swap failed.",
      setPendingTxns,
    })
      .then(async () => {
        setIsConfirming(false);
      })
      .finally(() => {
        setIsSubmitting(false);
        setIsPendingConfirmation(false);
      });
  };

  const createIncreaseOrder = async () => {
    let path = [fromTokenAddress];
    if (path[0] === USDX_ADDRESS) {
      if (isLong) {
        const stableToken = getMostAbundantStableToken(chainId, infoTokens);
        path.push(stableToken.address);
      } else {
        path.push(shortCollateralAddress);
      }
    }

    const ids = replaceNativeTokenAddress(path, nativeTokenAddress).map((x) => infoTokens[x.toLowerCase()]?.pirceId);
    const prcieData = await getPythPrices(ids, chainId);
    const minFees = minExecutionFee;
    // await vaultPriceFeedcontract.updatePriceFeeds(prcieData.priceData)
    if (!prcieData || !minFees) {
      toastError("System error!");
      setIsSubmitting(false);
      setIsPendingConfirmation(false);
      return
    }


    const minOut = 0;
    const indexToken = TOKEN_MAP[indexTokenAddress.toLowerCase()]
    const successMsg = `
      Created limit order for ${indexToken.symbol} ${isLong ? "Long" : "Short"}: ${formatAmount(
      toUsdMax,
      USD_DECIMALS,
      2,
    )} USD!`;
    return Api.createIncreaseOrder(
      plpName,
      chainId,
      provider,
      nativeTokenAddress,
      path,
      fromAmount,
      indexTokenAddress,
      minOut,
      toUsdMax,
      collateralTokenAddress,
      isLong,
      triggerPriceUsd,
      orderBookAddress,
      vault_address,
      minFees,
      prcieData.priceData,
      {

        pendingTxns,
        setPendingTxns,
        sentMsg: "Limit order submitted!",
        successMsg,
        failMsg: "Limit order creation failed.",
      },
    )
      .then((data) => {
        upDataBlock(data)
        setIsConfirming(false);
      })
      .finally(() => {
        setIsSubmitting(false);
        setIsPendingConfirmation(false);
      });
  };

  let referralCode = ethers.constants.HashZero;



  const increasePosition = async () => {
    setIsSubmitting(true);
    const tokenAddress0 = fromTokenAddress === AddressZero ? nativeTokenAddress : fromTokenAddress;
    const indexTokenAddress = toTokenAddress === AddressZero ? nativeTokenAddress : toTokenAddress;
    let path = [indexTokenAddress]; // assume long
    if (toTokenAddress !== fromTokenAddress) {
      if (!isGNS) {
        path = [tokenAddress0, indexTokenAddress];

      } else {
        path = [tokenAddress0];
      }
    }


    if (fromTokenAddress === AddressZero && toTokenAddress === nativeTokenAddress) {
      path = [nativeTokenAddress];
    }

    if (fromTokenAddress === nativeTokenAddress && toTokenAddress === AddressZero) {
      path = [nativeTokenAddress];
    }

    if (isShort) {
      path = [shortCollateralAddress];
      if (tokenAddress0 !== shortCollateralAddress) {
        if (!isGNS) {
          path = [tokenAddress0, shortCollateralAddress];

        }
      }
    }

    const refPrice = isLong ? toTokenInfo.maxPrice : toTokenInfo.minPrice;
    const priceBasisPoints = isLong ? BASIS_POINTS_DIVISOR + allowedSlippage : BASIS_POINTS_DIVISOR - allowedSlippage;
    const priceLimit = refPrice.mul(priceBasisPoints).div(BASIS_POINTS_DIVISOR);

    const boundedFromAmount = fromAmount ? fromAmount : bigNumberify(0);

    if (fromAmount && fromAmount.gt(0) && fromTokenAddress === USDX_ADDRESS && isLong) {
      const { amount: nextToAmount, path: multiPath } = getNextToAmount(
        plpName,
        chainId,
        fromAmount,
        fromTokenAddress,
        indexTokenAddress,
        infoTokens,
        undefined,
        undefined,
        usdxSupply,
        totalTokenWeights,
        isSwap,
      );
      if (nextToAmount.eq(0)) {
        toastError("Insufficient liquidity");
        return;
      }
      if (multiPath) {
        path = replaceNativeTokenAddress(multiPath);
      }
    }
    const ids = path.concat([indexTokenAddress]).map((x) => infoTokens[x.toLowerCase()]?.pirceId)
    // const priceData = chainInfoArr.map((x) => PythPrices[x.address.toLocaleLowerCase()]);
    const priceData = await getPythPrices(ids, chainId);
    // await vaultPriceFeedcontract.updatePriceFeeds(prcieData.priceData)
    if (!priceData || priceData.length == 0) {
      toastError("System error!");
      setIsSubmitting(false);
      setIsPendingConfirmation(false);
      return
    }


    let params = [
      path, // _path
      indexTokenAddress, // _indexToken
      boundedFromAmount, // _amountIn
      toUsdMax, // _sizeDelta
      isLong, // _isLong
      priceLimit, //_price
      priceData.priceData
    ];

    let method = "increasePositionAndUpdate";
    let value = minExecutionFee;
    if (fromTokenAddress === AddressZero) {
      method = "increasePositionETHAndUpdate";
      value = boundedFromAmount.add(minExecutionFee);

      params = [
        path, // _path
        indexTokenAddress, // _indexToken
        toUsdMax, // _sizeDelta
        isLong, // _isLong
        priceLimit, //_price
        priceData.priceData, //_updaterSignedMsg
      ];
    }

    // try {
    //   await RouterSignContract.increasePositionETHAndUpdate(
    //     path, // _path
    //     indexTokenAddress, // _indexToken
    //     toUsdMax, // _sizeDelta
    //     isLong, // _isLong
    //     priceLimit, //_price
    //     priceData, { value: value })
    // } catch (error) {

    //   console.log(error);

    // }

    if (shouldRaiseGasError(getTokenInfo(infoTokens, fromTokenAddress), fromAmount)) {
      setIsSubmitting(false);
      setIsPendingConfirmation(false);
      toastError(`Leave at least ${formatAmount(DUST_BNB, 18, 3)} ${getConstant(chainId, "nativeTokenSymbol")} for gas`);
      return;
    }

    const indexToken = getTokenInfo(infoTokens, indexTokenAddress);
    const tokenSymbol = indexToken.isWrapped ? getConstant(chainId, "nativeTokenSymbol") : indexToken.symbol;
    const successMsg = `Requested increase of ${tokenSymbol} ${isLong ? "Long" : "Short"} by ${formatAmount(
      toUsdMax,
      USD_DECIMALS,
      2,
    )} USD.`;

    toastWaiting(successMsg)

    // let allmethod = isGNS ? method2 : method;
    // let allparams = isGNS ? params2 : params;
    let allmethod = method
    let allparams = params
    let contracts = RouterSignContract


    Api.callContract(chainId, contracts, allmethod, allparams, {
      value,
      setPendingTxns,
      sentMsg: `${isLong ? "Long" : "Short"} submitted.`,
      failMsg: `${isLong ? "Long" : "Short"} failed.`,
      successMsg,
    })
      .then(async (res) => {
        upDataBlock(res)
        setIsConfirming(false);
      })
      .finally(() => {
        setIsSubmitting(false);
        setIsPendingConfirmation(false);
      });
  };

  const onSwapOptionChange = opt => {
    setSwapOption(opt);
    if (orderOption === STOP) {
      setOrderOption(MARKET);
    }
    setAnchorOnFromAmount(true);
    setFromValue("");
    setToValue("");
    setTriggerPriceValue("");
    setTriggerRatioValue("");

    if (opt === SHORT && infoTokens) {
      const fromToken = TOKEN_MAP[tokenSelection[opt].from.toLowerCase()];
      if (fromToken && fromToken.isStable) {
        setShortCollateralAddress(fromToken.address);
      } else {
        const stableToken = getMostAbundantStableToken(chainId, infoTokens);
        setShortCollateralAddress(stableToken.address);
      }
    }
  };

  const onConfirmationClick = () => {
    if (!connected) {
      connect();
      return;
    }

    // if (needOrderBookApproval) {
    //   approveOrderBook();
    //   return;
    // }

    setIsPendingConfirmation(true);

    if (isSwap) {
      swap();
      return;
    }

    if (orderOption === LIMIT) {
      createIncreaseOrder();
      return;
    }

    increasePosition();
  };

  const approveFromToken = async () => {
    await approveTokens({
      setIsApproving,
      library: provider,
      tokenAddress: fromToken.address,
      spender: spenders,
      chainId: chainId,
      onApproveSubmitted: () => {
        setIsWaitingForApproval(true);
        setNeedApproval(false);

      },
      infoTokens,
      getTokenInfo,
      pendingTxns,
      setPendingTxns,
    });
    // setIsWaitingForApproval(false);
  }

  const onClickPrimary = () => {
    if (isStopOrder) {
      setOrderOption(MARKET);
      return;
    }

    if (!connected) {
      connect();
      return;
    }

    // if (needPositionRouterApproval) {
    //   approvePositionRouter({
    //     sentMsg: "Enable leverage sent.",
    //     failMsg: "Enable leverage failed.",
    //   });
    //   return;
    // }
    if (needApproval) {
      approveToken();
      return;
    }

    // if (needOrderBookApproval) {
    //   setOrdersToaOpen(true);
    //   return;
    // }

    const [, modal, errorCode] = getError();

    if (modal) {
      console.log(errorCode, "errorCode", !!modalError);
      setModalError(errorCode);
      return;
    }

    if (isSwap) {
      if (fromTokenAddress === AddressZero && toTokenAddress === nativeTokenAddress) {
        wrap();
        return;
      }

      if (fromTokenAddress === nativeTokenAddress && toTokenAddress === AddressZero) {
        unwrap();
        return;
      }
    }

    setIsConfirming(true);
    // setIsHigherSlippageAllowed(false);
  };

  const isStopOrder = orderOption === STOP;
  const showFromAndToSection = !isStopOrder;
  const showTriggerPriceSection = !isSwap && !isMarketOrder && !isStopOrder;
  const showTriggerRatioSection = isSwap && !isMarketOrder && !isStopOrder;

  const allFees = useSwapFeeBas(plpName, fromValue, toTokenAddress)
  let fees;
  let feesUsd;
  let feeBps;
  let swapFees;
  let positionFee;
  if (isSwap) {
    if (fromAmount) {
      const address = fromTokenAddress === AddressZero ? nativeTokenAddress : fromTokenAddress
      const bps = allFees[address?.toLowerCase()]?.swapFee || 0
      // const { feeBasisPoints } = getNextToAmount(
      //   plpName,
      //   chainId,
      //   fromAmount,
      //   fromTokenAddress,
      //   toTokenAddress,
      //   infoTokens, //infoTokens currInfoTokens
      //   undefined,
      //   undefined,
      //   usdxSupply,
      //   totalTokenWeights,
      //   isSwap,
      // );
      if (bps !== undefined) {
        fees = fromAmount.mul(bps).div(BASIS_POINTS_DIVISOR);
        const feeTokenPrice =
          fromTokenInfo.address === USDX_ADDRESS ? expandDecimals(1, USD_DECIMALS) : fromTokenInfo.maxPrice;
        feesUsd = fees.mul(feeTokenPrice).div(expandDecimals(1, fromTokenInfo.decimals));
      }
      feeBps = bps;
    }
  } else if (toUsdMax) {
    positionFee = toUsdMax.mul(MARGIN_FEE_BASIS_POINTS).div(BASIS_POINTS_DIVISOR);
    feesUsd = positionFee;
    const { feeBasisPoints } = getNextToAmount(
      plpName,
      chainId,
      fromAmount,
      fromTokenAddress,
      collateralTokenAddress,
      infoTokens,
      undefined,
      undefined,
      usdxSupply,
      totalTokenWeights,
      isSwap,
    );
    if (feeBasisPoints) {
      swapFees = fromUsdMin.mul(feeBasisPoints).div(BASIS_POINTS_DIVISOR);
      feesUsd = feesUsd.add(swapFees);
    }
    feeBps = feeBasisPoints;
  }

  const leverageMarks = {
    2: "2x",
    5: "5x",
    10: "10x",
    15: "15x",
    20: "20x",
    25: "25x",
    30: "30x",
    40: "40x",
    50: "50x",
  };

  if (!fromToken || !toToken) {
    return null;
  }

  let hasZeroBorrowFee = false;
  let borrowFeeText;
  let premiumText;
  if (isLong && toTokenInfo && toTokenInfo.fundingRate) {
    // console.log("toTokenInfo", toTokenInfo, toTokenInfo.fundingRate)
    borrowFeeText = formatAmount(toTokenInfo.fundingRate, 4, 4) + "% / 1h";
    premiumText = formatAmount(toTokenInfo.longRatePerHour, 4, 4) + "% / 1h";
    if (toTokenInfo.fundingRate.eq(0)) {
      // hasZeroBorrowFee = true
    }
  }
  if (isShort && shortCollateralToken && shortCollateralToken.fundingRate) {
    // console.log("shortCollateralToken", shortCollateralToken, shortCollateralToken.fundingRate)
    borrowFeeText = formatAmount(shortCollateralToken.fundingRate, 4, 4) + "% / 1h";
    premiumText = formatAmount(toTokenInfo.shortRatePerHour, 4, 4) + "% / 1h";
    if (shortCollateralToken.fundingRate.eq(0)) {
      // hasZeroBorrowFee = true
    }
  }

  function setFromValueToMaximumAvailable() {
    if (!fromToken || !fromBalance) {
      return;
    }

    const maxAvailableAmount = fromToken.isNative ? fromBalance.sub(bigNumberify(DUST_BNB).mul(2)) : fromBalance;
    setFromValue(formatAmountFree(maxAvailableAmount, fromToken.decimals, fromToken.decimals));
    setAnchorOnFromAmount(true);
  }

  function shouldShowMaxButton() {
    if (!fromToken || !fromBalance) {
      return false;
    }
    const maxAvailableAmount = fromToken.isNative ? fromBalance.sub(bigNumberify(DUST_BNB).mul(2)) : fromBalance;
    return fromValue !== formatAmountFree(maxAvailableAmount, fromToken.decimals, fromToken.decimals);
  }

  const setMarketTableTriggerPrice = () => {
    setTriggerPriceValue(formatAmountFree(entryMarkPrice, USD_DECIMALS, 2))
  }
  return (
    <div className="Exchange-swap-box">
      <div className="Exchange-swap-box-inner App-box-highlight">
        <TradeTable options={SWAP_OPTIONS}
          option={swapOption}
          onChange={onSwapOptionChange} />
        <div className="swap_bg">
          {
            flagOrdersEnabled &&
            <MarketTable option={orderOption} onChange={onOrderOptionChange} entryMarkPrice={formatAmount(entryMarkPrice, USD_DECIMALS, 2, true)}
              setTriggerPrice={setMarketTableTriggerPrice}
              triggerPriceValue={isSwap ? triggerRatioValue : triggerPriceValue}
              onTriggerPriceChange={isSwap ? onTriggerRatioChange : onTriggerPriceChange}
            />
          }
          {showFromAndToSection && (
            <React.Fragment >
              <div className="">
                <div className="new_Exchange-swap">
                  <Box className="Exchange-swap_box">
                    <Box display="flex" alignItems="center">
                      <Column flex={'1'}>
                        <div className="muted  muted5">
                          {fromUsdMin ?
                            <div className="Exchange-swap-usd">Pay: {formatAmount(fromUsdMin, USD_DECIMALS, 2, true)} USD</div>
                            : "Pay"
                          }
                        </div>
                        <div className="Exchange-swap-input-container flex-1">
                          <input
                            type="number"
                            min="0"
                            placeholder="0.0"
                            className="Exchange-swap-input"
                            value={fromValue}
                            onChange={onFromValueChange}
                          />
                        </div>
                      </Column>
                      <Column width={'max-content'} alignItems={'flex-end'}>
                        <Box display="flex" alignItems="center">
                          <TokenSelector
                            className="ml-8 color4"
                            label="Pay"
                            chainId={chainId}
                            tokenAddress={fromTokenAddress}
                            onSelectToken={onSelectFromToken}
                            tokens={fromTokens}
                            infoTokens={infoTokens}
                            showMintingCap={false}
                            showTokenImgInDropdown={true}
                          />
                        </Box>
                        {fromBalance && (
                          <div className="muted  align-right clickable spwabisf" onClick={setFromValueToMaximumAvailable}>
                            Balance: {formatAmount(fromBalance, fromToken.decimals, 4, true)}
                          </div>
                        )}
                      </Column>
                    </Box>
                    <Box mt={'6px'}>
                      <MaxTab balance={formatAmountFree(fromBalance, fromToken.decimals, 4, true)} setInputValue={(value) => {
                        setAnchorOnFromAmount(true);
                        setFromValue(value);
                      }} />
                    </Box>
                  </Box>
                  {(isLong || isShort) && !isStopOrder &&
                    <Leverage
                      isLong={isLong}
                      onChange={value => setLeverageOption(value)}
                      leverageOption={leverageOption}
                      defaultValue={leverageOption}
                    />

                  }
                  <Box mt={'22px'}>
                    <Box height={'1px'} width={'100%'} bgcolor={'rgba(255, 255, 255, 0.16)'} ></Box>
                  </Box>
                </div>
                <div className="new_Exchange-swap">
                  <Box className="Exchange-swap_box">
                    <Box display="flex" alignItems="center">
                      <Column flex={'1'}>
                        <div className="muted  muted54">
                          {toUsdMax && (
                            <div className="Exchange-swap-usd">
                              {getToLabel()}: {formatAmount(toUsdMax, USD_DECIMALS, 2, true)} USD
                            </div>
                          )}
                          {!toUsdMax && getToLabel()}
                        </div>
                        <div className="Exchange-swap-input-container flex-1">
                          <input
                            type="number"
                            min="0"
                            placeholder="0.0"
                            className="Exchange-swap-input"
                            value={toValue}
                            onChange={onToValueChange}
                          />
                        </div></Column>
                      <Column width={'max-content'} alignItems={'flex-end'}>
                        <Box display="flex" alignItems="center">
                          <TokenSelector
                            className="ml-8 color4"
                            label={getTokenLabel()}
                            chainId={chainId}
                            tokenAddress={toTokenAddress}
                            onSelectToken={onSelectToToken}
                            tokens={toTokens}
                            infoTokens={infoTokens}
                            showMintingCap={false}
                            showTokenImgInDropdown={true}
                          />
                        </Box>

                        {toBalance && isSwap && (
                          <div className="muted align-right spwabisf">Balance: {formatAmount(toBalance, toToken.decimals, 4, true)}</div>
                        )}
                      </Column>
                    </Box>
                  </Box>
                </div>
                <Box mt={'4px'}>
                  <Box height={'1px'} width={'100%'} bgcolor={'rgba(255, 255, 255, 0.16)'} ></Box>
                </Box>
              </div>
            </React.Fragment>
          )}

          <div className="swapbox">
            {isSwap && (
              <Box p={'16px'} pb={'0'}>
                <ExchangeInfoRow label="Fees">
                  <div>
                    {!fees && "-"}
                    {fees && (
                      <div >
                        {formatAmount(feeBps, 2, 2, false)}%&nbsp; ({formatAmount(fees, fromToken.decimals, 4, true)}{" "}
                        {fromToken.symbol}: ${formatAmount(feesUsd, USD_DECIMALS, 2, true)})
                      </div>
                    )}
                  </div>
                </ExchangeInfoRow>
              </Box>
            )}
          </div>
          {(isLong || isShort) && !isStopOrder && (
            <div className="Exchange-leverage-box">
              <div className="all_size">
                {isShort && (
                  <div className="Exchange-info-row mt-16">
                    <div className="Exchange-info-label">Profits In</div>
                    <div className="align-right">
                      <TokenSelector
                        label="Profits In"
                        chainId={chainId}
                        tokenAddress={shortCollateralAddress}
                        onSelectToken={onSelectShortCollateralAddress}
                        tokens={stableTokens}
                        showTokenImgInDropdown={true}
                      />
                    </div>
                  </div>
                )}
                {isLong && (
                  <div className="Exchange-info-row mt-16">
                    <div className="Exchange-info-label">Profits In</div>
                    <div className="align-right strong">{toToken.symbol}</div>
                  </div>
                )}
                <div className="Exchange-info-row">
                  <div className="Exchange-info-label">Leverage</div>
                  <div className="align-right">
                    {hasExistingPosition && toAmount && toAmount.gt(0) && (
                      <div className="inline-block muted">
                        {formatAmount(existingPosition.leverage, 4, 2)}x
                        <BsArrowRight className="transition-arrow" />
                      </div>
                    )}
                    {toAmount && leverage && leverage.gt(0) && `${formatAmount(leverage, 4, 2)}x`}
                    {!toAmount && leverage && leverage.gt(0) && `-`}
                    {leverage && leverage.eq(0) && `-`}
                  </div>
                </div>
                <div className="Exchange-info-row">
                  <div className="Exchange-info-label">Entry Price</div>
                  <div className="align-right">
                    {hasExistingPosition && toAmount && toAmount.gt(0) && (
                      <div className="inline-block muted">
                        ${formatAmount(existingPosition.averagePrice, USD_DECIMALS, toTokenInfo?.tokenDecimals || 2, true)}
                        <BsArrowRight className="transition-arrow" />
                      </div>
                    )}
                    {nextAveragePrice && `$${formatAmount(nextAveragePrice, USD_DECIMALS, toTokenInfo?.tokenDecimals || 2, true)}`}
                    {!nextAveragePrice && `-`}
                  </div>
                </div>
                <div className="Exchange-info-row">
                  <div className="Exchange-info-label">Liq. Price</div>
                  <div className="align-right">
                    {hasExistingPosition && toAmount && toAmount.gt(0) && (
                      <div className="inline-block muted">
                        ${formatAmount(existingLiquidationPrice, USD_DECIMALS, toTokenInfo?.tokenDecimals || 2, true)}
                        <BsArrowRight className="transition-arrow" />
                      </div>
                    )}
                    {toAmount &&
                      displayLiquidationPrice &&
                      `$${formatAmount(displayLiquidationPrice, USD_DECIMALS, toTokenInfo?.tokenDecimals || 2, true)}`}
                    {!toAmount && displayLiquidationPrice && `-`}
                    {!displayLiquidationPrice && `-`}
                  </div>
                </div>
                <ExchangeInfoRow label="Fees">
                  <div>
                    {
                      isGNS ?
                        <>
                          {positionFee ? (
                            <Tooltips
                              position="right"
                              renderContent={
                                <>
                                  <TooltipItem label="Position Fee (0.1% of position size)" val={`$${formatAmount(positionFee, USD_DECIMALS, 2, true)}`} />
                                </>
                              }
                            >
                              <span className="toolBottom">${formatAmount(positionFee, USD_DECIMALS, 2, true)}</span>
                            </Tooltips>
                          ) : <>"-"</>}
                        </> :
                        <>
                          {!feesUsd && "-"}
                          {feesUsd && (
                            <Tooltips
                              position="right"
                              renderContent={
                                <>
                                  {swapFees && (
                                    <TooltipText text={
                                      <div>
                                        {collateralToken.symbol} is required for collateral. <br />
                                        <br />
                                        Swap {fromToken.symbol} to {collateralToken.symbol} Fee: $
                                        {formatAmount(swapFees, USD_DECIMALS, 2, true)}
                                      </div>
                                    } />)}
                                  <TooltipItem label="Position Fee (0.1% of position size)" val={`$${formatAmount(positionFee, USD_DECIMALS, 2, true)}`} />
                                </>
                              }
                            >
                              <span className="toolBottom">${formatAmount(feesUsd, USD_DECIMALS, 2, true)}</span>
                            </Tooltips>
                          )}
                        </>
                    }

                  </div>
                </ExchangeInfoRow>
                {isMarketOrder && (
                  <Box display="flex" alignItems="center" justifyContent={'space-between'} gridGap={8}>

                    <div className="Exchange-info-label">slippage</div>
                    <div className="">
                      <Row gap={'10px'} alignItems={'center'}>
                        {parseFloat(savedSlippageAmount / 100).toFixed(2) * 1}% <Sting onClick={openSettings} style={{ cursor: "pointer" }} />
                      </Row>
                    </div>
                  </Box>
                )}
              </div>
            </div>
          )}
          <div className="all_size">
            {isStopOrder && (
              <div className="Exchange-swap-section Exchange-trigger-order-info">
                Take-profit and stop-loss orders can be set after opening a position. <br />
                <br />
                There will be a "Close" button on each position row, clicking this will display the option to set trigger
                orders. <br />
                <br />
                For screenshots and more information, please see the{" "}
                <div
                  href=""
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  docs
                </div>
                .
              </div>
            )}
            <div className="mt-16">
              <ConfirmBtn onClick={onClickPrimary}
                disabled={!isPrimaryEnabled()}>
                {getPrimaryText()}
              </ConfirmBtn>

            </div>
          </div>
          {
            isSwap && (
              <div className="Exchange-swap-market-box App-box App-box-border">
                <div className="Exchange-swap-market-box-title">Swap</div>
                <div className="maker_size">

                  <div className="Exchange-info-row">
                    <div className="Exchange-info-label">{fromToken.symbol} Price</div>
                    <div className="align-right">
                      {fromTokenInfo && formatAmount(fromTokenInfo.minPrice, USD_DECIMALS, 2, true)} USD
                    </div>
                  </div>
                  <div className="Exchange-info-row">
                    <div className="Exchange-info-label">{toToken.symbol} Price</div>
                    <div className="align-right">
                      {toTokenInfo && formatAmount(toTokenInfo.maxPrice, USD_DECIMALS, 2, true)} USD
                    </div>
                  </div>
                  <div className="Exchange-info-row">
                    <div className="Exchange-info-label">Available Liquidity</div>
                    <div className="align-right al-swap">
                      <Tooltips
                        position="right"
                        renderContent={
                          <>
                            <TooltipText text={<div className="">
                              Max {fromTokenInfo.symbol} in: {formatAmount(maxFromTokenIn, fromTokenInfo.decimals, 2, true)}{" "}
                              {fromTokenInfo.symbol} <br />({"$ "}
                              {formatAmount(maxFromTokenInUSD, USD_DECIMALS, 2, true)})
                            </div>} />
                            <TooltipText text={<div className="">
                              Max {toTokenInfo.symbol} out: {formatAmount(maxToTokenOut, toTokenInfo.decimals, 2, true)}{" "}
                              {toTokenInfo.symbol} <br />({"$ "}
                              {formatAmount(maxToTokenOutUSD, USD_DECIMALS, 2, true)})
                            </div>} />
                          </>
                        }
                      >
                        <span className="toolBottom">{formatAmount(maxSwapAmountUsd, USD_DECIMALS, 2, true)} USD</span>
                      </Tooltips>
                    </div>
                  </div>
                  {!isMarketOrder && (
                    <ExchangeInfoRow label="Price">
                      {getExchangeRateDisplay(getExchangeRate(fromTokenInfo, toTokenInfo), fromToken, toToken)}
                    </ExchangeInfoRow>
                  )}
                </div>
              </div>
            )
          }
          {
            (isLong || isShort) && (
              <div className="Exchange-swap-market-box">
                <div className="Exchange-swap-market-box-title">
                  {isLong ? "Long" : "Short"}&nbsp;{toToken.symbol}
                </div>
                <div className="maker_size">

                  <div className="Exchange-info-row">
                    <div className="Exchange-info-label">Entry Price</div>
                    <div className="align-right">
                      <Tooltips
                        position="right"
                        renderContent={
                          <><TooltipText text={
                            <div >
                              The position will be opened at {formatAmount(entryMarkPrice, USD_DECIMALS, 2, true)} USD with a
                              max slippage of {parseFloat(savedSlippageAmount / 100.0).toFixed(2)}%.
                            </div>} />
                            <TooltipText text={
                              <div className="">
                                The slippage amount can be configured under Settings, found by clicking on your address at the top
                                right of the page after connecting your wallet.
                              </div>} />
                          </>
                        }
                      >
                        <span className="toolBottom">{formatAmount(entryMarkPrice, USD_DECIMALS, 2, true)} USD</span>
                      </Tooltips>
                    </div>
                  </div>
                  <div className="Exchange-info-row">
                    <div className="Exchange-info-label">Exit Price</div>
                    <div className="align-right">
                      <Tooltips
                        position="right"
                        renderContent={
                          <>
                            <TooltipText text={
                              <div className="">
                                If you have an existing position, the position will be closed at{" "}
                                {formatAmount(entryMarkPrice, USD_DECIMALS, 2, true)} USD.
                              </div>} />
                            <TooltipText text={
                              <div className="">
                                This exit price will change with the price of the asset.
                              </div>} />
                          </>
                        }
                      >
                        <span className="toolBottom">{formatAmount(exitMarkPrice, USD_DECIMALS, 2, true)} USD</span>
                      </Tooltips>
                    </div>
                  </div>
                  <div className="Exchange-info-row">
                    <div className="Exchange-info-label">Funding Fee</div>
                    <div className="align-right">
                      <Tooltips
                        position="right"
                        renderContent={
                          <Column gap='12px' lineHeight="20px" className="font-14" >
                            {hasZeroBorrowFee ?
                              (
                                <TooltipText text={
                                  <div>
                                    {isLong && "There are more shorts than longs, Funding Fees for longing is currently zero"}
                                    {isShort && "There are more longs than shorts, Funding Fees for shorting is currently zero"}
                                  </div>
                                } />
                              )
                              :
                              (
                                <TooltipText text={
                                  <>
                                    <div className="">Funding fees hourly rate, charged per block.</div>
                                    <div className="">
                                      The funding fee is calculated as (assets borrowed) / (total assets in pool) * 0.01% per hour.
                                    </div>
                                    {isShort &&
                                      <div className="">
                                        You can change the "Profits In" token above to find lower fees
                                      </div>
                                    }
                                  </>} />
                              )
                            }
                          </Column>
                        }
                      >
                        <span className="toolBottom">{borrowFeeText}</span>
                      </Tooltips>
                    </div>
                  </div>
                  <div className="Exchange-info-row">
                    <div className="Exchange-info-label">Premium</div>
                    <div className="align-right">
                      <Tooltips
                        handle={premiumText}
                        position="right"
                        renderContent={
                          < >
                            <TooltipText text={
                              <div className="">Premium hourly rate, charged per block.</div>
                            } />
                            <TooltipText text={
                              <div className="">
                                If the size of the long position is much larger than the size of the short position, the long position holders should pay a premium to the short position holder, and vice versa.
                              </div>} />
                          </>
                        }
                      >
                        <span className="toolBottom">{premiumText}</span>
                      </Tooltips>
                    </div>
                  </div>
                  {renderAvailableLongLiquidity()}
                  {isShort && toTokenInfo.hasMaxAvailableShort && (
                    <div className="Exchange-info-row">
                      <div className="Exchange-info-label">Available Liquidity</div>
                      <div className="align-right">
                        <Tooltips
                          position="right"
                          renderContent={
                            <>
                              <div className="toolBottom">
                                <TooltipItem label="Max short capacity" val={`$${formatAmount2(poolUsdt * 1, 2, true)}`} />
                              </div>
                              <TooltipItem label="Current shorts" val={`$${formatAmount2(utilizationUsdt * poolUsdt, 2, true)}`} />
                            </>
                          } >
                          <span className="toolBottom">{formatAmount2(poolUsdt * 1 - utilizationUsdt * poolUsdt, 2, true)}</span>
                        </Tooltips>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            )
          }
          {renderErrorModal()}
          {renderOrdersToa()}
          {
            isConfirming && (
              <ConfirmationBox
                plpName={plpName}
                library={provider}
                isHigherSlippageAllowed={isHigherSlippageAllowed}
                setIsHigherSlippageAllowed={setIsHigherSlippageAllowed}
                orders={orders}
                isSwap={isSwap}
                isLong={isLong}
                isMarketOrder={isMarketOrder}
                orderOption={orderOption}
                isShort={isShort}
                fromToken={fromToken}
                fromTokenInfo={fromTokenInfo}
                toToken={toToken}
                toTokenInfo={toTokenInfo}
                toAmount={toAmount}
                fromAmount={fromAmount}
                feeBps={feeBps}
                onConfirmationClick={onConfirmationClick}
                setIsConfirming={setIsConfirming}
                hasExistingPosition={hasExistingPosition}
                shortCollateralAddress={shortCollateralAddress}
                shortCollateralToken={shortCollateralToken}
                leverage={leverage}
                existingPosition={existingPosition}
                existingLiquidationPrice={existingLiquidationPrice}
                displayLiquidationPrice={displayLiquidationPrice}
                nextAveragePrice={nextAveragePrice}
                triggerPriceUsd={triggerPriceUsd}
                triggerRatio={triggerRatio}
                fees={fees}
                feesUsd={feesUsd}
                isSubmitting={isSubmitting}
                isPendingConfirmation={isPendingConfirmation}
                fromUsdMin={fromUsdMin}
                toUsdMax={toUsdMax}
                collateralTokenAddress={collateralTokenAddress}
                infoTokens={infoTokens}
                chainId={chainId}
                setPendingTxns={setPendingTxns}
                pendingTxns={pendingTxns}
                minExecutionFee={minExecutionFee}
                minExecutionFeeUSD={minExecutionFeeUSD}
                minExecutionFeeErrorMessage={minExecutionFeeErrorMessage}
              />
            )
          }

        </div >
      </div >
    </div >


  );
}