import { Trans } from "@lingui/macro";
import { Box, Link, Skeleton } from "@mui/material";
import { ThemedText } from "src/theme/text";
import styled from "styled-components/macro";
import { ReactComponent as PIKO } from "src/assets/images/PIKO.svg";
import { ReactComponent as EsPIKO } from "src/assets/images/esPIKO.svg";
import { ReactComponent as USDC } from "src/assets/images/tokens/USDC.svg";
import { formatAmount2 } from "src/helpers/Helpers";
import { useGetPikoPrice } from "src/hooks/get_price_piko";
import { useSelector } from "react-redux";
import { AssetBox } from "../AssetList";
import { BuyBtn } from "src/components/Button/BuyBtn";
import { ClaimBtn } from "src/components/Button/ClaimBtn";
import {
  useBalance_PIKO,
  useBalance_esPIKO,
  useBalance_usdc,
  useStaked_PIKO,
  useStaked_esPIKO,
} from "src/hooks/useQueryEarn";
import { NavLink } from "react-router-dom";
import { BUY_PIKO_URL } from "src/constants/misc";
import { useWeb3Context } from "src/hooks";

const AssetMBox = styled(AssetBox)`
  margin-top: 24px;
  .assetItem-m {
    padding-bottom: 20px;
    .asset {
      padding: 16px 0;
      border-bottom: 1px dashed ${({ theme }) => theme.bg3};
    }
  }
  @media only screen and (max-width: 1180px) {
  }
`;

export default function AssetListM() {
  const PIKOPrice = useGetPikoPrice();
  const usdcBalance = useBalance_usdc();
  const PIKOBalance = useBalance_PIKO();
  const PIKOStaked = useStaked_PIKO();
  const esPIKOBalance = useBalance_esPIKO();
  const esPIKOStaked = useStaked_esPIKO();
  const tokensArr = useSelector((state: any) => {
    return state?.app?.tokensArr || [];
  });
  const { chainID } = useWeb3Context();
  const handlerBuy = () => {
    chainID == 324 && window.open(BUY_PIKO_URL[chainID]);
  };
  return (
    <AssetMBox className="">
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        className="assetHeader-m"
      >
        <Box className="">
          <ThemedText.Text4Bold>
            <Trans>Total</Trans>
          </ThemedText.Text4Bold>
        </Box>
        <Box className="text-right">
          <ThemedText.Text4Bold>
            <Trans>Total</Trans>
          </ThemedText.Text4Bold>
        </Box>
      </Box>
      <div className="assetItem-m">
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          className="asset"
        >
          <Box display="flex" alignItems="center" className="">
            <PIKO width={48} height={48} />
            <ThemedText.Text1Bold fontSize={24} ml="16px">
              <Trans>PIKO</Trans>
            </ThemedText.Text1Bold>
          </Box>
          <div className="">
            <ThemedText.Text1Medium fontSize={16}>
              {formatAmount2(
                PIKOBalance?.plus(PIKOStaked?.amount ? PIKOStaked?.amount : 0),
                2,
                true
              )}
            </ThemedText.Text1Medium>
            <ThemedText.Text5 fontSize={12} mt="8px">
              $
              {formatAmount2(
                PIKOBalance?.plus(
                  PIKOStaked?.amount ? PIKOStaked?.amount : 0
                ).times(PIKOPrice ? PIKOPrice : 1),
                2,
                true
              )}
            </ThemedText.Text5>
          </div>
        </Box>
        <Box display="flex" alignItems="center" mt="16px">
          <Box display="flex" alignItems="center" className="">
            <ThemedText.Text4Bold>
              <Trans>Wallet</Trans>
            </ThemedText.Text4Bold>
            <ThemedText.Text1Medium fontSize={16} ml="8px">
              {formatAmount2(PIKOBalance, 2, true)}
            </ThemedText.Text1Medium>
          </Box>
          <Box
            display="flex"
            alignItems="center"
            className="text-right"
            ml="24px"
          >
            <ThemedText.Text4Bold>
              <Trans>Staked</Trans>
            </ThemedText.Text4Bold>
            <ThemedText.Text1Medium fontSize={16} ml="8px">
              {formatAmount2(PIKOStaked?.amount, 2, true)}
            </ThemedText.Text1Medium>
          </Box>
        </Box>
        <Box display="flex" alignItems="center" mt="16px" className="">
          <Box className="flex-1">
            <Link component={NavLink} to={`/Earn?curr=${0}`}>
              <BuyBtn>Earn</BuyBtn>
            </Link>
          </Box>
          <Box className="flex-1" ml="16px">
            <ClaimBtn onClick={handlerBuy}>Buy / Sell</ClaimBtn>
          </Box>
        </Box>
      </div>
      <div className="assetItem-m">
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          className="asset"
        >
          <Box display="flex" alignItems="center" className="">
            <EsPIKO width={48} height={48} />
            <ThemedText.Text1Bold fontSize={24} ml="16px">
              <Trans>esPIKO</Trans>
            </ThemedText.Text1Bold>
          </Box>
          <div className="">
            <ThemedText.Text1Medium fontSize={16}>
              {formatAmount2(
                esPIKOBalance?.plus(
                  esPIKOStaked?.amount ? esPIKOStaked?.amount : 0
                ),
                2,
                true
              )}
            </ThemedText.Text1Medium>
            <ThemedText.Text5 fontSize={12} mt="8px">
              $
              {formatAmount2(
                esPIKOBalance
                  ?.plus(esPIKOStaked?.amount ? esPIKOStaked?.amount : 0)
                  .times(PIKOPrice ? PIKOPrice : 1),
                2,
                true
              )}
            </ThemedText.Text5>
          </div>
        </Box>
        <Box display="flex" alignItems="center" mt="16px">
          <Box display="flex" alignItems="center" className="">
            <ThemedText.Text4Bold>
              <Trans>Wallet</Trans>
            </ThemedText.Text4Bold>
            <ThemedText.Text1Medium fontSize={16} ml="8px">
              {formatAmount2(esPIKOBalance, 2, true)}
            </ThemedText.Text1Medium>
          </Box>
          <Box
            display="flex"
            alignItems="center"
            className="text-right"
            ml="24px"
          >
            <ThemedText.Text4Bold>
              <Trans>Staked</Trans>
            </ThemedText.Text4Bold>
            <ThemedText.Text1Medium fontSize={16} ml="8px">
              {formatAmount2(esPIKOStaked?.amount, 2, true)}
            </ThemedText.Text1Medium>
          </Box>
        </Box>
        <Box display="flex" alignItems="center" mt="16px" className="">
          <Box className="flex-1">
            <Link component={NavLink} to={`/Earn?curr=${0}`}>
              <BuyBtn>Earn</BuyBtn>
            </Link>
          </Box>
          <Box className="flex-1" ml="16px">
            <ClaimBtn disabled={true}>Buy / Sell</ClaimBtn>
          </Box>
        </Box>
      </div>
      {tokensArr?.length > 0 ? (
        tokensArr?.map((item, i) => (
          <div className="assetItem-m" key={i}>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              className="asset"
            >
              <Box display="flex" alignItems="center" className="">
                <img src={item?.icon} width={48} height={48} />
                <ThemedText.Text1Bold fontSize={24} ml="16px">
                  {item?.name}
                </ThemedText.Text1Bold>
              </Box>
              <div className="">
                <ThemedText.Text1Medium fontSize={16}>
                  {formatAmount2(
                    item?.plpBalance * 1 + item?.plpBalanceStaked * 1,
                    2,
                    true
                  )}
                </ThemedText.Text1Medium>
                <ThemedText.Text5 fontSize={12} mt="8px">
                  $
                  {formatAmount2(
                    (item?.plpBalance * 1 + item?.plpBalanceStaked * 1) *
                      item?.price,
                    2,
                    true
                  )}
                </ThemedText.Text5>
              </div>
            </Box>
            <Box display="flex" alignItems="center" mt="16px">
              <Box display="flex" alignItems="center" className="">
                <ThemedText.Text4Bold>
                  <Trans>Wallet</Trans>
                </ThemedText.Text4Bold>
                <ThemedText.Text1Medium fontSize={16} ml="8px">
                  {formatAmount2(item?.plpBalance, 2, true)}
                </ThemedText.Text1Medium>
              </Box>
              <Box
                display="flex"
                alignItems="center"
                className="text-right"
                ml="24px"
              >
                <ThemedText.Text4Bold>
                  <Trans>Staked</Trans>
                </ThemedText.Text4Bold>
                <ThemedText.Text1Medium fontSize={16} ml="8px">
                  {formatAmount2(item?.plpBalanceStaked, 2, true)}
                </ThemedText.Text1Medium>
              </Box>
            </Box>
            <Box display="flex" alignItems="center" mt="16px" className="">
              <Box className="flex-1">
                <Link component={NavLink} to={`/Earn?curr=${1}`}>
                  <BuyBtn>Earn</BuyBtn>
                </Link>
              </Box>
              <Box className="flex-1" ml="16px">
                <Link component={NavLink} to={`/Earn?curr=${1}`}>
                  <ClaimBtn>Buy / Sell</ClaimBtn>
                </Link>
              </Box>
            </Box>
          </div>
        ))
      ) : (
        <div className="flex-1">
          <Skeleton width="100%" height={60} style={{ borderRadius: "10px" }} />
        </div>
      )}
      <div className="assetItem-m">
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          className="asset"
        >
          <Box display="flex" alignItems="center" className="">
            <USDC width={48} height={48} />
            <ThemedText.Text1Bold fontSize={24} ml="16px">
              <Trans>USDC</Trans>
            </ThemedText.Text1Bold>
          </Box>
          <div className="">
            <ThemedText.Text1Medium fontSize={16}>
              {formatAmount2(usdcBalance, 2, true)}
            </ThemedText.Text1Medium>
            <ThemedText.Text5 fontSize={12} mt="8px">
              ${formatAmount2(usdcBalance, 2, true)}
            </ThemedText.Text5>
          </div>
        </Box>
        <Box display="flex" alignItems="center" mt="16px">
          <Box display="flex" alignItems="center" className="">
            <ThemedText.Text4Bold>
              <Trans>Wallet</Trans>
            </ThemedText.Text4Bold>
            <ThemedText.Text1Medium fontSize={16} ml="8px">
              {formatAmount2(usdcBalance, 2, true)}
            </ThemedText.Text1Medium>
          </Box>
          <Box
            display="flex"
            alignItems="center"
            className="text-right"
            ml="24px"
          >
            <ThemedText.Text4Bold>
              <Trans>Staked</Trans>
            </ThemedText.Text4Bold>
            <ThemedText.Text1Medium fontSize={16} ml="8px">
              {formatAmount2(0, 2, true)}
            </ThemedText.Text1Medium>
          </Box>
        </Box>
        <Box display="flex" alignItems="center" mt="16px" className=""></Box>
      </div>
    </AssetMBox>
  );
}
