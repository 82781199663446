import Switch from "@mui/material/Switch";
import { styled } from "@mui/material/styles";
const SwitchBox = styled(Switch)(({ theme }) => ({
  "& .MuiSwitch-switchBase.Mui-checked": {
    color: "#14D1B2",
    "&:hover": {
      backgroundColor: "none",
    },
  },
  "& .MuiSwitch-track": {
    backgroundColor: "rgba(255,255,255,0.5)",
  },
  "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
    backgroundColor: "#14D1B2",
    opacity: "1",
  },
}));

export default function SwitchesSize(props: any) {
  const { isChecked, setIsChecked, disabled, className } = props;
  return (
    <SwitchBox
      size="small"
      defaultChecked={isChecked}
      checked={isChecked}
      onClick={() => setIsChecked(!isChecked)}
    />
  );
}
