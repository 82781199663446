import { styled } from "@mui/material/styles";
import { Box } from "@mui/material";
import { ReactComponent as PIKOcon } from "src/assets/images/PIKO.svg";
import { formatAmount2 } from "src/helpers/Helpers";
import { useGetPikoPrice } from "src/hooks/get_price_piko";
import { BUY_PIKO_URL } from "src/constants/misc";
import { useWeb3Context } from "src/hooks";
const MyBox = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
  font-size: 14px;
  padding: 4px 8px;
  border-radius: 4px;
  cursor: pointer;
  &:hover {
    background: rgba(255, 255, 255, 0.2);
  }
  .bors_price {
    background-image: linear-gradient(
      to right,
      rgba(255, 255, 255, 0.54) 0%,
      rgba(255, 255, 255, 0.54) 50%,
      transparent 50%
    );
    background-size: 8px 1px;
    background-repeat: repeat-x;
    background-position: left bottom;
  }
`;
const PikoPrice = () => {
  const PIKOPrice = useGetPikoPrice();
  const { chainID } = useWeb3Context();
  const openChart = () => {
    chainID == 324 && window.open(BUY_PIKO_URL[chainID]);
  };

  return (
    <>
      {chainID == 324 ? (
        <MyBox className="font-weight-6 color4" onClick={openChart}>
          <PIKOcon width={30} height={30} />
          <div className="bors_price">
            ${formatAmount2(PIKOPrice || 0, 2, true)}
          </div>
        </MyBox>
      ) : (
        <MyBox className="font-weight-6 color4">
          <PIKOcon width={30} height={30} />
          <div className="bors_price">$--</div>
        </MyBox>
      )}
    </>
  );
};

export default PikoPrice;
