import { Box } from "rebass";
import { ThemedText } from "src/theme/text";
import styled from "styled-components";

const CoundownBox = styled(Box)`
  .divided {
    margin: 0 8px;
  }
`;
type dataPros = {
  coundown?: any;
};

export default function CountDown({ coundown }: dataPros) {
  return (
    <CoundownBox className="coundown">
      <Box display="flex" alignItems="flex-start" className="">
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          className=""
        >
          <ThemedText.Text9Bold>{coundown?.hour}</ThemedText.Text9Bold>
          <Box mt="8px">
            <ThemedText.Text4>HRS</ThemedText.Text4>
          </Box>
        </Box>
        <div className="divided">
          <ThemedText.Text9Medium fontSize={24}>:</ThemedText.Text9Medium>
        </div>
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          className=""
        >
          <ThemedText.Text9Bold>{coundown?.minutes}</ThemedText.Text9Bold>
          <Box mt="8px">
            <ThemedText.Text4>MINS</ThemedText.Text4>
          </Box>
        </Box>
        <div className="divided">
          <ThemedText.Text9Medium fontSize={24}>:</ThemedText.Text9Medium>
        </div>
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          className=""
        >
          <ThemedText.PrimaryText1Bold fontSize={24}>
            {coundown?.seconds}
          </ThemedText.PrimaryText1Bold>
          <Box mt="8px">
            <ThemedText.Text4>SECS</ThemedText.Text4>
          </Box>
        </Box>
      </Box>
    </CoundownBox>
  );
}
