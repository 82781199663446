import React, { useState, useCallback } from "react";

import {
  SWAP,
  INCREASE,
  DECREASE,
  USD_DECIMALS,
  formatAmount,
  getOrderError,
  TRIGGER_PREFIX_ABOVE,
  TRIGGER_PREFIX_BELOW,
  getExchangeRateDisplay,
  getTokenInfo,
  getExchangeRate,
  getPositionForOrder,
  getUsd,
} from "../../helpers/Helpers.js";
import { handleCancelOrder } from "../../Api";
import { getContractAddress } from "../../Addresses";

import OrderEditor from "./OrderEditor";

import "./OrdersList.css";
import Checkbox from "../Checkbox/Checkbox.js";
import { DefaultNoData } from "../NoData";
import Tooltips from 'src/components/Tooltips/totalTips'
import Column from 'src/components/Column'
import Row, { RowBetween } from "src/components/Row";
import TooltipItem, { TooltipText } from 'src/components/TooltipItem'
import { useGetPLPData } from "src/hooks/useGetPLPData";
import { useUpDataBlockNumber } from "src/lib/hooks/useUpDataBlockNumber";
import { CloseBtn } from "../Button/CloseBtn";
import { Box } from "@mui/material";

import { ADDRESS_ZERO } from "@uniswap/v3-sdk";

const addzep = '0x0000000000000000000000000000000000000000'
export default function OrdersList(props) {
  const {
    plpName,
    account,
    library,
    setPendingTxns,
    pendingTxns,
    infoTokens,
    positionsMap,
    totalTokenWeights,
    usdxSupply,
    orders,
    hideActions,
    chainId,
    savedShouldDisableOrderValidation,
    cancelOrderIdList,
    setCancelOrderIdList,
  } = props;
  const [editingOrder, setEditingOrder] = useState(null);
  const upDataBlock = useUpDataBlockNumber()
  const onCancelClick = useCallback(
    async (order) => {
      const data = await handleCancelOrder(plpName, chainId, library, order, { pendingTxns, setPendingTxns }, order.contract);
      upDataBlock(data);
    },
    [library, pendingTxns, setPendingTxns, chainId, plpName]
  );

  const onEditClick = useCallback(
    (order) => {
      setEditingOrder(order);
    },
    [setEditingOrder]
  );

  const renderHead = useCallback(() => {
    const isAllOrdersSelected = cancelOrderIdList?.length > 0 && cancelOrderIdList?.length === orders.length;
    return (
      <tr className="Exchange-list-header">
        {orders.length > 0 && (
          <th>
            <div className="checkbox-inline ">
              <Checkbox
                isChecked={isAllOrdersSelected}
                setIsChecked={() => {
                  if (isAllOrdersSelected) {
                    setCancelOrderIdList([]);
                  } else {
                    const allOrderIds = orders.map((o) => `${o.type}-${o.index}`);
                    setCancelOrderIdList(allOrderIds);
                  }
                }}
              />
            </div>
          </th>
        )}

        <th>
          <div className="font-weight-b">Type</div>
        </th>
        <th>
          <div className="font-weight-b">Order</div>
        </th>
        <th>
          <div className="font-weight-b">Price</div>
        </th>
        <th>
          <div className="font-weight-b">Mark Price</div>
        </th>
      </tr>
    );
  }, [cancelOrderIdList, orders, setCancelOrderIdList]);

  const renderEmptyRow = useCallback(() => {
    if (orders && orders.length) {
      return null;
    }

    return (
      <DefaultNoData title="No open orders" />
    );
  }, [orders]);

  const renderActions = useCallback(
    (order) => {
      return (
        <>
          <td>
            <Row gap={'20px'}>
              <CloseBtn className="edots" > <Box color={'rgba(126, 159, 255, 0.87)'} className="pointer font-weight-b" onClick={() => onEditClick(order)}>Edit</Box></CloseBtn>
              <CloseBtn onClick={() => onCancelClick(order)}><span className="font-weight-b">  Cancel</span></CloseBtn>
            </Row>
          </td>

        </>
      );
    },
    [onEditClick, onCancelClick]
  );
  const renderLargeList = useCallback(() => {
    if (!orders || !orders.length) {
      return null;
    }
    return orders.map((order) => {
      if (order.type === SWAP) {

        const nativeTokenAddress = getContractAddress(chainId, "NATIVE_TOKEN");
        const fromTokenInfo = getTokenInfo(infoTokens, order.path[0], true, nativeTokenAddress);
        const toTokenInfo = getTokenInfo(
          infoTokens,
          order.path[order.path.length - 1],
          order.shouldUnwrap,
          nativeTokenAddress
        );

        const markExchangeRate = getExchangeRate(fromTokenInfo, toTokenInfo);
        const orderId = `${order.type}-${order.index}`;

        return (
          <tr className="Exchange-list-item" key={orderId}>
            <td>
              <div className="checkbox-inline ">
                <Checkbox
                  isChecked={cancelOrderIdList?.includes(orderId)}
                  setIsChecked={() => {
                    setCancelOrderIdList((prevState) => {
                      if (prevState.includes(orderId)) {
                        return prevState.filter((i) => i !== orderId);
                      } else {
                        return prevState.concat(orderId);
                      }
                    });
                  }}
                />
              </div>
            </td>
            <td className="Exchange-list-item-type font-weight-600">Limit</td>
            <td className="font-weight-600">
              Swap{" "}
              {formatAmount(
                order.amountIn,
                fromTokenInfo.decimals,
                fromTokenInfo.isStable || fromTokenInfo.isUsdx ? 2 : 4,
                true
              )}{" "}
              {fromTokenInfo.symbol} for{" "}
              {formatAmount(
                order.minOut,
                toTokenInfo.decimals,
                toTokenInfo.isStable || toTokenInfo.isUsdx ? 2 : 4,
                true
              )}{" "}
              {toTokenInfo.symbol}
            </td>
            <td style={{ 'position': 'relative' }} className="font-weight-600">
              <Tooltips
                position="center-bottom"
                handleClassName=""
                renderContent={
                  <TooltipText text={
                    <>
                      {`
                      You will receive at least ${formatAmount(
                        order.minOut,
                        toTokenInfo.decimals,
                        toTokenInfo.isStable || toTokenInfo.isUsdx ? 2 : 4,
                        true
                      )} ${toTokenInfo.symbol
                        } if this order is executed. The execution price may vary depending on swap fees at the time the order is executed.
                    `}
                    </>} />
                }
              >
                <span className="font-weight-600">
                  {getExchangeRateDisplay(order.triggerRatio, fromTokenInfo, toTokenInfo)}
                </span>
              </Tooltips>
            </td>
            <td >{getExchangeRateDisplay(markExchangeRate, fromTokenInfo, toTokenInfo, true)}</td>
            {!hideActions && renderActions(order)}
          </tr>
        );
      }

      const nativeTokenAddress = getContractAddress(chainId, "NATIVE_TOKEN");
      const isNative = nativeTokenAddress.toLocaleLowerCase() == order.indexToken.toLocaleLowerCase();
      const indexToken = getTokenInfo(infoTokens, isNative ? addzep : order.indexToken);
      const maximisePrice = (order.type === INCREASE && order.isLong) || (order.type === DECREASE && !order.isLong);
      const markPrice = maximisePrice ? indexToken.maxPrice : indexToken.minPrice;

      const triggerPricePrefix = order.triggerAboveThreshold ? TRIGGER_PREFIX_ABOVE : TRIGGER_PREFIX_BELOW;
      const indexTokenSymbol = indexToken.isWrapped ? indexToken.baseSymbol : indexToken.symbol;

      const error = getOrderError(account, order, positionsMap);
      const orderId = `${order.type}-${order.index}`;
      const orderText = (
        <>
          {order.type === INCREASE ? "Increase" : "Decrease"} {indexTokenSymbol} {order.isLong ? "Long" : "Short"}
          &nbsp;by ${formatAmount(order.sizeDelta, USD_DECIMALS, 2, true)}
          {error && <div className="Exchange-list-item-error">{error}</div>}
        </>
      );

      const collateralTokenInfo = getTokenInfo(infoTokens, order.purchaseToken);
      const collateralUSD = getUsd(order.purchaseTokenAmount, order.purchaseToken, false, infoTokens);

      return (
        <tr className="Exchange-list-item" key={`${order.isLong}-${order.type}-${order.index}`}>
          <td className="Exchange-list-item-type">
            <div>
              <Checkbox
                isChecked={cancelOrderIdList?.includes(orderId)}
                setIsChecked={() => {
                  setCancelOrderIdList((prevState) => {
                    if (prevState.includes(orderId)) {
                      return prevState.filter((i) => i !== orderId);
                    } else {
                      return prevState.concat(orderId);
                    }
                  });
                }}
              />
            </div>
          </td>
          <td className="Exchange-list-item-type">{order.type === INCREASE ? "Limit" : "Trigger"}</td>
          <td>
            {order.type === DECREASE ? (
              orderText
            ) : (
              <Tooltips
                position="center-bottom"
                className="fit-content"
                renderContent={
                  <TooltipItem
                    label="Collateral"
                    val={`${formatAmount(collateralUSD, USD_DECIMALS, 2, true)}  ${collateralTokenInfo.baseSymbol || collateralTokenInfo.symbol}`}
                  />
                }
              >
                <span className="toolBottom font-weight-600">
                  {orderText}
                </span>
              </Tooltips>
            )}
          </td>
          <td>
            {triggerPricePrefix} {formatAmount(order.triggerPrice, USD_DECIMALS, 2, true)}
          </td>
          <td>
            <Tooltips
              position="right-bottom"
              handleClassName=""
              renderContent={
                <TooltipText text={<>
                  The price that orders can be executed at may differ slightly from the chart price, as market orders
                  update oracle prices, while limit/trigger orders do not.
                </>} />
              }
            >
              <span className="toolBottom font-weight-600">
                {formatAmount(markPrice, USD_DECIMALS, indexToken.tokenDecimals, true)}
              </span>
            </Tooltips>
          </td>
          {!hideActions && renderActions(order)}
        </tr>
      );
    });
  }, [
    orders,
    renderActions,
    infoTokens,
    positionsMap,
    hideActions,
    chainId,
    account,
    cancelOrderIdList,
    setCancelOrderIdList,
  ]);

  const renderSmallList = useCallback(() => {
    if (!orders || !orders.length) {
      return null;
    }

    return orders.map((order) => {
      if (order.type === SWAP) {
        const nativeTokenAddress = getContractAddress(chainId, "NATIVE_TOKEN");
        const fromTokenInfo = getTokenInfo(infoTokens, order.path[0], true, nativeTokenAddress);
        const toTokenInfo = getTokenInfo(
          infoTokens,
          order.path[order.path.length - 1],
          order.shouldUnwrap,
          nativeTokenAddress
        );
        const markExchangeRate = getExchangeRate(fromTokenInfo, toTokenInfo);

        return (
          <div key={`${order.type}-${order.index}`} className="App-card">
            <div className="App-card-title-small">
              Swap {formatAmount(order.amountIn, fromTokenInfo.decimals, fromTokenInfo.isStable ? 2 : 4, true)}{" "}
              {fromTokenInfo.symbol} for{" "}
              {formatAmount(order.minOut, toTokenInfo.decimals, toTokenInfo.isStable ? 2 : 4, true)}{" "}
              {toTokenInfo.symbol}
            </div>
            <div className="App-card-divider"></div>
            <div className="App-card-content">
              <div className="App-card-row">
                <div className="label">Price</div>
                <Tooltips
                  position="right-bottom"
                  handleClassName=""
                  renderContent={
                    <TooltipText text={
                      <>
                        {`
                        You will receive at least ${formatAmount(
                          order.minOut,
                          toTokenInfo.decimals,
                          toTokenInfo.isStable || toTokenInfo.isUsdx ? 2 : 4,
                          true
                        )} ${toTokenInfo.symbol} if this order is executed. The exact execution price may vary depending on fees at the time the order is executed.
                  `}
                      </>} />
                  }
                >
                  <span className="toolBottom">
                    {getExchangeRateDisplay(order.triggerRatio, fromTokenInfo, toTokenInfo)}
                  </span>
                </Tooltips>
              </div>
              <div className="App-card-row">
                <div className="label">Mark Price</div>
                <div>{getExchangeRateDisplay(markExchangeRate, fromTokenInfo, toTokenInfo)}</div>
              </div>
              {!hideActions && (
                <Row gap={'20px'}>
                  <CloseBtn className="edots" > <Box color={'rgba(126, 159, 255, 0.87)'} className="pointer font-weight-b" onClick={() => onEditClick(order)}>Edit</Box></CloseBtn>
                  <CloseBtn onClick={() => onCancelClick(order)}><span className="font-weight-b">  Cancel</span></CloseBtn>
                </Row>
              )}
            </div>
          </div>
        );
      }

      const nativeTokenAddress = getContractAddress(chainId, "NATIVE_TOKEN");

      const isNative = nativeTokenAddress.toLocaleLowerCase() == order.indexToken.toLocaleLowerCase();
      const indexToken = getTokenInfo(infoTokens, isNative ? addzep : order.indexToken);
      const maximisePrice = (order.type === INCREASE && order.isLong) || (order.type === DECREASE && !order.isLong);
      const markPrice = maximisePrice ? indexToken.maxPrice : indexToken.minPrice;

      const triggerPricePrefix = order.triggerAboveThreshold ? TRIGGER_PREFIX_ABOVE : TRIGGER_PREFIX_BELOW;
      const indexTokenSymbol = indexToken.isWrapped ? indexToken.baseSymbol : indexToken.symbol;

      const collateralTokenInfo = getTokenInfo(infoTokens, order.purchaseToken);
      const collateralUSD = getUsd(order.purchaseTokenAmount, order.purchaseToken, true, infoTokens);

      const error = getOrderError(account, order, positionsMap);

      return (
        <div key={`${order.isLong}-${order.type}-${order.index}`} className="App-card">
          <div className="App-card-title-small">
            {order.type === INCREASE ? "Increase" : "Decrease"} {indexTokenSymbol} {order.isLong ? "Long" : "Short"}
            &nbsp;by ${formatAmount(order.sizeDelta, USD_DECIMALS, 2, true)}
            {error && <div className="Exchange-list-item-error">{error}</div>}
          </div>
          <div className="App-card-divider"></div>
          <div className="App-card-content">
            <div className="App-card-row">
              <div className="label">Price</div>
              <div>
                {triggerPricePrefix} {formatAmount(order.triggerPrice, USD_DECIMALS, 2, true)}
              </div>
            </div>
            <div className="App-card-row">
              <div className="label">Mark Price</div>
              <Tooltips
                position="right-bottom"
                handleClassName=""
                renderContent={
                  <TooltipText text={
                    <>
                      The price that the order can be executed at may differ slightly from the chart price as market
                      orders can change the price while limit / trigger orders cannot.
                    </>
                  } />
                }
              >
                <span className="toolBottom">
                  {formatAmount(markPrice, USD_DECIMALS, indexToken.tokenDecimals, true)}
                </span>
              </Tooltips>
            </div>
            {order.type === INCREASE && (
              <div className="App-card-row">
                <div className="label">Collateral</div>
                <div>
                  ${formatAmount(collateralUSD, USD_DECIMALS, 2, true)} (
                  {formatAmount(order.purchaseTokenAmount, collateralTokenInfo.decimals, 4, true)}{" "}
                  {collateralTokenInfo.baseSymbol || collateralTokenInfo.symbol})
                </div>
              </div>
            )}
            {!hideActions && (
              <Row gap={'20px'}>
                <CloseBtn className="edots" > <Box color={'rgba(126, 159, 255, 0.87)'} className="pointer font-weight-b" onClick={() => onEditClick(order)}>Edit</Box></CloseBtn>
                <CloseBtn onClick={() => onCancelClick(order)}><span className="font-weight-b">  Cancel</span></CloseBtn>
              </Row>

            )}
          </div>
        </div>
      );
    });
  }, [orders, onEditClick, onCancelClick, infoTokens, positionsMap, hideActions, chainId, account]);

  return (
    <React.Fragment>
      <table className="Exchange-list Orders App-box large">
        <tbody>
          {renderHead()}
          {renderLargeList()}
        </tbody>
      </table>
      {renderEmptyRow()}
      <div className="Exchange-list Orders small">
        {renderSmallList()}
      </div>
      {editingOrder && (
        <OrderEditor
          plpName={plpName}
          account={account}
          order={editingOrder}
          setEditingOrder={setEditingOrder}
          infoTokens={infoTokens}
          pendingTxns={pendingTxns}
          setPendingTxns={setPendingTxns}
          getPositionForOrder={getPositionForOrder}
          positionsMap={positionsMap}
          library={library}
          totalTokenWeights={totalTokenWeights}
          usdxSupply={usdxSupply}
          savedShouldDisableOrderValidation={savedShouldDisableOrderValidation}
        />
      )}
    </React.Fragment>
  );
}
