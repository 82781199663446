import { styled } from "@mui/material/styles";
import { Button } from "@mui/material";

export const ClaimBtn = styled(Button)<{
  borderRadius?: string;
  height?: string;
}>`
  height: ${({ height }) => (height ? height : "40px")};
  line-height: ${({ height }) => (height ? height : "40px")};
  max-height: ${({ height }) => (height ? height : "40px")};
  width: 90px;
  color: #14d1b2;
  font-size: 14px;
  font-family: "Gilroy-Bold";
  text-transform: uppercase;
  background: rgba(20, 209, 178, 0.2);
  border: none;
  border-radius: ${({ borderRadius }) =>
    borderRadius ? borderRadius : "8px 0"};
  &:hover {
    background: #14d1b2;
    color: #000000;
  }
`;
