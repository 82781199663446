import { ethers } from "ethers";
import { getContractAddress, getPriceKey } from "../Addresses";

import BTC_icon from "../assets/images/tokens/BTC.svg";
import BNB_icon from "../assets/images/tokens/BNB.svg";
import BUSD_icon from "../assets/images/tokens/BUSD.svg";
import ETH_icon from "../assets/images/tokens/ETH.svg";
import ADA_icon from "../assets/images/tokens/ADA.svg";
import CAKE_icon from "../assets/images/tokens/CAKE.svg";
import ARB_icon from "../assets/images/tokens/Arbitrum.svg";
import LINK_icon from "../assets/images/tokens/LINK.svg";
import XRP_icon from "../assets/images/tokens/XRP.svg";
import USDC_icon from "../assets/images/tokens/USDC.svg";
import USDT_icon from "../assets/images/tokens/USDT.svg";
import DAI_icon from "../assets/images/tokens/DAI.svg";
import DOGE_icon from "../assets/images/tokens/DOGE.svg";
import DOT_icon from "../assets/images/tokens/DOT.svg";
import FIL_icon from "../assets/images/tokens/FIL.svg";
import GMT_icon from "../assets/images/tokens/GMT.svg";
import INJ_icon from "../assets/images/tokens/INJ.svg";
import LTC_icon from "../assets/images/tokens/LTC.svg";
import MATIC_icon from "../assets/images/tokens/MATIC.svg";
import MBOX_icon from "../assets/images/tokens/MBOX.svg";
import REEF_icon from "../assets/images/tokens/REEF.svg";
import SXP_icon from "../assets/images/tokens/SXP.svg";
import TRX_icon from "../assets/images/tokens/TRX.svg";
import XAU_icon from "../assets/images/tokens/XAU.svg";
import XVS_icon from "../assets/images/tokens/XVS.svg";
import PIKO_icon from '../assets/images/PIKO.svg'
import SOL_icon from 'src/assets/images/tokens/SOL.svg'
import OP_icon from 'src/assets/images/tokens/OP.svg'
import SUI_icon from 'src/assets/images/tokens/SUI.svg'
import PEPE_icon from 'src/assets/images/tokens/PEPE.png'
import { PLP_TYPES } from "src/constants/tokens";

const TOKENS = {
  280: [
    {
      name: "ETH",
      symbol: "ETH",
      decimals: 18,
      isNative: true,
      address: ethers.constants.AddressZero,
      pirceId: getPriceKey(280, 'KEY_ETH'),
      imageUrl: ETH_icon,
      isShortable: true,
      isLongable: true,
      isPlp1: true,
      tokenDecimals: 2,
      bgcolor: "#6F7BBA",
    },
    {
      name: "ETH (Wrapped)",
      symbol: "WETH",
      baseSymbol: "ETH",
      decimals: 18,
      address: getContractAddress(280, "WETH"),
      pirceId: getPriceKey(280, 'KEY_ETH'),
      imageUrl: ETH_icon,
      isShortable: false,
      isWrapped: true,
      isPlp1: true,
      tokenDecimals: 2,
      bgcolor: "#6F7BBA",
      updataPrice: true,
    },
    {
      name: "Bitcoin",
      symbol: "BTC",
      decimals: 18,
      address: getContractAddress(280, "BTC"),
      pirceId: getPriceKey(280, 'KEY_BTC'),
      imageUrl: BTC_icon,
      isShortable: true,
      isLongable: true,
      isPlp1: true,
      tokenDecimals: 2,
      bgcolor: "#F7931A",
      updataPrice: true,
    },
    {
      name: "USDC",
      symbol: "USDC",
      decimals: 18,
      address: getContractAddress(280, "USDC"),
      pirceId: getPriceKey(280, 'KEY_USDC'),
      imageUrl: USDC_icon,
      isShortable: true,
      isStable: true,
      isPlp1: true,
      tokenDecimals: 2,
      bgcolor: "#2775CA",
      updataPrice: true,
    },
    {
      name: "USDT",
      symbol: "USDT",
      decimals: 18,
      address: getContractAddress(280, "USDT"),
      pirceId: getPriceKey(280, 'KEY_USDT'),
      imageUrl: USDT_icon,
      isShortable: true,
      isStable: true,
      isPlp1: true,
      isPlp3: true,
      tokenDecimals: 2,
      bgcolor: "#53AE94",
      updataPrice: true,
    },
    {
      name: "DAI",
      symbol: "DAI",
      decimals: 18,
      address: getContractAddress(280, "DAI"),
      pirceId: getPriceKey(280, 'KEY_DAI'),
      imageUrl: DAI_icon,
      isShortable: true,
      isStable: true,
      isPlp1: true,
      tokenDecimals: 2,
      bgcolor: "#F9AF1B",
    },
    {
      name: "PIKO",
      symbol: "PIKO",
      decimals: 18,
      address: getContractAddress(280, "PIKO"),
      pirceId: getPriceKey(280, 'KEY_DAI'),
      imageUrl: PIKO_icon,
      tokenDecimals: 2,
      bgcolor: "#F9AF1B",
    },
  ],
  324: [{
    name: "ETH",
    symbol: "ETH",
    decimals: 18,
    isNative: true,
    address: ethers.constants.AddressZero,
    pirceId: getPriceKey(324, 'KEY_ETH'),
    imageUrl: ETH_icon,
    isShortable: true,
    isLongable: true,
    isPlp1: true,
    tokenDecimals: 2,
    bgcolor: "#6F7BBA",
    updataPrice: false,
  },
  {
    name: "ETH (Wrapped)",
    symbol: "WETH",
    baseSymbol: "ETH",
    decimals: 18,
    address: getContractAddress(324, "WETH"),
    pirceId: getPriceKey(324, 'KEY_ETH'),
    imageUrl: ETH_icon,
    isShortable: false,
    isWrapped: true,
    isPlp1: true,
    tokenDecimals: 2,
    bgcolor: "#6F7BBA",
    updataPrice: true,
  },
  {
    name: "Bitcoin",
    symbol: "BTC",
    decimals: 8,
    address: getContractAddress(324, "BTC"),
    pirceId: getPriceKey(324, 'KEY_BTC'),
    imageUrl: BTC_icon,
    isShortable: true,
    isLongable: true,
    isPlp1: true,
    tokenDecimals: 2,
    bgcolor: "#F7931A",
    updataPrice: true,
  },
  {
    name: "USDC",
    symbol: "USDC",
    decimals: 6,
    address: getContractAddress(324, "USDC"),
    pirceId: getPriceKey(324, 'KEY_USDC'),
    imageUrl: USDC_icon,
    isShortable: true,
    isStable: true,
    isPlp1: true,
    tokenDecimals: 2,
    bgcolor: "#2775CA",
    updataPrice: true,
  },
  {
    name: "USDT",
    symbol: "USDT",
    decimals: 6,
    address: getContractAddress(324, "USDT"),
    pirceId: getPriceKey(324, 'KEY_USDT'),
    imageUrl: USDT_icon,
    isShortable: true,
    isStable: true,
    isPlp1: true,
    isPlp3: true,
    tokenDecimals: 2,
    bgcolor: "#53AE94",
    updataPrice: true,
  },
  {
    name: "PIKO",
    symbol: "PIKO",
    decimals: 18,
    address: getContractAddress(324, "PIKO"),
    pirceId: getPriceKey(324, 'KEY_USDT'),
    imageUrl: PIKO_icon,
    tokenDecimals: 2,
    bgcolor: "#F9AF1B",
  },
  ],
  5001: [
    {
      name: "ETH (Wrapped)",
      symbol: "WETH",
      baseSymbol: "ETH",
      decimals: 18,
      address: getContractAddress(5001, "ETH"),
      pirceId: getPriceKey(5001, 'KEY_ETH'),
      imageUrl: ETH_icon,
      isShortable: true,
      isLongable: true,
      isPlp1: true,
      tokenDecimals: 2,
      isWrapped: true,
      bgcolor: "#6F7BBA",
    },
    {
      name: "Bitcoin",
      symbol: "BTC",
      decimals: 8,
      address: getContractAddress(5001, "BTC"),
      pirceId: getPriceKey(5001, 'KEY_BTC'),
      imageUrl: BTC_icon,
      isShortable: true,
      isLongable: true,
      isPlp1: true,
      tokenDecimals: 2,
      bgcolor: "#F7931A",
    },
    {
      name: "USDC",
      symbol: "USDC",
      decimals: 6,
      address: getContractAddress(5001, "USDC"),
      pirceId: getPriceKey(5001, 'KEY_USDC'),
      imageUrl: USDC_icon,
      isShortable: true,
      isStable: true,
      isPlp1: true,
      tokenDecimals: 2,
      bgcolor: "#2775CA",
    },
    {
      name: "USDT",
      symbol: "USDT",
      decimals: 6,
      address: getContractAddress(5001, "USDT"),
      pirceId: getPriceKey(5001, 'KEY_USDT'),
      imageUrl: USDT_icon,
      isShortable: true,
      isStable: true,
      isPlp1: true,
      isPlp3: true,
      tokenDecimals: 2,
      bgcolor: "#53AE94",
    },
  ],
  5000: [
    {
      name: "ETH",
      symbol: "ETH",
      decimals: 18,
      isNative: true,
      address: ethers.constants.AddressZero,
      pirceId: getPriceKey(5000, 'KEY_ETH'),
      imageUrl: ETH_icon,
      isShortable: true,
      isLongable: true,
      isPlp1: true,
      tokenDecimals: 2,
      bgcolor: "#6F7BBA",
      updataPrice: false,
      isTempHidden: true,
    },
    {
      name: "ETH (Wrapped)",
      symbol: "WETH",
      baseSymbol: "ETH",
      decimals: 18,
      address: getContractAddress(5000, "WETH"),
      pirceId: getPriceKey(5000, 'KEY_ETH'),
      imageUrl: ETH_icon,
      isShortable: false,
      isWrapped: true,
      isPlp1: true,
      tokenDecimals: 2,
      bgcolor: "#6F7BBA",
      updataPrice: true,
    },
    {
      name: "Bitcoin",
      symbol: "BTC",
      decimals: 8,
      address: getContractAddress(5000, "BTC"),
      pirceId: getPriceKey(5000, 'KEY_BTC'),
      imageUrl: BTC_icon,
      isShortable: true,
      isLongable: true,
      isPlp1: true,
      tokenDecimals: 2,
      bgcolor: "#F7931A",
    },
    {
      name: "USDC",
      symbol: "USDC",
      decimals: 6,
      address: getContractAddress(5000, "USDC"),
      pirceId: getPriceKey(5000, 'KEY_USDC'),
      imageUrl: USDC_icon,
      isShortable: true,
      isStable: true,
      isPlp1: true,
      tokenDecimals: 2,
      bgcolor: "#2775CA",
    },
    {
      name: "PIKO",
      symbol: "PIKO",
      decimals: 18,
      address: getContractAddress(5000, "PIKO"),
      pirceId: getPriceKey(5000, 'KEY_USDT'),
      imageUrl: PIKO_icon,
      tokenDecimals: 2,
      bgcolor: "#F9AF1B",
    },
  ],
  421613: [
    {
      name: "ETH",
      symbol: "ETH",
      decimals: 18,
      isNative: true,
      address: ethers.constants.AddressZero,
      pirceId: getPriceKey(421613, 'KEY_ETH'),
      imageUrl: ETH_icon,
      isShortable: true,
      isLongable: true,
      isPlp1: true,
      tokenDecimals: 2,
      bgcolor: "#6F7BBA",
      updataPrice: false,
    },
    {
      name: "ETH (Wrapped)",
      symbol: "WETH",
      baseSymbol: "ETH",
      decimals: 18,
      address: getContractAddress(421613, "WETH"),
      pirceId: getPriceKey(421613, 'KEY_ETH'),
      imageUrl: ETH_icon,
      isShortable: false,
      isWrapped: true,
      isPlp1: true,
      tokenDecimals: 2,
      bgcolor: "#6F7BBA",
      updataPrice: true,
    },
    {
      name: "Bitcoin",
      symbol: "BTC",
      decimals: 8,
      address: getContractAddress(421613, "BTC"),
      pirceId: getPriceKey(421613, 'KEY_BTC'),
      imageUrl: BTC_icon,
      isShortable: true,
      isLongable: true,
      isPlp1: true,
      tokenDecimals: 2,
      bgcolor: "#F7931A",
    },
    {
      name: "USDC",
      symbol: "USDC",
      decimals: 6,
      address: getContractAddress(421613, "USDC"),
      pirceId: getPriceKey(421613, 'KEY_USDC'),
      imageUrl: USDC_icon,
      isShortable: true,
      isStable: true,
      isShortable: true,
      isLongable: true,
      tokenDecimals: 2,
      bgcolor: "#2775CA",
    },
    {
      name: "PIKO",
      symbol: "PIKO",
      decimals: 18,
      address: getContractAddress(421613, "PIKO"),
      pirceId: getPriceKey(421613, 'KEY_USDT'),
      imageUrl: PIKO_icon,
      tokenDecimals: 2,
      bgcolor: "#F9AF1B",

    },
  ],
  42161: [
    {
      name: "ETH",
      symbol: "ETH",
      decimals: 18,
      isNative: true,
      address: ethers.constants.AddressZero,
      pirceId: getPriceKey(42161, 'KEY_ETH'),
      imageUrl: ETH_icon,
      isShortable: true,
      isLongable: true,
      isPlp1: true,
      tokenDecimals: 2,
      bgcolor: "#6F7BBA",
      updataPrice: false,
    },
    {
      name: "ETH (Wrapped)",
      symbol: "WETH",
      baseSymbol: "ETH",
      decimals: 18,
      address: getContractAddress(42161, "WETH"),
      pirceId: getPriceKey(42161, 'KEY_ETH'),
      imageUrl: ETH_icon,
      isShortable: false,
      isWrapped: true,
      isPlp1: true,
      tokenDecimals: 2,
      bgcolor: "#6F7BBA",
      updataPrice: true,
    },
    {
      name: "Bitcoin",
      symbol: "BTC",
      decimals: 8,
      address: getContractAddress(42161, "BTC"),
      pirceId: getPriceKey(42161, 'KEY_BTC'),
      imageUrl: BTC_icon,
      isShortable: true,
      isLongable: true,
      isPlp1: true,
      tokenDecimals: 2,
      bgcolor: "#F7931A",
    },
    {
      name: "USDC",
      symbol: "USDC",
      decimals: 6,
      address: getContractAddress(42161, "USDC"),
      pirceId: getPriceKey(42161, 'KEY_USDC'),
      imageUrl: USDC_icon,
      isShortable: true,
      isStable: true,
      isPlp1: true,
      tokenDecimals: 2,
      bgcolor: "#2775CA",
    },
    {
      name: "ARB",
      symbol: "ARB",
      decimals: 18,
      address: getContractAddress(42161, "ARB"),
      pirceId: getPriceKey(42161, 'KEY_ARB'),
      imageUrl: ARB_icon,
      tokenDecimals: 2,
      bgcolor: "#F9AF1B",
    },
    {
      name: "PIKO",
      symbol: "PIKO",
      decimals: 18,
      address: getContractAddress(42161, "PIKO"),
      pirceId: getPriceKey(42161, 'KEY_USDT'),
      imageUrl: PIKO_icon,
      tokenDecimals: 2,
      bgcolor: "#F9AF1B",

    },
  ],
};


export const WHITE_GNS_TOKEN_ADDRESS = {
  421613: [{
    name: "ARB",
    symbol: "ARB",
    decimals: 18,
    address: getContractAddress(421613, "ARB"),
    pirceId: getPriceKey(421613, 'KEY_ARB'),
    imageUrl: ARB_icon,
    isShortable: true,
    isLongable: true,
    tokenDecimals: 4,
    bgcolor: "#F9AF1B",
  },
  {
    name: "OP",
    symbol: "OP",
    decimals: 18,
    address: getContractAddress(421613, "OP"),
    pirceId: getPriceKey(421613, 'KEY_OP'),
    imageUrl: OP_icon,
    tokenDecimals: 4,
    isShortable: true,
    isLongable: true,
    bgcolor: "#F9AF1B",

  },
  {
    name: "BNB",
    symbol: "BNB",
    decimals: 18,
    address: getContractAddress(421613, "BNB"),
    pirceId: getPriceKey(421613, 'KEY_BNB'),
    imageUrl: BNB_icon,
    isShortable: true,
    isLongable: true,
    tokenDecimals: 2,
    bgcolor: "#F9AF1B",

  },
  {
    name: "SOL",
    symbol: "SOL",
    decimals: 18,
    address: getContractAddress(421613, "SOL"),
    pirceId: getPriceKey(421613, 'KEY_SOL'),
    imageUrl: SOL_icon,
    isShortable: true,
    isLongable: true,
    tokenDecimals: 4,
    bgcolor: "#F9AF1B",

  },
  {
    name: "SUI",
    symbol: "SUI",
    decimals: 18,
    address: getContractAddress(421613, "SUI"),
    pirceId: getPriceKey(421613, 'KEY_SUI'),
    imageUrl: SUI_icon,
    tokenDecimals: 4,
    isShortable: true,
    isLongable: true,
    bgcolor: "#F9AF1B",

  },
  {
    name: "MATIC",
    symbol: "MATIC",
    decimals: 18,
    address: getContractAddress(421613, "MATIC"),
    pirceId: getPriceKey(421613, 'KEY_MATIC'),
    imageUrl: MATIC_icon,
    isShortable: true,
    isLongable: true,
    tokenDecimals: 4,
    bgcolor: "#F9AF1B",

  },
  {
    name: "PEPE",
    symbol: "PEPE",
    decimals: 18,
    isShortable: true,
    isLongable: true,
    address: getContractAddress(421613, "PEPE"),
    pirceId: getPriceKey(421613, 'KEY_PEPE'),
    imageUrl: PEPE_icon,
    tokenDecimals: 10,
    bgcolor: "#F9AF1B",

  },
  {
    name: "DOGE",
    symbol: "DOGE",
    decimals: 18,
    isShortable: true,
    isLongable: true,
    address: getContractAddress(421613, "DOGE"),
    pirceId: getPriceKey(421613, 'KEY_DOGE'),
    imageUrl: DOGE_icon,
    tokenDecimals: 4,
    bgcolor: "#F9AF1B",

  },]
}

const ADDITIONAL_TOKENS = {

};

const CHAIN_IDS = [280, 324, 5001, 5000, 421613, 42161];
const TOKENS_MAP = {};
const TOKENS_BY_SYMBOL_MAP = {};
const TOKENS_UP_MAP = {}

for (let j = 0;j < CHAIN_IDS.length;j++) {
  const chainId = CHAIN_IDS[j];
  TOKENS_MAP[chainId] = {};
  TOKENS_BY_SYMBOL_MAP[chainId] = {};
  TOKENS_UP_MAP[chainId] = {};
  let tokens = TOKENS[chainId];
  if (ADDITIONAL_TOKENS[chainId]) {
    tokens = tokens.concat(ADDITIONAL_TOKENS[chainId]);
  }
  if (WHITE_GNS_TOKEN_ADDRESS[chainId]) {
    tokens = tokens.concat(WHITE_GNS_TOKEN_ADDRESS[chainId])
  }

  for (let i = 0;i < tokens.length;i++) {
    const token = tokens[i];
    TOKENS_MAP[chainId][token.address] = token;
    TOKENS_UP_MAP[chainId][token.address.toLowerCase()] = token;
    TOKENS_BY_SYMBOL_MAP[chainId][token.symbol] = token;
  }
}

const WRAPPED_TOKENS_MAP = {};
const NATIVE_TOKENS_MAP = {};
for (const chainId of CHAIN_IDS) {
  for (const token of TOKENS[chainId]) {
    if (token.isWrapped) {
      WRAPPED_TOKENS_MAP[chainId] = token;
    } else if (token.isNative) {
      NATIVE_TOKENS_MAP[chainId] = token;
    }
  }
}

export function getWrappedToken(chainId) {
  return WRAPPED_TOKENS_MAP[chainId] ?? ethers.constants.AddressZero;
}

export function getNativeToken(chainId) {
  return NATIVE_TOKENS_MAP[chainId];
}

export function getTokens(chainId) {
  return TOKENS[chainId];
}

export function isValidToken(chainId, address) {
  if (!TOKENS_MAP[chainId]) {
    throw new Error(`Incorrect chainId ${chainId}`);
  }
  return address in TOKENS_MAP[chainId];
}

export function getToken(chainId, address) {
  if (!TOKENS_MAP[chainId]) {
    throw new Error(`Incorrect chainId ${chainId}`);
  }
  if (!TOKENS_MAP[chainId][address]) {
    throw new Error(`Incorrect address "${address}" for chainId ${chainId}`);
  }
  return TOKENS_MAP[chainId][address];
}
export function getUpToken(chainId, address) {
  address = address.toLowerCase();
  if (!TOKENS_UP_MAP[chainId]) {
    throw new Error(`Incorrect chainId ${chainId}`);
  }
  if (!TOKENS_UP_MAP[chainId][address]) {
    throw new Error(`Incorrect address "${address}" for chainId ${chainId}`);
  }
  return TOKENS_UP_MAP[chainId][address];
}
export function getUpToken2(chainId, address) {
  address = address.toLowerCase();
  return TOKENS_UP_MAP[chainId][address];
}

export function getTokenBySymbol(chainId, symbol) {
  const token = TOKENS_BY_SYMBOL_MAP[chainId][symbol];
  if (!token) {
    throw new Error(`Incorrect symbol "${symbol}" for chainId ${chainId}`);
  }
  return token;
}

export function getWhitelistedTokens(chainId) {
  return TOKENS[chainId].filter(token => token.symbol !== "USDX");
}

export function getWhitelistedTokens2(plpName, chainId) {
  const tokens = TOKENS[chainId]
  const token1 = tokens.filter(token => token.isPlp1)
  const token2 = tokens.filter(token => token.isPlp2)
  return plpName == PLP_TYPES.PLP_1 ? token1.filter(token => token.symbol !== "USDX") : token2.filter(token => token.symbol !== "USDX");
}

export function getTokenMeta(chainId, address) {
  let supportedTokens = TOKENS[chainId];
  const isGNS = WHITE_GNS_TOKEN_ADDRESS[chainId];
  let obj = supportedTokens.concat(isGNS).find(token => token.address.toLowerCase() === address.toLowerCase());

  return obj;
}

export function getPriceDecimals(chainId, tokenSymbol) {
  if (!tokenSymbol) return 2;
  try {
    const token = getTokenBySymbol(chainId, tokenSymbol);
    return token.tokenDecimals ?? 2;
  } catch (e) {
    return 2;
  }
}

