import { useMemo } from "react";
import { useSelector } from "react-redux";

export const useGetPLPData = (plpName?: string) => {
  const PLPMAP = useSelector((state: any) => {
    return state.app.PLPMAP || {};
  });
  return useMemo(() => {
    if (!plpName || !PLPMAP[plpName]) return {};
    const {
      routerSign_address,
      router_address,
      positionRouter_address,
      orderBook_address,
    } = PLPMAP[plpName];
    return {
      routerAddress: router_address,
      routerSign_addr: routerSign_address,
      positionRouterAddress: positionRouter_address,
      orderBookAddress: orderBook_address,
      ...PLPMAP[plpName],
    };
  }, [PLPMAP, plpName]);
};
