import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";
import cx from "classnames";
import { escapeRegExp } from "src/utils";
import USD from "src/assets/images/usd.svg";
import { BN } from "src/utils/bn";
const MyBox = styled(Box)`
  &.pay_box {
    background: #101010;
    mix-blend-mode: normal;
    /* box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25); */
    border-radius: 8px;
    padding: 16px 16px 16px;
    position: relative;
    .pay_bg {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: -1;
      border-radius: 8px;
      &.long {
        background: rgba(10, 213, 189, 0.1);
        text-transform: uppercase;
      }
      &.short {
        background: rgba(250, 85, 66, 0.1);
        text-transform: uppercase;
      }
    }

    .pay_titles {
      display: flex;
      justify-content: space-between;
      margin-bottom: 18px;

      .p_title_size {
        color: rgba(255, 255, 255, 0.54);
        font-size: 14px;
        &.long {
          text-transform: uppercase;
          color: #0ad5bd;
        }
        &.short {
          color: #fa5542;
          text-transform: uppercase;
        }
      }
      .p_title_r {
        font-weight: 400;
        font-size: 12px;
        text-align: right;
        color: #eef2f9;
      }
      .p_balnse {
        background: rgba(255, 255, 255, 0.05);
        border-radius: 4px;
        color: rgba(255, 255, 255, 0.54);
        line-height: 20px;
        padding: 4px 8px;
      }
    }

    .pay_token {
      display: flex;

      .p_token {
        display: flex;
        align-items: center;
        gap: 8px;
        font-size: 16px;

        > img {
          width: 30px;
          height: 30px;
        }
      }
    }
    .t_amount {
      display: flex;
      flex: 1;
      align-items: center;
    }
    .use_usud {
      text-align: right;
      font-size: 14px;
      color: rgba(255, 255, 255, 0.54);
      margin-top: 2px;
    }
    .g_input {
      width: 100%;
      padding: 0;
      min-height: 30px;
      font-size: 20px;
      text-align: right;
      &.long {
        color: #0ad5bd;
      }
      &.short {
        color: #fa5542;
      }
    }
    .show_max {
      display: flex;
      justify-content: flex-end;
      gap: 4px;
      width: 100%;
      .max_item {
        width: 100%;
        border: 1px solid rgba(255, 255, 255, 0.16);
        border-radius: 4px;
        font-size: 12px;
        text-align: center;
        font-size: 12px;
        color: rgba(255, 255, 255, 0.54);
        cursor: pointer;
        &:hover {
          background: rgba(255, 255, 255, 0.2);
          color: rgba(255, 255, 255, 0.87);
        }
      }
    }
  }
`;

type InputProps = {
  token?: {
    imageUrl: string;
    symbol: string;
  };
  balance?: string;
  inputDir?: string;
  leftLable?: keyof PositionPorps | string;
  disabled: boolean;
  totalBalance?: number;
  showMax: boolean;
  showUsd: boolean;
  value: string | number;
  isOpenPosition: boolean;
  rightLable?: string;
  usdText?: string;
  leftBalance?: string;
  onUserInput?: (value: string) => void;
  onUsdClick?: () => void;
  className?: string;
  children?: any;
};

enum PositionPorps {
  Long = "Long",
  Short = "Short",
}
PositionPorps.Long;
const inputRegex = RegExp(`^\\d*(?:\\\\[.])?\\d*$`);
const defaultToken = {
  symbol: "USD",
  imageUrl: USD,
};
const InputSelect = ({
  token = defaultToken,
  balance,
  disabled = false,
  usdText,
  totalBalance,
  value,
  showMax = false,
  showUsd = true,
  leftLable,
  rightLable,
  leftBalance,
  isOpenPosition = false,
  className,
  onUsdClick,
  onUserInput,
  children,
}: InputProps) => {
  const change = (e: any) => {
    const value = e.target.value;
    if (value === "" || inputRegex.test(escapeRegExp(value))) {
      onUserInput && onUserInput(value);
    }
  };
  const setAmount = (rate: number) => {
    if (!onUserInput) return;
    const totalAmount = totalBalance || 0;
    const amount = BN(totalAmount).times(rate).toFixed();
    onUserInput(amount);
  };

  return (
    <MyBox className={cx("pay_box", className)}>
      {isOpenPosition && (
        <div
          className={cx("pay_bg", {
            long: leftLable == PositionPorps.Long,
            short: leftLable == PositionPorps.Short,
          })}
        ></div>
      )}
      <div className="pay_titles">
        <div
          className={cx("p_title_size", "font700", {
            long: leftLable == PositionPorps.Long,
            short: leftLable == PositionPorps.Short,
          })}
        >
          {leftLable}
          {leftBalance}
        </div>
        <div className="p_title_r ">
          {rightLable && rightLable}
          {balance && <span className="p_balnse">{balance}</span>}
        </div>
      </div>
      <div className="pay_token">
        {children ?? (
          <div className="p_token color28 font700">
            <img src={token?.imageUrl} alt="" />
            {token?.symbol}
          </div>
        )}

        <div className="t_amount">
          <input
            type="text"
            onChange={change}
            className={cx("g_input font700 color28", {
              long: leftLable == PositionPorps.Long,
              short: leftLable == PositionPorps.Short,
            })}
            value={value}
            disabled={disabled}
          />
        </div>
      </div>
      {showUsd && (
        <div className="use_usud" onClick={() => onUsdClick && onUsdClick()}>
          {usdText}
        </div>
      )}
      {showMax && (
        <div className="show_max font700 mt-10">
          <div className="max_item" onClick={() => setAmount(0.25)}>
            25%
          </div>
          <div className="max_item" onClick={() => setAmount(0.5)}>
            50%
          </div>
          <div className="max_item" onClick={() => setAmount(0.75)}>
            75%
          </div>
          <div className="max_item" onClick={() => setAmount(1)}>
            MAX
          </div>
        </div>
      )}
    </MyBox>
  );
};

export default InputSelect;
