import moment from "moment";
import { useMemo } from "react";

import { addresses as ADDRESS } from "../configs/constants";
import { PLACEHOLDER_ACCOUNT } from "src/helpers/Helpers";
import { useSingleCallResult } from "src/lib/hooks/multicall";
import { BN, toFromBN } from "src/utils/bn";
import { useGetPikoPrice } from "./get_price_piko";
import {
  usePIKOContract,
  useDisTokenContract,
  usePUSDContract,
  useDisFeeContract,
  useInfoHelperContract,
  useRewardRouterContract,
  useVePIKOContract,
  useVeesPIKOContract,
  useEsPIKOContract,
  useTotalVeTokenContract,
  useUSDCContract,
} from "./useContract";
import { useWeb3Context } from "./web3Context";
import { ethers } from "ethers";
import { getTokens } from "src/configs/Tokens";
import { compareAddress } from "src/utils/address";

export const usePUSDAccount = () => {
  const { address: account } = useWeb3Context();
  const PUSDContract = usePUSDContract();

  const { result } = useSingleCallResult(PUSDContract, "balanceOf", [
    account ? account : PLACEHOLDER_ACCOUNT,
  ]);

  return useMemo(() => {
    if (!result) return;
    const data = result[0];
    return toFromBN(data);
  }, [result]);
};

export const usePUSDTotalSupply = () => {
  const PUSDContract = usePUSDContract();

  const { result } = useSingleCallResult(PUSDContract, "totalSupply");

  return useMemo(() => {
    if (!result) return;
    const data = result[0];
    return toFromBN(data);
  }, [result]);
};

export const useTotalSupply_ve = () => {
  const totalVeTokenContract = useTotalVeTokenContract();
  const { result } = useSingleCallResult(
    totalVeTokenContract,
    "totalSupplyAtNow"
  );

  return useMemo(() => {
    if (!result) return;
    const data = result[0];
    return toFromBN(data);
  }, [result]);
};

export const useTotalSupply_vePIKO = () => {
  const vePIKOContract = useVePIKOContract();
  const { result } = useSingleCallResult(vePIKOContract, "totalSupplyAtNow");
  return useMemo(() => {
    if (!result) return;
    const data = result[0];
    return toFromBN(data);
  }, [result]);
};

export const useTotalSupply_veesPIKO = () => {
  const veesPIKOContract = useVeesPIKOContract();
  const { result } = useSingleCallResult(veesPIKOContract, "totalSupplyAtNow");
  return useMemo(() => {
    if (!result) return;
    const data = result[0];
    return toFromBN(data);
  }, [result]);
};

export const useBalance_PIKO = () => {
  const { address } = useWeb3Context();
  const PIKOContract = usePIKOContract();
  const { result } = useSingleCallResult(PIKOContract, "balanceOf", [
    address ? address : PLACEHOLDER_ACCOUNT,
  ]);
  return useMemo(() => {
    if (!result) return;
    return toFromBN(result[0]);
  }, [result]);
};

export const useStaked_PIKO = () => {
  const { address: account } = useWeb3Context();
  const vePIKOContract = useVePIKOContract();
  const { result } = useSingleCallResult(vePIKOContract, "locked", [
    account ? account : PLACEHOLDER_ACCOUNT,
  ]);

  return useMemo(() => {
    if (!result) return;
    return {
      amount: toFromBN(result.amount),
      endTime: BN(result.end.toString()).toNumber(),
    };
  }, [result]);
};

export const useStaked_esPIKO = () => {
  const { address: account } = useWeb3Context();
  const veesPIKOContract = useVeesPIKOContract();
  const { result } = useSingleCallResult(veesPIKOContract, "locked", [
    account ? account : PLACEHOLDER_ACCOUNT,
  ]);
  return useMemo(() => {
    if (!result) return;
    return {
      amount: toFromBN(result.amount),
      endTime: BN(result.end.toString()).toNumber(),
    };
  }, [result]);
};

export const useVePIKOBalance = () => {
  const { address: account } = useWeb3Context();
  const vePIKOContract = useVePIKOContract();
  const { result } = useSingleCallResult(vePIKOContract, "balanceOfAtNow", [
    account ? account : PLACEHOLDER_ACCOUNT,
  ]);

  return useMemo(() => {
    if (!result) return;
    const data = result[0];
    return toFromBN(data);
  }, [result]);
};

export const useVeesPIKOBalance = () => {
  const { address: account } = useWeb3Context();
  const veesPIKOContract = useVeesPIKOContract();
  const { result } = useSingleCallResult(veesPIKOContract, "balanceOfAtNow", [
    account ? account : PLACEHOLDER_ACCOUNT,
  ]);

  return useMemo(() => {
    if (!result) return;
    const data = result[0];
    return toFromBN(data);
  }, [result]);
};

export const useTotalStaked_PIKO = () => {
  const vePIKOContract = useVePIKOContract();
  const { result } = useSingleCallResult(vePIKOContract, "totalTokenSupply");
  return useMemo(() => {
    if (!result) return;
    const data = result[0];
    return toFromBN(data);
  }, [result]);
};

export const useTotalStaked_esPIKO = () => {
  const veesPIKOContract = useVeesPIKOContract();
  const { result } = useSingleCallResult(veesPIKOContract, "totalTokenSupply");
  return useMemo(() => {
    if (!result) return;
    const data = result[0];
    return toFromBN(data);
  }, [result]);
};

export const useBalance_esPIKO = () => {
  const { address: account } = useWeb3Context();
  const esPIKOContract = useEsPIKOContract();
  const { result } = useSingleCallResult(esPIKOContract, "balanceOf", [
    account ? account : PLACEHOLDER_ACCOUNT,
  ]);
  return useMemo(() => {
    if (!result) return;
    const data = result[0];
    return toFromBN(data);
  }, [result]);
};

export const useBalance_usdc = () => {
  const { address: account } = useWeb3Context();
  const contract_usdc = useUSDCContract();
  const { result } = useSingleCallResult(contract_usdc, "balanceOf", [
    account ? account : PLACEHOLDER_ACCOUNT,
  ]);
  return useMemo(() => {
    if (!result) return;
    const data = result[0];
    return toFromBN(data, 6);
  }, [result]);
};

export const useDistributorToken = () => {
  const DisTokenContract = useDisTokenContract();
  const { result } = useSingleCallResult(
    DisTokenContract,
    "getYieldForDuration"
  );
  return useMemo(() => {
    if (!result) return;
    const data = result[0];
    return toFromBN(data);
  }, [result]);
};

export const useDistributorFee = () => {
  const DisTokenContract = useDisFeeContract();
  const { result } = useSingleCallResult(
    DisTokenContract,
    "getYieldForDuration"
  );
  return useMemo(() => {
    if (!result) return;
    const data = result[0];
    return toFromBN(data);
  }, [result]);
};

export const useQueryEarnApr = () => {
  const vePIKOBalance = useVePIKOBalance();
  const veesPIKOBalance = useVeesPIKOBalance();
  const weekRewards_PIKO = useDistributorToken();
  const weekrewards_usdc = useDistributorFee();
  const totalSupply_ve = useTotalSupply_ve();

  const PIKOPrice = useGetPikoPrice();
  const PIKOStakedAmount = useStaked_PIKO();
  const esPIKOStakedAmount = useStaked_esPIKO();

  return useMemo(() => {
    if (
      !vePIKOBalance ||
      !veesPIKOBalance ||
      !weekRewards_PIKO ||
      !weekrewards_usdc ||
      !totalSupply_ve ||
      !PIKOPrice ||
      !PIKOStakedAmount ||
      !esPIKOStakedAmount
    )
      return;
    let PIKOApr1 = BN(0);
    let PIKOApr2 = BN(0);
    let USDCApr1 = BN(0);
    let USDCApr2 = BN(0);
    // console.log(
    //   "weekRewards_PIKO",
    //   weekRewards_PIKO?.toFixed(),
    //   "totalSupply_ve",
    //   totalSupply_ve?.toFixed()
    // );
    if (totalSupply_ve.gt(0)) {
      PIKOApr1 = weekRewards_PIKO.div(7).times(365).div(totalSupply_ve);
      PIKOApr2 = weekRewards_PIKO
        .div(7)
        .times(365)
        .div(totalSupply_ve)
        .times(4);
    }
    if (weekrewards_usdc.gt(0) && totalSupply_ve.gt(0)) {
      USDCApr1 = weekrewards_usdc
        .div(PIKOPrice)
        .div(7)
        .times(365)
        .div(totalSupply_ve);
      USDCApr2 = weekrewards_usdc
        .div(PIKOPrice)
        .div(7)
        .times(365)
        .div(totalSupply_ve)
        .times(4);
    }
    const minApr = PIKOApr1.plus(USDCApr1);
    const maxApr = PIKOApr2.plus(USDCApr2);
    const totalBalance = vePIKOBalance.plus(veesPIKOBalance);
    const totalAmount = esPIKOStakedAmount.amount.plus(PIKOStakedAmount.amount);
    const PIKOApr = totalBalance.div(totalAmount).times(PIKOApr1);
    const USDCApr = totalBalance.div(totalAmount).times(USDCApr1);
    const estApr = PIKOApr.plus(USDCApr);
    const currtimestamp = moment().valueOf();
    const currtimestampS = (currtimestamp / 1000).toFixed(0);
    const endTime = PIKOStakedAmount.endTime - Number(currtimestampS);
    const endTime2 = esPIKOStakedAmount.endTime - Number(currtimestampS);
    return {
      vePIKOBalance: vePIKOBalance,
      veesPIKOBalance: veesPIKOBalance,
      PIKOApr1,
      PIKOApr2,
      USDCApr1,
      USDCApr2,
      minApr,
      maxApr,
      PIKOApr,
      USDCApr,
      estApr,
      endTime,
      endTime2,
      PIKOStaked: PIKOStakedAmount.amount,
      esPIKOStaked: esPIKOStakedAmount.amount,
    };
  }, [
    vePIKOBalance,
    veesPIKOBalance,
    weekRewards_PIKO,
    weekrewards_usdc,
    totalSupply_ve,
    PIKOPrice,
    PIKOStakedAmount,
    esPIKOStakedAmount,
  ]);
};

export const usePUSDPoolInfo = () => {
  const RewardRouterContract = useRewardRouterContract();
  const { result } = useSingleCallResult(
    RewardRouterContract,
    "getPUSDPoolInfo"
  );
  return useMemo(() => {
    if (!result) return;
    const data = result[0];
    return {
      totalCollateral: toFromBN(data[0], 30),
      circulatingSupply: toFromBN(data[2]),
      buyFees: BN(data[4].toString()),
      sellFees: BN(data[5].toString()),
    };
  }, [result]);
};

export const usePIKOStakeRewards = () => {
  const { address: account } = useWeb3Context();
  const DisTokenContract = useDisTokenContract();
  const DisFeeContract = useDisFeeContract();

  const { result: PIKOReward } = useSingleCallResult(
    DisTokenContract,
    "earned",
    [account ? account : PLACEHOLDER_ACCOUNT]
  );

  const { result: PUSDReward } = useSingleCallResult(DisFeeContract, "earned", [
    account ? account : PLACEHOLDER_ACCOUNT,
  ]);

  return useMemo(() => {
    if (!PIKOReward || !PUSDReward) return;
    const rewards_esPIKO = toFromBN(PIKOReward[0]);
    const rewards_usdc = toFromBN(PUSDReward[0]);
    return {
      rewards_esPIKO,
      rewards_usdc,
    };
  }, [PIKOReward, PUSDReward]);
};

export const useQueryInfoHelper = () => {
  const { address: account, chainID } = useWeb3Context();
  const InfoHelperContract = useInfoHelperContract();
  const data = useMemo(() => {
    return [ADDRESS[chainID].PID, account ? account : PLACEHOLDER_ACCOUNT];
  }, [account, chainID]);
  const { result } = useSingleCallResult(
    InfoHelperContract,
    "getBasicInfo",
    data,
    {
      gasRequired: 6000000,
    }
  );
  return useMemo(() => {
    if (!result) return;
    const userInfoArr = result[2];
    const rank = userInfoArr[10];
    return rank;
  }, [result]);
};

export const useQeuryUSDCCollateral = () => {
  const RewardRouterContract = useRewardRouterContract();
  const { chainID } = useWeb3Context();
  const tokens = getTokens(chainID);
  const { result } = useSingleCallResult(
    RewardRouterContract,
    "getPUSDCollateralDetail",
    undefined,
    { gasRequired: 3000000 }
  );
  return useMemo(() => {
    if (!result) return;
    const tokenAddressList = result[0];
    const tokenPriceList = result[1];
    const tokenPoolList = result[2];
    const collateralList: any = [];
    for (let i = 0; i < tokenAddressList.length; i++) {
      const currTokenArr = tokens.filter((token: { address: any }) =>
        compareAddress(tokenAddressList[i], token.address)
      );
      if (currTokenArr[0]) {
        const currToken = currTokenArr[0];
        const tokenAmount = ethers.utils.formatUnits(
          tokenPriceList[i],
          currTokenArr[0].decimals
        );
        const pool = ethers.utils.formatUnits(tokenPoolList[i], 30);
        const price = Number(pool) > 0 ? Number(pool) / Number(tokenAmount) : 0;
        const amount = Number(pool) > 0 ? Number(pool) / price : 0;
        collateralList.push({ amount, price, pool, ...currToken });
      }
    }

    return collateralList;
  }, [result, tokens]);
};

export const useQueryCircLocked = () => {
  const { chainID } = useWeb3Context();
  const PIKOContract = usePIKOContract();
  const totalSupply_vePIKO = useTotalSupply_vePIKO();
  const esPIKOContract = useEsPIKOContract();
  const totalSupply_veesPIKO = useTotalSupply_veesPIKO();
  const totalStaked_PIKO = useTotalStaked_PIKO();
  const totalStaked_esPIKO = useTotalStaked_esPIKO();

  //  common address
  const Deployer_addr = ADDRESS[chainID].Deployer;
  const DAO_addr = ADDRESS[chainID]?.Treasury;

  //  piko address
  const TokenVesting_PIKO_addr = ADDRESS[chainID].TokenVesting;
  const iPIKOVesting_PIKO_addr = ADDRESS[chainID].iPIKOVesting;

  // espiko address
  const RewardPool_esPIKO_addr = ADDRESS[chainID].RewardPool_PLP_β;
  const DisToken_esPIKO_addr = ADDRESS[chainID].DisToken;
  const Pool_izumi_addr = ADDRESS[chainID]?.Pool_izumi;
  const InviteToEarn_addr = ADDRESS[chainID]?.InviteToEarn;
  const TradeRebate_addr = ADDRESS[chainID]?.TradeRebate;

  //  piko
  const { result: PIKO_totalSupply_result } = useSingleCallResult(
    PIKOContract,
    "totalSupply"
  );

  const { result: PIKO_TokenVesting_result } = useSingleCallResult(
    PIKOContract,
    "balanceOf",
    [TokenVesting_PIKO_addr]
  );

  const { result: PIKO_iPIKOVesting_result } = useSingleCallResult(
    PIKOContract,
    "balanceOf",
    [iPIKOVesting_PIKO_addr]
  );

  const { result: PIKO_Deployer_result } = useSingleCallResult(
    PIKOContract,
    "balanceOf",
    [Deployer_addr]
  );

  const { result: PIKO_DAO_result } = useSingleCallResult(
    PIKOContract,
    "balanceOf",
    [DAO_addr]
  );

  //  espiko
  const { result: esPIKO_totalSupply_result } = useSingleCallResult(
    esPIKOContract,
    "totalSupply"
  );

  const { result: esPIKO_RewardPool_result } = useSingleCallResult(
    esPIKOContract,
    "balanceOf",
    [RewardPool_esPIKO_addr]
  );

  const { result: esPIKO_disToken_result } = useSingleCallResult(
    esPIKOContract,
    "balanceOf",
    [DisToken_esPIKO_addr]
  );

  const { result: esPIKO_Deployer_result } = useSingleCallResult(
    esPIKOContract,
    "balanceOf",
    [Deployer_addr]
  );

  const { result: esPIKO_DAO_result } = useSingleCallResult(
    esPIKOContract,
    "balanceOf",
    [DAO_addr]
  );

  const { result: esPIKO_Pool_izumi_result } = useSingleCallResult(
    esPIKOContract,
    "balanceOf",
    [Pool_izumi_addr]
  );

  const { result: esPIKO_InviteToEarn_result } = useSingleCallResult(
    esPIKOContract,
    "balanceOf",
    [InviteToEarn_addr]
  );

  const { result: esPIKO_TradeRebate_result } = useSingleCallResult(
    esPIKOContract,
    "balanceOf",
    [TradeRebate_addr]
  );
  // end

  // piko data
  const PIKO_balance_TokenVesting = useMemo(() => {
    if (!PIKO_TokenVesting_result) return;
    return toFromBN(PIKO_TokenVesting_result[0]);
  }, [PIKO_TokenVesting_result]);

  const PIKO_totalSupply = useMemo(() => {
    if (!PIKO_totalSupply_result) return;
    return toFromBN(PIKO_totalSupply_result[0]);
  }, [PIKO_totalSupply_result]);

  const PIKO_balance_iPIKOVesting = useMemo(() => {
    if (!PIKO_iPIKOVesting_result) return;
    return toFromBN(PIKO_iPIKOVesting_result[0]);
  }, [PIKO_iPIKOVesting_result]);

  const PIKO_balance_Deployer = useMemo(() => {
    if (!PIKO_Deployer_result) return;
    return toFromBN(PIKO_Deployer_result[0]);
  }, [PIKO_Deployer_result]);

  const PIKO_balance_DAO = useMemo(() => {
    if (!PIKO_DAO_result) return;
    return toFromBN(PIKO_DAO_result[0]);
  }, [PIKO_DAO_result]);

  //  espiko data
  const esPIKO_totalSupply = useMemo(() => {
    if (!esPIKO_totalSupply_result) return;
    return toFromBN(esPIKO_totalSupply_result[0]);
  }, [esPIKO_totalSupply_result]);

  const esPIKO_RewardPool = useMemo(() => {
    if (!esPIKO_RewardPool_result) return;
    return toFromBN(esPIKO_RewardPool_result[0]);
  }, [esPIKO_RewardPool_result]);

  const esPIKO_Deployer = useMemo(() => {
    if (!esPIKO_Deployer_result) return;
    return toFromBN(esPIKO_Deployer_result[0]);
  }, [esPIKO_Deployer_result]);

  const esPIKO_dis_token = useMemo(() => {
    if (!esPIKO_disToken_result) return;
    return toFromBN(esPIKO_disToken_result[0]);
  }, [esPIKO_disToken_result]);

  const esPIKO_balance_DAO = useMemo(() => {
    if (!esPIKO_DAO_result) return;
    return toFromBN(esPIKO_DAO_result[0]);
  }, [esPIKO_DAO_result]);

  const esPIKO_Pool_izumi = useMemo(() => {
    if (!esPIKO_Pool_izumi_result) return;
    return toFromBN(esPIKO_Pool_izumi_result[0]);
  }, [esPIKO_Pool_izumi_result]);

  const esPIKO_InviteToEarn = useMemo(() => {
    if (!esPIKO_InviteToEarn_result) return;
    return toFromBN(esPIKO_InviteToEarn_result[0]);
  }, [esPIKO_InviteToEarn_result]);

  const esPIKO_TradeRebate = useMemo(() => {
    if (!esPIKO_TradeRebate_result) return;
    return toFromBN(esPIKO_TradeRebate_result[0]);
  }, [esPIKO_TradeRebate_result]);

  const PIKOCirculatingSupply = useMemo(() => {
    if (
      !PIKO_totalSupply ||
      !PIKO_balance_TokenVesting ||
      !PIKO_balance_iPIKOVesting ||
      !PIKO_balance_Deployer ||
      !PIKO_balance_DAO
    )
      return;
    const data = PIKO_totalSupply.minus(PIKO_balance_TokenVesting)
      .minus(PIKO_balance_iPIKOVesting)
      .minus(PIKO_balance_Deployer)
      .minus(PIKO_balance_DAO);

    return data;
  }, [
    PIKO_balance_DAO,
    PIKO_balance_Deployer,
    PIKO_balance_TokenVesting,
    PIKO_balance_iPIKOVesting,
    PIKO_totalSupply,
  ]);

  const esPIKOCirculatingSupply = useMemo(() => {
    if (
      !esPIKO_totalSupply ||
      !esPIKO_RewardPool ||
      !esPIKO_Deployer ||
      !esPIKO_dis_token ||
      !esPIKO_balance_DAO ||
      !esPIKO_Pool_izumi ||
      !esPIKO_InviteToEarn ||
      !esPIKO_TradeRebate
    )
      return;
    const data = esPIKO_totalSupply
      .minus(esPIKO_RewardPool)
      .minus(esPIKO_Deployer)
      .minus(esPIKO_dis_token)
      .minus(esPIKO_balance_DAO)
      .minus(esPIKO_Pool_izumi)
      .minus(esPIKO_InviteToEarn)
      .minus(esPIKO_TradeRebate);
    return data;
  }, [
    esPIKO_totalSupply,
    esPIKO_RewardPool,
    esPIKO_Deployer,
    esPIKO_dis_token,
    esPIKO_balance_DAO,
    esPIKO_Pool_izumi,
    esPIKO_InviteToEarn,
    esPIKO_TradeRebate,
  ]);

  // const circulatingSupply = useMemo(() => {
  //   if (
  //     !totalSupply_vePIKO ||
  //     !balance_PIKO_TokenVesting_PIKO ||
  //     !balance_iPIKOVesting_str ||
  //     !balance_Deployer_str ||
  //     !balance_PIKO_dis_token ||
  //     !RewardPool_PIKO_str_str
  //   )
  //     return;
  //   const data = totalSupply_vePIKO
  //     .minus(balance_PIKO_TokenVesting_PIKO)
  //     .minus(balance_iPIKOVesting_str)
  //     .minus(balance_Deployer_str)
  //     .minus(balance_PIKO_dis_token)
  //     .minus(RewardPool_PIKO_str_str);
  //   return data;
  // }, [
  //   RewardPool_PIKO_str_str,
  //   balance_Deployer_str,
  //   balance_PIKO_TokenVesting_PIKO,
  //   balance_PIKO_dis_token,
  //   balance_iPIKOVesting_str,
  //   totalSupply_vePIKO,
  // ]);

  const circLocked_PIKO = useMemo(() => {
    if (!totalStaked_PIKO || !PIKOCirculatingSupply) return;
    return totalStaked_PIKO.div(PIKOCirculatingSupply);
  }, [PIKOCirculatingSupply, totalStaked_PIKO]);

  const circLocked_esPIKO = useMemo(() => {
    if (!totalStaked_esPIKO || !esPIKOCirculatingSupply) return;
    return totalStaked_esPIKO.div(esPIKOCirculatingSupply);
  }, [esPIKOCirculatingSupply, totalStaked_esPIKO]);

  const avgLockTime_PIKO = useMemo(() => {
    if (!totalSupply_vePIKO || !totalStaked_PIKO) return;
    return totalSupply_vePIKO.div(totalStaked_PIKO).minus(1).div(3).times(4);
  }, [totalStaked_PIKO, totalSupply_vePIKO]);

  const avgLockTime_esPIKO = useMemo(() => {
    if (!totalSupply_veesPIKO || !totalStaked_esPIKO) return;
    return totalSupply_veesPIKO
      .div(totalStaked_esPIKO)
      .minus(1)
      .div(3)
      .times(4);
  }, [totalSupply_veesPIKO, totalStaked_esPIKO]);

  return useMemo(() => {
    return {
      totalSupply_vePIKO,
      totalSupply_veesPIKO,
      totalStaked_PIKO,
      totalStaked_esPIKO,
      circulatingSupply: PIKOCirculatingSupply,
      circLocked_PIKO,
      circLocked_esPIKO,
      avgLockTime_PIKO,
      avgLockTime_esPIKO,
    };
  }, [
    PIKOCirculatingSupply,
    avgLockTime_PIKO,
    avgLockTime_esPIKO,
    circLocked_PIKO,
    circLocked_esPIKO,
    totalStaked_PIKO,
    totalStaked_esPIKO,
    totalSupply_vePIKO,
    totalSupply_veesPIKO,
  ]);
};
