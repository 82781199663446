import { useState } from "react";
import LeftTab from "src/components/LeftTab";
import { ReactComponent as ProfileIcon } from "src/assets/images/menu/Profile.svg";
import { ReactComponent as AssetIcon } from "src/assets/images/menu/Assets.svg";
import { ReactComponent as HistoryIcon } from "src/assets/images/menu/History.svg";
import Portfolio from "./Portfolio";
import Asset from "./Asset";
import History from "./History";
import ContainerBox from "src/components/ContainerBox";
import { getCookie, setCookie } from "src/utils/cookie";
import { Box, useMediaQuery } from "@mui/material";
import { queryURLParams } from "src/utils";

export default function PID() {
  const url = window.location.href;
  const urlParams: any = queryURLParams(url);
  const isVerySmallScreen = useMediaQuery("(max-width: 680px)");

  const refCode = getCookie("refCode");
  setCookie(
    "refCode",
    urlParams?.ref ? urlParams?.ref : refCode ? refCode : ""
  );
  const [curr, setCurr] = useState<number>(0);
  const tabList = [
    {
      icon: <ProfileIcon width={isVerySmallScreen ? "24px" : "32px"} />,
      title: "Profile",
    },
    {
      icon: <AssetIcon width={isVerySmallScreen ? "24px" : "32px"} />,
      title: "Asset",
    },
    {
      icon: <HistoryIcon width={isVerySmallScreen ? "24px" : "32px"} />,
      title: "History",
    },
  ];

  return (
    <Box className="pid-view" width={"100%"} height={"100vh"}>
      <ContainerBox
        leftContainer={
          <LeftTab curr={curr} setCurr={setCurr} tabList={tabList} />
        }
        rightContainer={
          curr == 0 ? <Portfolio /> : curr == 1 ? <Asset /> : <History />
        }
        rpt="24px"
        showBorder={true}
      />
    </Box>
  );
}
