import { useMemo } from "react";
import { PLACEHOLDER_ACCOUNT } from "src/helpers/Helpers";
import { useWeb3Context } from "src/hooks";
import {
  useTradeRebateContract,
  useTradeStorageContract,
} from "src/hooks/useContract";
import { useSingleCallResult } from "src/lib/hooks/multicall";
import { toFromBN } from "src/utils/bn";

export function useUserTrdeclaimable(currRoundId?: string | number) {
  const { address: account } = useWeb3Context();
  const tradeRebateContract = useTradeRebateContract();
  const roundId = currRoundId;

  const parms = useMemo(() => {
    return [account ? account : PLACEHOLDER_ACCOUNT, roundId];
  }, [account, roundId]);
  const { result } = useSingleCallResult(
    tradeRebateContract,
    "claimable",
    parms
  );

  return useMemo(() => {
    if (!result) return;
    return toFromBN(result[0]);
  }, [result]);
}

export function useUserTrderoundRewards(currRoundId?: string | number) {
  const { address: account } = useWeb3Context();
  const tradeRebateContract = useTradeRebateContract();
  const roundId = currRoundId;
  const { result } = useSingleCallResult(tradeRebateContract, "roundRewards", [
    roundId,
  ]);

  return useMemo(() => {
    if (!result) return;
    return toFromBN(result[0]);
  }, [result]);
}

export function useUserTradeVol(currRoundId?: string | number) {
  const { address: account } = useWeb3Context();
  const tradeStorageContract = useTradeStorageContract();
  const roundId = currRoundId;
  const parms = useMemo(() => {
    return [account ? account : PLACEHOLDER_ACCOUNT, roundId];
  }, [account, roundId]);

  const { result: tradeVol } = useSingleCallResult(
    tradeStorageContract,
    "tradeVol",
    parms
  );
  const { result: tradeSwap } = useSingleCallResult(
    tradeStorageContract,
    "swapVol",
    parms
  );

  return useMemo(() => {
    if (!tradeVol || !tradeSwap) return;
    return toFromBN(tradeVol[0], 30).plus(toFromBN(tradeSwap[0], 30));
  }, [tradeSwap, tradeVol]);
}

export function useTotalTradeVol(currRoundId?: string | number) {
  const tradeStorageContract = useTradeStorageContract();

  const { result: tradeVol } = useSingleCallResult(
    tradeStorageContract,
    "totalTradeVol",
    [currRoundId]
  );
  const { result: tradeSwap } = useSingleCallResult(
    tradeStorageContract,
    "totalSwapVol",
    [currRoundId]
  );

  return useMemo(() => {
    if (!tradeVol || !tradeSwap) return;

    return toFromBN(tradeVol[0], 30).plus(toFromBN(tradeSwap[0], 30));
  }, [tradeSwap, tradeVol]);
}
