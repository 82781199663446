import { Box, useMediaQuery } from "@mui/material";
import styled from "styled-components";
import { ThemedText } from "src/theme/text";
import { Trans } from "@lingui/macro";
import Dots from "src/components/Dots";
import { useBasicInfo } from "src/hooks/useQueryPID";
import { formatAmount2 } from "src/helpers/Helpers";
import { useMemo, useState } from "react";
import BoostDialog from "../BoostDialog";
import PointsTooltip from "./PointsTooltip";
import VePIKOProgress from "./VePIKOProgress";
import { useQueryEarnApr } from "src/hooks/useQueryEarn";

const AccountBox = styled.div`
  .accountInfo {
    margin-top: 60px;
    grid-gap: 8px;
    grid-template-columns: repeat(4, 1fr);
  }
  @media only screen and (max-width: 1080px) {
    .accountInfo {
      margin-top: 20px;
      grid-template-columns: repeat(2, 1fr);

      .infoItem {
        &:not(:first-child) {
          margin-top: 24px;
        }
      }
    }
  }
  @media only screen and (max-width: 680px) {
    .accountInfo {
      grid-template-columns: repeat(1, 1fr);
    }
  }
`;

export default function AccountInfo() {
  const isSmallScreen = useMediaQuery("(max-width: 1080px)");
  const baseInfo = useBasicInfo();
  const queryTotal = useQueryEarnApr();
  const [showBoost, setShowBoost] = useState<boolean>(false);
  const handleClose = () => {
    setShowBoost(false);
  };
  const veTokenBalance = useMemo(() => {
    return queryTotal?.vePIKOBalance?.times(queryTotal?.veesPIKOBalance);
  }, [queryTotal]);
  return (
    <AccountBox className="account-view">
      <Box display="grid" className="accountInfo">
        <div className="infoItem">
          <Box display="flex" alignItems="center" className="">
            <Dots bgColor="#14D1B2" />
            <ThemedText.Text4Bold ml="8px">
              <Trans>Trading Volumes</Trans>
            </ThemedText.Text4Bold>
          </Box>
          <ThemedText.Text1Bold fontSize={18} mt="12px">
            ${formatAmount2(baseInfo?.userSizeSum, 2, true)}
          </ThemedText.Text1Bold>
        </div>
        <div className="infoItem">
          <Box display="flex" alignItems="center" className="">
            <Dots bgColor="#14D1B2" />
            <ThemedText.Text4Bold ml="8px">
              <Trans>Points</Trans>
            </ThemedText.Text4Bold>
          </Box>
          <Box display="flex" alignItems="center" mt="12px" className="">
            <ThemedText.Text1Bold fontSize={18}>
              {formatAmount2(baseInfo?.score, 0, true)}
            </ThemedText.Text1Bold>
            <Box display="inline-flex" ml="10px" className="pointer">
              <PointsTooltip />
            </Box>
            {/* <Box ml="32px">
              <BoostBtn
                onClick={() => {
                  setShowBoost(true);
                }}
              >
                <BoostIcon />
                <Box ml="8px">BOOST</Box>
              </BoostBtn>
            </Box> */}
          </Box>
        </div>
        <div className="infoItem">
          <Box display="flex" alignItems="center" className="">
            <Dots bgColor="#14D1B2" />
            <ThemedText.Text4Bold ml="8px">
              <Trans>veToken</Trans>
            </ThemedText.Text4Bold>
          </Box>
          <Box mt="12px" className="">
            <ThemedText.Text9Bold fontSize={18} mt="12px">
              {formatAmount2(veTokenBalance, 4, true)}
            </ThemedText.Text9Bold>
            <Box display="flex" flexDirection="column" mt="12px" className="">
              <VePIKOProgress veTokenBalance={veTokenBalance} />
            </Box>
          </Box>
        </div>
      </Box>
      <BoostDialog open={showBoost} handleClose={handleClose} />
    </AccountBox>
  );
}
