import { AddressEnvMap, SupportedChainId } from "./chains.base";
export const SUPPORTED_CHAIN_IDS_MAP: AddressEnvMap = {
  // [SupportedChainId.ZKMAINNET]: SupportedChainId.ZKMAINNET,

  [SupportedChainId.ARBITRUM]: SupportedChainId.ARBITRUM,
  [SupportedChainId.ZKMAINNET]: SupportedChainId.ZKMAINNET,
  [SupportedChainId.MANTLE]: SupportedChainId.MANTLE,
};

export const SUPPORTED_CHAIN_IDS: SupportedChainId[] = [
  // SupportedChainId.ZKMAINNET,

  SupportedChainId.ARBITRUM,
  SupportedChainId.ZKMAINNET,
  SupportedChainId.MANTLE,
];
