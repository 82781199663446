import { ethers, logger } from "ethers";
import { gql } from "@apollo/client";
import { useState, useEffect, useMemo, useCallback, useRef } from "react";
import { Token as UniToken } from "@uniswap/sdk-core";
import { Pool } from "@uniswap/v3-sdk";
import useSWR from "swr";
import { serializeError } from "eth-rpc-errors";
import { useSelector } from "react-redux";
import OrderBook from "../abis/OrderBook.json";
import PositionManager from "../abis/PositionManager.json";
import Vault from "../abis/Vault.json";
import Router from "../abis/Router.json";
import UniPool from "../abis/UniPool.json";
import UniswapV2 from "../abis/UniswapV2.json";
import Token from "../abis/Token.json";
import VaultReader from "../abis/VaultReader.json";
import PositionRouter from "../abis/PositionRouter.json";
import { useOrderBookContract, useVaultReaderContract } from 'src/hooks/useContract'
import { getContractAddress } from "../Addresses";
import { getConstant } from "../configs/getConstant";
import { useAppSelector } from "src/state/hooks";
import {
  UI_VERSION,
  // DEFAULT_GAS_LIMIT,
  bigNumberify,
  getExplorerUrl,
  getServerBaseUrl,
  getServerUrl,
  setGasPrice,
  getGasLimit,
  replaceNativeTokenAddress,
  getProvider,
  getOrderKey,
  fetcher,
  parseValue,
  expandDecimals,
  getInfoTokens,
  helperToast,
  getUsd,
  USD_DECIMALS,
  HIGH_EXECUTION_FEES_MAP,
  SWAP,
  INCREASE,
  DECREASE, execInc, DEFAULT_CHAIN_ID,
} from "../helpers/Helpers";
import { getTokens, getTokenBySymbol, getWhitelistedTokens, getWhitelistedTokens2 } from "../configs/Tokens";

import { getPikoGraphClient, getTradesGraphClient } from "./common";
import { groupBy } from "lodash";
import { toastError, toastProcessing, toastSuccess, toastTransaction, toastTransaction2 } from "../helpers/toastHelpers";
import { getPositionManagerAddress } from "src/helpers/plpAddress";
import { useWeb3Context } from "src/hooks";
export * from "./prices";
import { useVaultTokenInfoV4 } from 'src/hooks/useTradeInfo'
import { compareAddress } from "src/utils/address";
import { useSingleCallResult } from "src/lib/hooks/multicall";

const { AddressZero } = ethers.constants;

function getPlpGraphClient(chainId) {
  return getPikoGraphClient(chainId);
}
function getPlpTradeGraphClient(chainId) {
  return getTradesGraphClient(chainId);
}

export function useOrderBookIndexes(chainId, account) {
  const query = gql(`{
    accounts(where:{address_in:[${account}]}) {
      id
      address
      lastOrderIndex
      deOrders(where:{excuted_in:[false]}){
        id
        orderIndex
        excuted
        
      }
      orders (where:{excuted_in:[false]}){
        id
        orderIndex
        excuted
      }
    }
  }`);

  const [res, setRes] = useState([]);
  // console.log("useOrderBookIndexes: " ,res, JSON.stringify(res));
  useEffect(() => {
    getPlpGraphClient(chainId).query({ query }).then(setRes).catch(console.warn);
  }, [setRes, query, chainId]);

  return res ? res : null;
}

const getIndexPrices = async (indexPricesUrl) => {

  const indexPrices = fetch(indexPricesUrl)
    .then(async (res) => {
      const data = await res.json()
      return data
    })
    .catch(error => console.log(error))
  return indexPrices

}

export function useInfoTokens(plpName, library, chainId, active, tokenBalances, fundingRateInfo, vaultPropsLength) {

  const nativeTokenAddress = getContractAddress(chainId, "NATIVE_TOKEN");
  const allToken = useAppSelector((state) => {
    return state.app.allToken || [];
  });
  const tokens = allToken;
  const whitelistedTokens = allToken

  const vaultTokenInfo = useVaultTokenInfoV4(plpName);

  const indexPricesUrl = getServerUrl(chainId, "/prices");
  const { data: indexPrices } = useSWR([indexPricesUrl], {
    fetcher: (...args) => fetch(...args).then(res => res.json()),
    refreshInterval: 100,
    refreshWhenHidden: true,
  });

  return {
    infoTokens: getInfoTokens(
      tokens,
      tokenBalances,
      whitelistedTokens,
      vaultTokenInfo,
      fundingRateInfo,
      vaultPropsLength,
      indexPrices,
      nativeTokenAddress,
    ),
  };
}

export function useUserStat(chainId) {
  const query = gql(`{
    userStat(id: "total") {
      id
      uniqueCount
    }
  }`);

  const [res, setRes] = useState();

  useEffect(() => {
    getPlpGraphClient(chainId).query({ query }).then(setRes).catch(console.warn);
  }, [setRes, query, chainId]);

  return res ? res.data.userStat : null;
}

export function useLiquidationsData(chainId, account) {
  const [data, setData] = useState(null);
  useEffect(() => {
    if (account) {
      const query = gql(`{
         liquidatedPositions(
           where: {account: "${account.toLowerCase()}"}
           first: 100
           orderBy: timestamp
           orderDirection: desc
         ) {
           key
           timestamp
           borrowFee
           loss
           collateral
           size
           markPrice
           type
         }
      }`);
      const graphClient = getPlpTradeGraphClient(chainId);
      graphClient?.query({ query })
        .then(res => {
          const _data = res.data.liquidatedPositions.map(item => {
            return {
              ...item,
              size: bigNumberify(item.size),
              collateral: bigNumberify(item.collateral),
              markPrice: bigNumberify(item.markPrice),
            };
          });
          setData(_data);
        })
        .catch(console.warn);
    }
  }, [setData, chainId, account]);

  return data;
}

export function usePositionsForOrders(chainId, library, orders) {
  const key = orders ? orders.map(order => getOrderKey(order) + "____") : null;
  const { data: positions = {} } = useSWR(key, async () => {
    const provider = getProvider(library, chainId);
    const vaultAddress = getContractAddress(chainId, "Vault");
    const contract = new ethers.Contract(vaultAddress, Vault.abi, provider);
    const data = await Promise.all(
      orders.map(async order => {
        try {
          const position = await contract.getPosition(
            order.account,
            order.collateralToken,
            order.indexToken,
            order.isLong,
          );
          if (position[0].eq(0)) {
            return [null, order];
          }
          return [position, order];
        } catch (ex) {
          console.error(ex);
        }
      }),
    );
    return data.reduce((memo, [position, order]) => {
      memo[getOrderKey(order)] = position;
      return memo;
    }, {});
  });

  return positions;
}

function invariant(condition, errorMsg) {
  if (!condition) {
    throw new Error(errorMsg);
  }
}

// trade history
export function useTradeHistory(chainId, account) {
  const [data, setData] = useState(null);
  useEffect(() => {
    if (account) {
      const query = gql(`{
       trades(
         where: {account: "${account.toLowerCase()}"}
        #  where: {blockNumber: 23687952}
         first: 100
         orderBy: timestamp
         orderDirection: desc
       ) {
        id
    action
    blockNumber
    txhash
    timestamp
    account
    
    indexToken
    isLong
    sizeDelta
    acceptablePrice
    markPrice
    size
    
    collateralDelta
    amountIn
    type
    createdAtBlock
    updatedAt
    orderIndex
    triggerPrice
    triggerAboveThreshold
    executionFee
    collateralToken
    purchaseToken
    purchaseTokenAmount
    
    tokenIn
    tokenOut
    amountIn
    amountOut
       }
    }`);
      const graphClient = getPlpTradeGraphClient(chainId);
      if (!graphClient) {
        return;
      }

      graphClient
        .query({ query })
        .then(res => {
          setData(res.data.trades);
        })
        .catch(err => {
          console.log("graphql trades err: ", err);
        });
    }
  }, [setData, chainId, account]);

  return data;
}

export function useTrades(chainId, account, forSingleAccount) {
  const url =
    account && account.length > 0
      ? `${getServerBaseUrl(chainId)}/actions?account=${account}`
      : !forSingleAccount && `${getServerBaseUrl(chainId)}/actions`;

  const { data: trades, mutate: updateTrades } = useSWR(url && url, {
    dedupingInterval: 10000,
    fetcher: (...args) => fetch(...args).then(res => res.json()),
  });

  if (trades) {
    trades.sort((item0, item1) => {
      const data0 = item0.data;
      const data1 = item1.data;
      const time0 = parseInt(data0.timestamp);
      const time1 = parseInt(data1.timestamp);
      if (time1 > time0) {
        return 1;
      }
      if (time1 < time0) {
        return -1;
      }

      const block0 = parseInt(data0.blockNumber);
      const block1 = parseInt(data1.blockNumber);

      if (isNaN(block0) && isNaN(block1)) {
        return 0;
      }

      if (isNaN(block0)) {
        return 1;
      }

      if (isNaN(block1)) {
        return -1;
      }

      if (block1 > block0) {
        return 1;
      }

      if (block1 < block0) {
        return -1;
      }

      return 0;
    });
  }

  return { trades, updateTrades };
}

export function useMinExecutionFee(plpName, library, active, chainId, infoTokens) {
  const PLPMAP = useSelector(state => {
    return state.app.PLPMAP || {};
  })
  const { positionRouter_address, orderBook_address } = PLPMAP[plpName] || {}
  // const nativeTokenAddress = getContractAddress(chainId, "NATIVE_TOKEN");

  const orderContract = useOrderBookContract(orderBook_address)

  // const { result: minExecutionFee } = useSingleCallResult(orderContract, 'minExecutionFee')

  // const { data: minExecutionFee } = useSWR([active, chainId, positionRouter_address, "minExecutionFee"], {
  //   fetcher: fetcher(library, PositionRouter),
  // });

  // const { data: gasPrice } = useSWR(["gasPrice", chainId], {
  //   fetcher: () => {
  //     return new Promise(async (resolve, reject) => {
  //       const provider = getProvider(library, chainId);
  //       if (!provider) {
  //         resolve(undefined);
  //         return;
  //       }

  //       try {
  //         const gasPrice = await provider.getGasPrice();
  //         resolve(gasPrice);
  //       } catch (e) {
  //         console.error(e);
  //       }
  //     });
  //   },
  // });

  // let multiplier = 65000;

  // let finalExecutionFee = minExecutionFee;

  // if (gasPrice && minExecutionFee) {
  //   const estimatedExecutionFee = gasPrice.mul(multiplier);
  //   if (estimatedExecutionFee.gt(minExecutionFee)) {
  //     finalExecutionFee = estimatedExecutionFee;
  //   }
  // }

  // const finalExecutionFeeUSD = getUsd(finalExecutionFee, nativeTokenAddress, false, infoTokens);
  // const isFeeHigh = finalExecutionFeeUSD?.gt(expandDecimals(HIGH_EXECUTION_FEES_MAP[chainId], USD_DECIMALS));
  // const errorMessage =
  //   isFeeHigh &&
  //   `The network cost to send transactions is high at the moment, please check the "Execution Fee" value before proceeding.`;

  return {
    minExecutionFee: bigNumberify(0),
    minExecutionFeeUSD: bigNumberify(0),
    minExecutionFeeErrorMessage: '',
  };
}

export function useHasOutdatedUi() {
  const url = getServerUrl(DEFAULT_CHAIN_ID, "/ui_version");
  const { data, mutate } = useSWR([url], {
    fetcher: (...args) => fetch(...args).then(res => res.text()),
  });

  let hasOutdatedUi = false;

  if (data && parseFloat(data) > parseFloat(UI_VERSION)) {
    hasOutdatedUi = true;
  }

  return { data: hasOutdatedUi, mutate };
}


export async function approvePlugin(
  routerAddress,
  pluginAddress,
  { plpName, library, pendingTxns, setPendingTxns, sentMsg, failMsg, chainId },
) {
  const contract = new ethers.Contract(routerAddress, Router.abi, library);
  return callContract(chainId, contract, "approvePlugin", [pluginAddress], {
    sentMsg,
    failMsg,
    pendingTxns,
    setPendingTxns,
  });
}

export async function createSwapOrder(
  plpName,
  chainId,
  library,
  path,
  amountIn,
  minOut,
  triggerRatio,
  nativeTokenAddress,
  orderBookAddress,
  vaultAddress,
  fee,
  minFees,
  prcieData,
  opts = {},
) {
  const executionFee = minFees;
  const triggerAboveThreshold = false;
  let shouldWrap = false;
  let shouldUnwrap = false;
  opts.value = executionFee

  if (path[0] === AddressZero) {
    shouldWrap = true;
    opts.value = opts.value.add(amountIn);
  }
  if (path[path.length - 1] === AddressZero) {
    shouldUnwrap = true;
  }
  path = replaceNativeTokenAddress(path, nativeTokenAddress);

  const params = [vaultAddress, path, amountIn, minOut, triggerRatio, triggerAboveThreshold, executionFee, shouldWrap, shouldUnwrap, prcieData];

  const contract = new ethers.Contract(orderBookAddress, OrderBook.abi, library);
  return callContract(chainId, contract, "createSwapOrder", params, opts);
}

export async function createIncreaseOrder(
  plpName,
  chainId,
  library,
  nativeTokenAddress,
  path,
  amountIn,
  indexTokenAddress,
  minOut,
  sizeDelta,
  collateralTokenAddress,
  isLong,
  triggerPrice,
  orderBookAddress,
  vault_address,
  minFees,
  prcieData,
  opts = {},
) {
  invariant(!isLong || compareAddress(
    indexTokenAddress,
    collateralTokenAddress
  ), "invalid token addresses");
  invariant(indexTokenAddress !== AddressZero, "indexToken is 0");
  invariant(collateralTokenAddress !== AddressZero, "collateralToken is 0");

  const fromETH = path[0] === AddressZero;

  path = replaceNativeTokenAddress(path, nativeTokenAddress);
  const shouldWrap = fromETH;
  const triggerAboveThreshold = !isLong;
  const executionFee = minFees;

  const params = [
    [vault_address, indexTokenAddress, collateralTokenAddress, amountIn, minOut, sizeDelta, triggerPrice, executionFee, isLong, triggerAboveThreshold, shouldWrap],
    path,
    prcieData
  ];
  if (!opts.value) {
    opts.value = fromETH ? amountIn.add(executionFee) : executionFee;
  }
  const contract = new ethers.Contract(orderBookAddress, OrderBook.abi, library);
  return callContract(chainId, contract, "createIncreaseOrder", params, opts);
}

export async function createDecreaseOrder(
  plpName,
  chainId,
  library,
  indexTokenAddress,
  sizeDelta,
  collateralTokenAddress,
  collateralDelta,
  isLong,
  triggerPrice,
  triggerAboveThreshold,
  orderBookAddress,
  vault_address,
  minFees,
  prcieData,
  isGNS,
  opts = {},
) {
  // invariant(!isLong, "invalid token addresses");
  invariant(indexTokenAddress !== AddressZero, "indexToken is 0");
  invariant(collateralTokenAddress !== AddressZero, "collateralToken is 0");
  const executionFee = minFees;
  const params = [
    vault_address,
    indexTokenAddress,
    sizeDelta,
    collateralTokenAddress,
    collateralDelta,
    isLong,
    triggerPrice,
    triggerAboveThreshold,
    prcieData
  ];
  opts.value = executionFee;
  const contract = new ethers.Contract(orderBookAddress, OrderBook.abi, library);
  return callContract(chainId, contract, "createDecreaseOrder", params, opts);
}

export async function cancelSwapOrder(plpName, chainId, library, index, opts, orderBookAddress) {
  const params = [index];
  const method = "cancelSwapOrderByKey";
  const contract = new ethers.Contract(orderBookAddress, OrderBook.abi, library);


  return callContract(chainId, contract, method, params, opts);
}

export async function cancelDecreaseOrder(plpName, chainId, library, index, opts, orderBookAddress) {
  const params = [index];
  const method = "cancelDecreaseOrderByKey";
  const contract = new ethers.Contract(orderBookAddress, OrderBook.abi, library);

  return callContract(chainId, contract, method, params, opts);
}

export async function cancelIncreaseOrder(plpName, chainId, library, index, opts, orderBookAddress) {
  const params = [index];
  const method = "cancelIncreaseOrderByKey";
  const contract = new ethers.Contract(orderBookAddress, OrderBook.abi, library);

  return callContract(chainId, contract, method, params, opts);
}

export function handleCancelOrder(plpName, chainId, library, order, opts, orderBookAddress) {
  let func;
  let orderIndex = order.key;
  if (order.type === SWAP) {
    func = cancelSwapOrder;
  } else if (order.type === INCREASE) {
    func = cancelIncreaseOrder;
  } else if (order.type === DECREASE) {
    func = cancelDecreaseOrder;
  }


  return func(plpName, chainId, library, orderIndex, {
    successMsg: "Order cancelled.",
    failMsg: "Cancel failed.",
    sentMsg: "Cancel submitted.",
    pendingTxns: opts.pendingTxns,
    setPendingTxns: opts.setPendingTxns,
  }, orderBookAddress);
}

export async function cancelMultipleOrders(plpName, chainId, library, allIndexes = [], orderBookAddress, opts) {
  const ordersWithTypes = groupBy(allIndexes, v => v.split("-")[0]);
  function getIndexes(key) {
    if (!ordersWithTypes[key]) return;
    return ordersWithTypes[key].map(d => d.split("-")[1]);
  }
  // params order => swap, increase, decrease
  const params = ["Swap", "Increase", "Decrease"].map(key => getIndexes(key) || []);
  const method = "cancelMultiple";
  const contract = new ethers.Contract(orderBookAddress, OrderBook.abi, library);
  return callContract(chainId, contract, method, params, opts);
}

export async function updateDecreaseOrder(
  plpName,
  chainId,
  library,
  key,
  collateralDelta,
  sizeDelta,
  triggerPrice,
  triggerAboveThreshold,
  opts,
  orderBookAddress
) {
  const params = [key, collateralDelta, sizeDelta, triggerPrice, triggerAboveThreshold];
  const method = "updateDecreaseOrderByKey";
  const contract = new ethers.Contract(orderBookAddress, OrderBook.abi, library);

  return callContract(chainId, contract, method, params, opts);
}

export async function updateIncreaseOrder(
  plpName,
  chainId,
  library,
  key,
  sizeDelta,
  triggerPrice,
  triggerAboveThreshold,
  opts,
  orderBookAddress
) {
  const params = [key, sizeDelta, triggerPrice, triggerAboveThreshold];
  const method = "updateIncreaseOrderByKey";
  const contract = new ethers.Contract(orderBookAddress, OrderBook.abi, library);

  return callContract(chainId, contract, method, params, opts);
}

export async function updateSwapOrder(plpName, chainId, library, key, index, minOut, triggerRatio, triggerAboveThreshold, opts, orderBookAddress) {
  const params = [key, minOut, triggerRatio, triggerAboveThreshold];
  const method = "updateSwapOrderByKey";

  const contract = new ethers.Contract(orderBookAddress, OrderBook.abi, library);

  return callContract(chainId, contract, method, params, opts);
}

export async function _executeOrder(plpName, chainId, library, method, account, index, feeReceiver, opts) {
  const params = [account, index, feeReceiver];
  const positionManagerAddress = getPositionManagerAddress(chainId, plpName);
  const contract = new ethers.Contract(positionManagerAddress, PositionManager.abi, library);
  return callContract(chainId, contract, method, params, opts);
}

export function executeSwapOrder(plpName, chainId, library, account, index, feeReceiver, opts) {
  return _executeOrder(plpName, chainId, library, "executeSwapOrder", account, index, feeReceiver, opts);
}

export function executeIncreaseOrder(plpName, chainId, library, account, index, feeReceiver, opts) {
  return _executeOrder(plpName, chainId, library, "executeIncreaseOrder", account, index, feeReceiver, opts);
}

export function executeDecreaseOrder(plpName, chainId, library, account, index, feeReceiver, opts) {
  return _executeOrder(plpName, chainId, library, "executeDecreaseOrder", account, index, feeReceiver, opts);
}

const NOT_ENOUGH_FUNDS = "NOT_ENOUGH_FUNDS";
const USER_DENIED = "USER_DENIED";
const SLIPPAGE = "SLIPPAGE";
const TX_ERROR_PATTERNS = {
  [NOT_ENOUGH_FUNDS]: ["not enough funds for gas", "failed to execute call with revert code InsufficientGasFunds"],
  [USER_DENIED]: ["User denied transaction signature"],
  [SLIPPAGE]: ["Router: mark price lower than limit", "Router: mark price higher than limit"],
};
export function extractError(ex) {
  if (!ex) {
    return [];
  }
  const message = ex.data?.message || ex.message;
  if (!message) {
    return [];
  }
  for (const [type, patterns] of Object.entries(TX_ERROR_PATTERNS)) {
    for (const pattern of patterns) {
      if (message.includes(pattern)) {
        return [message, type];
      }
    }
  }
  return [message];
}

function ToastifyDebug(props) {
  const [open, setOpen] = useState(false);
  return (
    <div className="Toastify-debug">
      {!open && (
        <span className="Toastify-debug-button" onClick={() => setOpen(true)}>
          Show error
        </span>
      )}
      {open && props.children}
    </div>
  );
}

export async function callContract(chainId, contract, method, params, opts) {
  let successMsg = "";
  let res;
  let hash;
  try {
    if (!Array.isArray(params) && typeof params === "object" && opts === undefined) {
      opts = params;
      params = [];
    }
    if (!opts) {
      opts = {};
    }

    const txnOpts = {};

    if (opts.value) {
      txnOpts.value = opts.value;
    }

    txnOpts.gasLimit = opts.gasLimit ? opts.gasLimit : await getGasLimit(contract, method, params, opts.value);

    await setGasPrice(txnOpts, contract.provider, chainId);
    res = await contract[method](...params, txnOpts);
    // if (method === "createIncreasePosition" || method === "createDecreasePosition") {
    //   execInc(method, params, chainId, contract.address);
    // }
    const txUrl = getExplorerUrl(chainId) + "tx/" + res.hash;
    const sentMsg = opts.sentMsg || "Transaction sent.";
    hash = res.hash
    // toastTransaction("Transaction Submitted", txUrl, sentMsg)
    if (opts.setPendingTxns) {
      const pendingTxn = {
        hash,
        message: opts.successMsg || "Transaction Submitted",
      };
      opts.setPendingTxns(pendingTxns => [...pendingTxns, pendingTxn]);
      successMsg = opts.successMsg
      toastProcessing(successMsg)
    }
    const data = await res.wait();
    console.log('[endTime]:', Math.round(new Date() / 1000));

    return data;
  } catch (e) {
    console.log('[e]:', e);
    const rpcError = serializeError(e);
    let message, type;
    message = rpcError.data && rpcError.data.originalError ? rpcError.data.originalError.reason : rpcError.message;
    type = rpcError.data && rpcError.data.originalError ? rpcError.data.originalError.code : "";
    toastError(message);
    throw rpcError;
  } finally {
    if (res && successMsg) {
      toastSuccess(successMsg, hash)
    }
  }
}
