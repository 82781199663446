import React, { useState, useEffect } from "react";
import cx from "classnames";

import { formatAmount, expandDecimals, bigNumberify } from "../../helpers/Helpers";

import { getToken, getNativeToken } from "../../configs/Tokens";

import {
  ReactComponent as BiChevronDown
} from "src/assets/images/tedetxia.svg";
import Modal from "../Modal/Modal";
import { compareAddress } from 'src/utils/address'
import dropDownIcon from "../../img/DROP_DOWN.svg";
import BackIcon from "../../assets/images/back.svg";
import "./TokenSelector.css";
import "./TokenSelector2.scss";
import { Box } from "@material-ui/core";
import { useSelector } from "react-redux";
import { useWeb3Context } from "src/hooks";
import { useGetToken } from "src/hooks/useGetToken";
import { ReactComponent as SearchIcon } from 'src/assets/images/searchIcon.svg';
import { ReactComponent as SearchIconClose } from 'src/assets/images/searchClose.svg';
import Row, { RowBetween } from "../Row";
import Column from "../Column";

export default function TokenSelector(props) {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [searchKeyword, setSearchKeyword] = useState("");
  const tokenInfo = useGetToken(props.tokenAddress);

  const {
    plpName,
    tokens,
    mintingCap,
    infoTokens,
    showMintingCap,
    disabled,
    showTokenImgInDropdown = false,
    showSymbolImage = true,
    showNewCaret = false,
  } = props;

  let visibleTokens = tokens.filter((t) => !t.isTempHidden);

  const onSelectToken = (token) => {
    setIsModalVisible(false);
    props.onSelectToken(token);
  };
  useEffect(() => {
    if (isModalVisible) {
      setSearchKeyword("");
    }
  }, [isModalVisible]);

  if (!tokenInfo) {
    return null;
  }



  const onSearchKeywordChange = (e) => {
    setSearchKeyword(e.target.value);
  };

  const filteredTokens = visibleTokens.filter((item) => {
    return (
      item.name.toLowerCase().indexOf(searchKeyword.toLowerCase()) > -1 ||
      item.symbol.toLowerCase().indexOf(searchKeyword.toLowerCase()) > -1
    );
  });

  const _handleKeyDown = (e) => {
    if (e.key === "Enter" && filteredTokens.length > 0) {
      onSelectToken(filteredTokens[0]);
    }
  };

  return (
    <div className={cx("TokenSelector2 TokenSelector5-box", { disabled }, props.className)} style={{ maxWidth: '600px', zIndex: 366 }} >
      <Modal isVisible={isModalVisible} setIsVisible={setIsModalVisible} label={"USDX"}>
        <Column width={'100%'} height={'600px'}>
          <Box height={'112px'}>
            <Box display='flex' alignItems='center' p={'16px'} pb={'12px'} fontSize={'16px'} className=" color4 font600">
              <div className='font-24' > {props.label ?? 'Select Token'}</div>
            </Box>
            <RowBetween padding={'12px'} gap={'4px'}>
              <SearchIcon />
              <input
                style={{ "flex": "1", paddingLeft: '0px' }}
                type="text"
                placeholder="Search Token"
                className="plpswap"
                value={searchKeyword}
                onChange={(e) => onSearchKeywordChange(e)}
                onKeyDown={_handleKeyDown}
                autoFocus
              />
              {
                searchKeyword.length > 0 && <SearchIconClose className="pointer" onClick={() => { setSearchKeyword('') }} />

              }

            </RowBetween>

          </Box>

          <Column className='tokenList'>
            {filteredTokens.map((token) => {
              let info = infoTokens ? infoTokens[token.address.toLowerCase()] : {};
              let mintAmount;
              let balance = info?.balance;
              if (showMintingCap && mintingCap && info.usdxAmount) {
                mintAmount = mintingCap.sub(info.usdxAmount);
              }
              if (mintAmount && mintAmount.lt(0)) {
                mintAmount = bigNumberify(0);
              }
              let balanceUsd;
              if (balance && info.maxPrice) {
                balanceUsd = balance.mul(info.maxPrice).div(expandDecimals(1, token.decimals));
              }
              return (

                <Box display='flex' alignItems='center' justifyContent='space-between' className="tokenItem"
                  onClick={() => onSelectToken(token)} key={token.address}>
                  <Row alignItems='center' gap={'8px'}>
                    <img src={token.imageUrl} alt={token.name} className="coinIcon" height={34} style={{ borderRadius: '50%' }} />
                    <div >
                      <div className='font-16 font600 color28'>{token.name}</div>
                      <div className='font-14 color14'>{token.symbol}</div>
                    </div>
                  </Row>
                  <div className='text-right'>
                    <div className=''>
                      {balance && (
                        <div className="font-16 font600 color28">
                          {balance.gt(0) && formatAmount(balance, token.decimals, 4, true)}
                          {balance.eq(0) && "-"}
                        </div>
                      )}</div>
                    <div className='color14 font600'>
                      {mintAmount && <div>Mintable: {formatAmount(mintAmount, token.decimals, 2, true)} USDX</div>}
                      {showMintingCap && !mintAmount && <div>-</div>}
                      {!showMintingCap && balanceUsd && balanceUsd.gt(0) && (
                        <div>${formatAmount(balanceUsd, 30, 2, true)}</div>
                      )}
                    </div>
                  </div>
                </Box>

              );
            })}
          </Column>
        </Column>

      </Modal >
      <Box display='flex' alignItems="center" className="TokenSelector-box " onClick={() => setIsModalVisible(true)}>
        {showSymbolImage && (
          <img src={tokenInfo.imageUrl} width={24} height={24} alt={tokenInfo.symbol} className="TokenSelector-box-symbol" style={{ display: 'block' }} />
        )}
        <div className="ml-8 font-16 font700 color28">{tokenInfo.symbol}</div>
        {showNewCaret && <img src={dropDownIcon} alt="dropDownIcon" className="TokenSelector-box-caret" />}
        {!showNewCaret && <BiChevronDown className=" TokenSelector-caret5 " />}
      </Box>
    </div >
  );
}
