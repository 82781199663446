import { serializeError } from "eth-rpc-errors";
import { SerializedEthereumRpcError } from "eth-rpc-errors/dist/classes";
import { toastError } from "./toastHelpers";

export const handlerError = (error) => {
  const rpcError: SerializedEthereumRpcError = serializeError(error);
  // @ts-ignore
  if (rpcError.data || rpcError.data.originalError) {
    const errs = rpcError.data as any;
    toastError(errs.originalError.reason);
  } else {
    toastError(rpcError.message);
  }
};
