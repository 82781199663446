import { styled } from "@mui/material/styles";
import { Button } from "@mui/material";

export const BuyOrSellBtn = styled(Button)({
  height: "40px",
  lineHeight: "40px",
  maxHeight: "40px",
  width: "120px",
  color: "#000000",
  fontSize: 14,
  fontFamily: "Gilroy-Bold",
  textTransform: "uppercase",
  background: "#14D1B2",
  border: "none",
  borderRadius: "4px",
  "&:hover": {
    background: "#14D1B2",
    color: "#ffffff",
  },
});
