import { AddressEnvMap, SupportedChainId } from "./chains.base";
export const SUPPORTED_CHAIN_IDS_MAP: AddressEnvMap = {
  [SupportedChainId.ARB_TEST]: SupportedChainId.ARB_TEST,
  [SupportedChainId.ARBITRUM]: SupportedChainId.ARBITRUM,
  [SupportedChainId.BSCTEST]: SupportedChainId.BSCTEST,
  [SupportedChainId.BSCMAINNET]: SupportedChainId.BSCMAINNET,
  [SupportedChainId.ZKMAINNET]: SupportedChainId.ZKMAINNET,
  [SupportedChainId.ZKTEST]: SupportedChainId.ZKTEST,
  [SupportedChainId.MANTLE_TEST]: SupportedChainId.MANTLE_TEST,
  [SupportedChainId.MANTLE]: SupportedChainId.MANTLE,
};

export const SUPPORTED_CHAIN_IDS: SupportedChainId[] = [
  SupportedChainId.ARB_TEST,
  SupportedChainId.ARBITRUM,
  // SupportedChainId.BSCTEST,
  // SupportedChainId.BSCMAINNET,
  SupportedChainId.ZKTEST,
  SupportedChainId.ZKMAINNET,
  // SupportedChainId.MANTLE_TEST,
  SupportedChainId.MANTLE,
];
