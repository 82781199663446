import { Trans } from "@lingui/macro";
import { Box, useMediaQuery } from "@mui/material";
import CountUp from "react-countup";
import { ClaimBtn } from "src/components/Button/ClaimBtn";
import Dots from "src/components/Dots";
import { ThemedText } from "src/theme/text";
import styled from "styled-components/macro";
import {
  clearPendingTxn,
  fetchPendingTxns,
  isPendingTxn,
  txnButtonText,
} from "src/slices/PendingTxnsSlice";
import { useDispatch, useSelector } from "react-redux";
import PendingIcon from "src/assets/images/loading/pending.svg";
import { ReactComponent as ToIcon } from "src/assets/images/pid/to.svg";
import { handlerError } from "src/helpers/errorHelpers";
import { useInviteToEarnContract } from "src/hooks/useContract";
import { toastSuccess } from "src/helpers/toastHelpers";
import { useUpDataBlockNumber } from "src/lib/hooks/useUpDataBlockNumber";
import { useQuotaInformation } from "../hooks";
const ReferStepBox = styled(Box)`
  display: flex;
  align-items: center;
  margin-top: 24px;
  padding: 32px;
  background: ${({ theme }) => theme.bg1};
  box-shadow: 0px 78px 58px -43px ${({ theme }) => theme.bg9};
  border-radius: 10px;
  .stepContainer {
    margin-left: 38px;
    position: relative;
    .toIcon {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }
    .step {
      padding: 16px 32px 34px 16px;
      border: 1px solid ${({ theme }) => theme.bg4};
      box-shadow: 0px 0px 2px ${({ theme }) => theme.bg9};
      border-radius: 8px;
    }
  }
  @media only screen and (max-width: 1280px) {
    flex-direction: column;
    padding: 16px;
    .stepContainer {
      margin-left: 0;
      margin-top: 16px;
      .step {
      }
    }
  }
  @media only screen and (max-width: 680px) {
  }
`;

export default function ReferStep() {
  const isVerySmallScreen = useMediaQuery("(max-width: 680px)");
  const queryUser = useQuotaInformation();
  const inviteContract = useInviteToEarnContract();
  const dispatch = useDispatch();
  const updateBlockNumber = useUpDataBlockNumber();
  const pendingTransactions = useSelector((state: any) => {
    return state.pendingTransactions;
  });
  const handlerClaim = async () => {
    if (!inviteContract) return;
    let tx: any;
    try {
      tx = await inviteContract.claim();
      dispatch(
        fetchPendingTxns({
          txnHash: tx.hash,
          text: "claim",
          type: "claim_invite",
        })
      );
      const res = await tx.wait();
      updateBlockNumber(res);
      dispatch(clearPendingTxn(tx.hash));
      toastSuccess("claim Success!", tx.hash);
    } catch (e: unknown) {
      handlerError(e);
    }
  };
  return (
    <ReferStepBox className="referStep-view">
      <div className="">
        <ThemedText.Text1Bold>
          <Trans>Refer Friends.</Trans>
        </ThemedText.Text1Bold>
        <Box display="flex">
          <ThemedText.PrimaryText1Bold fontSize={24}>
            <Trans>Earn esPIKO Together.</Trans>
          </ThemedText.PrimaryText1Bold>
        </Box>
      </div>
      <Box
        display="flex"
        flexDirection={isVerySmallScreen ? "column" : "row"}
        className="stepContainer flex-1"
      >
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
          className="step flex-1"
        >
          {!isVerySmallScreen && <ToIcon className="toIcon" />}
          <div className="">
            <Box display="flex" className="">
              <ThemedText.PrimaryText1Bold fontSize={24}>
                <Trans>Step 1</Trans>
              </ThemedText.PrimaryText1Bold>
              <ThemedText.Text1Bold fontSize={24} ml="8px">
                <Trans>Get esPIKO</Trans>
              </ThemedText.Text1Bold>
            </Box>
            <ThemedText.Text4Bold mt="8px">
              <Trans>
                Invite friends to mint PID. Whenever they claim esPIKO via PLP
                stake/Trade to Earn, you will get esPIKO. rebate esPIKO = your
                rebate rates * invitee claim amount.
              </Trans>
            </ThemedText.Text4Bold>
          </div>
          <Box mt="16px" className="">
            <Box display="flex" alignItems="center" className="">
              <Dots bgColor="#14D1B2" />
              <ThemedText.Text4Bold ml="8px">
                <Trans>Pending Amounts</Trans>
              </ThemedText.Text4Bold>
            </Box>
            <ThemedText.Text2Bold fontSize={18} mt="12px">
              <CountUp
                preserveValue={true}
                end={Number(queryUser?.pendingAmount.toFixed())}
                separator=","
                decimals={4}
                style={{ fontFamily: "Gilroy-Bold" }}
              />{" "}
              esPIKO
            </ThemedText.Text2Bold>
          </Box>
        </Box>
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
          className="step flex-1"
          ml={isVerySmallScreen ? 0 : "16px"}
          mt={isVerySmallScreen ? "16px" : 0}
        >
          <div className="">
            <Box display="flex" className="">
              <ThemedText.PrimaryText1Bold fontSize={24}>
                <Trans>Step 2</Trans>
              </ThemedText.PrimaryText1Bold>
              <ThemedText.Text1Bold fontSize={24} ml="8px">
                <Trans>Unlock esPIKO</Trans>
              </ThemedText.Text1Bold>
            </Box>
            <ThemedText.Text4Bold mt="8px">
              <Trans>Each additional 200 points unlocks 1 rebate esPIKO.</Trans>
            </ThemedText.Text4Bold>
          </div>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <div className="">
              <Box display="flex" alignItems="center" className="">
                <Dots bgColor="#14D1B2" />
                <ThemedText.Text4Bold ml="8px">
                  <Trans>Claimable</Trans>
                </ThemedText.Text4Bold>
              </Box>
              <ThemedText.Text2Bold fontSize={18} mt="12px">
                <CountUp
                  preserveValue={true}
                  end={Number(queryUser?.withdrawAmount.toFixed())}
                  separator=","
                  decimals={4}
                  style={{ fontFamily: "Gilroy-Bold" }}
                />{" "}
                esPIKO
              </ThemedText.Text2Bold>
            </div>
            <Box width="90px">
              <ClaimBtn
                onClick={handlerClaim}
                disabled={isPendingTxn(pendingTransactions, "claim_invite")}
              >
                {isPendingTxn(pendingTransactions, "claim_invite") ? (
                  <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <div className="">Pending</div>
                    <img src={PendingIcon} height={20} className="ml-12" />
                  </Box>
                ) : (
                  txnButtonText(pendingTransactions, "claim_invite", "Claim")
                )}
              </ClaimBtn>
            </Box>
          </Box>
        </Box>
      </Box>
    </ReferStepBox>
  );
}
function dispatch(arg0: any) {
  throw new Error("Function not implemented.");
}
