import styled from "styled-components";
import Row, { RowBetween } from "../Row";
import { Box, useMediaQuery } from "@mui/material";
import { ReactComponent as Notex } from "src/assets/images/notex.svg";
import { useState } from "react";
import Column from "../Column";

const MyNote = styled(Box)`
  background: rgba(20, 209, 178, 1);
  color: rgba(10, 10, 10, 1);
  font-size: 16px;
  min-height: 30px;
  width: 100%;
  display: flex;
  align-items: center;
  padding: 0 32px;
  position: sticky;
  top: 0;
  .shows {
    font-size: 14px;
    text-decoration: underline;
    cursor: pointer;
  }
  @media screen and (max-width: 960px) {
    padding: 0 8px;
    font-size: 14px;
    .h5s {
      flex-direction: column;
      align-items: center;
    }
  }
`;

const Note = () => {
  const locgse = window.localStorage.getItem("MyNote");
  const [showNote, setNote] = useState(!locgse);
  const isVerySmallScreen = useMediaQuery("(max-width: 680px)");

  const Hinde = () => {
    window.localStorage.setItem("MyNote", "1");
    setNote(false);
  };

  return (
    <>
      {showNote &&
        (isVerySmallScreen ? (
          <MyNote>
            <RowBetween>
              <Column className="font-weight-b" height={"100%"}>
                <div className="font-weight-600">
                  {" "}
                  Note: The gas cost is dynamic and 50%~90% of estimated gas
                  will be refunded.
                </div>
                <div className="font-weight-b shows" onClick={Hinde}>
                  Don&apos;t show this message again
                </div>
              </Column>
              <Notex className="pointer" onClick={() => setNote(false)} />
            </RowBetween>
          </MyNote>
        ) : (
          <MyNote>
            <RowBetween className="h5s">
              <Row className="font-weight-600" height={"100%"}>
                Note: The gas cost is dynamic and 50%~90% of estimated gas will
                be refunded.
              </Row>
              <Row
                className=""
                height={"100%"}
                justifyContent={"flex-end"}
                gap={"16px"}
              >
                <div className="font-weight-b shows" onClick={Hinde}>
                  Don&apos;t show this message again
                </div>{" "}
                <Notex className="pointer" onClick={() => setNote(false)} />
              </Row>
            </RowBetween>
          </MyNote>
        ))}
    </>
  );
};

export default Note;
