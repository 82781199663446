import { Box, Skeleton } from "@mui/material";
import styled from "styled-components/macro";
import { ReactComponent as PIKO } from "src/assets/images/PIKO.svg";
import { ReactComponent as EsPIKO } from "src/assets/images/esPIKO.svg";
import { ReactComponent as USDC } from "src/assets/images/tokens/USDC.svg";
import { ThemedText } from "src/theme/text";
import { Trans } from "@lingui/macro";
import { formatAmount2 } from "src/helpers/Helpers";
import { useGetPikoPrice } from "src/hooks/get_price_piko";
import { useSelector } from "react-redux";
import { useMemo } from "react";
import { BN } from "src/utils/bn";
import {
  useBalance_PIKO,
  useBalance_esPIKO,
  useBalance_usdc,
} from "src/hooks/useQueryEarn";
import { ReactComponent as ADDtoken } from "src/assets/images/AddToken.svg";
import useAddTokenToMetamask from "src/hooks/useAddTokenToMetamask";
import { useWeb3Context } from "src/hooks";
import { addresses as ADDRESS } from "src/configs/constants";
const TokenListBox = styled(Box)`
  .balance {
    padding: 18px 8px 10px;
    border-bottom: 1px solid ${({ theme }) => theme.bg3};
  }
  .tokenItem {
    padding: 18px 8px 14px;
    :not(:last-child) {
      border-bottom: 1px solid ${({ theme }) => theme.bg3};
    }
  }
  .balance,
  .tokenItem {
    padding-left: 10px;
    padding-right: 6px;
  }
`;

export default function TokenList() {
  const PIKOPrice = useGetPikoPrice();
  const PIKOBalance = useBalance_PIKO();
  const esPIKOBalance = useBalance_esPIKO();
  const usdcBalance = useBalance_usdc();
  const { addToken } = useAddTokenToMetamask();
  const { chainID } = useWeb3Context();
  const address_PIKO = ADDRESS[chainID].PIKO;
  const address_esPIKO = ADDRESS[chainID].esPIKO;
  const address_USDC = ADDRESS[chainID].USDC;
  const Add = (
    address: string,
    symbol: string,
    decimals: number,
    url: string
  ) => {
    addToken(address, symbol, decimals, url);
  };
  const tokensArr = useSelector((state: any) => {
    return state?.app?.tokensArr || [];
  });

  const totalAmount = useMemo(() => {
    if (
      PIKOBalance == undefined ||
      esPIKOBalance == undefined ||
      PIKOPrice == undefined ||
      usdcBalance == undefined ||
      tokensArr.length == 0
    )
      return;
    const pikoAmount = PIKOBalance.times(PIKOPrice);
    const espikoAmount = esPIKOBalance.times(PIKOPrice);
    const plpAmount = tokensArr.reduce((pre, curr) => {
      const { plpBalance, price } = curr;
      pre = pre.plus(BN(plpBalance).times(price));
      return pre;
    }, BN(0));
    return plpAmount
      .plus(pikoAmount)
      .plus(usdcBalance)
      .plus(espikoAmount)
      .toFixed();
  }, [PIKOBalance, esPIKOBalance, PIKOPrice, usdcBalance, tokensArr]);

  return (
    <TokenListBox className="tokenList">
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        className="balance"
      >
        <ThemedText.Text4Bold>
          <Trans>Wallet</Trans>
        </ThemedText.Text4Bold>
        <ThemedText.Text4Bold>
          ${formatAmount2(totalAmount, 2, true)}
        </ThemedText.Text4Bold>
      </Box>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        className="tokenItem"
      >
        <Box display="flex" alignItems="center">
          <PIKO width={48} height={48} />
          <ThemedText.Text9Bold ml="12px" mr="8px" fontSize={16}>
            PIKO
          </ThemedText.Text9Bold>
          <ADDtoken
            className="pointer"
            onClick={() => {
              Add(
                address_PIKO,
                "PIKO",
                18,
                "https://app.pinnako.io/img/PIKO.svg"
              );
            }}
          />
        </Box>
        <div className="text-right">
          <ThemedText.Text9Bold fontSize={16}>
            {formatAmount2(PIKOBalance, 2, true)}
          </ThemedText.Text9Bold>
          <ThemedText.Text4 fontSize={14}>
            $
            {formatAmount2(
              PIKOBalance?.times(PIKOPrice ? PIKOPrice : 1),
              2,
              true
            )}
          </ThemedText.Text4>
        </div>
      </Box>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        className="tokenItem"
      >
        <Box display="flex" alignItems="center">
          <EsPIKO width={48} height={48} />
          <ThemedText.Text9Bold ml="12px" mr="8px" fontSize={16}>
            esPIKO
          </ThemedText.Text9Bold>
          <ADDtoken
            className="pointer"
            onClick={() => {
              Add(
                address_esPIKO,
                "esPIKO",
                18,
                "https://app.pinnako.io/img/esPIKO.svg"
              );
            }}
          />
        </Box>
        <div className="text-right">
          <ThemedText.Text9Bold fontSize={16}>
            {formatAmount2(esPIKOBalance, 2, true)}
          </ThemedText.Text9Bold>
          <ThemedText.Text4 fontSize={14}>
            $
            {formatAmount2(
              esPIKOBalance?.times(PIKOPrice ? PIKOPrice : 1),
              2,
              true
            )}
          </ThemedText.Text4>
        </div>
      </Box>
      {tokensArr.length > 0 ? (
        tokensArr.map((item, i) => (
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            className="tokenItem"
            key={i}
          >
            <Box display="flex" alignItems="center">
              <img src={item.icon} width={48} height={48} />
              <ThemedText.Text9Bold ml="12px" mr="8px" fontSize={16}>
                {item?.name}
              </ThemedText.Text9Bold>
              <ADDtoken
                className="pointer"
                onClick={() => {
                  Add(
                    item.address,
                    item.name,
                    18,
                    "https://app.pinnako.io/img/PLP.svg"
                  );
                }}
              />
            </Box>
            <div className="text-right">
              <ThemedText.Text9Bold fontSize={16}>
                {formatAmount2(item?.plpBalance * 1, 2, true)}
              </ThemedText.Text9Bold>
              <ThemedText.Text4 fontSize={14}>
                ${formatAmount2(item?.plpBalance * 1 * item?.price, 2, true)}
              </ThemedText.Text4>
            </div>
          </Box>
        ))
      ) : (
        <Skeleton width="100%" height={60} style={{ borderRadius: "10px" }} />
      )}
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        className="tokenItem"
      >
        <Box display="flex" alignItems="center">
          <USDC width={48} height={48} />
          <ThemedText.Text9Bold ml="12px" mr="8px" fontSize={16}>
            USDC
          </ThemedText.Text9Bold>
          <ADDtoken
            className="pointer"
            onClick={() => {
              Add(
                address_USDC,
                "USDC",
                6,
                "https://app.pinnako.io/img/USDC.svg"
              );
            }}
          />
        </Box>
        <div className="text-right">
          <ThemedText.Text9Bold fontSize={16}>
            {formatAmount2(usdcBalance, 2, true)}
          </ThemedText.Text9Bold>
          <ThemedText.Text4 fontSize={14}>
            ${formatAmount2(usdcBalance, 2, true)}
          </ThemedText.Text4>
        </div>
      </Box>
    </TokenListBox>
  );
}
