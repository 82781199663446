import { formatAmount2 } from "src/helpers/Helpers";
import { useBasicInfo } from "src/hooks/useQueryPID";
import { ThemedText } from "src/theme/text";
import ProgressBar from "../ProgressBar";

export default function EPointProgress() {
  const baseInfo = useBasicInfo();
  return (
    <>
      <ProgressBar
        value={
          baseInfo?.rank == 1
            ? 100 - Number(baseInfo?.score) < 0
              ? 100
              : ((Number(baseInfo?.score) - 0) / (100 - 0)) * 100
            : baseInfo?.rank == 2
            ? 5000 - Number(baseInfo?.score) < 0
              ? 100
              : ((Number(baseInfo?.score) - 100) / (5000 - 100)) * 100
            : baseInfo?.rank == 3
            ? 25000 - Number(baseInfo?.score) < 0
              ? 100
              : ((Number(baseInfo?.score) - 5000) / (25000 - 5000)) * 100
            : baseInfo?.rank == 4
            ? 125000 - Number(baseInfo?.score) < 0
              ? 100
              : ((Number(baseInfo?.score) - 25000) / (125000 - 25000)) * 100
            : baseInfo?.rank == 5
            ? 500000 - Number(baseInfo?.score) < 0
              ? 100
              : ((Number(baseInfo?.score) - 125000) / (500000 - 125000)) * 100
            : 1000000 - Number(baseInfo?.score) < 0
            ? 100
            : ((Number(baseInfo?.score) - 500000) / (1000000 - 500000)) * 100
        }
      />
      <ThemedText.Text5 fontSize={12} mt="12px">
        {baseInfo?.rank == 1 ? (
          <div className=" " style={{ marginTop: "-8px" }}>
            +
            {100 - Number(baseInfo?.score) < 0
              ? 0
              : formatAmount2(100 - Number(baseInfo?.score), 0, 2, true)}{" "}
            Points to Level D
          </div>
        ) : baseInfo?.rank == 2 ? (
          <div className=" " style={{ marginTop: "-8px" }}>
            +
            {5000 - Number(baseInfo?.score) < 0
              ? 0
              : formatAmount2(5000 - Number(baseInfo?.score), 0, 2, true)}{" "}
            Points to Level C
          </div>
        ) : baseInfo?.rank == 3 ? (
          <div className=" " style={{ marginTop: "-8px" }}>
            +
            {25000 - Number(baseInfo?.score) < 0
              ? 0
              : formatAmount2(25000 - Number(baseInfo?.score), 0, 2, true)}{" "}
            Points to Level B
          </div>
        ) : baseInfo?.rank == 4 ? (
          <div className=" " style={{ marginTop: "-8px" }}>
            +
            {125000 - Number(baseInfo?.score) < 0
              ? 0
              : formatAmount2(
                  125000 - Number(baseInfo?.score),
                  0,
                  2,
                  true
                )}{" "}
            Points to Level A
          </div>
        ) : baseInfo?.rank == 5 ? (
          <div className=" " style={{ marginTop: "-8px" }}>
            +
            {500000 - Number(baseInfo?.score) < 0
              ? 0
              : formatAmount2(
                  500000 - Number(baseInfo?.score),
                  0,
                  2,
                  true
                )}{" "}
            Points to Level S
          </div>
        ) : (
          <div className=" " style={{ marginTop: "-8px" }}>
            +
            {1000000 - Number(baseInfo?.score) < 0
              ? 0
              : formatAmount2(
                  1000000 - Number(baseInfo?.score),
                  0,
                  2,
                  true
                )}{" "}
            Points to Level SS
          </div>
        )}
      </ThemedText.Text5>
    </>
  );
}
