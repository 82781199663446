import ZK from "src/assets/images/chains/zkSync.svg";
import Mantle from "src/assets/images/chains/Mantle.svg";
import Arbitrum from "src/assets/images/tokens/Arbitrum.svg";
export type AddressEnvMap = { [chainId: number]: number };
export enum SupportedChainId {
  BSCTEST = 97,
  BSCMAINNET = 56,
  ARB_TEST = 421613,
  ARBITRUM = 42161,
  ZKMAINNET = 324,
  ZKTEST = 280,
  MANTLE_TEST = 5001,
  MANTLE = 5000,
}

export const BLOCKEXPLOERER_URLS: {
  [chainId: number]: string;
} = {
  [SupportedChainId.ARB_TEST]: "https://goerli.arbiscan.io",
  [SupportedChainId.ARBITRUM]: "https://arbiscan.io",

  [SupportedChainId.BSCTEST]: "https://testnet.bscscan.com",
  [SupportedChainId.BSCMAINNET]: "https://bscscan.com",

  [SupportedChainId.ZKMAINNET]: "https://explorer.zksync.io",
  [SupportedChainId.ZKTEST]: "https://goerli.explorer.zksync.io",

  [SupportedChainId.MANTLE_TEST]: "https://explorer.testnet.mantle.xyz",
  [SupportedChainId.MANTLE]: "https://explorer.mantle.xyz",
};

export const RPC_URLS: {
  [chainId: number]: string;
} = {
  [SupportedChainId.ARB_TEST]:
    "https://endpoints.omniatech.io/v1/arbitrum/goerli/public",
  [SupportedChainId.ARBITRUM]:
    "https://endpoints.omniatech.io/v1/arbitrum/one/public",
  [SupportedChainId.BSCTEST]: "https://data-seed-prebsc-1-s1.binance.org:8545",
  [SupportedChainId.BSCMAINNET]:
    "https://endpoints.omniatech.io/v1/bsc/mainnet/public",
  [SupportedChainId.ZKMAINNET]: "https://zksync2-mainnet.zksync.io",
  [SupportedChainId.ZKTEST]: "https://testnet.era.zksync.dev",
  [SupportedChainId.MANTLE_TEST]: "https://rpc.testnet.mantle.xyz",
  [SupportedChainId.MANTLE]: "https://mantle.publicnode.com",
};

export const CHAINNAME_LIST = {
  [SupportedChainId.ZKMAINNET]: {
    chainName: "zkSync Era",
    nativeCurrency: "ETH",
    icon: ZK,
  },
  [SupportedChainId.ZKTEST]: {
    chainName: "zkSync Era Testnet",
    nativeCurrency: "ETH",
    icon: ZK,
  },
  [SupportedChainId.MANTLE_TEST]: {
    chainName: "Mantle Testnet",
    nativeCurrency: "MNT",
    icon: Mantle,
  },
  [SupportedChainId.MANTLE]: {
    chainName: "Mantle",
    nativeCurrency: "MNT",
    icon: Mantle,
  },
  [SupportedChainId.ARB_TEST]: {
    chainName: "Arbitrum Testnet",
    nativeCurrency: "ETH",
    icon: Arbitrum,
  },
  [SupportedChainId.ARBITRUM]: {
    chainName: "Arbitrum One",
    nativeCurrency: "ETH",
    icon: Arbitrum,
  },
};
