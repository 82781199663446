import { JsonRpcProvider, FallbackProvider } from "@ethersproject/providers";
// import { useWeb3Modal } from "@web3modal/react";
import React, {
  ReactElement,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react";
import { BLOCKEXPLOERER_URLS, SUPPORTED_CHAIN_IDS } from "src/constants/chains";
import {
  type PublicClient,
  usePublicClient,
  useWalletClient,
  type WalletClient,
  useNetwork,
  useDisconnect,
  useAccount,
} from "wagmi";
import { providers, utils } from "ethers";
import { type HttpTransport } from "viem";
import { useConnectModal } from "@rainbow-me/rainbowkit";
export function publicClientToProvider(publicClient: PublicClient) {
  const { chain, transport } = publicClient;
  const network = {
    chainId: chain.id,
    name: chain.name,
    ensAddress: chain.contracts?.ensRegistry?.address,
  };

  if (transport.type === "fallback")
    return new providers.FallbackProvider(
      (transport.transports as ReturnType<HttpTransport>[]).map(
        ({ value }) => new providers.JsonRpcProvider(value?.url, network)
      )
    );
  return new providers.JsonRpcProvider(transport.url, network);
}

/** Hook to convert a viem Public Client to an ethers.js Provider. */
export function useEthersProvider({ chainId }: { chainId?: number } = {}) {
  const publicClient = usePublicClient({ chainId });
  return React.useMemo(
    () => publicClientToProvider(publicClient),
    [publicClient]
  );
}
export function walletClientToSigner(walletClient: WalletClient) {
  const { account, chain, transport } = walletClient;
  const network = {
    chainId: chain.id,
    name: chain.name,
    ensAddress: chain.contracts?.ensRegistry?.address,
  };
  const provider = new providers.Web3Provider(transport, network);
  const signer = provider.getSigner(account.address);
  return signer;
}

/** Hook to convert a viem Wallet Client to an ethers.js Signer. */
export function useEthersSigner({ chainId }: { chainId?: number } = {}) {
  const { data: walletClient } = useWalletClient({ chainId });
  const provider = useEthersProvider({ chainId });
  return React.useMemo(
    () => (walletClient ? walletClientToSigner(walletClient) : provider),
    [walletClient]
  );
}

/*
  Types
*/
type onChainProvider = {
  connect: () => void;
  disconnect: () => void;
  provider: JsonRpcProvider | FallbackProvider;
  address: string;
  connected: boolean;
  switchNetwork: any;
  chainID: number;
  Error: boolean;
};

export type Web3ContextData = {
  onChainProvider: onChainProvider;
} | null;

const Web3Context = React.createContext<Web3ContextData>(null);

export const useWeb3Context = () => {
  const web3Context = useContext(Web3Context);
  if (!web3Context) {
    throw new Error(
      "useWeb3Context() can only be used inside of <Web3ContextProvider />, " +
        "please declare it at a higher level."
    );
  }
  const { onChainProvider } = web3Context;
  return useMemo(() => {
    return { ...onChainProvider };
  }, [onChainProvider]);
};

export const Web3ContextProvider: React.FC<{ children: ReactElement }> = ({
  children,
}) => {
  // const { open } = useWeb3Modal();
  const { chain } = useNetwork();
  const { disconnect, disconnectAsync } = useDisconnect();

  const NETWORKID = localStorage.getItem("NETWORKID");

  const { address, isConnected } = useAccount();

  let caheId;
  if (!isConnected && NETWORKID) {
    caheId = Number(NETWORKID);
  }
  const provider = useEthersProvider({ chainId: caheId });

  const [Error, setError] = useState(false);
  const { openConnectModal } = useConnectModal();
  const disconnectF = useCallback(async () => {
    await disconnectAsync();
    setTimeout(() => window.location.reload(), 1);
  }, [disconnectAsync]);

  const switchNetwork2 = useCallback(
    async (
      chainId: number,
      chainName: string,
      nativeCurrency: string,
      rpcUrl: string,
      blockExplorerUrl: string
    ) => {
      try {
        try {
          await window?.ethereum.request({
            method: "wallet_switchEthereumChain",
            params: [{ chainId: utils.hexValue(chainId) }],
          });
          localStorage.setItem("NETWORKID", utils.hexValue(chainId));
          setTimeout(() => window.location.reload(), 1);
        } catch (switchError: any) {
          console.log("switchNetwork2 switch Error", switchError);
          if (switchError.code === 4902) {
            await window?.ethereum.request({
              method: "wallet_addEthereumChain",
              params: [
                {
                  chainId: utils.hexValue(chainId),
                  chainName,
                  nativeCurrency: {
                    name: nativeCurrency,
                    symbol: nativeCurrency,
                    decimals: 18,
                  },
                  rpcUrls: [rpcUrl],
                  blockExplorerUrls: [BLOCKEXPLOERER_URLS[chainId]],
                },
              ],
            });
            localStorage.setItem("NETWORKID", utils.hexValue(chainId));
            setTimeout(() => window.location.reload(), 1);
          }
        }
      } catch (addError) {
        console.log("switchNetwork2", addError);
        // handle "add" error
      }
    },
    []
  );

  const activeChainID = useMemo(() => {
    if (!chain?.id) return provider?.network?.chainId;
    if (
      provider?.network?.chainId == chain?.id &&
      SUPPORTED_CHAIN_IDS.includes(chain?.id)
    ) {
      if (Error) {
        setError(false);
      }
      return chain?.id;
    } else {
      if (isConnected) {
        disconnect();
        setTimeout(() => window.location.reload(), 1);
      }
      setError(true);
      return provider?.network?.chainId;
    }
  }, [provider, chain, Error, isConnected, disconnect]);

  const onChainProvider = useMemo(
    () => ({
      connect: openConnectModal,
      disconnect: disconnectF,
      switchNetwork: switchNetwork2,
      provider,
      connected: isConnected,
      address: address ?? "",
      chainID: activeChainID,
      Error,
    }),
    [
      openConnectModal,
      disconnectF,
      switchNetwork2,
      provider,
      isConnected,
      address,
      activeChainID,
      Error,
    ]
  );

  return (
    <Web3Context.Provider value={{ onChainProvider }}>
      {children}
    </Web3Context.Provider>
  );
};
