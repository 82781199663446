import { Box, useMediaQuery } from "@mui/material";
import styled from "styled-components/macro";
import { ThemedText } from "src/theme/text";
import { Trans } from "@lingui/macro";
import { MenuBtn } from "../Button/MenuBtn";

const TabBox = styled(Box)`
  display: flex;
  flex-direction: column;
  @media only screen and (max-width: 1280px) {
    flex-direction: row;
    align-items: space-between;
  }
`;

type dataPros = {
  curr?: number;
  setCurr?: any;
  tabList?: any;
};

const LeftTab = ({ curr, tabList, setCurr }: dataPros) => {
  const isSmallScreen = useMediaQuery("(max-width: 1280px)");
  return (
    <TabBox className="tabBox">
      {tabList.map((tab: any, index: number) => (
        <MenuBtn key={index}>
          <Box
            display="flex"
            alignItems="center"
            className={`tabItem pointer ${curr == index && "activeTabItem"}`}
            onClick={() => setCurr(index)}
          >
            {tab.icon}
            <>
              {curr == index ? (
                <ThemedText.Text1Medium
                  fontSize={isSmallScreen ? 14 : 16}
                  ml={isSmallScreen ? "6px" : "10px"}
                  className="text"
                >
                  <Trans>{tab.title}</Trans>
                </ThemedText.Text1Medium>
              ) : (
                <ThemedText.Text4Medium
                  fontSize={isSmallScreen ? 14 : 16}
                  ml={isSmallScreen ? "6px" : "10px"}
                  className="text"
                >
                  <Trans>{tab.title}</Trans>
                </ThemedText.Text4Medium>
              )}
            </>
          </Box>
        </MenuBtn>
      ))}
    </TabBox>
  );
};

export default LeftTab;
