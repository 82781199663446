import { ApolloClient, InMemoryCache } from "@apollo/client";

const chainID = process.env.REACT_APP_NETWORKID

export const pikoGraphClient_arb_dev = createClient("https://api.thegraph.com/subgraphs/name/metaverseblock/pnk_stats");
export const pikoGraphClient_arb_prod = createClient("https://api.thegraph.com/subgraphs/name/metaverseblock/pnk_stats");
const pikoGraphClient_zk_dev = createClient("https://graph.pinnako.io/subgraphs/name/ekmanss/pnk");
const pikoGraphClient_zk_prod = createClient("https://graph.pinnako.io/subgraphs/name/ekmanss/stats");

export const pidGraphClient_arb_dev = createClient("https://api.thegraph.com/subgraphs/name/metaverseblock/pnk_pid")
export const pidGraphClient_arb_prod = createClient("https://api.thegraph.com/subgraphs/name/metaverseblock/pnk_pid")
const pidGraphClient_zk_dev = createClient("https://graph.pinnako.io/subgraphs/name/ekmanss/pid")
const pidGraphClient_zk_prod = createClient("https://api.studio.thegraph.com/query/49418/zkmain_stats/version/latest")

const rankClient_arb_dev = createClient("https://api.thegraph.com/subgraphs/name/metaverseblock/pnk_ranking")
const rankClient_arb_prod = createClient("https://api.thegraph.com/subgraphs/name/metaverseblock/pnk_ranking")
const rankClient_zk_dev = createClient('https://graph.pinnako.io/subgraphs/name/ekmanss/ranking')
const rankClient_zk_prod = createClient('https://api.studio.thegraph.com/query/49418/zkmain_ranking/version/latest')

const vestakeTimeUrl = ""
const vestakeTime_dev = vestakeTimeUrl + "?chain=bsc"
const vestakeTime_prod = vestakeTimeUrl + "?chain=bsc"
const vestakeTime_zk_dev = vestakeTimeUrl + "?chain=zk"
const vestakeTime_zk_prod = vestakeTimeUrl + "?chain=zk"

const pikoGraphTradeClient_zk_prod = createClient("https://api.studio.thegraph.com/query/49418/pnk_graph/version/latest");
const pikoGraphTradeClient_arb_prod = createClient("https://api.thegraph.com/subgraphs/name/metaverseblock/pnk_graph");

export const chainlinkClient = createClient("https://api.thegraph.com/subgraphs/name/deividask/chainlink");

export function createClient(uri) {
  return new ApolloClient({
    uri,
    cache: new InMemoryCache(),
  });
}

export const getVestakeTimeUrl = (chainID) => {
  switch (chainID) {
    case 56:
      return vestakeTime_prod
      break;
    case 97:
      return vestakeTime_dev
      break;
    case 324:
      return vestakeTime_zk_prod
      break;
    case 280:
      return vestakeTime_zk_dev
      break;
    default:
      return vestakeTime_zk_prod
      break;
  }
}

export const getPikoGraphClient_all = (roundid) => {
  return `?roundid=${roundid}`
}

export const getPikoGraphClient = (chainID) => {
  switch (chainID) {
    case 421613:
      return pikoGraphClient_arb_dev
      break;
    case 42161:
      return pikoGraphClient_arb_prod
      break;
    case 280:
      return pikoGraphClient_zk_dev
      break;
    case 324:
      return pikoGraphClient_zk_prod
      break;
    case 5001:
      return
    default:
      return pikoGraphClient_arb_prod
      break;
  }
}

export const getTradesGraphClient = (chainID) => {
  switch (chainID) {
    case 421613:
      return pikoGraphTradeClient_arb_prod
      break;
    case 42161:
      return pikoGraphTradeClient_arb_prod
      break;
    case 280:
      return pikoGraphTradeClient_zk_prod
      break;
    case 324:
      return pikoGraphTradeClient_zk_prod
      break;
    case 5001:
      return
    default:
      return pikoGraphTradeClient_arb_prod
      break;
  }
}

export const getPikoGraphClient_daily = (chainID) => {
  switch (chainID) {
    case 421613:
      return rankClient_arb_dev
      break;
    case 42161:
      return rankClient_arb_prod
      break;
    case 280:
      return rankClient_zk_dev
      break;
    case 324:
      return rankClient_zk_prod
      break;
    case 5001:
      return
    default:
      return rankClient_arb_prod
      break;
  }
}

export const getPidGraphClient = (chainID) => {
  switch (chainID) {
    case 421613:
      return pidGraphClient_arb_dev
      break;
    case 42161:
      return pidGraphClient_arb_prod
      break;
    case 280:
      return pidGraphClient_zk_dev
      break;
    case 324:
      return pidGraphClient_zk_prod
      break;
    case 5001:
      return
    default:
      return pidGraphClient_arb_prod
      break;
  }
}


