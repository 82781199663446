import { ethers } from "ethers";
import { addresses } from "../configs/constants";
import { setAll } from "../helpers";
import {
  createAsyncThunk,
  createSelector,
  createSlice,
} from "@reduxjs/toolkit";
import { RootState } from "src/store";
import { IBaseAddressAsyncThunk } from "./interfaces";
import { abi as RewardRouterABI } from "../abis/RewardRouter.json";
import { abi as DisTokenABI } from "../abis/DisToken.json";
import { useEthersSigner } from "src/hooks/web3Context";

export const loadAccountRewards = createAsyncThunk(
  "account/loadAccountRewards",
  async ({ networkID, provider, address }: IBaseAddressAsyncThunk) => {
    const signer = useEthersSigner();

    // stake PIKO rewards
    const DisTokenContract = new ethers.Contract(
      addresses[networkID].dis_token,
      DisTokenABI,
      provider
    );
    const rewards_piko = await DisTokenContract.earned(address);
    const DisFeeContract = new ethers.Contract(
      addresses[networkID].dis_fee,
      DisTokenABI,
      provider
    );
    const rewards_usdc = await DisFeeContract.earned(address);
    // console.log("rewards_piko", rewards_piko, "rewards_usdc", rewards_usdc)
    // stake PLP rewards
    const RewardRouterContract = new ethers.Contract(
      addresses[networkID].RewardRouter,
      RewardRouterABI,
      signer
    );
    const address_plp1 = addresses[networkID].PLP;
    const address_plp2 = addresses[networkID].PLP_2;
    const address_plp1p = addresses[networkID].PLP_1p;
    const claimablePikoList = await RewardRouterContract.claimablePIKOList();
    // console.log("claimablePikoList", claimablePikoList)
    const claimablePIKOAddress = claimablePikoList[0];
    const claimablePikoVal = claimablePikoList[1];
    const index = claimablePIKOAddress.findIndex(
      (item: string) => item == address_plp1
    );
    const claimablePikoArr = [
      ethers.utils.formatEther(claimablePikoVal[index]),
    ];

    const claimableUsdcList = await RewardRouterContract.claimableUSDCList();
    const claimableUsdcAddress = claimableUsdcList[0];
    const claimableUsdcVal = claimableUsdcList[1];
    const key = claimableUsdcAddress.findIndex(
      (item: string) => item == address_plp1
    );
    const claimableUsdcArr = [ethers.utils.formatEther(claimableUsdcVal[key])];

    return {
      claimablePikoArr,
      claimableUsdcArr,
      rewards_piko: ethers.utils.formatUnits(rewards_piko, 18),
      rewards_usdc: ethers.utils.formatUnits(rewards_usdc, 18),
    };
  }
);

interface IAccountRewardsSlice {
  loading: boolean;
}

const initialState: IAccountRewardsSlice = {
  loading: false,
};

const accountRewardsSlice = createSlice({
  name: "account",
  initialState,
  reducers: {
    fetchAccountSuccess(state, action) {
      setAll(state, action.payload);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(loadAccountRewards.pending, (state) => {
        state.loading = true;
      })
      .addCase(loadAccountRewards.fulfilled, (state, action) => {
        setAll(state, action.payload);
        state.loading = false;
      })
      .addCase(loadAccountRewards.rejected, (state, {}) => {
        state.loading = false;
      });
  },
});

const baseInfo = (state: RootState) => state.reward;
export default accountRewardsSlice.reducer;
export const { fetchAccountSuccess } = accountRewardsSlice.actions;
export const getAccountState = createSelector(
  baseInfo,
  (accountRewards) => accountRewards
);
