import {
  useEffect,
  useState,
  useMemo,
  useCallback,
  forwardRef,
  useImperativeHandle,
} from "react";
import { Box } from "@material-ui/core";
import useSWR from "swr";
import { ethers, logger } from "ethers";
import cx from "classnames";

import "./trade.scss";
import "./trade-m.scss";
import { getContractAddress } from "../../Addresses";
import {
  getTokens,
  getToken,
  getWhitelistedTokens,
  getTokenBySymbol,
} from "../../configs/Tokens";
import { useGetpositions } from "./hooks";
import {
  SWAP,
  LONG,
  SHORT,
  USD_DECIMALS,
  getExplorerUrl,
  helperToast,
  formatAmount,
  bigNumberify,
  getTokenInfo,
  fetcher,
  getPositionKey,
  useLocalStorageSerializeKey,
  useLocalStorageByChainId,
  useAccountOrders,
  getPageTitle,
} from "../../helpers/Helpers";
import { getPositionQuery } from "./trade";
import { useWeb3Context } from "src/hooks";
import { getConstant } from "../../configs/getConstant";
import {
  approvePlugin,
  useMinExecutionFee,
  cancelMultipleOrders,
  useInfoTokens,
} from "../../Api";
import Reader from "../../abis/ReaderV2.json";
import VaultV2 from "../../abis/VaultV2.json";
import Router from "../../abis/Router.json";
import Tab from "../../components/Tab/Tab";
import Checkbox from "../../components/Checkbox/Checkbox";
import SwapBox from "../../components/Exchange/SwapBox";
import ExchangeBanner from "../../components/Exchange/ExchangeBanner";
import ExchangeTVChart, {
  getChartToken,
} from "../../components/Exchange/ExchangeTVChart";
import TVChartContainer from "./TVChartContainer/TVChartContainer";
import PositionsList from "../../components/Exchange/PositionsList";
import OrdersList from "../../components/Exchange/OrdersList";
import TradeHistory from "../../components/Exchange/TradeHistory";
import ExchangeWalletTokens from "../../components/Exchange/ExchangeWalletTokens";
import { useSelector } from "react-redux";
import { useGetTokenBalances, useQueryApprovedPlugins } from "./hooks";
import {
  toastError,
  toastProcessing,
  toastSuccess,
  toastTransaction,
  toastTransaction2,
} from "src/helpers/toastHelpers";
import { compareAddress } from "src/utils/address";
import { useTokenInfo, useTotalTokenWeights } from "src/hooks/useTradeInfo";
import { useAccountOrderList } from "src/hooks/useTradeList";
import { useRouterContract } from "src/hooks/useContract";
import { useUpDataBlockNumber } from "src/lib/hooks/useUpDataBlockNumber";
import { serializeError } from "eth-rpc-errors";
import Loading from "src/components/LoadingSplash";
import { useGetToken } from "src/hooks/useGetToken";
import ListTab from "./ListTab";
import SwitchesSize from "src/components/Switchv5";
import { useEthersSigner } from "src/hooks/web3Context";
const { AddressZero } = ethers.constants;

const notifications = {};

function pushSuccessNotification(chainID, message, e) {
  const { transactionHash } = e;
  const id = ethers.utils.id(message + transactionHash);
  if (notifications[id]) {
    return;
  }

  notifications[id] = true;

  const txUrl = getExplorerUrl(chainID) + "tx/" + transactionHash;
  helperToast.success(
    <div>
      {message}
      <a href={txUrl} target="_blank" rel="noopener noreferrer">
        View
      </a>
    </div>
  );
}

function pushErrorNotification(chainID, message, e) {
  const { transactionHash } = e;
  const id = ethers.utils.id(message + transactionHash);
  if (notifications[id]) {
    return;
  }

  notifications[id] = true;

  const txUrl = getExplorerUrl(chainID) + "tx/" + transactionHash;
  helperToast.error(
    <div>
      {message}
      <a href={txUrl} target="_blank" rel="noopener noreferrer">
        View
      </a>
    </div>
  );
}

export const Trade = forwardRef((props, ref) => {
  const {
    savedIsPnlInLeverage,
    setSavedIsPnlInLeverage,
    savedShowPnlAfterFees,
    savedSlippageAmount,
    pendingTxns,
    setPendingTxns,
    savedShouldShowPositionLines,
    setSavedShouldShowPositionLines,
    savedShouldDisableOrderValidation,
    openSettings,
  } = props;
  const [showBanner, setShowBanner] = useLocalStorageSerializeKey(
    "showBanner",
    true
  );
  const [bannerHidden, setBannerHidden] = useLocalStorageSerializeKey(
    "bannerHidden",
    null
  );
  const [pendingPositions, setPendingPositions] = useState({});
  const [updatedPositions, setUpdatedPositions] = useState({});
  const [orderBookApproved, setOrderBookApproved] = useState(false);
  const [plpName, setPlpName] = useState("");

  const [tokenDecimals, setTokenDecimals] = useState(0);
  const [usdxSupply, setUsdxSupply] = useState(0);

  const PLPMAP = useSelector((state) => {
    return state.app.PLPMAP || {};
  });

  const allTokens = useSelector((state) => {
    return state.app.allToken || [];
  });
  const ALL_TOKEN_MAP = useSelector((state) => {
    return state.app.tokensMap || {};
  });

  const ALL_PLP_LIST = useSelector((state) => {
    return state.app.tokensArr;
  });

  const hideBanner = () => {
    const hiddenLimit = new Date(
      new Date().getTime() + 2 * 24 * 60 * 60 * 1000
    );
    setBannerHidden(hiddenLimit);
    setShowBanner(false);
  };
  const provider = useEthersSigner();
  const { chainID, connected, address } = useWeb3Context();
  const currentAccount = address;

  const tokens = allTokens;

  const nativeTokenAddress = getContractAddress(chainID, "NATIVE_TOKEN");
  const UpDataBlockNumber = useUpDataBlockNumber();

  const {
    vaultAddress,
    positionRouterAddress,
    orderBookAddress,
    routerAddress,
  } = useMemo(() => {
    if (!plpName || !PLPMAP[plpName])
      return {
        vaultAddress: undefined,
        positionRouterAddress: undefined,
        orderBookAddress: undefined,
        routerAddress: undefined,
      };
    const data = PLPMAP[plpName];
    return {
      vaultAddress: data.vault_address,
      positionRouterAddress: data.positionRouter_address,
      orderBookAddress: data.orderBook_address,
      routerAddress: data.router_address,
    };
  }, [plpName, PLPMAP]);

  const RouterContract = useRouterContract(routerAddress);

  const whitelistedTokens = allTokens;
  const defaultCollateralSymbol = getConstant(
    chainID,
    "defaultCollateralSymbol"
  );
  const defaultTokenSelection = useMemo(
    () => ({
      [SWAP]: {
        from: getTokenBySymbol(chainID, "BTC").address,
        to: getTokenBySymbol(chainID, defaultCollateralSymbol).address,
      },
      [LONG]: {
        from: getTokenBySymbol(chainID, "BTC").address,
        to: getTokenBySymbol(chainID, "BTC").address,
      },
      [SHORT]: {
        from: getTokenBySymbol(chainID, defaultCollateralSymbol).address,
        to: getTokenBySymbol(chainID, "BTC").address,
      },
    }),
    [chainID, defaultCollateralSymbol]
  );
  const [tokenSelection, setTokenSelection] = useLocalStorageByChainId(
    chainID,
    "Exchange-token-selection-v2",
    defaultTokenSelection
  );

  const [swapOption, setSwapOption] = useLocalStorageByChainId(
    chainID,
    "Swap-option-v2",
    LONG
  );
  const fromTokenAddress = tokenSelection[swapOption].from;
  const toTokenAddress = tokenSelection[swapOption].to;

  useEffect(() => {
    if (!ALL_PLP_LIST || ALL_PLP_LIST.length <= 0) return;
    const token = ALL_TOKEN_MAP[toTokenAddress.toLocaleLowerCase()];
    const usd = PLPMAP[token.plp_type];
    setPlpName(token.plp_type);
    setTokenDecimals(token.tokenDecimals);
    setUsdxSupply(usd.usdxSupply);
  }, [PLPMAP, ALL_PLP_LIST, ALL_TOKEN_MAP]);

  const setFromTokenAddress = useCallback(
    (selectedSwapOption, address) => {
      const newTokenSelection = JSON.parse(JSON.stringify(tokenSelection));
      const { from, to } = tokenSelection[selectedSwapOption];
      newTokenSelection[selectedSwapOption].from = address;
      const setTo = ALL_TOKEN_MAP[to.toLocaleLowerCase()];
      const toTokenData = setTo ? PLPMAP[setTo.plp_type] : undefined;
      const fandIndex = toTokenData?.tokenList.findIndex((x) =>
        compareAddress(x, setTo.address)
      );
      if (
        toTokenData.isGNS &&
        fandIndex == -1 &&
        toTokenData?.tokenList &&
        toTokenData?.tokenList.length > 0
      ) {
        const addr = toTokenData?.tokenList[0].toLocaleLowerCase();
        newTokenSelection[LONG].to = addr;
        newTokenSelection[SHORT].to = addr;
      }
      setPlpName(setTo.plp_type);

      setTokenSelection(newTokenSelection);
    },
    [tokenSelection, setTokenSelection, ALL_TOKEN_MAP, PLPMAP]
  );

  const setToTokenAddress = useCallback(
    (selectedSwapOption, address) => {
      const newTokenSelection = JSON.parse(JSON.stringify(tokenSelection));
      newTokenSelection[selectedSwapOption].to = address;
      if (selectedSwapOption === LONG || selectedSwapOption === SHORT) {
        const setTo = ALL_TOKEN_MAP[address.toLocaleLowerCase()];
        const toTokenData = setTo ? PLPMAP[setTo.plp_type] : undefined;
        const fandIndex = toTokenData?.tokenList.findIndex((x) =>
          compareAddress(x, setTo.address)
        );
        if (
          toTokenData.isGNS &&
          fandIndex == -1 &&
          toTokenData?.tokenList &&
          toTokenData?.tokenList.length > 0
        ) {
          const addr = toTokenData?.tokenList[0].toLocaleLowerCase();
          newTokenSelection[LONG].from = addr;
          newTokenSelection[SHORT].from = addr;
        }

        setPlpName(setTo.plp_type);
        newTokenSelection[LONG].to = address;
        newTokenSelection[SHORT].to = address;
      }
      setTokenSelection(newTokenSelection);
    },
    [tokenSelection, setTokenSelection, ALL_TOKEN_MAP, PLPMAP]
  );

  const setMarket = (selectedSwapOption, toTokenAddress) => {
    setSwapOption(selectedSwapOption);
    const newTokenSelection = JSON.parse(JSON.stringify(tokenSelection));
    newTokenSelection[selectedSwapOption].to = toTokenAddress;
    if (selectedSwapOption === LONG || selectedSwapOption === SHORT) {
      newTokenSelection[LONG].to = toTokenAddress;
      newTokenSelection[SHORT].to = toTokenAddress;
      const setTo = ALL_TOKEN_MAP[toTokenAddress.toLocaleLowerCase()];
      const toTokenData = setTo ? PLPMAP[setTo.plp_type] : undefined;
      const fandIndex = toTokenData?.tokenList.findIndex((x) =>
        compareAddress(x, setTo.address)
      );
      if (
        fandIndex == -1 &&
        toTokenData?.tokenList &&
        toTokenData?.tokenList.length > 0
      ) {
        const addr = toTokenData?.tokenList[0].toLocaleLowerCase();
        newTokenSelection[LONG].from = addr;
        newTokenSelection[SHORT].from = addr;
      }
      setPlpName(setTo.plp_type);
    }
    setTokenSelection(newTokenSelection);
  };

  const [isConfirming, setIsConfirming] = useState(false);
  const [isPendingConfirmation, setIsPendingConfirmation] = useState(false);
  const tokenBalances1 = useGetTokenBalances();
  const fundingRateInfo = useTokenInfo(plpName);

  const { infoTokens } = useInfoTokens(
    plpName,
    provider,
    chainID,
    connected,
    tokenBalances1,
    fundingRateInfo
  );
  const { positions, positionsMap } = useGetpositions(
    infoTokens,
    pendingPositions
  );

  const positionsDataIsLoading = connected && !positions;

  const totalTokenWeights = useTotalTokenWeights(plpName);
  const { orderBookApproved1, positionRouterApproved } =
    useQueryApprovedPlugins(plpName);
  useEffect(() => {
    const isApproved = orderBookApproved1;
    setOrderBookApproved(isApproved);
  }, [plpName, orderBookApproved1]);

  const currInfoTokens = infoTokens;

  const { minExecutionFee, minExecutionFeeUSD, minExecutionFeeErrorMessage } =
    useMinExecutionFee(plpName, provider, connected, chainID, infoTokens);

  useEffect(() => {
    const fromToken = getTokenInfo(infoTokens, fromTokenAddress);
    const toToken = getTokenInfo(infoTokens, toTokenAddress);
    let selectedToken = getChartToken(swapOption, fromToken, toToken, chainID);
    if (!fromToken || !toToken) return;
    let currentTokenPriceStr = formatAmount(
      selectedToken?.maxPrice,
      USD_DECIMALS,
      selectedToken.tokenDecimals,
      true
    );
    let title = getPageTitle(
      currentTokenPriceStr +
        ` | ${selectedToken.symbol}${selectedToken.isStable ? "" : "USD"}`
    );
    document.title = title;
  }, [
    tokenSelection,
    swapOption,
    infoTokens,
    chainID,
    fromTokenAddress,
    toTokenAddress,
  ]);

  useImperativeHandle(ref, () => ({
    onUpdatePosition(
      key,
      size,
      collateral,
      averagePrice,
      entryFundingRate,
      reserveAmount,
      realisedPnl
    ) {
      for (let i = 0; i < positions.length; i++) {
        const position = positions[i];
        if (position.contractKey === key) {
          updatedPositions[position.key] = {
            size,
            collateral,
            averagePrice,
            entryFundingRate,
            reserveAmount,
            realisedPnl,
            updatedAt: Date.now(),
          };
          setUpdatedPositions({ ...updatedPositions });
          break;
        }
      }
    },
    onClosePosition(
      key,
      size,
      collateral,
      averagePrice,
      entryFundingRate,
      reserveAmount,
      realisedPnl,
      e
    ) {
      for (let i = 0; i < positions.length; i++) {
        const position = positions[i];
        if (position.contractKey === key) {
          updatedPositions[position.key] = {
            size: bigNumberify(0),
            collateral: bigNumberify(0),
            averagePrice,
            entryFundingRate,
            reserveAmount,
            realisedPnl,
            updatedAt: Date.now(),
          };
          setUpdatedPositions({ ...updatedPositions });
          break;
        }
      }
    },

    onIncreasePosition(
      key,
      address,
      collateralToken,
      indexToken,
      collateralDelta,
      sizeDelta,
      isLong,
      price,
      fee,
      e
    ) {
      if (address !== currentAccount) {
        return;
      }

      const indexTokenItem = useGetToken(indexToken);
      const tokenSymbol = indexTokenItem.isWrapped
        ? getConstant(chainID, "nativeTokenSymbol")
        : indexTokenItem.symbol;

      let message;
      if (sizeDelta.eq(0)) {
        message = `Deposited ${formatAmount(
          collateralDelta,
          USD_DECIMALS,
          2,
          true
        )} USD into ${tokenSymbol} ${isLong ? "Long" : "Short."}`;
      } else {
        message = `Increased ${tokenSymbol} ${
          isLong ? "Long" : "Short"
        }, +${formatAmount(sizeDelta, USD_DECIMALS, 2, true)} USD.`;
      }

      pushSuccessNotification(chainID, message, e);
    },

    onDecreasePosition(
      key,
      address,
      collateralToken,
      indexToken,
      collateralDelta,
      sizeDelta,
      isLong,
      price,
      fee,
      e
    ) {
      if (address !== currentAccount) {
        return;
      }

      const indexTokenItem = useGetToken(indexToken);
      const tokenSymbol = indexTokenItem.isWrapped
        ? getConstant(chainID, "nativeTokenSymbol")
        : indexTokenItem.symbol;

      let message;
      if (sizeDelta.eq(0)) {
        message = `Withdrew ${formatAmount(
          collateralDelta,
          USD_DECIMALS,
          2,
          true
        )} USD from ${tokenSymbol} ${isLong ? "Long" : "Short"}.`;
      } else {
        message = `Decreased ${tokenSymbol} ${
          isLong ? "Long" : "Short"
        }, -${formatAmount(sizeDelta, USD_DECIMALS, 2, true)} USD.`;
      }

      pushSuccessNotification(chainID, message, e);
    },

    onCancelIncreasePosition(
      account = { address },
      path,
      indexToken,
      amountIn,
      minOut,
      sizeDelta,
      isLong,
      acceptablePrice,
      executionFee,
      blockGap,
      timeGap,
      e
    ) {
      if (address !== currentAccount) {
        return;
      }
      const indexTokenItem = useGetToken(indexToken);
      const tokenSymbol = indexTokenItem.isWrapped
        ? getConstant(chainID, "nativeTokenSymbol")
        : indexTokenItem.symbol;

      const message = `Could not increase ${tokenSymbol} ${
        isLong ? "Long" : "Short"
      } within the allowed slippage, you can adjust the allowed slippage in the settings on the top right of the page.`;

      pushErrorNotification(chainID, message, e);

      const key = getPositionKey(
        address,
        path[path.length - 1],
        indexToken,
        isLong
      );
      pendingPositions[key] = {};
      setPendingPositions({ ...pendingPositions });
    },

    onCancelDecreasePosition(
      account = { address },
      path,
      indexToken,
      collateralDelta,
      sizeDelta,
      isLong,
      receiver,
      acceptablePrice,
      minOut,
      executionFee,
      blockGap,
      timeGap,
      e
    ) {
      if (address !== currentAccount) {
        return;
      }
      const indexTokenItem = useGetToken(indexToken);
      const tokenSymbol = indexTokenItem.isWrapped
        ? getConstant(chainID, "nativeTokenSymbol")
        : indexTokenItem.symbol;

      const message = `Could not decrease ${tokenSymbol} ${
        isLong ? "Long" : "Short"
      } within the allowed slippage, you can adjust the allowed slippage in the settings on the top right of the page.`;

      pushErrorNotification(chainID, message, e);

      const key = getPositionKey(
        address,
        path[path.length - 1],
        indexToken,
        isLong
      );
      pendingPositions[key] = {};
      setPendingPositions({ ...pendingPositions });
    },
  }));

  const flagOrdersEnabled = true;
  const orders = useAccountOrderList();
  const [isWaitingForPluginApproval, setIsWaitingForPluginApproval] =
    useState(false);
  const [
    isWaitingForPositionRouterApproval,
    setIsWaitingForPositionRouterApproval,
  ] = useState(false);
  const [isPluginApproving, setIsPluginApproving] = useState(false);
  const [isPositionRouterApproving, setIsPositionRouterApproving] =
    useState(false);
  const [isCancelMultipleOrderProcessing, setIsCancelMultipleOrderProcessing] =
    useState(false);
  const [cancelOrderIdList, setCancelOrderIdList] = useState([]);

  const onMultipleCancelClick = useCallback(
    async function () {
      setIsCancelMultipleOrderProcessing(true);
      try {
        const tx = await cancelMultipleOrders(
          plpName,
          chainID,
          provider,
          cancelOrderIdList,
          orderBookAddress,
          {
            successMsg: "Orders cancelled.",
            failMsg: "Cancel failed.",
            sentMsg: "Cancel submitted.",
            pendingTxns,
            setPendingTxns,
          }
        );
        const receipt = await tx.wait();
        if (receipt.status === 1) {
          setCancelOrderIdList([]);
        }
      } catch (error) {
        console.error(error);
      } finally {
        setIsCancelMultipleOrderProcessing(false);
      }
    },
    [
      chainID,
      provider,
      pendingTxns,
      setPendingTxns,
      setCancelOrderIdList,
      cancelOrderIdList,
      setIsCancelMultipleOrderProcessing,
    ]
  );

  const approveOrderBook = useCallback(async () => {
    if (!RouterContract || !orderBookAddress) return;
    setIsPluginApproving(true);
    setIsWaitingForPluginApproval(true);
    try {
      const res = await RouterContract.approvePlugin(orderBookAddress);
      const data = await res.wait();
      UpDataBlockNumber(data);
      toastSuccess("Enable orders sent.", res.hash);
    } catch (error) {
      const rpcError = serializeError(error);
      let message, type;
      message =
        rpcError.data && rpcError.data.originalError
          ? rpcError.data.originalError.reason
          : rpcError.message;
      type =
        rpcError.data && rpcError.data.originalError
          ? rpcError.data.originalError.code
          : "";
      toastError(message);
    }
    setIsWaitingForPluginApproval(false);
    setIsPluginApproving(false);
  }, [RouterContract, orderBookAddress]);

  const approvePositionRouter = useCallback(
    async ({ sentMsg, failMsg }) => {
      if (!RouterContract || !positionRouterAddress) return;
      setIsPositionRouterApproving(true);
      toastProcessing("Pending ApprovePlugin");
      try {
        const res = await RouterContract.approvePlugin(positionRouterAddress);
        const data = await res.wait();
        UpDataBlockNumber(data);
        if (res && sentMsg) {
          toastSuccess(sentMsg, res.hash);
        }
      } catch (error) {
        const rpcError = serializeError(error);
        let message, type;
        message =
          rpcError.data && rpcError.data.originalError
            ? rpcError.data.originalError.reason
            : rpcError.message;
        type =
          rpcError.data && rpcError.data.originalError
            ? rpcError.data.originalError.code
            : "";
        toastError(message);
      }
      setIsWaitingForPositionRouterApproval(false);
      setIsPositionRouterApproving(false);
    },
    [RouterContract, positionRouterAddress]
  );

  const LIST_SECTIONS = [
    "Positions",
    flagOrdersEnabled ? "Orders" : undefined,
    "Trades",
  ].filter(Boolean);
  let [listSection, setListSection] = useLocalStorageByChainId(
    chainID,
    "List-section-v2",
    LIST_SECTIONS[0]
  );
  const LIST_SECTIONS_LABELS = {
    Orders: orders?.length ?? 0,
    Positions: positions.length ?? 0,
  };
  if (!LIST_SECTIONS.includes(listSection)) {
    listSection = LIST_SECTIONS[0];
  }
  if (!ALL_TOKEN_MAP[toTokenAddress.toLocaleLowerCase()]) {
    return null;
  }
  const renderCancelOrderButton = () => {
    const orderText = cancelOrderIdList.length > 1 ? "orders" : "order";
    if (cancelOrderIdList.length === 0) return;
    return (
      <button
        className="muted font-base cancel-order-btn"
        disabled={isCancelMultipleOrderProcessing}
        type="button"
        onClick={onMultipleCancelClick}
      >
        Cancel {cancelOrderIdList.length} {orderText}
      </button>
    );
  };
  const getListSection = () => {
    return (
      <Box width={"100%"} display={"flex"} flexDirection={"column"} flex={"1"}>
        <div className="Exchange-list-tab-container ">
          <ListTab
            options={LIST_SECTIONS}
            optionLabels={LIST_SECTIONS_LABELS}
            option={listSection}
            onChange={(section) => setListSection(section)}
          />

          {/* <div className="align-right Exchange-should-show-position-lines disda">
            {renderCancelOrderButton()}

            <SwitchesSize
              isChecked={savedShouldShowPositionLines}
              setIsChecked={setSavedShouldShowPositionLines}
            ></SwitchesSize>
            <span>Chart positions</span>
          </div> */}
        </div>
        <div className="list_section">
          {listSection === "Positions" && (
            <PositionsList
              positionsDataIsLoading={positionsDataIsLoading}
              pendingPositions={pendingPositions}
              setPendingPositions={setPendingPositions}
              setListSection={setListSection}
              setIsWaitingForPluginApproval={setIsWaitingForPluginApproval}
              setIsWaitingForPositionRouterApproval={
                setIsWaitingForPositionRouterApproval
              }
              approveOrderBook={approveOrderBook}
              approvePositionRouter={approvePositionRouter}
              isPluginApproving={isPluginApproving}
              isPositionRouterApproving={isPositionRouterApproving}
              isWaitingForPluginApproval={isWaitingForPluginApproval}
              isWaitingForPositionRouterApproval={
                isWaitingForPositionRouterApproval
              }
              orderBookApproved={orderBookApproved}
              positionRouterApproved={positionRouterApproved}
              positions={positions}
              positionsMap={positionsMap}
              infoTokens={infoTokens}
              active={connected}
              account={address}
              library={provider}
              pendingTxns={pendingTxns}
              setPendingTxns={setPendingTxns}
              flagOrdersEnabled={flagOrdersEnabled}
              savedIsPnlInLeverage={savedIsPnlInLeverage}
              chainId={chainID}
              nativeTokenAddress={nativeTokenAddress}
              setMarket={setMarket}
              orders={orders}
              showPnlAfterFees={savedShowPnlAfterFees}
              minExecutionFee={minExecutionFee}
              minExecutionFeeUSD={minExecutionFeeUSD}
              minExecutionFeeErrorMessage={minExecutionFeeErrorMessage}
            />
          )}
          {listSection === "Orders" && (
            <OrdersList
              plpName={plpName}
              account={address}
              active={connected}
              library={provider}
              pendingTxns={pendingTxns}
              setPendingTxns={setPendingTxns}
              infoTokens={infoTokens}
              positionsMap={positionsMap}
              chainId={chainID}
              orders={orders}
              totalTokenWeights={totalTokenWeights}
              usdxSupply={usdxSupply}
              savedShouldDisableOrderValidation={
                savedShouldDisableOrderValidation
              }
              cancelOrderIdList={cancelOrderIdList}
              setCancelOrderIdList={setCancelOrderIdList}
            />
          )}
          {listSection === "Trades" && (
            <TradeHistory
              account={address}
              forSingleAccount={true}
              infoTokens={infoTokens}
              getTokenInfo={getTokenInfo}
              chainID={chainID}
              nativeTokenAddress={nativeTokenAddress}
            />
          )}
        </div>
      </Box>
    );
  };

  const onSelectWalletToken = (token) => {
    setFromTokenAddress(swapOption, token.address);
  };

  const renderChart = () => {
    return (
      <ExchangeTVChart
        plpName={plpName}
        fromTokenAddress={fromTokenAddress}
        toTokenAddress={toTokenAddress}
        tokenDecimals={tokenDecimals}
        infoTokens={infoTokens}
        swapOption={swapOption}
        chainId={chainID}
        positions={positions}
        savedShouldShowPositionLines={savedShouldShowPositionLines}
        orders={orders}
        setToTokenAddress={setToTokenAddress}
        setFromTokenAddress={setFromTokenAddress}
        currInfoTokens={currInfoTokens}
      />
    );
  };

  return (
    <Box className="trade-view">
      <Box className="" height={"100%"}>
        <div className="Exchange ">
          {/* {showBanner && <ExchangeBanner hideBanner={hideBanner} />} */}
          <div className="Exchange-content">
            <div className="Exchange-left">
              {renderChart()}
              <div className="Exchange-lists large">{getListSection()}</div>
            </div>
            <div className="Exchange-right">
              <SwapBox
                plpName={plpName}
                pendingPositions={pendingPositions}
                setPendingPositions={setPendingPositions}
                setIsWaitingForPluginApproval={setIsWaitingForPluginApproval}
                setIsWaitingForPositionRouterApproval={
                  setIsWaitingForPositionRouterApproval
                }
                approveOrderBook={approveOrderBook}
                approvePositionRouter={approvePositionRouter}
                isPluginApproving={isPluginApproving}
                isPositionRouterApproving={isPositionRouterApproving}
                isWaitingForPluginApproval={isWaitingForPluginApproval}
                isWaitingForPositionRouterApproval={
                  isWaitingForPositionRouterApproval
                }
                orderBookApproved={orderBookApproved}
                positionRouterApproved={positionRouterApproved}
                orders={orders}
                flagOrdersEnabled={flagOrdersEnabled}
                chainId={chainID}
                infoTokens={infoTokens}
                currInfoTokens={currInfoTokens}
                positionsMap={positionsMap}
                fromTokenAddress={fromTokenAddress}
                setFromTokenAddress={setFromTokenAddress}
                toTokenAddress={toTokenAddress}
                setToTokenAddress={setToTokenAddress}
                swapOption={swapOption}
                setSwapOption={setSwapOption}
                pendingTxns={pendingTxns}
                setPendingTxns={setPendingTxns}
                tokenSelection={tokenSelection}
                setTokenSelection={setTokenSelection}
                isConfirming={isConfirming}
                setIsConfirming={setIsConfirming}
                isPendingConfirmation={isPendingConfirmation}
                setIsPendingConfirmation={setIsPendingConfirmation}
                savedIsPnlInLeverage={savedIsPnlInLeverage}
                setSavedIsPnlInLeverage={setSavedIsPnlInLeverage}
                nativeTokenAddress={nativeTokenAddress}
                savedSlippageAmount={savedSlippageAmount}
                totalTokenWeights={totalTokenWeights}
                usdxSupply={usdxSupply}
                savedShouldDisableOrderValidation={
                  savedShouldDisableOrderValidation
                }
                minExecutionFee={minExecutionFee}
                minExecutionFeeUSD={minExecutionFeeUSD}
                minExecutionFeeErrorMessage={minExecutionFeeErrorMessage}
                openSettings={openSettings}
              />
              <div className="Exchange-wallet-tokens">
                <div className="Exchange-wallet-tokens-content">
                  <ExchangeWalletTokens
                    tokens={tokens}
                    infoTokens={infoTokens}
                    onSelectToken={onSelectWalletToken}
                  />
                </div>
              </div>
            </div>
            <div className="Exchange-lists small">{getListSection()}</div>
          </div>
        </div>
      </Box>
    </Box>
  );
});
