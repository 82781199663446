import { configureChains, createConfig } from "wagmi";
import { zkSyncTestnet, zkSync, arbitrum, arbitrumGoerli, mantleTestnet, mantle } from "wagmi/chains";
import "@rainbow-me/rainbowkit/styles.css";
import {
  connectorsForWallets,
} from "@rainbow-me/rainbowkit";
import {
  braveWallet,
  coinbaseWallet,
  injectedWallet,
  metaMaskWallet,
  okxWallet,
  rabbyWallet,
  rainbowWallet,
  walletConnectWallet,
} from "@rainbow-me/rainbowkit/wallets";
import { jsonRpcProvider } from "wagmi/providers/jsonRpc";
const chains = [
  {
    ...zkSync,
    rpcUrls: {
      default: { http: ["https://mainnet.era.zksync.io"] },
      public: { http: ["https://mainnet.era.zksync.io"] },
    },
  },
  {
    ...mantle,
    rpcUrls: {
      default: { http: ["https://mantle.publicnode.com"] },
      public: { http: ["https://mantle.publicnode.com"] },
    },
  },
  {
    ...arbitrum,
    rpcUrls: {
      default: { http: ["https://arb1.arbitrum.io/rpc"] },
      public: { http: ["https://arb1.arbitrum.io/rpc"] },
    },
  },
  // {
  //   ...mantleTestnet,
  //   rpcUrls: {
  //     default: { http: ["https://rpc.testnet.mantle.xyz"] },
  //     public: { http: ["https://rpc.testnet.mantle.xyz"] },
  //   },
  // },
  // {
  //   ...arbitrumGoerli,
  //   rpcUrls: {
  //     default: { http: ["https://endpoints.omniatech.io/v1/arbitrum/goerli/public"] },
  //     public: { http: ["https://endpoints.omniatech.io/v1/arbitrum/goerli/public"] },
  //   },
  // },
  // {
  //   ...zkSyncTestnet,
  //   rpcUrls: {
  //     default: { http: ["https://testnet.era.zksync.dev"] },
  //     public: { http: ["https://testnet.era.zksync.dev"] },
  //   },
  // },
];

const projectId = "e55dd0a6717a3f7490b3674e00d8deed";

const { publicClient } = configureChains(
  chains,
  [
    jsonRpcProvider({
      rpc: (chain) => ({ http: chain.rpcUrls.default.http[0] }),
    }),
  ],
  { batch: { multicall: true } }
);

const needsInjectedWalletFallback =
  typeof window !== "undefined" &&
  window.ethereum &&
  !window.ethereum.isMetaMask &&
  !window.ethereum.isCoinbaseWallet;

const walletConnectProjectId = projectId;

const connectors = connectorsForWallets([
  {
    groupName: "Pinnako",
    wallets: [
      metaMaskWallet({
        projectId: walletConnectProjectId,
        chains,
        shimDisconnect: true,
      }),
      braveWallet({ chains, shimDisconnect: true }),
      rainbowWallet({ projectId: walletConnectProjectId, chains }),
      walletConnectWallet({
        projectId: walletConnectProjectId,
        chains,
        options: {
          projectId: walletConnectProjectId,
          showQrModal: false,
          qrModalOptions: {
            tokenImages: "",
            chainImages: "",
            explorerRecommendedWalletIds: [
              "0b415a746fb9ee99cce155c2ceca0c6f6061b1dbca2d722b3ba16381d0562150",
              "1aedbcfc1f31aade56ca34c38b0a1607b41cccfa3de93c946ef3b4ba2dfab11c",
            ],
          },
        },
        shimDisconnect: true,
      }),
      coinbaseWallet({ appName: "Pinnako", chains }),
      rabbyWallet({ chains, shimDisconnect: true }),
      okxWallet({ projectId: walletConnectProjectId, chains }),
      ...(needsInjectedWalletFallback
        ? [injectedWallet({ chains, shimDisconnect: true })]
        : []),
    ],
  },
]);

const wagmiConfig = createConfig({
  autoConnect: true,
  connectors,
  publicClient,
});


export { chains, wagmiConfig };