import { formatAmount2 } from "src/helpers/Helpers";
import { useBasicInfo } from "src/hooks/useQueryPID";
import { ThemedText } from "src/theme/text";
import ProgressBar from "../ProgressBar";

type ProgressPros = {
  veTokenBalance?: any;
};

export default function VePIKOProgress({ veTokenBalance }: ProgressPros) {
  const baseInfo = useBasicInfo();

  return (
    <>
      <ProgressBar
        value={
          baseInfo?.rank == 1
            ? 100
            : baseInfo?.rank == 2
            ? 100
            : baseInfo?.rank == 3
            ? 100
            : baseInfo?.rank == 4
            ? 100
            : baseInfo?.rank == 5
            ? 10000 - Number(veTokenBalance) < 0
              ? 100
              : ((Number(veTokenBalance) - 0) / (10000 - 0)) * 100
            : 30000 - Number(veTokenBalance) < 0
            ? 100
            : ((Number(veTokenBalance) - 10000) / (30000 - 10000)) * 100
        }
      />
      <ThemedText.Text5 fontSize={12} mt="12px">
        {baseInfo?.rank == 1 ? (
          <div className="color16" style={{ marginTop: "-8px" }}>
            +0 vetoken to Level D
          </div>
        ) : baseInfo?.rank == 2 ? (
          <div className="color16" style={{ marginTop: "-8px" }}>
            +0 vetoken to Level C
          </div>
        ) : baseInfo?.rank == 3 ? (
          <div className="color16" style={{ marginTop: "-8px" }}>
            +0 vetoken to Level B
          </div>
        ) : baseInfo?.rank == 4 ? (
          <div className="color16" style={{ marginTop: "-8px" }}>
            +0 vetoken to Level A
          </div>
        ) : baseInfo?.rank == 5 ? (
          <div className="color16" style={{ marginTop: "-8px" }}>
            +
            {formatAmount2(
              10000 - Number(veTokenBalance) < 0
                ? 0
                : 10000 - Number(veTokenBalance),
              0,
              true
            )}{" "}
            vetoken to Level S
          </div>
        ) : (
          <div className="color16" style={{ marginTop: "-8px" }}>
            +
            {formatAmount2(
              30000 - Number(veTokenBalance) < 0
                ? 0
                : 30000 - Number(veTokenBalance),
              0,
              true
            )}{" "}
            vetoken to Level SS
          </div>
        )}
      </ThemedText.Text5>
    </>
  );
}
