import React, { useState } from "react";
import { Menu } from "@headlessui/react";
import {
  ReactComponent as FaChevronDown
} from "src/assets/images/tedetxia.svg";
import cx from "classnames";
import "./ChartTokenSelector.css";
import "./chartToken.scss";
import { getTokens, getWhitelistedTokens } from "../../configs/Tokens";
import { LONG, SHORT, SWAP, USD_DECIMALS, formatAmount, formatAmount2 } from "../../helpers/Helpers";
import { useSelector } from "react-redux";
import Row from "../Row";
import { ReactComponent as SearchIcon } from '../../assets/images/search.svg'
import { useAppSelector } from "src/hooks";
import NoDataItem, { DefaultNoData } from "../NoData";
export default function ChartTokenSelector(props) {
  const { infoTokens, selectedToken, onSelectToken, swapOption, plpName, fromTokenAddress } = props;

  const [searchKeyword, setSearchKeyword] = useState("");
  const isLong = swapOption === LONG;
  const isShort = swapOption === SHORT;
  const isSwap = swapOption === SWAP;
  const allTokens = useSelector((state) => {
    return state.app.allToken || [];
  });
  const [curr, setCurr] = useState(0);
  let arr = Object.values(infoTokens);
  let options = arr; //allTokens
  const whitelistedTokens = arr;//allTokens
  // const indexTokens = whitelistedTokens.filter((token) => !token.isStable && !token.isWrapped);
  const indexTokens = whitelistedTokens.filter((token) => token.isLongable);
  const shortableTokens = indexTokens.filter((token) => token.isShortable);

  if (isLong) {
    options = indexTokens;
  }
  if (isShort) {
    options = shortableTokens;
  }

  const price_gns_arr = useAppSelector((state) => {
    return state.http.price_gns || [];
  });
  const price_gns_obj = price_gns_arr.reduce((pre, curr) => {
    pre[curr.symbol.toLocaleUpperCase()] = curr;
    return pre;
  }, {});

  const onSelect = async (token) => {
    onSelectToken(token);
  };

  var value = selectedToken;

  const onSearchKeywordChange = (e) => {
    setSearchKeyword(e.target.value);
  };
  const filteredTokens = options.filter((item) => {
    return (
      item.name.toLowerCase().indexOf(searchKeyword.toLowerCase()) > -1 ||
      item.symbol.toLowerCase().indexOf(searchKeyword.toLowerCase()) > -1
    );
  });
  const _handleKeyDown = (e) => {
    if (e.key === "Enter" && filteredTokens.length > 0) {
      onSelectToken(filteredTokens[0]);
    }
  };

  return (
    <Menu>
      <Menu.Button as="div" disabled={isSwap}>
        <button className={cx("App-cta App_sel transparent chart-token-selector", { "default-cursor": isSwap })}>
          <span className="chart-token-selector--current"> <img src={value.imageUrl} width={'30px'} />{value.symbol} <em>/</em> <span> USD</span></span>
          {!isSwap && <FaChevronDown color="rgba(238, 242, 249, 0.5);" />}
        </button>
      </Menu.Button>
      <div className="chart-token-menu">
        <Menu.Items as="div" className="tokenInfo">
          <Row className="search">
            <SearchIcon />
            <input className="flex-1 search_all "
              type="text"
              placeholder="Search Token"
              value={searchKeyword}
              onChange={(e) => onSearchKeywordChange(e)}
              onKeyDown={_handleKeyDown}
            />
          </Row>
          <Row gap='4px' className="typeTab b-border font-12 font-weight-5">
            <div className={`tabItem pointer ${curr == 0 ? "tabItemActive color2" : "color9"}`} onClick={() => setCurr(0)}>All({filteredTokens.length})</div>
            <div className={`tabItem pointer ${curr == 1 ? "tabItemActive color2" : "color9"}`} onClick={() => setCurr(1)} >Crypto({filteredTokens.length})</div>
            <div className={`tabItem pointer ${curr == 2 ? "tabItemActive color2" : "color9"}`} onClick={() => setCurr(2)} >Forex(0)</div>
            <div className={`tabItem pointer ${curr == 3 ? "tabItemActive color2" : "color9"}`} onClick={() => setCurr(3)}>US Stocks(0)</div>
          </Row>
          <div className="font-weight-6">
            <Row className="token_th b-border font-12 color9">
              <div className="pair">Pairs</div>
              <div className="price flex-1  text-right">Price</div>
              <div className="change text-right">Change</div>
            </Row>
            {curr == 0 || curr == 1 ?
              <div className="tokenList">
                {filteredTokens.map((option, index) => (
                  <Menu.Item key={index} >
                    {/* {console.log("option", option)} */}
                    <Row className="tokenItem font-14 pointer"
                      onClick={() => {
                        onSelect(option);
                      }}>
                      <Row className="pair">
                        <img src={option.imageUrl} title={option.symbol} height={'20px'} />
                        <div className="ml-6 token-label font-weight-b">
                          {option.symbol}<span className="color9"><span className="font-weight-4"> /</span> USD</span>
                        </div>
                      </Row>
                      {/* <div className="price flex-1  text-right">{option.price}</div> */}
                      <div className="price flex-1  text-right">{option.maxPrice && formatAmount(option.maxPrice, USD_DECIMALS, option.tokenDecimals, true)}</div>
                      <div className="change text-right" style={{ color: price_gns_obj[option.symbol]?.priceChangePercent > 0 ? "#0AD5BD" : "#FA5341" }}>
                        {price_gns_obj[option.symbol]?.priceChangePercent > 0 && <span style={{ color: "#0AD5BD" }}>+</span>}{formatAmount2(price_gns_obj[option.symbol]?.priceChangePercent, 2, true)}%</div>
                    </Row>
                  </Menu.Item>
                ))}
              </div>
              : <div className="font-12"><DefaultNoData title="No trading pairs" /></div>}
          </div>
        </Menu.Items>
      </div >
    </Menu >
  );
}
