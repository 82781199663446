import { Box, Link, MenuItem, styled } from "@mui/material";
import { ReactComponent as ArrowDropDownIcon } from "src/assets/images/downicon.svg";
import { ReactComponent as Discord } from "src/assets/images/links/discord.svg";
import { ReactComponent as Telegram } from "src/assets/images/links/telegram.svg";
import { ReactComponent as Twitter } from "src/assets/images/links/twitter.svg";
import { ReactComponent as Medium } from "src/assets/images/links/medium.svg";
import { ReactComponent as Github } from "src/assets/images/links/github.svg";
import { ThemedText } from "src/theme/text";
import { Trans } from "@lingui/macro";
import { DOC_LINK, DOC_LINK_arr } from "src/constants/misc";
import { MenuBox } from "./ChainBox";
import React, { useEffect, useState } from "react";
import { ChainBtn } from "../Button/ChainBtn";
import { NavLink, useLocation } from "react-router-dom";

const LinkBox = styled(Link)`
  .muilink-underlinehover:hover {
    text-decoration: none;
  }
`;

export const MenuContainer = styled(MenuBox)`
  .MuiMenuItem-root {
    &:last-child {
      &:hover {
        cursor: auto;
        background: transparent;
      }
    }
    .linkIcon {
      &:hover {
        path {
          fill: #14d1b2;
        }
      }
    }
  }
`;

export default function MoreItem() {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const location = useLocation();
  const [pathname, setPathname] = useState(location.pathname);
  useEffect(() => {
    setPathname(location.pathname);
  }, [location]);

  return (
    <>
      <ChainBtn
        id="basic-button"
        className="hoverbtns"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
      >
        <Box display="flex" alignItems="center">
          <ThemedText.Text4Medium fontSize={16} mr="8px">
            <Trans>
              <span style={{ textTransform: "initial" }}>More</span>
            </Trans>
          </ThemedText.Text4Medium>
          <ArrowDropDownIcon />
        </Box>
      </ChainBtn>
      <MenuContainer
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <MenuItem>
          <LinkBox component={NavLink} to={`/DAO`} style={{ width: "100%" }}>
            <ThemedText.Text1Medium fontSize={14}>DAO</ThemedText.Text1Medium>
          </LinkBox>
        </MenuItem>
        {DOC_LINK_arr.map((item, index) => {
          return (
            <MenuItem key={index}>
              <a
                href={item.link}
                target="_blank"
                rel="noreferrer"
                style={{
                  display: "inline-block",
                  width: "100%",
                  height: "100%",
                }}
              >
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  height="100%"
                >
                  <ThemedText.Text1Medium fontSize={14}>
                    {item.name}
                  </ThemedText.Text1Medium>
                  <img src={item.icon} />
                </Box>
              </a>
            </MenuItem>
          );
        })}
        <MenuItem>
          <Box
            display="grid"
            gridTemplateColumns="repeat(5, 24px)"
            alignItems="center"
            style={{ gridGap: "8px" }}
          >
            <a href={DOC_LINK.discord} target="_blank" rel="noreferrer">
              <Discord className="linkIcon" />
            </a>
            <a href={DOC_LINK.telegram} target="_blank" rel="noreferrer">
              <Telegram className="linkIcon" />
            </a>
            <a href={DOC_LINK.twitter} target="_blank" rel="noreferrer">
              <Twitter className="linkIcon" />
            </a>
            <a href={DOC_LINK.medium} target="_blank" rel="noreferrer">
              <Medium className="linkIcon" />
            </a>
            <a href={DOC_LINK.github} target="_blank" rel="noreferrer">
              <Github className="linkIcon" />
            </a>
          </Box>
        </MenuItem>
      </MenuContainer>
    </>
  );
}
