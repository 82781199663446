import styled from "styled-components";

const DotBox = styled.div<{
  bgColor?: string;
  height?: string;
}>`
  .dot {
    width: ${({ height }) => (height ? height : "8px")};
    height: ${({ height }) => (height ? height : "8px")};
    background: ${({ bgColor }) => (bgColor ? bgColor : "#147709")};
  }
`;
type dataPros = {
  bgColor?: string;
  height?: string;
};

export default function Dots({ bgColor, height }: dataPros) {
  return (
    <DotBox bgColor={bgColor} height={height}>
      <div className="dot"></div>
    </DotBox>
  );
}
