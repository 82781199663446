import { useCallback, useState } from "react";
import { batch, useDispatch } from "react-redux";
import { addresses } from "src/configs/constants";
import { clearPendingTxn, fetchPendingTxns } from "src/slices/PendingTxnsSlice";
import { useWeb3Context } from ".";
import { ethers } from "ethers";
import { abi as InfoHelperABI } from "../abis/InfoHelper.json";
import { abi as ShareholdersRebateABI } from "../abis/ShareholdersRebate.json";
import { abi as RewardRouterABI } from "../abis/RewardRouter.json";
import { abi as BurnToScoreABI } from "../abis/BurnToScore.json";
import { abi as PIDABI } from "../abis/PID.json";
import { loadAppDetails } from "../slices/AppSlice";
import { toastSuccess } from "src/helpers/toastHelpers";
import useTokenApprove from "./useTokenApprove";
import { handlerError } from "src/helpers/errorHelpers";
import { useUpDataBlockNumber } from "src/lib/hooks/useUpDataBlockNumber";
import { useEthersSigner } from "./web3Context";

export default function usePid() {
  const dispatch = useDispatch();
  const { chainID, address, provider } = useWeb3Context();
  const signer = useEthersSigner();

  const updateBlockNumber = useUpDataBlockNumber();

  const { approveToken, getERC20Approved } = useTokenApprove();

  const [isApproved_esPIKO, setApproved_esPIKO] = useState(false);
  const initApproved = useCallback(async () => {
    if (chainID && provider && address) {
      getERC20Approved(
        addresses[chainID].esPIKO,
        addresses[chainID].BurnToScore
      ).then(setApproved_esPIKO);
    }
  }, [chainID, provider, address, getERC20Approved]);

  const DoApprove_esPIKO = useCallback(async () => {
    if (chainID && provider && address) {
      try {
        await approveToken(
          addresses[chainID].esPIKO,
          addresses[chainID].BurnToScore
        );
      } catch (e) {
        console.log(e);
      } finally {
        initApproved().then();
      }
    }
  }, [chainID, provider, address, approveToken, initApproved]);

  const DoBoost = useCallback(
    async (amount) => {
      if (chainID && provider && address && amount) {
        let tx: any;
        try {
          const BurnToScoreContract = new ethers.Contract(
            addresses[chainID].BurnToScore,
            BurnToScoreABI,
            signer
          );
          tx = await BurnToScoreContract.burnToken(amount);
          dispatch(
            fetchPendingTxns({ txnHash: tx.hash, text: "boost", type: "boost" })
          );
          const data = await tx.wait();
          updateBlockNumber(data);
          return tx;
        } catch (e: unknown) {
          handlerError(e);
        } finally {
          if (tx) {
            batch(() => {
              dispatch(
                loadAppDetails({ networkID: chainID, provider, address })
              );
              dispatch(clearPendingTxn(tx.hash));
              toastSuccess("Boost Success!", tx.hash);
            });
          }
        }
      }
    },
    [chainID, provider, address, signer, dispatch, updateBlockNumber]
  );

  const changeName = useCallback(
    async (name) => {
      if (chainID && provider && address && name) {
        let tx: any;
        try {
          const PIDContract = new ethers.Contract(
            addresses[chainID].PID,
            PIDABI,
            signer
          );
          tx = await PIDContract.setNickName(name);
          dispatch(
            fetchPendingTxns({ txnHash: tx.hash, text: "edit", type: "edit" })
          );
          const data = await tx.wait();
          updateBlockNumber(data);
          return tx;
        } catch (e: unknown) {
          handlerError(e);
        } finally {
          if (tx) {
            batch(() => {
              dispatch(
                loadAppDetails({ networkID: chainID, provider, address })
              );
              dispatch(clearPendingTxn(tx.hash));
              toastSuccess("Edit Success!", tx.hash);
            });
          }
        }
      }
    },
    [chainID, provider, address, signer, dispatch, updateBlockNumber]
  );

  const mint = useCallback(
    async (inviteCode) => {
      if (chainID && provider && address && inviteCode) {
        let tx: any;
        try {
          const PIDContract = new ethers.Contract(
            addresses[chainID].PID,
            PIDABI,
            signer
          );
          tx = await PIDContract.safeMint(inviteCode);
          dispatch(
            fetchPendingTxns({ txnHash: tx.hash, text: "mint", type: "mint" })
          );
          const data = await tx.wait();
          updateBlockNumber(data);
          return tx;
        } catch (e: unknown) {
          handlerError(e);
        } finally {
          if (tx) {
            batch(() => {
              dispatch(
                loadAppDetails({ networkID: chainID, provider, address })
              );
              dispatch(clearPendingTxn(tx.hash));
              toastSuccess("Mint Success!", tx.hash);
            });
          }
        }
      }
    },
    [chainID, provider, address, signer, dispatch, updateBlockNumber]
  );

  const mintWithName = useCallback(
    async (inviteCode, name) => {
      if (chainID && provider && address && name) {
        let tx: any;
        try {
          const PIDContract = new ethers.Contract(
            addresses[chainID].PID,
            PIDABI,
            signer
          );
          const estimation = await PIDContract.estimateGas.mintWithName(
            inviteCode,
            name
          );
          tx = await PIDContract.mintWithName(inviteCode, name, {
            gasLimit: estimation.mul(150).div(100),
          });
          dispatch(
            fetchPendingTxns({ txnHash: tx.hash, text: "mint", type: "mint" })
          );
          const data = await tx.wait();
          updateBlockNumber(data);
          return tx;
        } catch (e: unknown) {
          handlerError(e);
        } finally {
          if (tx) {
            batch(() => {
              dispatch(
                loadAppDetails({ networkID: chainID, provider, address })
              );
              dispatch(clearPendingTxn(tx.hash));
              toastSuccess("Mint Success!", tx.hash);
            });
          }
        }
      }
    },
    [chainID, provider, address, signer, dispatch, updateBlockNumber]
  );

  const claimRewards = useCallback(
    async (round) => {
      if (chainID && provider && address) {
        let tx: any;
        try {
          const ShareholdersRebateContract = new ethers.Contract(
            addresses[chainID].ShareholdersRebate,
            ShareholdersRebateABI,
            signer
          );
          tx = await ShareholdersRebateContract.claim(round);
          dispatch(
            fetchPendingTxns({ txnHash: tx.hash, text: "claim", type: "claim" })
          );
          const data = await tx.wait();
          updateBlockNumber(data);
          return tx;
        } catch (e: unknown) {
          handlerError(e);
        } finally {
          if (tx) {
            batch(() => {
              dispatch(
                loadAppDetails({ networkID: chainID, provider, address })
              );
              dispatch(clearPendingTxn(tx.hash));
              toastSuccess("Claim Success!", tx.hash);
            });
          }
        }
      }
    },
    [chainID, provider, address, signer, dispatch]
  );

  const claimPIDFee = useCallback(async () => {
    if (chainID && provider && address) {
      let tx: any;
      try {
        const RewardRouterContract = new ethers.Contract(
          addresses[chainID].RewardRouter,
          RewardRouterABI,
          signer
        );
        tx = await RewardRouterContract.claimPIDFee();
        dispatch(
          fetchPendingTxns({
            txnHash: tx.hash,
            text: "claim",
            type: "claim_pidFee",
          })
        );
        const data = await tx.wait();
        updateBlockNumber(data);
        return tx;
      } catch (e: unknown) {
        handlerError(e);
      } finally {
        if (tx) {
          batch(() => {
            dispatch(loadAppDetails({ networkID: chainID, provider, address }));
            dispatch(clearPendingTxn(tx.hash));
            toastSuccess("Claim Success!", tx.hash);
          });
        }
      }
    }
  }, [chainID, provider, address, signer, dispatch, updateBlockNumber]);

  const refreshCompleteness = useCallback(async () => {
    if (chainID && provider && address) {
      let tx: any;
      try {
        const InfoHelperContract = new ethers.Contract(
          addresses[chainID].InfoHelper,
          InfoHelperABI,
          signer
        );
        const needUpdate = Number(
          await InfoHelperContract.needUpdate(
            addresses[chainID].ShareholdersRebate,
            address
          )
        );
        const isNeedUpdate = needUpdate > 0 ? true : false;
        // console.log("isNeedUpdate", isNeedUpdate, needUpdate);
        const ShareholdersRebateContract = new ethers.Contract(
          addresses[chainID].ShareholdersRebate,
          ShareholdersRebateABI,
          signer
        );
        if (isNeedUpdate) {
          if (needUpdate == 1) {
            tx = await ShareholdersRebateContract.updateCompleteness(
              address,
              0
            );
          }
          if (needUpdate == 2) {
            tx = await ShareholdersRebateContract.updateCompleteness(
              address,
              1
            );
          }

          dispatch(
            fetchPendingTxns({
              txnHash: tx.hash,
              text: "refresh",
              type: "refresh",
            })
          );
          const data = await tx.wait();
          updateBlockNumber(data);
          return tx;
        }
      } catch (e: unknown) {
        handlerError(e);
      } finally {
        if (tx) {
          batch(() => {
            dispatch(loadAppDetails({ networkID: chainID, provider, address }));
            dispatch(clearPendingTxn(tx.hash));
            toastSuccess("refresh Success!", tx.hash);
          });
        }
      }
    }
  }, [chainID, provider, address, signer, dispatch, updateBlockNumber]);

  return {
    claimRewards,
    mint,
    changeName,
    refreshCompleteness,
    claimPIDFee,
    mintWithName,
    isApproved_esPIKO,
    DoApprove_esPIKO,
    DoBoost,
  };
}
