import styled from "styled-components";
import DialogBox from "src/components/DialogBox";
import { CancelBtn } from "src/components/Button/CancelBtn";
import { Box, useMediaQuery } from "@mui/material";
import { ConfirmBtn } from "src/components/Button/ConfirmBtn";
import html2canvas from "html2canvas";
import QRCode from "qrcode.react";
import { useRef, useState } from "react";
import { Klines } from "src/components/Exchange/ConfirmStyles";
// import shareBG from "src/assets/images/shareBG.svg";
import shareBG from "src/assets/images/shareBG3.png";
import Row, { RowBetween } from "src/components/Row";
import { ReactComponent as Avatar } from "src/assets/images/avatar.svg";
import { ReactComponent as Earth } from "src/assets/images/earth.svg";

import Column from "src/components/Column";
import { useBasicInfo } from "src/hooks/useQueryPID";
import { useWeb3Context } from "src/hooks";
import { shorten } from "src/helpers";
import { ThemedText } from "src/theme/text";
import cx from "classnames";
import { USD_DECIMALS, bigNumberify, formatAmount } from "src/helpers/Helpers";
import { SHARE_URL } from "src/constants/misc";
const ShareDialogBox = styled(Box)`
  overflow: hidden;
  .sharesbox {
    /* background: url(${shareBG}) no-repeat; */
    /* background-size: 100%; */
    min-height: 400px;
    padding-left: 24px;
    padding-top: 20px;
    position: relative;
    .qrcode {
      position: absolute;
      bottom: -30px;
      left: 24px;
      width: 74px;
      height: 74px;
      border-radius: 10px;
      border: 3px solid #14d1b2;
      overflow: hidden;
      z-index: 11;
    }
    .sharesbg {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 0;
    }
  }
  .t_borders {
    border: 1px dashed rgba(0, 0, 0, 0.16);
    padding: 8px 12px 8px 45px;
    text-align: right;
  }
  .referralcode {
    background: #fff;
    padding: 12px 20px;
    position: relative;
    z-index: 10;
  }
  .positives {
    color: #0ad5bd;
  }
  .negatives {
    color: #fa5542;
  }
  @media only screen and (max-width: 1080px) {
    flex-direction: column;
    .unlock {
      width: 100%;
    }
  }
`;
const PlsRowBetween = styled(RowBetween)`
  padding: 0 16px;
  margin-bottom: 16px;
  .pnls {
    padding: 6px 6px 6px 24px;
    background: rgba(16, 16, 16, 1);
    border-radius: 8px;
    width: 100%;
    .actscwe {
      font-family: "Gilroy-bold";
      font-size: 16px;
      border-radius: 8px;
      color: rgba(255, 255, 255, 0.54);
      border: 1px solid rgba(255, 255, 255, 0.16);
      padding: 10px 40px;
      width: auto;
      cursor: pointer;
      &.active {
        border: 1px solid rgba(20, 209, 178, 1);
        background: rgba(66, 232, 224, 0.2);
        color: rgba(20, 209, 178, 1);
      }
      &:hover {
        border: 1px solid rgba(20, 209, 178, 1);
        background: rgba(66, 232, 224, 0.2);
        color: rgba(20, 209, 178, 1);
      }
    }
  }
`;
type dialogPros = {
  open: boolean;
  handleClose?: any;
  bondAddr: string;
  position: any;
  showPnlAfterFees: any;
};
export default function ShareDialog({
  open,
  handleClose,
  position,
  showPnlAfterFees,
}: dialogPros) {
  const isSmallScreen = useMediaQuery("(max-width: 1080px)");
  const isVerySmallScreen = useMediaQuery("(max-width: 680px)");
  const exportRef = useRef();
  const baseInfo = useBasicInfo();
  const { address } = useWeb3Context();
  const downloadImage = (blob, fileName) => {
    const fakeLink = window.document.createElement("a");
    fakeLink.setAttribute("style", "display:none");
    fakeLink.download = fileName;

    fakeLink.href = blob;

    document.body.appendChild(fakeLink);
    fakeLink.click();
    document.body.removeChild(fakeLink);

    fakeLink.remove();
  };

  const [SharePnl, setSharePnl] = useState(true);

  const exportAsImage = async (el, imageFileName) => {
    const canvas = document.createElement("canvas");
    // 获取设备dpi
    const dpi = devicePixelRatio > 1 ? devicePixelRatio : 1;
    // 获取dom原宽高
    const width = parseInt(el.offsetWidth, 10);
    const height = parseInt(el.offsetHeight, 10);
    // 调整画布大小
    canvas.width = dpi * width;
    canvas.height = dpi * height;
    canvas.style.width = width + "px";
    canvas.style.height = height + "px";
    const ctx = canvas.getContext("2d");
    // 放大画布
    if (!ctx) return;
    ctx?.scale(dpi, dpi);

    const canvass = await html2canvas(el, {
      scale: 4,
      useCORS: true,
      allowTaint: true,
      x: 0,
    });
    const image = canvass.toDataURL();
    downloadImage(image, imageFileName);
  };
  const hasPositionProfit = position
    ? position[showPnlAfterFees ? "hasProfitAfterFees" : "hasProfit"]
    : undefined;
  const positionDelta = position
    ? position[showPnlAfterFees ? "pendingDeltaAfterFees" : "pendingDelta"] ||
      bigNumberify(0)
    : undefined;
  return (
    <DialogBox
      width="432px"
      title="Share"
      open={open}
      handleClose={handleClose}
    >
      <>
        <ShareDialogBox p={"21px 16px 16px"}>
          <Box ref={exportRef}>
            <Box className="sharesbox">
              <img className="sharesbg" src={shareBG} alt="" />
              <Box position={"relative"} zIndex={10}>
                <Row gap={"8px"}>
                  <Avatar width={30} height={30} />
                  <Column gap={"0"} lineHeight={1.1}>
                    <ThemedText.Text9Bold fontSize={16}>
                      {baseInfo?.nickName}
                    </ThemedText.Text9Bold>
                    <ThemedText.Text8Medium>
                      {shorten(address)}
                    </ThemedText.Text8Medium>
                  </Column>
                </Row>
                <Row mt={"28px"} gap={"8px"}>
                  <ThemedText.Text9Bold fontSize={20}>
                    {position?.indexTokenInfo.symbol}USD
                  </ThemedText.Text9Bold>
                  <Row
                    className={cx(" upliver", {
                      negative: !position?.isLong,
                      positive: position?.isLong,
                    })}
                    width={"auto"}
                    p={"4px"}
                  >
                    {position?.isLong ? "Long" : "Short"}{" "}
                    {formatAmount(position?.leverage, 4, 2, true)}x
                  </Row>
                </Row>
                <Column mt={"26px"}>
                  <ThemedText.Text8Medium fontSize={14}>
                    PnL
                  </ThemedText.Text8Medium>
                  <ThemedText.Text9Bold
                    fontSize={36}
                    className={cx({
                      positives: hasPositionProfit && positionDelta?.gt(0),
                      negatives: !hasPositionProfit && positionDelta?.gt(0),
                    })}
                  >
                    {SharePnl
                      ? position?.deltaStr
                      : position?.deltaPercentageStr}
                  </ThemedText.Text9Bold>
                </Column>
                <Column mt={"8px"}>
                  <ThemedText.Text8Medium fontSize={14}>
                    Entry Price
                  </ThemedText.Text8Medium>
                  <ThemedText.Text9Bold fontSize={24}>
                    $
                    {formatAmount(
                      position?.averagePrice,
                      USD_DECIMALS,
                      position?.indexTokenInfo.tokenDecimals,
                      true
                    )}
                  </ThemedText.Text9Bold>
                </Column>
                <Column mt={"8px"}>
                  <ThemedText.Text8Medium fontSize={14}>
                    Mark Price
                  </ThemedText.Text8Medium>
                  <ThemedText.Text9Bold fontSize={24}>
                    $
                    {formatAmount(
                      position?.markPrice,
                      USD_DECIMALS,
                      position?.indexTokenInfo.tokenDecimals,
                      true
                    )}
                  </ThemedText.Text9Bold>
                </Column>
              </Box>
              <Box className="qrcode">
                <QRCode
                  size={68}
                  value={SHARE_URL + baseInfo?.refCode}
                  renderAs="svg"
                />
              </Box>
            </Box>
            <RowBetween className="referralcode">
              <Column gap={"2px"}>
                <ThemedText.Text9Bold
                  style={{ color: "rgba(34, 104, 92, 1)" }}
                  fontSize={16}
                  pt={"10px"}
                >
                  Trade with ease!
                </ThemedText.Text9Bold>

                <Row
                  color={"#C4C4C4"}
                  alignContent={"center"}
                  gap={"8px"}
                  fontFamily={"Gilroy-bold"}
                >
                  <Earth />
                  Pinnako.io
                </Row>
              </Column>
              <Column className="t_borders">
                <ThemedText.Text8Medium fontSize={16}>
                  <Box color={"rgba(0, 0, 0, 0.25)"}>Referral Code</Box>
                </ThemedText.Text8Medium>
                <ThemedText.Text9Bold
                  fontSize={24}
                  style={{ color: "rgba(0, 0, 0, 0.87)" }}
                >
                  {baseInfo?.refCode}
                </ThemedText.Text9Bold>
              </Column>
            </RowBetween>
          </Box>
        </ShareDialogBox>
        <PlsRowBetween>
          <Box className="pnls">
            <RowBetween width={"auto"}>
              <ThemedText.Text9Bold
                style={{ color: "rgba(255, 255, 255, 0.54))" }}
                fontSize={14}
              >
                PnL
              </ThemedText.Text9Bold>
              <Row gap={"4px"} width={"auto"}>
                <div
                  onClick={() => setSharePnl(true)}
                  className={cx("actscwe", { active: SharePnl })}
                >
                  $
                </div>
                <div
                  onClick={() => setSharePnl(false)}
                  className={cx("actscwe", { active: !SharePnl })}
                >
                  %
                </div>
              </Row>
            </RowBetween>
          </Box>
        </PlsRowBetween>
        <Klines />
        <Box display="flex" p="16px">
          <Box mr="8px" className="flex-1">
            <ConfirmBtn
              onClick={() => exportAsImage(exportRef.current, "Pinnako")}
            >
              Save Image
            </ConfirmBtn>
          </Box>
          <Box width={isVerySmallScreen ? "112px" : "200px"}>
            <CancelBtn onClick={handleClose}>Cancel</CancelBtn>
          </Box>
        </Box>
      </>
    </DialogBox>
  );
}
