import { Trans } from "@lingui/macro";
import { Box, useMediaQuery } from "@mui/material";
import { ThemedText } from "src/theme/text";
import styled from "styled-components";
import { ReactComponent as Discount } from "src/assets/images/pid/discount.svg";
import { ReactComponent as Rebates } from "src/assets/images/pid/rebates.svg";
import { ReactComponent as ReferralCode } from "src/assets/images/pid/referralCode.svg";
import { ReactComponent as ReferralLink } from "src/assets/images/pid/referralLink.svg";
import PendingIcon from "src/assets/images/loading/pending.svg";
import { ReactComponent as USDC } from "src/assets/images/tokens/USDC.svg";
import { ReactComponent as EsPIKO } from "src/assets/images/esPIKO.svg";
import { CopyBtn } from "src/components/Button/CopyBtn";
import { ClaimBtn } from "./ClaimBtn";
import { useBasicInfo, useClaimableInfo } from "src/hooks/useQueryPID";
import { formatAmount, formatAmount2 } from "src/helpers/Helpers";
import copy from "copy-to-clipboard";
import { toastSuccess } from "src/helpers/toastHelpers";
import { shorten2 } from "src/helpers";
import { useWeb3Context } from "src/hooks";
import usePid from "src/hooks/usePid";
import { isPendingTxn, txnButtonText } from "src/slices/PendingTxnsSlice";
import { useSelector } from "react-redux";
import Dots from "src/components/Dots";
import { getPidGraphClient } from "src/Api/common";
import { useEffect, useState } from "react";
import { gql } from "@apollo/client";

const ReferralBox = styled(Box)`
  display: flex;
  align-items: stretch;
  margin-top: 52px;
  .referralItem {
    min-height: 60px;
    padding: 12px 20px 16px 20px;
    border: 1px solid ${({ theme }) => theme.bg4};
    border-radius: 8px;
  }
  .rebates {
    border: 1px solid rgba(20, 209, 178, 0.2);
    border-radius: 8px;
    padding-top: 28px;
    padding-left: 18px;
    position: relative;
    &:hover {
      border: 1px solid #14d1b2;
      .claim {
        button {
          background: #14d1b2;
          color: #000000;
        }
      }
    }
    .claim {
      position: absolute;
      bottom: 0;
      right: 0;
    }
  }
  @media only screen and (max-width: 1280px) {
    margin-top: 32px;
    flex-direction: column;
  }
`;

const TradeBox = styled(Box)`
  margin-top: 16px;
  display: grid;
  grid-gap: 8px;
  grid-template-columns: repeat(4, 1fr);
  padding: 26px 20px 24px 20px;
  border: 1px solid rgba(255, 255, 255, 0.16);
  border-radius: 8px;
  @media only screen and (max-width: 1280px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media only screen and (max-width: 680px) {
    grid-template-columns: repeat(1, 1fr);
  }
`;
export default function Referral() {
  const isSmallScreen = useMediaQuery("(max-width: 1280px)");
  const isVerySmallScreen = useMediaQuery("(max-width: 680px)");
  const baseInfo = useBasicInfo();
  const { chainID, address } = useWeb3Context();
  const claimableInfo = useClaimableInfo();
  const pendingTransactions = useSelector((state: any) => {
    return state.pendingTransactions;
  });
  const { claimPIDFee } = usePid();
  const handlerClaim = async () => {
    await claimPIDFee();
  };
  const [friends, setFriends] = useState<any>("");
  const [tradingVolume, setTradingVolume] = useState<any>("");
  const [LPstaked, setLPstaked] = useState<any>("");
  const [earned_USDC, setEarned_USDC] = useState<any>("");
  const [earned_esPIKO, setEarned_esPIKO] = useState<any>("");
  const query_str = gql(`{
  accounts
  (where:{address:"${address.toLowerCase()}"})
  {
    id
    address
    sons{
      id
    }
    totalSonVolume
    totalSonElp1Staked
    claimedRebate
    invetedReward
  }
}`);
  useEffect(() => {
    if (address) {
      getPidGraphClient(chainID)
        ?.query({ query: query_str })
        .then((res) => {
          const data = res.data.accounts;
          if (data.length > 0) {
            // console.log("data", data);
            if (data[0].sons.length > 0) setFriends(data[0].sons.length);
            setTradingVolume(data[0].totalSonVolume);
            setLPstaked(data[0].totalSonElp1Staked);
            setEarned_USDC(data[0].claimedRebate);
            setEarned_esPIKO(data[0].invetedReward);
          }
        })
        .catch(console.warn);
    }
  }, [chainID, address, query_str]);

  return (
    <>
      <ReferralBox className="referral-view">
        <Box width={isSmallScreen ? "100%" : "200px"}>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            className="referralItem"
          >
            <Box display="flex" alignItems="center">
              <Discount />
              <ThemedText.Text1Bold fontSize={18} ml="12px">
                <Trans>Discount</Trans>
              </ThemedText.Text1Bold>
            </Box>
            <ThemedText.Text2 fontSize={18}>
              {formatAmount2(Number(baseInfo?.feeDiscount) * 100, 2, true)}%
            </ThemedText.Text2>
          </Box>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            className="referralItem"
            mt="16px"
          >
            <Box display="flex" alignItems="center">
              <Rebates />
              <ThemedText.Text1Bold fontSize={18} ml="12px">
                <Trans>Rebates</Trans>
              </ThemedText.Text1Bold>
            </Box>
            <ThemedText.Text2 fontSize={18}>
              {formatAmount2(Number(baseInfo?.feeRebate) * 100, 2, true)}%
            </ThemedText.Text2>
          </Box>
        </Box>
        <Box
          width={isSmallScreen ? "100%" : "500px"}
          ml={isSmallScreen ? 0 : "12px"}
          mt={isSmallScreen ? "16px" : 0}
        >
          <Box
            display="flex"
            flexDirection={isVerySmallScreen ? "column" : "row"}
            justifyContent="space-between"
            alignItems={isVerySmallScreen ? "flex-start" : "center"}
            className="referralItem"
            gap="8px"
          >
            <Box display="flex" alignItems="center">
              <ReferralCode />
              <ThemedText.Text1Bold fontSize={18} ml="12px">
                <Trans>Referral Code</Trans>
              </ThemedText.Text1Bold>
            </Box>
            <Box display="flex" alignItems="center">
              <ThemedText.Text2 mr="16px">
                {baseInfo?.refCode ? baseInfo?.refCode : "--"}
              </ThemedText.Text2>
              <CopyBtn
                onClick={() => {
                  copy(baseInfo?.refCode), toastSuccess("copy success");
                }}
              >
                Copy
              </CopyBtn>
            </Box>
          </Box>
          <Box
            display="flex"
            flexDirection={isVerySmallScreen ? "column" : "row"}
            justifyContent="space-between"
            alignItems={isVerySmallScreen ? "flex-start" : "center"}
            className="referralItem"
            mt="16px"
            gap="8px"
          >
            <Box display="flex" alignItems="center">
              <ReferralLink />
              <ThemedText.Text1Bold fontSize={18} ml="12px">
                <Trans>Referral Link</Trans>
              </ThemedText.Text1Bold>
            </Box>
            <Box display="flex" alignItems="center">
              <ThemedText.Text2 mr="16px">
                {shorten2(
                  `https://app.pinnako.io/#/PID?ref=${baseInfo?.refCode}`,
                  chainID
                )}
              </ThemedText.Text2>
              <CopyBtn
                onClick={() => {
                  copy(`https://app.pinnako.io/#/PID?ref=${baseInfo?.refCode}`),
                    toastSuccess("copy success");
                }}
              >
                Copy
              </CopyBtn>
            </Box>
          </Box>
        </Box>
        <Box
          className="flex-1 rebates pointer"
          ml={isSmallScreen ? 0 : "16px"}
          mt={isSmallScreen ? "16px" : 0}
          pb={isSmallScreen ? "36px" : "auto"}
        >
          <ThemedText.Text4Bold>
            <Trans>Rebates</Trans>
          </ThemedText.Text4Bold>
          <Box display="flex" alignItems="center" className="" mt="20px">
            <USDC width={32} height={32} />
            <ThemedText.Text1Bold fontSize={18} ml="12px">
              {formatAmount2(claimableInfo?.rebate_fee, 2, true)}
            </ThemedText.Text1Bold>
            <ThemedText.Text1Medium ml="20px">
              <Trans>USDC</Trans>
            </ThemedText.Text1Medium>
          </Box>
          <div className="claim" onClick={handlerClaim}>
            <ClaimBtn
              disabled={isPendingTxn(pendingTransactions, "claim_pidFee")}
            >
              {isPendingTxn(pendingTransactions, "claim_pidFee") ? (
                <Box display="flex" justifyContent="center" alignItems="center">
                  <div className="">Pending</div>
                  <img src={PendingIcon} height={20} className="ml-12" />
                </Box>
              ) : (
                txnButtonText(pendingTransactions, "claim_pidFee", "Claim")
              )}
            </ClaimBtn>
          </div>
        </Box>
      </ReferralBox>
      <TradeBox>
        <div className="">
          <Box display="flex" alignItems="center" className="">
            <Dots bgColor="#14D1B2" />
            <ThemedText.Text4Bold ml="8px">
              <Trans>Friends</Trans>
            </ThemedText.Text4Bold>
          </Box>
          <ThemedText.Text1Bold fontSize={18} mt="8px">
            {formatAmount2(friends, 0, true)}
          </ThemedText.Text1Bold>
        </div>
        <div className="">
          <Box display="flex" alignItems="center" className="">
            <Dots bgColor="#14D1B2" />
            <ThemedText.Text4Bold ml="8px">
              <Trans>Their Trading Volume</Trans>
            </ThemedText.Text4Bold>
          </Box>
          <ThemedText.Text1Bold fontSize={18} mt="8px">
            ${formatAmount(tradingVolume, 30, 0, true)}
          </ThemedText.Text1Bold>
        </div>
        <div className="">
          <Box display="flex" alignItems="center" className="">
            <Dots bgColor="#14D1B2" />
            <ThemedText.Text4Bold ml="8px">
              <Trans>Their LP staked</Trans>
            </ThemedText.Text4Bold>
          </Box>
          <ThemedText.Text1Bold fontSize={18} mt="8px">
            ${formatAmount(LPstaked, 18, 0, true)}
          </ThemedText.Text1Bold>
        </div>
        <div className="">
          <Box display="flex" alignItems="center" className="">
            <Dots bgColor="#14D1B2" />
            <ThemedText.Text4Bold ml="8px">
              <Trans>Your Earned</Trans>
            </ThemedText.Text4Bold>
          </Box>
          <Box
            display="flex"
            alignItems="center"
            className=""
            mt="8px"
            gap="8px"
          >
            <USDC width={20} height={20} />
            <ThemedText.Text1Bold fontSize={18}>
              {formatAmount(earned_USDC, 6, 2, true)}
            </ThemedText.Text1Bold>
            <ThemedText.Text1Bold fontSize={18}>+</ThemedText.Text1Bold>
            <EsPIKO width={20} height={20} />
            <ThemedText.Text1Bold fontSize={18}>
              {formatAmount(earned_esPIKO, 18, 2, true)}
            </ThemedText.Text1Bold>
          </Box>
        </div>
      </TradeBox>
    </>
  );
}
