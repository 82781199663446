// import { Box } from "@mui/material";

import styled from "styled-components/macro";
import cx from "classnames";
import Row, { AutoRow } from "src/components/Row";
type dataPros = {
  options: string[];
  val?: number | string;
  option?: string;
  setOption?: (value: string) => void;
  optionLabels: any;
  onChange?: (value: string) => void;
};

const MyRow = styled(Row)`
  width: auto;
  @media (max-width: 700px) {
    width: 100%;
    justify-content: space-between;
    height: 4.8rem;
  }
`;

const TradeRow = styled(AutoRow)<{ active?: boolean }>`
  justify-content: flex-start;
  padding: 0 24px;
  gap: 16px;
  width: auto;
  flex-wrap: nowrap;
  color: ${({ theme, active }) =>
    active ? "rgba(255, 255, 255, 0.87)" : " rgba(255, 255, 255, 0.54)"};
  /* border-bottom: 1px solid rgba(255, 255, 255, 0.08); */
  font-size: 16px;
  font-weight: 700;
  cursor: pointer;
  position: relative;
  height: 100%;
  font-family: "Gilroy-bold";
  .size2 {
    padding: 2px 8px;
    background: rgba(255, 255, 255, 0.1);
    font-family: "Gilroy-bold";
    font-size: 14px;
    color: #ffffff;
  }
  &.active::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    background: #14d1b2;
    width: 100%;
    height: 2px;
  }
  @media (max-width: 700px) {
    padding: 0 8px;
    justify-content: center;
    width: 100%;
  }
`;

export default function ListTab({
  options,
  option,
  onChange,
  setOption,
  optionLabels,
}: dataPros) {
  const onClick = (opt: string) => {
    if (setOption) {
      setOption(opt);
    }
    if (onChange) {
      onChange(opt);
    }
  };

  return (
    <MyRow>
      {options?.map((item, index) => {
        return (
          <TradeRow
            className={cx({ active: item === option })}
            key={"trde_tablec" + index}
            onClick={() => onClick(item)}
            active={option === item}
          >
            {item}{" "}
            {item != "Trades" && (
              <div className="size2">{optionLabels[item] ?? 0}</div>
            )}
          </TradeRow>
        );
      })}
    </MyRow>
  );
}
