import { useMemo, useState } from "react";

import { addresses } from "../configs/constants";
import { PLACEHOLDER_ACCOUNT } from "src/helpers/Helpers";
import { useSingleCallResult } from "src/lib/hooks/multicall";
import {
  usePIDContract,
  useInfoHelperContract,
  useRewardRouterContract,
} from "./useContract";
import { useWeb3Context } from "./web3Context";
import { ethers } from "ethers";
import { toFromBN } from "src/utils/bn";
import { getUpToken } from "src/configs/Tokens";

export const useBasicInfo = (account?: string) => {
  const { address, chainID } = useWeb3Context();
  const InfoHelperContract = useInfoHelperContract();
  const { result } = useSingleCallResult(InfoHelperContract, "getBasicInfo", [
    addresses[chainID].PID,
    account ? account : address ? address : PLACEHOLDER_ACCOUNT,
  ]);
  return useMemo(() => {
    if (!result) return;
    const userInfoArr1 = result[0];
    const nickName = userInfoArr1[0];
    const refCode = userInfoArr1[1];
    const userInfoArr2 = result[2];
    const feeDiscount = userInfoArr2[0];
    const feeRebate = userInfoArr2[1];
    const userSizeSum = userInfoArr2[2];
    const score = userInfoArr2[9];
    const rank = userInfoArr2[10];
    const rankUpdateTime = userInfoArr2[15];
    return {
      nickName,
      refCode,
      feeDiscount: ethers.utils.formatUnits(feeDiscount, 6),
      feeRebate: ethers.utils.formatUnits(feeRebate, 6),
      userSizeSum: ethers.utils.formatUnits(userSizeSum, 30),
      score: ethers.utils.formatEther(score),
      rank,
      rankUpdateTime,
    };
  }, [result]);
};

export const useReferralCodeOwner = (refCode: string) => {
  const PIDContract = usePIDContract();
  const { result } = useSingleCallResult(PIDContract, "refCodeOwner", [
    refCode,
  ]);
  return useMemo(() => {
    if (!result) return;
    const data = result[0];
    return data;
  }, [result]);
};

export const useDefaultRefCode = () => {
  const { chainID } = useWeb3Context();
  const PIDContract = usePIDContract();
  const [refCode, setrefCode] = useState<any>();
  PIDContract?.getRefCode(addresses[chainID].PID)
    .then((res) => setrefCode(res))
    .catch((e) => console.log("Error", e));
  return refCode;
  // const { result, error } = useSingleCallResult(
  //   PIDContract,
  //   "getRefCode",
  //   addresses[chainID].PID
  // );
  // console.log("defaultRefCode", result, error, addresses[chainID].PID);
  // return useMemo(() => {
  //   if (!result) return;
  //   const data = result[0];
  //   return data;
  // }, [result]);
};

export const useClaimableInfo = () => {
  const { address: account, chainID } = useWeb3Context();
  const rewardRouterContract = useRewardRouterContract();
  const USDC = addresses[chainID].USDC;
  const token = getUpToken(chainID, USDC);

  const { result } = useSingleCallResult(
    rewardRouterContract,
    "claimablePIDFee",
    [account ? account : PLACEHOLDER_ACCOUNT]
  );

  return useMemo(() => {
    if (!result) return;
    return {
      rebate_fee: toFromBN(result[1][0], token.decimals),
    };
  }, [result, token.decimals]);
};
