import {
  BLOCKEXPLOERER_URLS,
  RPC_URLS,
  CHAINNAME_LIST,
} from "./chainENV/chains.base";
import {
  SUPPORTED_CHAIN_IDS_MAP as SUPPORTED_CHAIN_IDS_MAP_dev,
  SUPPORTED_CHAIN_IDS as SUPPORTED_CHAIN_IDS_dev,
} from "./chainENV/chains.dev";
import {
  SUPPORTED_CHAIN_IDS_MAP as SUPPORTED_CHAIN_IDS_MAP_all,
  SUPPORTED_CHAIN_IDS as SUPPORTED_CHAIN_IDS_all,
} from "./chainENV/chains.all";
import {
  SUPPORTED_CHAIN_IDS_MAP as SUPPORTED_CHAIN_IDS_MAP_prod,
  SUPPORTED_CHAIN_IDS as SUPPORTED_CHAIN_IDS_prod,
} from "./chainENV/chains.prod";

let SupportedChainId = SUPPORTED_CHAIN_IDS_MAP_all;
let SUPPORTED_CHAIN_IDS = SUPPORTED_CHAIN_IDS_all;

if (process.env.REACT_APP_NEV_NAME === "development") {
  SupportedChainId = SUPPORTED_CHAIN_IDS_MAP_dev;
  SUPPORTED_CHAIN_IDS = SUPPORTED_CHAIN_IDS_dev;
} else if (process.env.REACT_APP_NEV_NAME === "prod") {
  SupportedChainId = SUPPORTED_CHAIN_IDS_MAP_prod;
  SUPPORTED_CHAIN_IDS = SUPPORTED_CHAIN_IDS_prod;
}
export const NETWORK_LIST = SUPPORTED_CHAIN_IDS.map((Id) => ({
  chainId: Id,
  chainName: CHAINNAME_LIST[Id].chainName,
  nativeCurrency: CHAINNAME_LIST[Id].nativeCurrency,
  icon: CHAINNAME_LIST[Id].icon,
  rpcUrl: RPC_URLS[Id],
  blockExplorerUrl: BLOCKEXPLOERER_URLS[Id],
}));

export {
  BLOCKEXPLOERER_URLS,
  RPC_URLS,
  SupportedChainId,
  SUPPORTED_CHAIN_IDS,
  CHAINNAME_LIST,
};
