import { useEffect, useState } from "react";
import { calTime2 } from "src/helpers";
import { useBasicInfo } from "src/hooks/useQueryPID";

export default function CountDown() {
  const baseInfo = useBasicInfo();
  const [coundown, setCoundown] = useState<any>({
    day: "00",
    hour: "00",
    minutes: "00",
  });

  useEffect(() => {
    let interval;
    if (baseInfo?.rankUpdateTime > 0) {
      interval = setInterval(() => {
        const nowDate = new Date().getTime();
        const endTime: number =
          Number(baseInfo?.rankUpdateTime) - nowDate / 1000;
        if (endTime > 0) {
          setCoundown(calTime2(endTime));
        }
      }, 1000);
    }

    return () => {
      clearInterval(interval);
    };
  }, [baseInfo]);

  return (
    <div className="coundown">
      Update in: {coundown.day}d {coundown.hour}h {coundown.minutes}m
    </div>
  );
}
