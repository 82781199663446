import { Trans } from "@lingui/macro";
import { Box, Link, Skeleton } from "@mui/material";
import { ThemedText } from "src/theme/text";
import styled from "styled-components/macro";
import { ReactComponent as PIKO } from "src/assets/images/PIKO.svg";
import { ReactComponent as EsPIKO } from "src/assets/images/esPIKO.svg";
import { ReactComponent as USDC } from "src/assets/images/tokens/USDC.svg";
import { formatAmount2 } from "src/helpers/Helpers";
import { useGetPikoPrice } from "src/hooks/get_price_piko";
import { useSelector } from "react-redux";
import { BuyBtn } from "src/components/Button/BuyBtn";
import { ClaimBtn } from "src/components/Button/ClaimBtn";
import {
  useBalance_PIKO,
  useBalance_esPIKO,
  useBalance_usdc,
  useStaked_PIKO,
  useStaked_esPIKO,
} from "src/hooks/useQueryEarn";
import { NavLink } from "react-router-dom";
import { BUY_PIKO_URL } from "src/constants/misc";
import { useWeb3Context } from "src/hooks";

export const AssetBox = styled(Box)`
  margin-top: 32px;
  .assetHeader,
  .assetItem {
    .td {
      margin-left: 12px;
      :last-child {
        margin-right: 12px;
      }
    }
  }
  .assetHeader,
  .assetHeader-m {
    height: 40px;
  }
  .assetItem {
    padding: 18px 0;
  }
  /* .assetHeader,
  .assetHeader-m, */
  .assetItem,
  .assetItem-m {
    border-top: 1px solid ${({ theme }) => theme.bg4};
  }
`;

export default function AssetList() {
  const PIKOPrice = useGetPikoPrice();
  const usdcBalance = useBalance_usdc();
  const PIKOBalance = useBalance_PIKO();
  const PIKOStaked = useStaked_PIKO();
  const esPIKOBalance = useBalance_esPIKO();
  const esPIKOStaked = useStaked_esPIKO();
  const tokensArr = useSelector((state: any) => {
    return state?.app?.tokensArr || [];
  });
  const { chainID } = useWeb3Context();
  const handlerBuy = () => {
    chainID == 324 && window.open(BUY_PIKO_URL[chainID]);
  };
  return (
    <AssetBox className="assetList">
      <Box display="flex" alignItems="center" className="assetHeader">
        <ThemedText.Text4Bold className="flex-1 td">
          <Trans>Asset</Trans>
        </ThemedText.Text4Bold>
        <Box width="200px" className="text-right td">
          <ThemedText.Text4Bold>
            <Trans>Total</Trans>
          </ThemedText.Text4Bold>
        </Box>
        <Box width="200px" className="text-right td">
          <ThemedText.Text4Bold>
            <Trans>Wallet</Trans>
          </ThemedText.Text4Bold>
        </Box>
        <Box width="200px" className="text-right td">
          <ThemedText.Text4Bold>
            <Trans>Staked</Trans>
          </ThemedText.Text4Bold>
        </Box>
        <div className="flex-1 td"></div>
      </Box>
      <Box display="flex" alignItems="center" className="assetItem">
        <Box display="flex" alignItems="center" className="flex-1 td">
          <PIKO width={48} height={48} />
          <ThemedText.Text1Bold fontSize={24} ml="16px">
            <Trans>PIKO</Trans>
          </ThemedText.Text1Bold>
        </Box>
        <Box width="200px" className="text-right td">
          <ThemedText.Text1Medium fontSize={16}>
            {formatAmount2(
              PIKOBalance?.plus(PIKOStaked?.amount ? PIKOStaked?.amount : 0),
              2,
              true
            )}
          </ThemedText.Text1Medium>
          <ThemedText.Text5 fontSize={12} mt="8px">
            $
            {formatAmount2(
              PIKOBalance?.plus(
                PIKOStaked?.amount ? PIKOStaked?.amount : 0
              ).times(PIKOPrice ? PIKOPrice : 1),
              2,
              true
            )}
          </ThemedText.Text5>
        </Box>
        <Box width="200px" className="text-right td">
          <ThemedText.Text1Medium fontSize={16}>
            {formatAmount2(PIKOBalance, 2, true)}
          </ThemedText.Text1Medium>
        </Box>
        <Box width="200px" className="text-right td">
          <ThemedText.Text1Medium fontSize={16}>
            {formatAmount2(PIKOStaked?.amount, 2, true)}
          </ThemedText.Text1Medium>
        </Box>
        <Box display="flex" justifyContent="flex-end" className="flex-1 td">
          <Link component={NavLink} to={`/Earn?curr=${0}`}>
            <Box width="120px">
              <BuyBtn>Earn</BuyBtn>
            </Box>
          </Link>
          <Box width="120px" ml="16px">
            <ClaimBtn onClick={handlerBuy}>Buy / Sell</ClaimBtn>
          </Box>
        </Box>
      </Box>
      <Box display="flex" alignItems="center" className="assetItem">
        <Box display="flex" alignItems="center" className="flex-1 td">
          <EsPIKO width={48} height={48} />
          <ThemedText.Text1Bold fontSize={24} ml="16px">
            <Trans>esPIKO</Trans>
          </ThemedText.Text1Bold>
        </Box>
        <Box width="200px" className="text-right td">
          <ThemedText.Text1Medium fontSize={16}>
            {formatAmount2(
              esPIKOBalance?.plus(
                esPIKOStaked?.amount ? esPIKOStaked?.amount : 0
              ),
              2,
              true
            )}
          </ThemedText.Text1Medium>
          <ThemedText.Text5 fontSize={12} mt="8px">
            $
            {formatAmount2(
              esPIKOBalance
                ?.plus(esPIKOStaked?.amount ? esPIKOStaked?.amount : 0)
                .times(PIKOPrice ? PIKOPrice : 1),
              2,
              true
            )}
          </ThemedText.Text5>
        </Box>
        <Box width="200px" className="text-right td">
          <ThemedText.Text1Medium fontSize={16}>
            {formatAmount2(esPIKOBalance, 2, true)}
          </ThemedText.Text1Medium>
        </Box>
        <Box width="200px" className="text-right td">
          <ThemedText.Text1Medium fontSize={16}>
            {formatAmount2(esPIKOStaked?.amount, 2, true)}
          </ThemedText.Text1Medium>
        </Box>
        <Box display="flex" justifyContent="flex-end" className="flex-1 td">
          <Link component={NavLink} to={`/Earn?curr=${0}`}>
            <Box width="120px">
              <BuyBtn>Earn</BuyBtn>
            </Box>
          </Link>
          <Box width="120px" ml="16px">
            <ClaimBtn disabled={true}>Buy / Sell</ClaimBtn>
          </Box>
        </Box>
      </Box>
      {tokensArr?.length > 0 ? (
        tokensArr?.map((item, i) => (
          <Box display="flex" alignItems="center" className="assetItem" key={i}>
            <Box display="flex" alignItems="center" className="flex-1 td">
              <img src={item?.icon} width={48} height={48} />
              <ThemedText.Text1Bold fontSize={24} ml="16px">
                {item?.name}
              </ThemedText.Text1Bold>
            </Box>
            <Box width="200px" className="text-right td">
              <ThemedText.Text1Medium fontSize={16}>
                {formatAmount2(
                  item?.plpBalance * 1 + item?.plpBalanceStaked * 1,
                  2,
                  true
                )}
              </ThemedText.Text1Medium>
              <ThemedText.Text5 fontSize={12} mt="8px">
                $
                {formatAmount2(
                  (item?.plpBalance * 1 + item?.plpBalanceStaked * 1) *
                    item?.price,
                  2,
                  true
                )}
              </ThemedText.Text5>
            </Box>
            <Box width="200px" className="text-right td">
              <ThemedText.Text1Medium fontSize={16}>
                {formatAmount2(item?.plpBalance, 2, true)}
              </ThemedText.Text1Medium>
            </Box>
            <Box width="200px" className="text-right td">
              <ThemedText.Text1Medium fontSize={16}>
                {formatAmount2(item?.plpBalanceStaked, 2, true)}
              </ThemedText.Text1Medium>
            </Box>
            <Box display="flex" justifyContent="flex-end" className="flex-1 td">
              <Link component={NavLink} to={`/Earn?curr=${1}`}>
                <Box width="120px">
                  <BuyBtn>Earn</BuyBtn>
                </Box>
              </Link>
              <Box width="120px" ml="16px">
                <Link component={NavLink} to={`/Earn?curr=${1}`}>
                  <ClaimBtn>Buy / Sell</ClaimBtn>
                </Link>
              </Box>
            </Box>
          </Box>
        ))
      ) : (
        <div className="flex-1">
          <Skeleton width="100%" height={60} style={{ borderRadius: "10px" }} />
        </div>
      )}
      <Box display="flex" alignItems="center" className="assetItem">
        <Box display="flex" alignItems="center" className="flex-1 td">
          <USDC width={48} height={48} />
          <ThemedText.Text1Bold fontSize={24} ml="16px">
            <Trans>USDC</Trans>
          </ThemedText.Text1Bold>
        </Box>
        <Box width="200px" className="text-right td">
          <ThemedText.Text1Medium fontSize={16}>
            {formatAmount2(usdcBalance, 2, true)}
          </ThemedText.Text1Medium>
          <ThemedText.Text5 fontSize={12} mt="8px">
            ${formatAmount2(usdcBalance, 2, true)}
          </ThemedText.Text5>
        </Box>
        <Box width="200px" className="text-right td">
          <ThemedText.Text1Medium fontSize={16}>
            {formatAmount2(usdcBalance, 2, true)}
          </ThemedText.Text1Medium>
        </Box>
        <Box width="200px" className="text-right td">
          <ThemedText.Text1Medium fontSize={16}>
            {formatAmount2(0, 2, true)}
          </ThemedText.Text1Medium>
        </Box>
        <Box display="flex" justifyContent="flex-end" className="flex-1 td">
          <></>
        </Box>
      </Box>
    </AssetBox>
  );
}
