import { Box } from "@mui/material";
import Tooltips from "src/components/Tooltips/totalTips";
import { TooltipContentBox } from "./LevelTooltip";
import { TipsIconBox } from "src/components/TipsIcon";

function TooltipContent() {
  return (
    <TooltipContentBox>
      <Box
        display="flex"
        alignItems={"center"}
        className="rankHead font-weight-b"
      >
        <Box display="flex" width="240px" pl="8px" className="">
          Action
        </Box>
        <div className="rankLine"></div>
        <Box pl="8px" width="100px" className="">
          Volume(USD)
        </Box>
        <div className="rankLine"></div>
        <Box pl="8px" width="80px" className="">
          + Points
        </Box>
      </Box>
      <div className="rankListTips">
        <Box display="flex" alignItems={"center"} className="rankItem">
          <Box display="flex" width="240px" pl="8px" className="font-weight-b">
            Margin trading (by yourself)
          </Box>
          <div className="rankLine"></div>
          <Box pl="8px" width="100px" className="">
            100
          </Box>
          <div className="rankLine"></div>
          <Box pl="8px" width="80px" className="">
            1
          </Box>
        </Box>
        <Box display="flex" alignItems={"center"} className="rankItem">
          <Box display="flex" width="240px" pl="8px" className="font-weight-b">
            Swap (by yourself)
          </Box>
          <div className="rankLine"></div>
          <Box pl="8px" width="100px" className="">
            100
          </Box>
          <div className="rankLine"></div>
          <Box pl="8px" width="80px" className="">
            1
          </Box>
        </Box>
        <Box display="flex" alignItems={"center"} className="rankItem">
          <Box display="flex" width="240px" pl="8px" className="font-weight-b">
            Mint/Redeem PLP (by yourself)
          </Box>
          <div className="rankLine"></div>
          <Box pl="8px" width="100px" className="">
            100
          </Box>
          <div className="rankLine"></div>
          <Box pl="8px" width="80px" className="">
            1
          </Box>
        </Box>
        <Box display="flex" alignItems={"center"} className="rankItem">
          <Box display="flex" width="240px" pl="8px" className="font-weight-b">
            Create PID (by Invitees)
          </Box>
          <div className="rankLine"></div>
          <Box pl="8px" width="100px" className="">
            N/A
          </Box>
          <div className="rankLine"></div>
          <Box pl="8px" width="80px" className="">
            5
          </Box>
        </Box>
        <Box display="flex" alignItems={"center"} className="rankItem">
          <Box display="flex" width="240px" pl="8px" className="font-weight-b">
            Margin trading (by Invitees)
          </Box>
          <div className="rankLine"></div>
          <Box pl="8px" width="100px" className="">
            100
          </Box>
          <div className="rankLine"></div>
          <Box pl="8px" width="80px" className="">
            1
          </Box>
        </Box>
        <Box display="flex" alignItems={"center"} className="rankItem">
          <Box display="flex" width="240px" pl="8px" className="font-weight-b">
            Swap (by Invitees)
          </Box>
          <div className="rankLine"></div>
          <Box pl="8px" width="100px" className="">
            100
          </Box>
          <div className="rankLine"></div>
          <Box pl="8px" width="80px" className="">
            1
          </Box>
        </Box>
        <Box display="flex" alignItems={"center"} className="rankItem">
          <Box display="flex" width="240px" pl="8px" className="font-weight-b">
            Mint/Redeem PLP (by Invitees)
          </Box>
          <div className="rankLine"></div>
          <Box pl="8px" width="100px" className="">
            100
          </Box>
          <div className="rankLine"></div>
          <Box pl="8px" width="80px" className="">
            1
          </Box>
        </Box>
      </div>
    </TooltipContentBox>
  );
}

export default function PointsTooltip() {
  return (
    <>
      <Tooltips
        position="bottom"
        handleClassName=""
        renderContent={<TooltipContent />}
        width="auto"
        padding="0"
      >
        <TipsIconBox />
      </Tooltips>
    </>
  );
}
