// import { Box } from "@mui/material";
import { ReactComponent as LongImg } from "../../../assets/images/trade/long.svg";
import { AutoRow, RowBetween } from "../../Row";
import { ReactComponent as ShortImg } from "../../../assets/images/trade/short.svg";
import { ReactComponent as SwapImg } from "../../../assets/images/trade/swap.svg";
import styled from "styled-components/macro";
import cx from "classnames";
import { STOP } from "src/helpers/Helpers";
type dataPros = {
  options: string[];
  val?: number | string;
  option?: string;
  setOption?: (value: string) => void;
  onChange?: (value: string) => void;
  bgColor?: string;
  className?: string;
  isDialog?: boolean;
};

const TradeRow = styled(AutoRow)<{
  active?: boolean;
  bgColor?: string;
  isDialog?: boolean;
}>`
  justify-content: center;
  align-items: center;
  padding: 14px 0;
  gap: 8px;
  color: ${({ theme, active }) =>
    active ? "rgba(255, 255, 255, 0.87)" : " rgba(255, 255, 255, 0.54)"};
  border-bottom: 1px solid rgba(255, 255, 255, 0.16);
  font-size: 16px;
  font-weight: 700;
  cursor: pointer;
  /* background: ${({ theme, active, isDialog }) =>
    active && !isDialog ? "#161616" : "none"}; */
  position: relative;

  &.dialogTabBorder {
    border-top: 1px solid rgba(255, 255, 255, 0.08);
    border-left: 1px solid rgba(255, 255, 255, 0.16);
  }

  &:last-child {
    &.dialogTabBorder {
      border-right: 1px solid rgba(255, 255, 255, 0.16);
    }
  }

  .icons {
    > path {
      fill: rgba(255, 255, 255, 0.54);
    }
  }
  &.active::after {
    content: "";
    position: absolute;
    top: 0;
    height: 2px;
    width: 100%;
    background: ${({ bgColor }) => bgColor ?? "#fa5341"};
  }

  &.tradeActive {
    border-bottom: 1px solid #161616;
  }

  &.dialogActive {
    border-bottom: none;
  }

  &.active {
    .icons {
      > path {
        fill: #14d1b2;
      }
    }
    &.tradeLong {
      background: #161616;
      border-left: none;

      &::after {
        background: #14d1b2;
      }
    }

    &.tradeShort {
      background: #161616;
      .icons {
        > path {
          fill: rgba(250, 83, 65, 1);
        }
      }
      &::after {
        background: #fa5341;
      }
    }

    &.tradeSwap {
      background: #161616;
      &::after {
        background: #f0b90b;
      }
      .icons {
        > path {
          fill: #f0b90b;
        }
      }
    }
  }
  &.closes {
    background: ${({ theme, active }) => (active ? "#1A191E" : "none")};
    border-bottom: 1px solid rgba(255, 255, 255, 0.08);
    &.active {
      border-bottom: 1px solid #1a191e;
    }
  }
  &.tradeShort {
    border-left: 1px solid rgba(255, 255, 255, 0.16);
    border-right: 1px solid rgba(255, 255, 255, 0.16);
  }
`;

const SWAP_ICONS = {
  Long: <LongImg className="icons" />,
  Short: <ShortImg className="icons" />,
  Swap: <SwapImg className="icons" />,
};

export default function TradeTable({
  options,
  option,
  onChange,
  setOption,
  bgColor,
  isDialog,
  ...props
}: dataPros) {
  const onClick = (opt: string) => {
    if (setOption) {
      setOption(opt);
    }
    if (onChange) {
      onChange(opt);
    }
  };

  return (
    <RowBetween {...props}>
      {options?.map((item, index) => {
        return (
          <TradeRow
            bgColor={bgColor}
            className={cx(
              `trade${item}  ${isDialog && "dialogTabBorder"}`,
              {
                active: item === option,
              },
              { tradeActive: item === option && !isDialog },
              {
                dialogActive: item === option && isDialog,
              }
            )}
            key={"trade_table" + index}
            onClick={() => onClick(item)}
            active={option === item}
          >
            {SWAP_ICONS[item]}
            {item == STOP ? "Trigger" : item}
          </TradeRow>
        );
      })}
    </RowBetween>
  );
}
