import { Trans } from "@lingui/macro";
import { Box, Dialog } from "@mui/material";

import { ThemedText } from "src/theme/text";
import { ReactComponent as Close } from "src/assets/images/close.svg";
import styled from "styled-components";
import React from "react";

const DialogDiv = styled(Dialog)<{
  width?: string;
  bgColor?: string;
}>`
  .MuiDialog-paper {
    width: ${({ width }) => (width ? width : "700px")};
    max-width: 1000px;
    background: ${({ bgColor }) => (bgColor ? bgColor : "#1A191E")};
    border: 1px solid ${({ theme }) => theme.bg4};
    border-radius: 8px;
  }
  .header {
    padding: 0 16px;
    height: 60px;
    background: ${({ theme }) => theme.bg1};
  }
  .info {
    border: 1px solid ${({ theme }) => theme.bg3};
    border-radius: 8px;
    .infoItem {
      :first-child {
        .infoTitle {
          border-radius: 8px 8px 0 0;
        }
      }
      :last-child {
        border-radius: 0 0 8px 8px;
      }
      .infoVal {
        padding-left: 16px;
      }

      .infoVal {
        height: 48px;
      }
    }
  }
  .infoTitle {
    height: 40px;
    padding-left: 16px;
    background: ${({ theme }) => theme.bg1};
  }
  .summaryInfo {
    border: 1px solid ${({ theme }) => theme.bg3};
    border-radius: 8px;
    .infoTitle {
      border-radius: 8px 8px 0 0;
    }
    .infoItem {
      :not(:last-child) {
        border-bottom: 1px dashed ${({ theme }) => theme.bg3};
      }
    }
  }
  .infoBox {
    border: 1px solid ${({ theme }) => theme.bg3};
    border-radius: 8px;
    .infoTitle,
    .infoVal {
      grid-gap: 16px;
      grid-template-columns: repeat(2, 1fr);
    }
    .infoTitle {
      border-radius: 8px 8px 0 0;
      height: 40px;
      padding-left: 0;
      background: ${({ theme }) => theme.bg1};
    }
    .infoVal {
      height: 48px;
    }
  }
  @media only screen and (max-width: 1080px) {
    .MuiDialog-paper {
      max-width: 90vw;
    }
  }
`;
type DialogPros = {
  width?: string;
  bgColor?: string;
  icon?: any;
  title: string;
  open: boolean;
  handleClose: () => void;
  children: React.ReactElement;
};

export default function DialogBox({
  width,
  bgColor,
  icon,
  title,
  open,
  handleClose,
  children,
}: DialogPros) {
  return (
    <DialogDiv
      width={width}
      bgColor={bgColor}
      onClose={handleClose}
      open={open}
      aria-labelledby="customized-dialog-title"
    >
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        className="header"
      >
        <Box display="flex" alignItems="center">
          {icon}
          <ThemedText.Text9Bold fontSize={18} ml={icon ? "10px" : ""}>
            <Trans>{title}</Trans>
          </ThemedText.Text9Bold>
        </Box>
        <div className="pointer" onClick={handleClose}>
          <Close />
        </div>
      </Box>
      <div className="content">{children}</div>
    </DialogDiv>
  );
}
