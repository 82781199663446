import { SupportedChainId } from "./chainENV/chains.base";
export type AddressMap = { [chainId: number]: string };
export const AddressZero = "0x0000000000000000000000000000000000000000";

export const MULTICALL_ADDRESS: AddressMap = {
  [SupportedChainId.ARB_TEST]: "0x259D00Aab414B0e12E13b027Ef317204759C78Eb",
  [SupportedChainId.BSCTEST]: "0x87648372948f8e1a83da5e92c01afd8b6cee73a3",
  [SupportedChainId.ARBITRUM]: "0xB6AA4C228Df4f96DFA269a5D10658f24D59E4C30",
  [SupportedChainId.BSCMAINNET]: "0x67320a56e188b7b1d578ec10b7665b7a21a3ebc2",
  [SupportedChainId.ZKMAINNET]: "0x62610509577ceCe9C30aE7BeE3d8871aB9FF57e1",
  [SupportedChainId.ZKTEST]: "0x5071798Fa6E6D3560A9Ce0c8541C28d9b653D2eA",
  [SupportedChainId.MANTLE_TEST]: "0x76e4b6585AE7934F27b807fa4B1EbD25199D5061",
  [SupportedChainId.MANTLE]: "0x5E497fa2b145cdd7258C36275721dd464A90fe67",
};
