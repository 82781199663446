import { useMemo } from "react";
import { PLACEHOLDER_ACCOUNT } from "src/helpers/Helpers";
import { useWeb3Context } from "src/hooks";
import { useInviteToEarnContract } from "src/hooks/useContract";
import { useSingleCallResult } from "src/lib/hooks/multicall";
import { toFromBN } from "src/utils/bn";

export function useQuotaInformation() {
  const { address: account } = useWeb3Context();
  const inviteContract = useInviteToEarnContract();
  const { result, error } = useSingleCallResult(inviteContract, "quota", [
    account ? account : PLACEHOLDER_ACCOUNT,
  ]);
  // inviteContract
  //   ?.quota(account ? account : PLACEHOLDER_ACCOUNT)
  //   ?.then((res) => console.log(res))
  //   .catch((error) => console.log(error));
  // console.log("result", result, inviteContract, account, error);
  return useMemo(() => {
    if (!result || !result[0]) return;
    const withdrawAmount = toFromBN(result[0][2]);
    const pendingAmount = toFromBN(result[0][0])
      .minus(toFromBN(result[0][4]))
      .minus(withdrawAmount);
    // console.log("pendingAmount", pendingAmount?.toFixed(), result);
    return {
      withdrawAmount: withdrawAmount,
      pendingAmount: pendingAmount,
    };
  }, [result]);
}
