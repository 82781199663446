import { Button } from "@mui/material";
import { styled } from "@mui/material/styles";

export const MenuBtn = styled(Button)({
  height: "48px",
  lineHeight: "48px",
  maxHeight: "48px",
  width: "auto",
  color: "rgba(255, 255, 255, 0.54)",
  fontSize: 16,
  fontFamily: "Gilroy-Medium",
  textTransform: "initial",
  justifyContent: "flex-start",
  padding: "0",
  marginTop: "12px",
  background: "transparent",
  border: "none",
  borderRadius: "4px",
  "&:hover": {
    background: "rgba(255, 255, 255, 0.1)",
  },
  svg: {
    path: {
      fill: "rgba(255, 255, 255, 0.54)",
    },
  },
  ".tabItem": {
    width: "100%",
    padding: "0 12px",
  },
  ".activeTabItem": {
    width: "100%",
    background: "rgba(20, 209, 178, 0.2)",
    borderRadius: "4px",
    svg: {
      path: {
        fill: "rgba(255, 255, 255, 1)",
      },
    },
  },
  "@media only screen and (max-width: 1280px)": {
    width: "100%",
    marginTop: "0",
  },
  "@media only screen and (max-width: 680px)": {
    ".tabItem": {
      padding: "0 8px",
    },
  },
});
