import { Box } from "@material-ui/core";
import { Drawer, Link } from "@mui/material";
import { styled } from "@mui/material/styles";
import { ThemedText } from "src/theme/text";
import { ReactComponent as LogoIcon } from "src/assets/images/logo.svg";
import { ReactComponent as DashboardIcon } from "src/assets/images/tabMenu/Dashboard.svg";
import { ReactComponent as PIDIcon } from "src/assets/images/tabMenu/PID.svg";
import { ReactComponent as BridgeIcon } from "src/assets/images/tabMenu/bridge.svg";
import { ReactComponent as TradeIcon } from "src/assets/images/tabMenu/Trade.svg";
import { ReactComponent as EarnIcon } from "src/assets/images/tabMenu/Earn.svg";
import { ReactComponent as LeaderBoardIcon } from "src/assets/images/tabMenu/LeaderBoard.svg";
import { ReactComponent as DaoIcon } from "src/assets/images/tabMenu/DAO.svg";
import { ReactComponent as DocsIcon } from "src/assets/images/links/docs.svg";
import { ReactComponent as StatsIcon } from "src/assets/images/links/stats.svg";
import { ReactComponent as SettingIcon } from "src/assets/images/links/setting.svg";
import { NavLink } from "react-router-dom";
import PriceM from "./PriceM";
import { useWeb3Context } from "src/hooks";
import { DOC_LINK } from "src/constants/misc";
import SettingDialog from "../SettingDialog";

const DrawerBox = styled(Drawer)`
  .MuiDrawer-paper {
    background: #282828;
    width: 90vw;
  }
  a {
    text-decoration: none;
  }
  .logo-header {
    padding-left: 18px;
    height: 60px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.16);
  }
  .priceBox {
    padding: 16px 0;
    border-bottom: 1px solid rgba(255, 255, 255, 0.16);
    .priceBorder {
      border-top: 1px solid rgba(255, 255, 255, 0.16);
      border-bottom: 1px solid rgba(255, 255, 255, 0.16);
    }
  }
  .menuList-m {
    padding: 4px 16px;
    .menuItem {
      padding: 12px 0;
      border-bottom: 1px dashed rgba(255, 255, 255, 0.08);
    }
  }
  .links-m {
    margin-bottom: 24px;
    .linkItem {
      margin-left: 32px;
    }
  }
`;

export const DrawerContainer = ({
  showDrawer,
  toggleDrawer,
  openSettings,
  showSetting,
  handleClose,
  showPnlAfterFees,
  setShowPnlAfterFees,
  isPnlInLeverage,
  setIsPnlInLeverage,
  saveAndCloseSettings,
  slippageAmount,
  onInputValueChange,
}: any) => {
  const { chainID } = useWeb3Context();
  return (
    <DrawerBox anchor={"left"} open={showDrawer} onClose={toggleDrawer}>
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        style={{ height: "100%" }}
      >
        <div className="flex-1">
          <Box display="flex" alignItems="center" className="logo-header ">
            <LogoIcon />
          </Box>
          <div className="priceBox">
            <div className="priceBorder">
              <PriceM />
            </div>
          </div>
          <div className="menuList-m">
            <Link component={NavLink} to={`/`} onClick={toggleDrawer}>
              <Box display="flex" alignItems="center" className="menuItem">
                <DashboardIcon />
                <ThemedText.Text5Medium fontSize={18} ml="8px">
                  Dashboard
                </ThemedText.Text5Medium>
              </Box>
            </Link>
            <Link component={NavLink} to={`/PID`} onClick={toggleDrawer}>
              <Box display="flex" alignItems="center" className="menuItem">
                <PIDIcon />
                <ThemedText.Text5Medium fontSize={18} ml="8px">
                  PID
                </ThemedText.Text5Medium>
              </Box>
            </Link>
            <Link component={NavLink} to={`/Trade`} onClick={toggleDrawer}>
              <Box display="flex" alignItems="center" className="menuItem">
                <TradeIcon />
                <ThemedText.Text5Medium fontSize={18} ml="8px">
                  Trade
                </ThemedText.Text5Medium>
              </Box>
            </Link>
            {chainID != 5001 && (
              <Link component={NavLink} to={`/Earn`} onClick={toggleDrawer}>
                <Box display="flex" alignItems="center" className="menuItem">
                  <EarnIcon />
                  <ThemedText.Text5Medium fontSize={18} ml="8px">
                    Earn
                  </ThemedText.Text5Medium>
                </Box>
              </Link>
            )}
            <Link component={NavLink} to={`/Dao`} onClick={toggleDrawer}>
              <Box display="flex" alignItems="center" className="menuItem">
                <DaoIcon />
                <ThemedText.Text5Medium fontSize={18} ml="8px">
                  Dao
                </ThemedText.Text5Medium>
              </Box>
            </Link>
            <Link
              component={NavLink}
              to={`/Leaderboard`}
              onClick={toggleDrawer}
            >
              <Box display="flex" alignItems="center" className="menuItem">
                <LeaderBoardIcon />
                <ThemedText.Text5Medium fontSize={18} ml="8px">
                  Leaderboard
                </ThemedText.Text5Medium>
              </Box>
            </Link>
            <a
              href="https://www.orbiter.finance/?dest=Arbitrum&source=Ethereum"
              target="_blank"
              className={``}
              rel="noreferrer"
            >
              <Box display="flex" alignItems="center" className="menuItem">
                <BridgeIcon />
                <ThemedText.Text5Medium fontSize={18} ml="8px">
                  Bridge
                </ThemedText.Text5Medium>
              </Box>
            </a>
            {/* <Box display="flex" alignItems="center" className="menuItem" 
                onClick={toggleDrawer}>
          <MoreIcon />
          <ThemedText.Text5Medium fontSize={18} ml="8px">
            More
          </ThemedText.Text5Medium>
        </Box> */}
          </div>
        </div>
        <Box display="flex" alignItems="center" className="links-m">
          <a
            href={DOC_LINK.documentation}
            target="_blank"
            className="linkItem"
            rel="noreferrer"
          >
            <Box display="flex" alignItems="center" className="">
              <DocsIcon />
              <ThemedText.Text4Medium fontSize={14} ml="8px">
                Docs
              </ThemedText.Text4Medium>
            </Box>
          </a>
          <a
            href={DOC_LINK.stats}
            target="_blank"
            className="linkItem"
            rel="noreferrer"
          >
            <Box display="flex" alignItems="center" className="">
              <StatsIcon />
              <ThemedText.Text4Medium fontSize={14} ml="8px">
                Stats
              </ThemedText.Text4Medium>
            </Box>
          </a>
          <Box
            display="flex"
            alignItems="center"
            className="linkItem"
            onClick={() => {
              toggleDrawer();
              openSettings();
            }}
          >
            <SettingIcon />
            <ThemedText.Text4Medium fontSize={14} ml="8px">
              Setting
            </ThemedText.Text4Medium>
          </Box>
        </Box>
      </Box>
      <SettingDialog
        open={showSetting}
        handleClose={handleClose}
        showPnlAfterFees={showPnlAfterFees}
        setShowPnlAfterFees={setShowPnlAfterFees}
        isPnlInLeverage={isPnlInLeverage}
        setIsPnlInLeverage={setIsPnlInLeverage}
        saveAndCloseSettings={saveAndCloseSettings}
        slippageAmount={slippageAmount}
        onInputValueChange={onInputValueChange}
      />
    </DrawerBox>
  );
};
